import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { ShippingData } from '@obeta/models/lib/models/ShoppingCart/ShippingData'

export function isPickup(shippingData: ShippingData) {
  return (
    shippingData.shippingType === ShippingType.DefaultPickup ||
    shippingData.shippingType === ShippingType.ExpressPickup ||
    shippingData.shippingType === ShippingType.PickupRenzbox
  )
}
