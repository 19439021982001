import { useState, useEffect, useMemo, useContext } from 'react'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import {
  ArticleSearchParams,
  Facet,
  FacetElement,
  FacetsStats,
} from '@obeta/models/lib/models/Search'
import { gql, useApolloClient } from '@apollo/client'
import React from 'react'
import { validateContextValue } from '@obeta/utils/lib/validateContextValue'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { WAREHOUSE_FACETS } from '@obeta/utils/lib/getFacetByWarehouseId'
export interface ArticleSearchFilter {
  suppliers: FacetElement[]
  etimClasses: FacetElement[]
  etimAttributes: Facet[]
  hitCount: number
  listPriceMin: number
  listPriceMax: number
}

const initialFilters: ArticleSearchFilter = {
  suppliers: [],
  hitCount: 0,
  etimClasses: [],
  etimAttributes: [],
  listPriceMin: 0,
  listPriceMax: 0,
}

export const articleSearchFilterQuery = gql`
  query searchToFilterProducts(
    $searchString: String
    $hitsPerPage: Int
    $filter: String
    $page: Int
    $facets: [String!]
  ) {
    searchProducts(
      searchString: $searchString
      requestOptions: { facets: $facets, hitsPerPage: $hitsPerPage, filters: $filter, page: $page }
    ) {
      nbHits
      nbPages
      facets {
        name
        values {
          value
          count
          meta
        }
        meta
      }
      facetsStats {
        name
        min
        max
      }
      queryId
    }
  }
`

export const oldFacetTypeNames = {
  alphanumerisch: 'string',
  numerisch: 'number',
  Range: 'range',
  logisch: 'bool',
}

/**
 * using searchParams request and build available etimClasses (classes of features product can have),
 *  etimAttributes (features product can have) and ...
 * @param searchParams
 * @returns
 */
export const useArticleSearchFilter = (searchParams?: ArticleSearchParams | null) => {
  const [searchFilter, setSearchFilter] = useState<ArticleSearchFilter>(initialFilters)
  const [loading, setLoading] = useState(false)
  const { warehouseId } = useWarehouseContext()

  const client = useApolloClient()

  useEffect(() => {
    let unmounted = false

    if (!searchParams) {
      return
    }

    const doSearch = async () => {
      setLoading(true)
      const facets = ['type', ...WAREHOUSE_FACETS, 'zEtimData.class.id', 'listPrice']

      if (searchParams.selectedEtim?.id && searchParams.selectedEtim.id !== '0') {
        facets.push(`zEtimData.features.${searchParams.selectedEtim?.id}.*`)
      }
      searchParams.etim?.forEach((el) => {
        if (el.facet) {
          facets.push(el.facet.name)
        }
      })

      const response = await client.query({
        query: articleSearchFilterQuery,
        variables: {
          searchString: searchParams.searchString,
          hitsPerPage: 0,
          filter: buildFilterParams(searchParams, warehouseId),
          facets: facets,
        },
      })

      if (unmounted) {
        return
      }

      if (response.data?.searchProducts) {
        const facets: Facet[] = response.data?.searchProducts.facets
        const facetsStats: FacetsStats[] = response.data?.searchProducts.facetsStats
        let etimAttributes: Facet[] = []
        if (searchParams.selectedEtim?.id !== '0') {
          etimAttributes = facets.filter((facet) =>
            facet.name.startsWith(`zEtimData.features.${searchParams.selectedEtim?.id}`)
          )
        }

        setSearchFilter({
          etimClasses: facets.find((facet) => facet.name === 'zEtimData.class.id')?.values || [],
          etimAttributes: etimAttributes,
          suppliers: facets.find((facet) => facet.name === 'supplierFilterName')?.values || [], // remove
          hitCount: response.data?.searchProducts.nbHits,
          listPriceMin:
            facetsStats.find((facetsStats) => facetsStats.name === 'listPrice')?.min || 0,
          listPriceMax:
            facetsStats.find((facetsStats) => facetsStats.name === 'listPrice')?.max || 100000, //probably never used cause min/max always come from initial search result
        })
        setLoading(false)
      }
    }
    doSearch()

    return () => {
      unmounted = true
    }
  }, [searchParams, client, warehouseId])

  return { searchFilter, loading }
}

interface IArticleFilterData {
  articleSearchFilter: ArticleSearchFilter
  normalizedEtimAttributes: Record<string, Facet>
  normalizedSuppliers: Record<string, FacetElement>
  loading: boolean
}

const ArticleFilterContext = React.createContext<IArticleFilterData | null>(null)

export const ArticleFilterProvider: React.FC<{ searchParams: ArticleSearchParams | null }> = (
  props
) => {
  const { searchParams, children } = props
  const { searchFilter: articleFilter, loading } = useArticleSearchFilter(searchParams)

  const data = useMemo(() => {
    const normalizedEtimAttributes = articleFilter.etimAttributes.reduce<Record<string, Facet>>(
      (acc, etimAttr) => {
        acc[etimAttr.name] = etimAttr
        return acc
      },
      {}
    )

    const normalizedSuppliers = articleFilter.suppliers.reduce<Record<string, FacetElement>>(
      (acc, sup) => {
        acc[sup.value] = sup
        return acc
      },
      {}
    )

    return {
      articleSearchFilter: articleFilter,
      normalizedEtimAttributes,
      normalizedSuppliers,
      loading,
    }
  }, [articleFilter, loading])

  return <ArticleFilterContext.Provider value={data}>{children}</ArticleFilterContext.Provider>
}

export const useArticleFilterData = () => {
  return validateContextValue(
    useContext(ArticleFilterContext),
    'ArticleFilterContext',
    'useArticleFilterData'
  )
}
