export enum RaffleActionTypes {
  GetRaffleInformation = '[Raffle] Get Raffle Information',
  GetRaffleInformationResult = '[Raffle] Get Raffle Information Result',
  TakePartInRaffle = '[Raffle] Take part in raffle',
  TakePartInRaffleResult = '[Raffle] Take part in raffle Result',
}

interface RaffleAction {
  type: RaffleActionTypes
}

export interface GetRaffleInformationAction extends RaffleAction {
  readonly type: RaffleActionTypes.GetRaffleInformation
}

export const getRaffleInformation = (): GetRaffleInformationAction => ({
  type: RaffleActionTypes.GetRaffleInformation,
})

export interface GetRaffleInformationResultAction extends RaffleAction {
  alreadyRegistered: boolean
}

export const getRaffleInformationResult = (
  alreadyRegistered: boolean
): GetRaffleInformationResultAction => ({
  type: RaffleActionTypes.GetRaffleInformationResult,
  alreadyRegistered,
})

export interface TakePartInRaffleAction extends RaffleAction {
  readonly type: RaffleActionTypes.TakePartInRaffle
}

export const takePartInRaffle = (): TakePartInRaffleAction => ({
  type: RaffleActionTypes.TakePartInRaffle,
})
export interface TakePartInRaffleResultAction extends RaffleAction {
  success: boolean
}

export const takePartInRaffleResult = (success: boolean): TakePartInRaffleResultAction => ({
  type: RaffleActionTypes.TakePartInRaffleResult,
  success,
})
