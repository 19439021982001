import { ShopRoutes } from './variables'

/**
 * Utility to add route to disabled routes list.
 * @param route ShopRoute
 * @param disabledRoutes Disabled ShopRoutes array
 * @returns Updated ShopRoutes array
 */
export const addToDisabledRoutes = (
  route: ShopRoutes,
  disabledRoutes: ShopRoutes[]
): ShopRoutes[] | undefined => {
  const isIncluded = disabledRoutes.findIndex((value) => value === route)
  if (isIncluded < 0) {
    disabledRoutes.push(route)
    return [...disabledRoutes]
  }
  return undefined
}

/**
 * Utility to remove route from disabled routes list.
 * @param route ShopRoute
 * @param disabledRoutes Disabled ShopRoutes array
 * @returns Updated ShopRoutes array
 */
export const removeFromDisabledRoutes = (
  route: ShopRoutes,
  disabledRoutes: ShopRoutes[]
): ShopRoutes[] => {
  let updatedDisabledRoutes: ShopRoutes[] = [...disabledRoutes]
  updatedDisabledRoutes = disabledRoutes.filter((value) => value !== route)
  return updatedDisabledRoutes
}
