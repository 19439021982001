import React from 'react'
import { useTranslation } from 'react-i18next'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { ButtonBase, Typography } from '@mui/material'
import './CartSelection.scss'

interface Props {
  unselectableIds?: string[]
  onCloseClick: () => void
  onCartSelected: (cart: ShoppingCartV2) => void
  context?: CART_SELECTION_CONTEXT
}

export enum CART_SELECTION_CONTEXT {
  MOVE_ARTICLE,
  MOVE_ARTICLES,
  DEFAULT_SELECTION,
  ADD_TEMPLATE,
  ADD_OFFER,
  ADD_OFFER_ARTICLE,
  ADD_TEMPLATE_ARTICLE,
  ADD_ORDER_ARTICLE,
  IMPORT_ARTICLES,
}

export const CartSelectionMenu: React.FC<Props> = (props) => {
  const { onCloseClick, onCartSelected } = props
  const { t } = useTranslation()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const { getSessionCartName, isSessionCart } = useSessionCartName()
  const unselectableIds = props.unselectableIds || []
  let title = t('SHOPPING_CART.CHOOSE_CART_TITLE')
  let content

  switch (props.context) {
    case CART_SELECTION_CONTEXT.ADD_TEMPLATE:
      content = <p>Welchem Warenkorb sollen die Artikel der Merkliste hinzugefügt werden?</p>
      break
    case CART_SELECTION_CONTEXT.ADD_TEMPLATE_ARTICLE:
      content = <p>Welchem Warenkorb soll der Artikel der Merkliste hinzugefügt werden?</p>
      break
    case CART_SELECTION_CONTEXT.ADD_ORDER_ARTICLE:
      content = <p>Welchem Warenkorb soll der Artikel des Auftrages erneut hinzugefügt werden?</p>
      break
    case CART_SELECTION_CONTEXT.ADD_OFFER:
      content = <p>Welchem Warenkorb sollen die Artikel des Angebotes hinzugefügt werden?</p>
      break
    case CART_SELECTION_CONTEXT.ADD_OFFER_ARTICLE:
      content = <p>Welchem Warenkorb soll der Artikel des Angebotes hinzugefügt werden?</p>
      break
    case CART_SELECTION_CONTEXT.DEFAULT_SELECTION:
      title = 'Aktiven Warenkorb wählen'
      content = (
        <>
          <h1>Es wurde noch kein aktiver Warenkorb festgelegt</h1>
          <p>
            Der aktive Warenkorb erscheint in der Kopfleiste. Dorthin werden alle gewählten Artikel
            des Einkaufs gelegt. Eine Änderung des aktiven Warenkorbs ist über den Warenkorb-Button
            in der Kopfleiste der Warenkorb-Übersicht möglich.
          </p>
          <h1>Verfügbare Warenkörbe</h1>
        </>
      )
      break
    case CART_SELECTION_CONTEXT.IMPORT_ARTICLES:
      content = (
        <p>
          {t('SHOPPING_CART.SELECTION_CONTEXT.IMPORT_ARTICLE', {
            count: 2,
          })}
        </p>
      )
      break

    case CART_SELECTION_CONTEXT.MOVE_ARTICLE:
    case CART_SELECTION_CONTEXT.MOVE_ARTICLES:
      content = (
        <p>
          {t('SHOPPING_CART.SELECTION_CONTEXT.MOVE_ARTICLE', {
            count: props.context === CART_SELECTION_CONTEXT.MOVE_ARTICLES ? 2 : 1,
          })}
        </p>
      )
      break
  }
  return (
    <div className="menuroot cartselection">
      <div className="header">
        <div className="toolbar">
          <ButtonBase onClick={onCloseClick}>
            <CloseIcon className="closeIcon" />
          </ButtonBase>

          <div className="titleWrapper">
            <Typography variant="h4">{title}</Typography>
          </div>
        </div>
      </div>
      <div>
        {content && <div className="explanation">{content}</div>}
        <List className="list">
          {carts.map((cart: ShoppingCartV2, idx: number) => (
            <ListItem disablePadding className="listItem" key={cart.id}>
              <ListItemButton
                key={idx}
                className="listItemButton"
                disabled={unselectableIds.includes(cart.id)}
                onClick={() => onCartSelected(cart)}
              >
                {isSessionCart(cart) ? getSessionCartName(cart) : cart.name}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}
