import { useEffect, useState } from 'react'
import { Device } from '@capacitor/device'
import { DeviceInfo } from '@capacitor/device/dist/esm/definitions'

type DeviceInfoResult = DeviceInfo | null
type UseDeviceInfo = () => DeviceInfoResult

export const useDeviceInfo: UseDeviceInfo = () => {
  const [deviceData, setDeviceData] = useState<DeviceInfoResult>(null)

  useEffect(() => {
    const getPlatform = async () => {
      const data = await Device.getInfo()

      setDeviceData(data)
    }
    getPlatform()
  }, [])

  return deviceData
}
