import { gql } from '@apollo/client'

export const CART_TEMPLATE_LIST_ITEM_PRICES_AND_STOCKS = gql`
  query getCartTemplateListItemPricesAndStocks($sapIds: [String!]!, $storeIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      sapId
      unit
      priceDimension
      minimumAmount
      isTopseller
      stock(warehouseIds: $storeIds) {
        sapId
        warehouseId
        amount
        unit
      }
      stockAvailabilityEstimate {
        sapId
        minValue
        maxValue
        error
        unit
      }
      prices {
        currency
        netPrice
        listPrice
        strikeThroughPrice
        tecSelect
      }
    }
  }
`
