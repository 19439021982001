import { useEffect, useRef } from 'react'

export function useIntervalInActiveTab<P extends () => void>(callback: P, interval: number): void {
  const savedCallback = useRef<P | null>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const executeCallback = (): void => {
      if (savedCallback.current) savedCallback.current()
    }
    executeCallback()
    let id = setInterval(executeCallback, interval)
    const onVisibilitychange = () => {
      if (!document.hidden) {
        clearInterval(id)
        executeCallback()
        id = setInterval(executeCallback, interval)
      } else {
        clearInterval(id)
      }
    }
    document.addEventListener('visibilitychange', onVisibilitychange)
    return () => {
      clearInterval(id)
      document.removeEventListener('visibilitychange', onVisibilitychange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval])
}
