import React, { useLayoutEffect, useRef } from 'react'
import { CategoryElementProps, MobileProps } from './types'
import clsx from 'clsx'
import styles from './AllCategory.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SubcategoryTypesEnum } from '@obeta/models/lib/models/Category'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import { CategoryIcon } from '../categories/Categories'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

export const Subcategories: React.FC<CategoryElementProps & MobileProps> = (props) => {
  const minHeightCategoryList = useRemToPx(parseFloat(styles.minHeightCategoryList))
  const singleObetaLevel0ListItemheight = useRemToPx(
    parseFloat(styles.singleObetaLevel0ListItemheight)
  )
  const heightDivHoldingBackButton = useRemToPx(parseFloat(styles.heightDivHoldingBackButton))
  const listSubheaderheight = useRemToPx(parseFloat(styles.listSubheaderheight))
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const divObetaAndDehaSubcategoriesDesktop = useRef<HTMLUListElement>(null)

  const {
    categoryElements,
    listSubheader,
    subcategoryType,
    setDivHeight,
    handleClickOnSubcategory,
    endPositionForInfoAndTopHeaderSectionsMobile = 0,
    tabletTopPosition,
  } = props
  const ionTabBarHeight = document.getElementById('ion-tab-bar')?.clientHeight

  useLayoutEffect(() => {
    //Calculate the list height only based on Obeta Level 0 because this is the longer list of the two subcategories
    if (
      !divObetaAndDehaSubcategoriesDesktop.current?.offsetHeight ||
      subcategoryType !== SubcategoryTypesEnum.Obeta
    ) {
      return
    }
    if (divObetaAndDehaSubcategoriesDesktop.current?.offsetHeight <= minHeightCategoryList) {
      setDivHeight && setDivHeight(minHeightCategoryList)
    } else {
      setDivHeight &&
        setDivHeight(
          (categoryElements?.length as number) * singleObetaLevel0ListItemheight +
            listSubheaderheight
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryElements])
  let height = 0
  if (mobile) {
    height =
      window.innerHeight -
      (endPositionForInfoAndTopHeaderSectionsMobile +
        heightDivHoldingBackButton +
        listSubheaderheight)
  }

  if (tabletAll) {
    height =
      window.innerHeight -
      ((tabletTopPosition as number) + heightDivHoldingBackButton + listSubheaderheight)
  }
  if (ionTabBarHeight) {
    height -= ionTabBarHeight
  }

  return (
    <List
      className={clsx(styles.categoryList, styles.categoryListSubcategories, styles.noHover)}
      ref={divObetaAndDehaSubcategoriesDesktop}
      style={mobile || tabletAll ? { height: height } : {}}
    >
      {desktop && (
        <ListSubheader>
          <Typography variant={'bodyBold'} className={styles.listItemText}>
            {' '}
            {listSubheader}{' '}
          </Typography>
        </ListSubheader>
      )}

      {categoryElements &&
        categoryElements.map((subCategory) => {
          let level = 0
          if (subCategory.indexName.includes('lvl1')) {
            level = 1
          }

          return (
            <ListItem
              id={subCategory.id}
              key={subCategory.id}
              className={clsx(styles.dehaCategoryListItem, {
                [styles.listItemMobileAndTablet]: !desktop,
              })}
            >
              {subcategoryType === SubcategoryTypesEnum.Obeta && (
                <CategoryIcon catId={`hid${subCategory.id}`} size="md" imgSize="xs" />
              )}
              <ListItemButton
                className={
                  subcategoryType !== SubcategoryTypesEnum.Obeta
                    ? clsx(styles.noPaddingLeft, styles.listItemButton)
                    : clsx(styles.listItemButton)
                }
                onClick={() => {
                  handleClickOnSubcategory &&
                    handleClickOnSubcategory({
                      id: subCategory.id,
                      title: subCategory.title,
                      level,
                      subcategoryType,
                      rootId: subCategory.id,
                    })
                }}
              >
                <ListItemText
                  primaryTypographyProps={{ variant: 'body' }}
                  primary={subCategory.title}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
    </List>
  )
}
