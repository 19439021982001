import { useMemo } from 'react'
import { MangoQuery } from 'rxdb'
import { useEntities } from './useEntities'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useUserDataV2 } from './useUserDataV2'

export const useUserSelectedStore = (): {
  user: UserV2 | null
  selectedStore: StoreV2 | undefined
} => {
  const { user } = useUserDataV2()
  const defaultStoreId = user?.settings?.defaultStoreId
  const storeQuery = useMemo<MangoQuery | undefined>(() => {
    if (defaultStoreId === undefined) {
      return undefined
    }

    return {
      selector: {
        id: defaultStoreId,
      },
    }
  }, [defaultStoreId])
  const selectedStore = useEntities<StoreV2>('storesv2', storeQuery)[0]

  return { user, selectedStore }
}
