import { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './MenuButton.module.scss'

type MenuButtonProps = {
  active?: boolean
  disabled?: boolean
  title: string
  onClick: () => void
}

export const MenuButton: FC<MenuButtonProps> = (props) => {
  const { active, disabled, title, onClick } = props

  return (
    <div
      className={clsx(
        styles.menuButton,
        active && styles.menuButtonActive,
        !disabled && styles.menuButtonEnabled
      )}
    >
      <Typography
        className={clsx(
          styles.menuButtonTitle,
          !disabled ? styles.menuButtonTitleEnabled : styles.menuButtonTitleDisabled
        )}
        variant="bodyBold"
        onClick={() => !disabled && onClick()}
      >
        {title}
      </Typography>
    </div>
  )
}
