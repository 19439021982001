export interface Category {
  id: string
  name: string
  url?: string
  level?: number
  parent?: Category
  rootId?: string
  permission?: string
  isDisabled?: boolean
}

export interface CategoryTree {
  id: string
  title: string
  indexName: string
  dehaSubCategories: CategoryTree[]
  obetaSubCategories: CategoryTree[]
}

export interface ObetaCategory {
  id: string
  name: string
}

export enum SubcategoryTypesEnum {
  Obeta = 'Obeta',
}
export interface SubCategory {
  id: string
  title: string
  level: number
  subcategoryType: SubcategoryTypesEnum | undefined
  rootId: string | undefined
}
