import { Theme, useMediaQuery } from '@mui/material'

// todo: it would be more convenient to detect platforms like:
// "if (currentBreakpoint >= tabletWide) {}"
// instead of "if (mobile && tabletPortrait && tabletWide) {}"
// Breakpoint definition: https://www.figma.com/file/ecHZSQzDC4bIep2dpB8atn/Obeta-mit-Styleguide?node-id=13240%3A466601
export const useBreakpoints = () => {
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const tablet = useMediaQuery<Theme>((theme) => theme.breakpoints.between('sm', 'md'))
  const tabletWide = useMediaQuery<Theme>((theme) => theme.breakpoints.between('md', 'lg'))
  const tabletAll = tablet || tabletWide
  const desktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  return { mobile, tabletWide, tablet, tabletAll, desktop }
}
