import { EventType, getEventSubscription, NotificationType } from '../lib/pubSub'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'

/**
 * Shop currently rendered inside iFrame. To communicate with "parent" app use these helpers
 */

/**
 * @typedef {Object} ResponseCallback
 * @param responsePayload - object with data sent by parent application
 */
interface ResponseCallback {
  (responsePayload): void
}

/**
 * Send message to "parent" application.
 * @param command {string} - type of command (TODO: I think we should add types).
 * @param requestPayload {Object} - object with data sent to "parent" application.
 * @param callback {ResponseCallback}
 */
interface SendMessage {
  (command: string, requestPayload?: Record<string, unknown>, callback?: ResponseCallback): void
}

export const interFrameCommunication = (() => {
  let requestCounter = 0
  const callbacksByRequestId = {}

  const sendMessage: SendMessage = (command, requestPayload?, callback?: ResponseCallback) => {
    callback ??= () => {
      /* noop */
    }
    requestPayload ??= {}
    requestCounter++
    const requestId = requestCounter
    callbacksByRequestId[requestId] = callback
    window?.top?.postMessage(
      {
        command: command,
        requestId,
        requestPayload: requestPayload,
      },
      '*'
    )
  }

  return {
    // This should only be called once at application boot
    init: () => {
      window?.addEventListener('message', function (event) {
        const message = event.data

        if (message.command === 'updateHeight') {
          const iframe = document?.getElementById('shop-next-iframe')
          if (iframe) {
            iframe.style.height = `${message.height}px`
          }
          return
        }

        // we only interested in messages with requestId and responsePayload
        if (!message.requestId || !message.responsePayload) {
          return
        }
        if (message.command === 'order-create') {
          getEventSubscription().next({
            type: EventType.Data,
            notificationType: NotificationType.OrderSuccess,
            id: message.requestId,
            options: {
              ...message.responsePayload,
            },
          })
          return
        }
        if (message.command === 'order-failure') {
          getEventSubscription().next({
            type: EventType.Data,
            notificationType: NotificationType.OrderFailure,
            id: message.requestId,
            options: {
              reason: OrderFailureReason.OrderSubmitFailure,
            },
          })
          return
        }
        callbacksByRequestId[message.requestId](message.responsePayload)
      })
    },

    /**
     * Interframe Communication Interface - Methods
     *  */
    // ============================================

    setIFrameHeight: () => {
      sendMessage('setIFrameHeight', {
        height: (document.getElementById('main')?.clientHeight || 0) + 20,
      })
    },
    getLegacyAuthToken: (callback: ResponseCallback) => {
      sendMessage('getLegacyAuthToken', {}, callback)
    },
    getVerticalScrollPosition: (callback: ResponseCallback) => {
      sendMessage('getVerticalScrollPosition', {}, (responsePayload) => {
        //
      })
    },
    setNotificationButton: () => {
      sendMessage('setNotificationButton', {}, ((responsePayload) => {
        //
      }) as ResponseCallback)
    },
    removeNotificationButton: () => {
      sendMessage('removeNotificationButton', {}, ((responsePayload) => {
        //
      }) as ResponseCallback)
    },
  }
})()
