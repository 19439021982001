import {
  catchError,
  concatMap,
  defer,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  retry,
  switchMap,
  withLatestFrom,
} from 'rxjs'
import Axios from 'axios-observable'
import {
  CustomerActionTypes,
  LoadProfileAction,
  loadUserProfileResult,
  SaveCustomerSettingsAction,
  saveCustomerSettingsResult,
  UpdateCardAction,
  updateCardResult,
  LoadUserGraphQLAction,
  loadUserGraphQLResult,
  UpdateUserSettingsAction,
  DeleteUserToken,
} from '../actions/customer-actions'

import { Customer } from '@obeta/models/lib/models/CustomerData/Customer'
import { deleteFirebasePushToken } from '@obeta/models/lib/models/Tokens/Tokens'
import { getUserV2Result, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import { AxiosResponse } from 'axios'
import { noop } from '../actions'
import { RxDatabase, CollectionsOfDatabase } from 'rxdb'
import { EventType, NotificationType, getEventSubscription } from '@obeta/utils/lib/pubSub'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { CustomerMetaData } from '../hooks/useUserData'
import { gqlQueryUserV2, gqlUpdateUserV2, assignCustomerCard } from '../queries/userV2'

export interface CustomerSettings {
  creditLimitWarning: boolean
  orderConfirmation: boolean
  defaultProjectId: string
}

const getMainUserPermissions = () => [
  'changeAdditionalText',
  'changeAddresses',
  'changeCommission',
  'changeDeliveryAddress',
  'showAccountInfo',
  'showPendingItems',
  'orderShoppingCart',
  'showCatalogPrices',
  'showListenPrices',
  'showPurchasePrices',
  'showOffers',
  'showOrders',
]

const createLoadUserProfile = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<LoadProfileAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.LoadProfile),
      switchMap((action: LoadProfileAction) =>
        /**
         * Do not use AxiosObservable. In staging-web AxiosObservable is not recongnized as
         * observable by rxjs.
         * https://github.com/nrwl/nx/issues/2125#issuecomment-560680297
         */
        forkJoin([
          Axios.request({
            url: `user/${action.user.companyId}/${action.user.userId}/profile`,
          }),
          Axios.request({
            url: `user/details`,
          }),
        ]).pipe(
          mergeMap(([profileResponse, detailsResponse]) => {
            const profile = profileResponse.data
            const details = detailsResponse.data

            const customer: Customer = {
              ...details.data,
              general: profile.data,
            }

            if (!customer.general.isSubUser) {
              // it is important to set those permissions here as the
              // permission model includes negative and positive permissions
              // main users only get positive permissions not the negative ones
              // an example for a negative permission is commissionIsObligatory
              // if this is set the customer MUST set a commission for every cart
              // which is intended to be used for subusers only
              customer.general.permissions.subUser = getMainUserPermissions()
            }

            return defer(async () => {
              const usermeta = await db.getLocal<CustomerMetaData>('usermeta')
              await usermeta?.incrementalModify((data) => {
                data = {
                  ...data,
                  isFetching: false,
                  lastUpdated: new Date().getTime(),
                  userId: action.user.userId,
                  companyId: action.user.companyId || '',
                }
                return data
              })
              const user = await db.getLocal<Customer>('user')
              await user?.incrementalModify((data) => {
                data = { ...data, ...customer }
                return data
              })

              return customer
            })
          }),
          concatMap((customer: Customer) => of(loadUserProfileResult(customer))),
          catchError((error) => {
            error.message = 'error in ' + createLoadUserProfile.name + ' ' + error.message
            handleError(error)
            return of(loadUserProfileResult(undefined, error))
          })
        )
      )
    )
}

const createSaveCustomerSettingsEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$) =>
    actions$.pipe(
      ofType(CustomerActionTypes.SaveCustomerSettings),
      switchMap((action: SaveCustomerSettingsAction) =>
        Axios.request({
          method: 'POST',
          url: '/user/settings',
          data: action.settings,
          headers: {
            'Content-Type': 'application/json',
          },
        }).pipe(withLatestFrom(of(action)))
      ),
      concatMap(([response, action]: [AxiosResponse, SaveCustomerSettingsAction]) => {
        return defer(async () => {
          const user = await db.getLocal<Customer>('user')
          const updatedDoc = await user?.incrementalModify((data: Customer) => {
            data.general.defaultPjId = action.settings.defaultProjectId
            data.user.customerSettings.creditLimitWarning = action.settings.creditLimitWarning
            data.user.customerSettings.orderConfirmation = action.settings.orderConfirmation

            return data
          })

          return updatedDoc?.toJSON()
        })
      }),
      map((customer: Customer) => saveCustomerSettingsResult(customer)),
      catchError((error) => {
        error.message = 'error in ' + createSaveCustomerSettingsEpic.name + ' ' + error.message
        handleError(error)
        return of(saveCustomerSettingsResult(undefined, error))
      })
    )
}

const createUpdateCardEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateCard = async (cardId: string) => {
    const response = await apolloClient.mutate({
      mutation: assignCustomerCard,
      variables: {
        input: {
          barcode: cardId,
        },
      },
    })

    if (response.data.assignCustomerCard.type === 'Error') {
      return updateCardResult({
        ...response.data.assignCustomerCard,
        message: response.data.assignCustomerCard.message,
      })
    }

    const user = await db.getLocal<UserV2>('userv2')
    await user?.incrementalModify((user) => {
      return { ...user, customerCard: { ...user.customerCard, barcode: cardId } }
    })

    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.Toast,
      id: 'user card toast',
      options: {
        message: 'Die Kundenkarte wurde erfolgreich hinzugefügt',
      },
    })

    return updateCardResult()
  }

  return (actions$: Observable<UpdateCardAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.UpdateCard),
      switchMap(async (action: UpdateCardAction) => {
        return await updateCard(action.cardId)
      }),
      catchError((error) => {
        handleError({
          ...error,
          message: 'error in ' + createUpdateCardEpic.name + ' ' + error.message,
        })
        return of(updateCardResult(error))
      })
    )
}

const createUpdateUserV2 = <Action>(
  requestUser: (action: Action) => Promise<UserV2>,
  db: RxDatabase<CollectionsOfDatabase>
) => {
  return (action: Action) => {
    return defer(async () => {
      const user = await requestUser(action)
      const userBase = await db.getLocal<UserV2>('userv2')
      await userBase?.incrementalModify((data) => ({ ...data, ...user }))
      return user
    }).pipe(
      retry(1),
      mergeMap((result: UserV2) => of(loadUserGraphQLResult(result))),
      catchError((error) => {
        error.message = 'error in ' + createUpdateUserV2.name + ' ' + error.message
        handleError(error)
        return of(loadUserGraphQLResult(undefined, error))
      })
    )
  }
}

export const createLoadUserGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<LoadUserGraphQLAction>(async () => {
    const response = await apolloClient.query<getUserV2Result>({
      query: gqlQueryUserV2,
    })

    return response.data.getUser
  }, db)
  return (actions$: Observable<LoadUserGraphQLAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.LoadUserGraphql),
      concatMap((action: LoadUserGraphQLAction) => updateUserV2(action))
    )
}

export const createUpdateUserSettings = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<UpdateUserSettingsAction>(async (action) => {
    const response = await apolloClient.mutate<{ updateUserSettings: UserV2 }>({
      mutation: gqlUpdateUserV2,
      variables: {
        input: action.payload,
      },
    })
    const user = response.data?.updateUserSettings
    if (!user) {
      throw new Error("updateUserSettings: response doesn't have new user")
    }

    return user
  }, db)

  return (actions$: Observable<UpdateUserSettingsAction>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.UpdateUserSettings),
      switchMap((action) => updateUserV2(action))
    )
  }
}

const createDeleteUserTokenEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const deleteToken = async () => {
    const pushToken = await db.getLocal('pushToken')
    const token = pushToken?.get('token')

    if (!token) {
      return
    }

    try {
      const response = await apolloClient.mutate({
        mutation: deleteFirebasePushToken,
        variables: {
          token,
        },
      })
      return { deleteToken: response.data.deleteToken }
    } catch (err) {
      err.message = 'error in ' + deleteToken.name + ' ' + err.message
      handleError(err)
      throw new Error(err)
    }
  }

  return (actions$: Observable<DeleteUserToken>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.DeleteUserToken),
      switchMap(async () => {
        await deleteToken()

        return noop()
      })
    )
  }
}

export const initAllCustomerEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createLoadUserProfile(db),
    createSaveCustomerSettingsEpic(db),
    createUpdateCardEpic(db, apolloClient),
    createLoadUserGraphQLEffect(db, apolloClient),
    createUpdateUserSettings(db, apolloClient),
    createDeleteUserTokenEpic(db, apolloClient),
  ]
}
