import React from 'react'
import { datadogRum } from '@datadog/browser-rum'

export class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    datadogRum.addError(error, errorInfo)
  }

  render() {
    return this.props.children
  }
}
