import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useHistory } from './useHistoryApi'
import React from 'react'

export const useRedirect = () => {
  const history = useHistory()
  const isNativeUITarget = isUiTarget('app')

  const redirectToStartPage = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault()
    }
    const isOnHomePage =
      window.location.pathname + window.location.search === '/' ||
      window.location.pathname + window.location.search === ''

    if (!isOnHomePage) {
      history.push('/')
    } else if (!isNativeUITarget) {
      window.location.reload()
    }
  }
  return { redirectToStartPage }
}
