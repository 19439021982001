import React, { useCallback, useEffect, useState, useRef } from 'react'
import { BroadcastChannel } from 'broadcast-channel'
import { useFeatureToggle } from '../hooks/feature-toggles'
import { useInterval } from '../hooks/useInterval'
import { RefreshDialog } from './RefreshDialog'

const SHOP_VERSION_STORAGE_KEY = 'ldShopVersion'
const VERSION_CHECK_INTERVAL_IN_MS = 5 * 60 * 1000 // 5 minutes

export const DeployRefreshManager: React.FC = () => {
  const [showRefreshDialog, setShowRefreshDialog] = useState(false)
  const versionFromLaunchDarkly = useFeatureToggle('SHOP_VERSION')
  const didInitialVersionCheck = useRef(false)

  // update version once, when mounted
  useEffect(() => {
    if (typeof window === 'undefined' || !localStorage) return
    const versionFromStorage = localStorage.getItem(SHOP_VERSION_STORAGE_KEY)

    if (versionFromLaunchDarkly && !didInitialVersionCheck.current) {
      if (versionFromStorage !== versionFromLaunchDarkly) {
        localStorage.setItem(SHOP_VERSION_STORAGE_KEY, versionFromLaunchDarkly)
      }
      didInitialVersionCheck.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionFromLaunchDarkly])

  const refresh = useCallback(() => {
    if (typeof window === 'undefined' || !localStorage) return
    setShowRefreshDialog(false)

    const channel = new BroadcastChannel('shop_version')
    channel.postMessage({
      action: 'version_change',
      storageKey: SHOP_VERSION_STORAGE_KEY,
      storageValue: versionFromLaunchDarkly,
    })
  }, [versionFromLaunchDarkly])

  useInterval(
    async () => {
      if (typeof window === 'undefined' || !localStorage) return
      const versionFromStorage = localStorage.getItem(SHOP_VERSION_STORAGE_KEY)
      if (!versionFromStorage) {
        localStorage.setItem(SHOP_VERSION_STORAGE_KEY, versionFromLaunchDarkly)
        return
      }
      if (
        versionFromLaunchDarkly &&
        versionFromStorage &&
        versionFromLaunchDarkly !== versionFromStorage &&
        !showRefreshDialog
      ) {
        // eslint-disable-next-line no-console
        setShowRefreshDialog(true)
      }
    },
    { interval: VERSION_CHECK_INTERVAL_IN_MS }
  )

  if (!showRefreshDialog) return null

  return (
    <RefreshDialog
      refresh={refresh}
      open={showRefreshDialog}
      onClose={() => setShowRefreshDialog(false)}
    />
  )
}
