import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createContext, useContext } from 'react'
import { RxDatabase } from 'rxdb'
import { firstValueFrom, mergeMap, Subject } from 'rxjs'
import {
  BusinessLayer,
  Effect,
  Action,
  BusinessLayerOptions,
  SyncOptions,
} from '@obeta/models/lib/models/BusinessLayer/BusinessLayer'
import { replication$ } from '..'
import { tokens$ } from './bootstrapAppActions'
import { AppActions } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { EntityNames } from '@obeta/models/lib/models'

let epic$: Subject<Effect<Action, Action>>
let actionSubject$: Subject<Action>
let initialized = false

const dispatch = (action: Action) => {
  actionSubject$.next(action)
}

const getCollectionSync = (entityName: EntityNames) => {
  return null
}

export const BusinessLayerContext = createContext<BusinessLayer>({} as BusinessLayer)

export const useBusinessLayer = () => useContext(BusinessLayerContext)

/**
 * sets up action stream for all effects, sync and provides eas to use interface for all important logic pieces
 *
 * @param appActions
 * @param db
 * @param apolloClient
 * @param sync
 * @returns
 */
export const bootstrapBusinessLogic = async (
  appActions: AppActions,
  db: RxDatabase,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  sync: SyncOptions | object = {}
): Promise<BusinessLayer> => {
  const opts = {
    dependencies: {
      db,
      apolloClient,
      getCollectionSync,
    },
  }

  // TODO use lightweight action stream to reduce dependencies
  // this is currently unused
  initActionStream(opts)

  const startSync = async () => {
    const tokens = await firstValueFrom(tokens$)
    replication$.next({
      ...sync,
      type: 'start',
      token: tokens?.accessToken,
    })
  }

  const stopSync = () => {
    replication$.next({
      type: 'stop',
    })
  }

  return {
    db,
    dispatch,
    addEffect,
    startSync,
    stopSync,
    getCollectionSync,
    tokens$,
    logout$: appActions.logout$,
    logoutFinished$: appActions.logoutFinished$,
    requestNewToken: appActions.requestNewToken,
  }
}

const initActionStream = (options: BusinessLayerOptions) => {
  epic$ = new Subject<Effect<Action, Action>>()
  actionSubject$ = new Subject<Action>()

  const result$ = epic$.pipe(
    mergeMap((effect) => {
      const output$ = effect(actionSubject$, options.dependencies)

      if (!output$) {
        throw new TypeError(
          `Your root Effect "${
            effect.name || '<anonymous>'
          }" does not return a stream. Double check you're not missing a return statement!`
        )
      }

      return output$
    })
  )

  const sub = result$.subscribe((action?: Action) => {
    if (action) {
      dispatch(action)
    }
  })

  initialized = true

  return () => {
    sub.unsubscribe()
    initialized = false
  }
}

export const addMultipleEffects = (effects: Effect[]) => {
  effects.forEach(addEffect)
}

export const addEffect = (effect: Effect) => {
  if (!initialized) {
    throw new Error('before adding effects, initActionStream must be called')
  }
  epic$.next(effect)
}
