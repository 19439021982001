import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: UserAddressV2): ICheckpoint => ({
  lastId: doc.addressId,
  since: doc.updatedAt,
})

export const userAddressesV2Entity: EntityDescriptor<UserAddressV2, ICheckpoint> = {
  name: 'useraddressesv2',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'useraddressesv2',
    version: 2,
    description: 'a document representing an address',
    type: 'object',
    primaryKey: 'addressId',
    properties: {
      addressId: {
        type: 'string',
        maxLength: 100,
      },
      address: {
        type: 'object',
      },
      customName: {
        type: 'string',
      },
      addressType: {
        type: 'string',
      },
      updatedAt: {
        type: 'string',
      },
      isDefault: {
        type: 'boolean',
      },
    },
  },
  subscription: null,
  pullSync: {
    batchSize: 500,
    queryBuilder: (checkpoint, limit) => {
      checkpoint = checkpoint || emptyCheckpoint

      const query = `query getUserAddresses ($since: String, $lastId: String, $batchSize: Float){
        getUserAddresses(since: $since, lastId: $lastId, batchSize:$batchSize) {
          addressId,
          address {
            name1,
            name2,
            name3,
            name4,
            street,
            houseNumber,
            zipCode,
            city
          }
          addressType,
          customName,
          updatedAt,
          isDefault,
          deleted
        }
      }`
      return {
        query,
        variables: {
          ...checkpoint,
          batchSize: limit,
        },
      }
    },
    responseModifier: (
      documents: (UserAddressV2 & { _deleted: true })[],
      origin,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents: documents,
    }),
  },
  authentication: true,
  liveInterval: 60 * 1000, // 1 min
}
