import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'
import { useMemo } from 'react'
import { initApollo } from '../apolloClient'
import { STRAPI_GRAPHQL_URL } from '@obeta/utils/lib/config'
import { useRxDB } from 'rxdb-hooks'

export const useStrapiGql = () => {
  const appActions = useAppActions()
  const db = useRxDB()
  return useMemo(() => initApollo(STRAPI_GRAPHQL_URL, appActions, true, db), [appActions, db])
}
