import React, { useMemo } from 'react'
import { Link, LinkProps, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { clickedObetaLinks } from './Footer'
import { prepareFooterLinks } from './FooterLinks'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const ListOfLinksForDesktopAndTabletLandscape: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const isNewSupplierOverview = useFeatureToggle('UseNewSupplierOverview')

  const links = useMemo(
    () => prepareFooterLinks(t, isNewSupplierOverview),
    [t, isNewSupplierOverview]
  )

  return (
    <>
      {links.map((group, idx) => (
        <div className={styles.divLinks} key={idx}>
          <Typography
            variant={'bodyBold'}
            className={clsx(styles.displayBlock, styles.marginBottomOneAndHalfRem)}
          >
            {group.name}
          </Typography>
          {group.items.map((el, idx) => {
            const isExternal = el.url?.match(/^(http|https):\/\//i) !== null
            const linkAttr: Partial<LinkProps> = {
              underline: 'none',
              color: 'inherit',
              href: el.url,
            }
            if (isExternal) {
              linkAttr.rel = 'noopener'
              linkAttr.target = '_blank'
            } else {
              linkAttr.onClick = (e: React.MouseEvent) => clickedObetaLinks(e, history, el.url)
            }

            return (
              <Typography
                key={group.name + idx}
                variant={'body'}
                className={clsx(styles.displayBlock, styles.marginBottomHalfRem)}
              >
                <Link underline="none" color="inherit" {...linkAttr}>
                  {el.name}
                </Link>
              </Typography>
            )
          })}
        </div>
      ))}
    </>
  )
}
