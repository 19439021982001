import { EntityDescriptor } from '@obeta/models/lib/models'
import { StockAvailabilityEstimate } from '@obeta/schema'

export const stockAvailabilityEstimatesEntity: EntityDescriptor<
  Omit<StockAvailabilityEstimate, '__typename'>
> = {
  name: 'stockavailabilityestimates',
  schema: {
    title: 'stockavailabilityestimates',
    version: 2,
    description:
      'a document representing a stock availability estimate entry (only relevant for products of type special)',
    type: 'object',
    primaryKey: 'sapId',
    properties: {
      sapId: {
        type: 'string',
        maxLength: 100,
      },
      minValue: {
        type: 'float',
      },
      maxValue: {
        type: 'float',
      },
      error: {
        type: 'string',
      },
      unit: {
        type: 'object',
      },
    },
  },
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
}
