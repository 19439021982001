import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SvgIcon,
  Typography,
} from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  heading: string
  body: string
  handleConfirm: () => void
  handleCancel: () => void
  openConfirmDialog: boolean
  changesInCart?: boolean
}

export const Confirm: React.FC<Props> = (props) => {
  const { body, heading, handleConfirm, handleCancel, openConfirmDialog, changesInCart } = props
  const { t } = useTranslation()

  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { width: '18.75rem', m: 0, pb: 1 } }}
      open={openConfirmDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {heading}
      </DialogTitle>
      <DialogContent sx={{ p: 0, px: 1, pb: 1, textAlign: 'center' }}>
        <Typography variant={'body'} color={'secondary'}>
          {body}
        </Typography>
      </DialogContent>
      <Divider variant="middle" sx={{ mx: 1 }} />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 1,
          px: 1.5,
          pb: 0,
        }}
      >
        <Button
          variant={'tertiary'}
          fullWidth={false}
          startIcon={<SvgIcon component={CloseIcon} color="primary" />}
          onClick={handleCancel}
        >
          {t('SHOPPING_CART.ACTIONS.ABORT')}
        </Button>
        <Button
          variant={'outlined'}
          fullWidth={false}
          startIcon={<SvgIcon component={CheckIcon} color="success" />}
          onClick={handleConfirm}
          autoFocus
        >
          {changesInCart ? t('SHOPPING_CART.ACTIONS.SAVE') : t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
