interface Action {
  type: CommonActionTypes
}

export enum CommonActionTypes {
  LOGIN = '[Common] Login',
  LOGIN_SUCCESS = '[Common] Login Success',
  LOGIN_FAIL = '[Common] Login Fail',
  NOOP = '[Common] No-Op',
}

export class LoginAction implements Action {
  readonly type = CommonActionTypes.LOGIN
}
export class LoginSuccessAction implements Action {
  readonly type = CommonActionTypes.LOGIN_SUCCESS
  constructor(public userId: string, public companyId: string) {}
}
export class LoginFailAction implements Action {
  readonly type = CommonActionTypes.LOGIN_FAIL
}

export interface NoOpAction {
  type: CommonActionTypes.NOOP
}

export const noop = (): NoOpAction => ({
  type: CommonActionTypes.NOOP,
})
