import { AppActions, Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { fetchPayloadFromToken } from './session-based-login-helpers'
import { firstValueFrom } from 'rxjs'
import { trackCustom } from './tracking'

export const requestTokenIfExpired = async (appActions: AppActions, tokens: Tokens | null) => {
  if (!tokens) {
    return null
  }
  const tokenPayload = fetchPayloadFromToken(tokens.accessToken)
  const expiryDateInMs = tokenPayload.exp * 1000
  const currentTimeInMs = Date.now()

  if (expiryDateInMs <= currentTimeInMs) {
    trackCustom('expired-access-token-need-token-update', {
      expiryDate: tokenPayload.exp,
      currentDateInMs: currentTimeInMs,
      expiryDateInMs: expiryDateInMs,
    })
    await appActions.requestNewToken()
    return await firstValueFrom(appActions.tokens$)
  }
  return null
}
