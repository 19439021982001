import { GraphQLError, GraphQLFormattedError } from 'graphql'

export const isAuthenticationError = (error: GraphQLFormattedError) => {
  // I use optional chaining because server doesn't always respect GraphQLError interface
  // extensions can be undefined.
  return error.extensions?.code === 'AUTHENTICATION_ERROR'
}

export const isAuthenticationErrorSync = (error: GraphQLError) => {
  return error.message?.startsWith('Access denied')
}
