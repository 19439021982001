import React, { createContext, useContext, useState } from 'react'

// Models
import { OrderItemForInArrearsPage } from '@obeta/models/lib/schema-models/order-in-arrears'
import {
  DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS,
  OrderItemStateViewType,
} from '@obeta/models/lib/models/Orders'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { DropdownOption } from '@obeta/models/lib/models/UI'

interface OrderDetailsInArrearsContextValue {
  itemStateOptions: DropdownOption[]
  itemState: OrderItemStateViewType
  orderItems: OrderItemForInArrearsPage[]
  queryParams: QueryParams
  searchTerm: string
  totalItemsCount: number
  search: string
  createdAt: string
  offerId: string
  setItemStateOptions: (itemStateOptions: DropdownOption[]) => void
  setItemState: (itemState: OrderItemStateViewType) => void
  setOrderItems: (orderItems: OrderItemForInArrearsPage[]) => void
  setSearchTerm: (searchTerm: string) => void
  setTotalItemsCount: (totalItemsCount: number) => void
  setSearch: (search: string) => void
  setOfferId: (search: string) => void
  setCreatedAt: (search: string) => void
  setQueryParams: (queryParams: QueryParams) => void
}

const OrderDetailsInArrearsContext = createContext<OrderDetailsInArrearsContextValue>({
  itemState: 'all',
  itemStateOptions: [],
  orderItems: [],
  searchTerm: '',
  totalItemsCount: 0,
  queryParams: DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS,
  search: '',
  createdAt: '',
  offerId: '',
  setOfferId: () => {
    //
  },
  setCreatedAt: () => {
    //
  },
  setItemState: () => {
    //
  },
  setItemStateOptions: () => {
    //
  },
  setTotalItemsCount: () => {
    //
  },
  setQueryParams: () => {
    //
  },
  setOrderItems: () => {
    //
  },
  setSearchTerm: () => {
    //
  },
  setSearch: () => {
    //
  },
})

export const OrderDetailsInArrearsContextProvider = ({ children }) => {
  const [itemState, setItemState] = useState<OrderItemStateViewType>('all')
  const [orderItems, setOrderItems] = useState<OrderItemForInArrearsPage[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [offerId, setOfferId] = useState<string>('')
  const [createdAt, setCreatedAt] = useState<string>('')
  const [itemStateOptions, setItemStateOptions] = useState<DropdownOption[]>([])
  const [queryParams, setQueryParams] = useState<QueryParams>(
    DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS
  )

  return (
    <OrderDetailsInArrearsContext.Provider
      value={{
        itemStateOptions,
        itemState,
        search,
        totalItemsCount,
        orderItems,
        searchTerm,
        queryParams,
        offerId,
        createdAt,
        setCreatedAt,
        setOfferId,
        setItemState,
        setItemStateOptions,
        setQueryParams,
        setOrderItems,
        setSearchTerm,
        setSearch,
        setTotalItemsCount,
      }}
    >
      {children}
    </OrderDetailsInArrearsContext.Provider>
  )
}

export const useOrderDetailsInArrearsContext = () => {
  return useContext(OrderDetailsInArrearsContext)
}
