import { AppActions } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import React, { useContext } from 'react'

export const AppActionsContext = React.createContext<AppActions | null>(null)

export const useAppActions = () => {
  const state = useContext(AppActionsContext)
  if (!state) {
    throw new Error(
      'AppActionsContext is undefined. Make sure you wrapped your app with <AppActionsContext/>'
    )
  }

  return state
}
