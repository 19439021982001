import { MainStockId } from './variables'

const WAREHOUSE_FACET_BASE = 'warehouseAvailability'
const WAREHOUSE_10_KEY = 'warehouse_10'
const WAREHOUSE_500_KEY = 'warehouse_500'
const WAREHOUSE_10_FACET = `${WAREHOUSE_FACET_BASE}.${WAREHOUSE_10_KEY}` as const
const WAREHOUSE_500_FACET = `${WAREHOUSE_FACET_BASE}.${WAREHOUSE_500_KEY}` as const

export const WAREHOUSE_FACETS = [WAREHOUSE_500_FACET, WAREHOUSE_10_FACET] as const
export type WarehouseFacet = typeof WAREHOUSE_FACETS[number]

export const getFacetByWarehouseId = (warehouseId: string): WarehouseFacet => {
  if (warehouseId !== MainStockId) {
    // add more logic here as soon as there is more than one non-main warehouse
    return WAREHOUSE_500_FACET
  }
  return WAREHOUSE_10_FACET
}
