import {
  BarcodeScanner as CapacitorBarcodeScanner,
  CameraDirection,
} from '@capacitor-community/barcode-scanner/dist/plugin.cjs'
import { useCallback, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
// this component is app only and can use react-router features that rely on history events which are not exposed by nextJSRouter anyhow
// eslint-disable-next-line no-restricted-imports
import { useHistory } from './useHistoryApi'
import { useTranslation } from 'react-i18next'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { BarcodeContext } from './useBarcodeState'
import { useSearchArticles } from './useSearchArticles'
import { updateCard } from '../actions/customer-actions'
import { ScreenOrientation } from '@capacitor/screen-orientation'

type LocationBarcodeScannerState = { barcodeScanner?: boolean }
export const TABS_ID = 'appMainContent'

export const useBarcodeScanner = () => {
  const { scan, setScan } = useContext(BarcodeContext)
  const searchScanner = useSearchArticles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const tabsComponent = document.getElementById(TABS_ID) as HTMLElement

  const stopScan = useCallback(() => {
    CapacitorBarcodeScanner.stopScan()
    if (tabsComponent) tabsComponent.style.display = 'unset'
    document.documentElement.style.setProperty('--background-color', '#f6f6f6')
    document.body.style.background = '#fff'
    ScreenOrientation.unlock()

    if (setScan) setScan(false)

    const state = history.location.state as LocationBarcodeScannerState | null
    if (state?.barcodeScanner) {
      history.goBack()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setScan, tabsComponent, history.location])

  const toggleTorch = CapacitorBarcodeScanner.toggleTorch.bind(CapacitorBarcodeScanner)

  const prepareScannerLayout = () => {
    if (setScan) setScan(true)

    if (tabsComponent) tabsComponent.style.display = 'none'
    document.body.style.background = 'transparent'
    document.documentElement.style.setProperty('--background-color', 'transparent')
  }

  const performScan = async (callback) => {
    if (isPlatform('web')) return

    const approved = await checkPermision()

    if (approved) {
      history.push(history.location, { barcodeScanner: true })

      CapacitorBarcodeScanner.prepare()

      prepareScannerLayout()

      const results = await CapacitorBarcodeScanner.startScan({
        cameraDirection: CameraDirection.BACK,
      })

      if (results.hasContent) {
        stopScan()
        callback(results.content)
      }
    } else {
      const notificationMessage = t('ALERTS.CAMERA_PERMISSION_BARCODE_SCANNER')
      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Toast,
        id: 'toast',
        options: {
          message: notificationMessage,
        },
      })
    }
  }

  const startTransferCardScan = async () => {
    performScan((content) => {
      dispatch(updateCard(content))
    })
  }

  const startSearchScan = async () => {
    performScan((content) => {
      searchScanner.setSearchText(content)
      searchScanner.handleSubmit()
    })
  }

  const checkPermision = async () => {
    const status = await CapacitorBarcodeScanner.checkPermission({ force: true })
    return !!status.granted
  }

  useEffect(() => {
    const state = history.location.state as LocationBarcodeScannerState | null

    if (scan && !state?.barcodeScanner) {
      stopScan()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scan, history.location])

  return {
    startSearchScan,
    stopScan,
    toggleTorch,
    scan,
    startTransferCardScan,
  }
}
