import React from 'react'
import { OpeningHours } from '@obeta/models/lib/models/opening-hours'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'
import { Typography } from '@mui/material'
import styles from './StoreWorkingHours.module.scss'

interface IStoreAddressProps {
  openingHours: OpeningHours[]
  variant: 'body' | 'smallText'
}

export const StoreWorkingHours: React.FC<IStoreAddressProps> = (props) => {
  const openingHours = formatOpeningHours(props.openingHours)
  const variant = props.variant

  return (
    <div>
      <div className={styles.flexRow}>
        <div className={styles.days}>
          {openingHours.map((openingHourGrouping, index) => {
            return (
              <Typography key={index} variant={variant}>
                {openingHourGrouping.days}
              </Typography>
            )
          })}
        </div>
        <div className={styles.times}>
          {openingHours.map((openingHourGrouping, index) => {
            return (
              <Typography key={index} variant={variant}>
                {openingHourGrouping.times}
              </Typography>
            )
          })}
        </div>
      </div>
    </div>
  )
}
