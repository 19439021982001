import { createContext, useContext, useMemo, useState } from 'react'
import { FastProductEntryPageItem } from '@obeta/models/lib/schema-models/fast-product-entry'
import { FastProductEntryTabState } from '@obeta/models/lib/models/FastProductEntry'

const initialEmptyFunction = () => {
  //
}

interface FastProductEntryContextValue {
  activeTab: FastProductEntryTabState
  fileUploadFiles: File[]
  fileUploadIsLoadingPricesAndStock: boolean
  fileUploadProducts: FastProductEntryPageItem[]
  singleEntryProducts: FastProductEntryPageItem[]
  setActiveTab: (activeTab: FastProductEntryTabState) => void
  setFileUploadFiles: (fileUploadFiles: File[]) => void
  setFileUploadIsLoadingPricesAndStock: (fileUploadIsLoadingPricesAndStock: boolean) => void
  setFileUploadProducts: (fileUploadProducts: FastProductEntryPageItem[]) => void
  setSingleEntryProducts: (singleEntryProducts: FastProductEntryPageItem[]) => void
}

const FastProductEntryContext = createContext<FastProductEntryContextValue>({
  activeTab: FastProductEntryTabState.FileUpload,
  fileUploadFiles: [],
  fileUploadIsLoadingPricesAndStock: true,
  fileUploadProducts: [],
  singleEntryProducts: [],
  setActiveTab: initialEmptyFunction,
  setFileUploadFiles: initialEmptyFunction,
  setFileUploadIsLoadingPricesAndStock: initialEmptyFunction,
  setFileUploadProducts: initialEmptyFunction,
  setSingleEntryProducts: initialEmptyFunction,
})

export const FastProductEntryProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState<FastProductEntryTabState>(
    FastProductEntryTabState.FileUpload
  )
  const [fileUploadFiles, setFileUploadFiles] = useState<File[]>([])
  const [fileUploadIsLoadingPricesAndStock, setFileUploadIsLoadingPricesAndStock] =
    useState<boolean>(true)
  const [fileUploadProducts, setFileUploadProducts] = useState<FastProductEntryPageItem[]>([])
  const [singleEntryProducts, setSingleEntryProducts] = useState<FastProductEntryPageItem[]>([])

  const value: FastProductEntryContextValue = useMemo(
    () => ({
      activeTab,
      fileUploadFiles,
      fileUploadIsLoadingPricesAndStock,
      fileUploadProducts,
      singleEntryProducts,
      setActiveTab,
      setFileUploadFiles,
      setFileUploadIsLoadingPricesAndStock,
      setFileUploadProducts,
      setSingleEntryProducts,
    }),
    [
      activeTab,
      fileUploadFiles,
      fileUploadIsLoadingPricesAndStock,
      fileUploadProducts,
      singleEntryProducts,
    ]
  )

  return (
    <FastProductEntryContext.Provider value={value}>{children}</FastProductEntryContext.Provider>
  )
}

export const useFastProductEntryContext = () => {
  return useContext(FastProductEntryContext)
}
