import { ButtonBase, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import styles from './CustomButton.module.scss'

interface CustomButtonProps extends React.ComponentProps<typeof ButtonBase> {
  buttonType: 'primary' | 'secondary' | 'tertiary' | 'dark' | 'light'
  size?: 'small' | 'large'
  leftIcon?: JSX.Element | null
  rightIcon?: JSX.Element | null
  rightIconOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  fullWidth?: boolean
  typographyClassname?: string
  usedInSplitButton?: boolean
  iconObetaRed?: boolean
}

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const {
    buttonType,
    size = 'large',
    onClick,
    leftIcon,
    fullWidth = true,
    rightIcon,
    disabled,
    type,
    rightIconOnClick,
    typographyClassname,
    children,
    usedInSplitButton,
    iconObetaRed = false,
    ...restButtonProps
  } = props

  const splitButton = buttonType === 'primary' && rightIcon

  return (
    <Stack
      direction={'row'}
      overflow={'hidden'}
      gap={splitButton ? '0.25rem' : '0'}
      className={clsx(styles.outerWrap, styles[buttonType], {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
        [styles.iconOnly]: !children && !usedInSplitButton,
        [styles.large]: size === 'large',
        [styles.splitButton]: splitButton,
        [styles.small]: size === 'small',
      })}
    >
      <ButtonBase
        disabled={disabled}
        type={type}
        className={clsx(styles['button-wrapper'], styles[buttonType])}
        disableRipple
        onClick={
          onClick
            ? (e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick(e)
              }
            : undefined
        }
        {...restButtonProps}
      >
        <Stack
          direction={'row'}
          gap={size === 'small' && buttonType !== 'tertiary' ? '0.25rem' : '0.5rem'}
          width={'100%'}
          overflow={'hidden'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {leftIcon && (
            <div className={iconObetaRed ? clsx(styles.icon, styles.iconObetaRed) : styles.icon}>
              {leftIcon}
            </div>
          )}
          {children && (
            <Typography
              variant={size === 'large' || buttonType === 'tertiary' ? 'bodyBold' : 'smallTextBold'}
              className={clsx(styles.submitTypography, typographyClassname)}
            >
              {children}
            </Typography>
          )}
        </Stack>
        {!splitButton && rightIcon && (
          <div className={clsx(styles.icon, styles.iconRight)}>{rightIcon}</div>
        )}
      </ButtonBase>
      {splitButton && (
        <ButtonBase
          disabled={disabled}
          type={type}
          className={clsx(styles.splitpart, styles[buttonType])}
          onClick={
            rightIconOnClick
              ? (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  rightIconOnClick(e)
                }
              : undefined
          }
          disableRipple
        >
          <div className={clsx(styles.icon)}>{rightIcon}</div>
        </ButtonBase>
      )}
    </Stack>
  )
}

type FrequentlyOmittedButtonProps = 'buttonType' | 'className' | 'size'

export const PrimaryButton: React.FC<
  PropsWithChildren<
    Omit<CustomButtonProps, 'buttonType' | 'className' | 'rightIconOnClick' | 'rightIcon'>
  >
> = ({ children, size, ...rest }) => {
  return (
    <CustomButton buttonType="primary" size={size ?? 'large'} {...rest}>
      {children}
    </CustomButton>
  )
}

export const PrimarySplitButton: React.FC<
  PropsWithChildren<
    Omit<CustomButtonProps, 'buttonType' | 'className'> & {
      rightIcon: JSX.Element
    }
  >
> = ({ children, ...rest }) => {
  return (
    <CustomButton buttonType="primary" usedInSplitButton={true} {...rest}>
      {children}
    </CustomButton>
  )
}

export const DarkButton: React.FC<
  PropsWithChildren<Omit<CustomButtonProps, FrequentlyOmittedButtonProps>>
> = ({ children, ...rest }) => {
  return (
    <CustomButton buttonType="dark" size="large" {...rest}>
      {children}
    </CustomButton>
  )
}

export const SecondaryButton: React.FC<
  PropsWithChildren<Omit<CustomButtonProps, FrequentlyOmittedButtonProps>>
> = ({ children, ...rest }) => {
  return (
    <CustomButton buttonType="secondary" size="large" {...rest}>
      {children}
    </CustomButton>
  )
}

export const SecondaryLightButton: React.FC<
  PropsWithChildren<Omit<CustomButtonProps, FrequentlyOmittedButtonProps>>
> = ({ children, ...rest }) => {
  return (
    <CustomButton buttonType="light" size="large" {...rest}>
      {children}
    </CustomButton>
  )
}

export const TertiaryButton: React.FC<
  PropsWithChildren<Omit<CustomButtonProps, 'buttonType' | 'className'>>
> = ({ children, size = 'small', ...rest }) => {
  return (
    <CustomButton buttonType="tertiary" size={size} {...rest}>
      {children}
    </CustomButton>
  )
}

type IconButtonProps = Omit<
  CustomButtonProps,
  'buttonType' | 'className' | 'children' | 'fullWidth'
> & {
  icon: JSX.Element
}

export const SecondaryIconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  icon,
  ...rest
}) => {
  return <CustomButton leftIcon={icon} buttonType="secondary" fullWidth={false} {...rest} />
}
export const SecondaryResponsiveIconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  children,
  ...rest
}) => {
  const { desktop } = useBreakpoints()
  return <SecondaryIconButton size={desktop ? 'small' : 'large'} {...rest} />
}

export const TertiaryIconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  icon,
  size = 'small',
  ...rest
}) => {
  return (
    <CustomButton leftIcon={icon} size={size} fullWidth={false} buttonType="tertiary" {...rest} />
  )
}
export const TertiaryResponsiveIconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  children,
  ...rest
}) => {
  const { desktop } = useBreakpoints()
  return (
    <TertiaryIconButton size={desktop ? 'small' : 'large'} {...rest}>
      {children}
    </TertiaryIconButton>
  )
}
