import { MangoQuerySortDirection } from 'rxdb'
import { useEntities } from './useEntities'
import { Message } from '@obeta/models/lib/models/Message'

const UNREAD_MESSAGES_QUERY = {
  selector: {
    status: {
      $eq: 'new',
    },
  },
  sort: [{ updatedAtNumber: 'desc' as MangoQuerySortDirection }],
}

export const useUnreadMessages = () => {
  return useEntities<Message>('message', UNREAD_MESSAGES_QUERY)
}
