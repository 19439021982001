import clsx from 'clsx'
import styles from './StatusBubble.module.scss'

interface IStatusBubble {
  color: string
  className?: string
}

export const StatusBubble: React.FC<IStatusBubble> = (props) => {
  return (
    <div
      className={clsx(styles.statusBubble, props.className)}
      style={{ backgroundColor: props.color }}
    />
  )
}
