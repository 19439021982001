import React, { useContext, CSSProperties, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { validateContextValue } from '@obeta/utils/lib/validateContextValue'

export interface IPageElements {
  headerElementRef: React.RefObject<HTMLElement>
  pageRoute: string
}

export const PageElementsContext = React.createContext<IPageElements | null>(null)

export const PageElementsProvider: React.FC<IPageElements> = (props) => {
  const { headerElementRef, pageRoute, ...restProps } = props

  return (
    <PageElementsContext.Provider
      value={{
        headerElementRef,
        pageRoute,
      }}
      {...restProps}
    />
  )
}

export const usePageElements = () => {
  const elements = useContext(PageElementsContext)
  return validateContextValue(elements, 'PageElementsContext', 'usePageElements')
}

type UseStickyElementStylesProps = {
  getSizesCallback?: (opts: { topDistance: number }) => void
}

export const useStickyElementStyles = ({ getSizesCallback }: UseStickyElementStylesProps) => {
  const [cssStyles, setCssStyles] = useState<CSSProperties>({ top: 0 })
  const { headerElementRef } = usePageElements()

  useResizeObserver(headerElementRef, (entry) => {
    getSizesCallback?.({ topDistance: entry.contentRect.height })

    setCssStyles({
      top: `calc(${entry.contentRect.height}px + var(--obeta-safe-area-top-root))`,
    })
  })

  return cssStyles
}
