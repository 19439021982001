import { DocumentNode, gql } from '@apollo/client'

export const createUserAddress: DocumentNode = gql`
  mutation createUserAddress($input: UserAddressCreateInput!) {
    createUserAddress(input: $input) {
      handleResult {
        success
        errorCode
        errorMessage
        addressId
      }
    }
  }
`
export const updateUserAddress: DocumentNode = gql`
  mutation updateUserAddress($input: UserAddressUpdateInput!) {
    updateUserAddress(input: $input) {
      handleResult {
        success
        errorCode
        errorMessage
        addressId
      }
    }
  }
`

export const deleteUserAddress: DocumentNode = gql`
  mutation deleteUserAddress($input: UserAddressDeleteInput!) {
    deleteUserAddress(input: $input) {
      handleResult {
        success
        errorCode
        errorMessage
        addressId
      }
    }
  }
`
