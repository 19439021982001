const weekDays = {
  1: 'Mo',
  2: 'Di',
  3: 'Mi',
  4: 'Do',
  5: 'Fr',
  6: 'Sa',
  7: 'So',
}

export const formatOpeningHours = (rawOpeningHours) => {
  if (rawOpeningHours?.length > 0) {
    const formattedOpeningHours: { times: string; days: string }[] = []
    let firstDay = weekDays[rawOpeningHours[0].weekday]
    let lastDay = weekDays[rawOpeningHours[0].weekday]
    let times = rawOpeningHours[0].from + ' - ' + rawOpeningHours[0].to

    for (let i = 1; i < rawOpeningHours.length; i++) {
      const current = rawOpeningHours[i]
      const previous = rawOpeningHours[i - 1]

      if (
        current.weekday - previous.weekday === 1 &&
        current.from === previous.from &&
        current.to === previous.to
      ) {
        lastDay = weekDays[current.weekday]
      } else {
        formattedOpeningHours.push({
          days: firstDay === lastDay ? firstDay : `${firstDay} - ${lastDay}`,
          times,
        })
        firstDay = weekDays[current.weekday]
        lastDay = weekDays[current.weekday]
        times = current.from + ' - ' + current.to
      }
    }

    if (firstDay) {
      formattedOpeningHours.push({
        days: firstDay === lastDay ? firstDay : `${firstDay} - ${lastDay}`,
        times,
      })
    }

    return formattedOpeningHours
  }

  return []
}
