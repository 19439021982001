import { useState, useEffect } from 'react'
import { useRxDB } from 'rxdb-hooks'
import { map } from 'rxjs'
import { initialMeta } from '@obeta/utils/lib/epics-helpers'

/**
 * @deprecated
 * Duplicated by useMetadata (check useEntity.ts module).
 * Data typing is improved there and metaData can be null.
 * @param entityName
 * @returns
 */
export const useEntityMetaData = (entityName: string) => {
  const db = useRxDB()
  const [metaData, setMetaData] = useState({ ...initialMeta })

  useEffect(() => {
    const collection = db.entitymeta
    const query = collection.findOne({
      selector: {
        id: entityName,
      },
    })
    const dataPipe = query.$.pipe(
      map((document) => (document ? { ...document.toJSON() } : { ...initialMeta }))
    )
    const dataSub = dataPipe.subscribe((doc) => setMetaData(doc))

    return () => {
      dataSub.unsubscribe()
    }
  }, [entityName, db])

  return metaData
}
