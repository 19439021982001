import { createContext, useCallback, useContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useInterval } from '../hooks/useInterval'
import { creditUsedInfoQuery } from '../queries/creditUsedInfo'
import { GetCreditUsedInfoQuery, GetCreditUsedInfoQueryVariables } from '@obeta/schema'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useUserDataV2 } from '../hooks/useUserDataV2'
import { noop } from '@obeta/utils/lib/noop'
import { useFeatureToggle } from '../hooks/feature-toggles'

type CreditLimitPreAlertValue = {
  showCreditLimitPreAlert: () => void
  closeCreditLimitPreAlertShown: () => void
}

const defaultValue: CreditLimitPreAlertValue = {
  showCreditLimitPreAlert: noop,
  closeCreditLimitPreAlertShown: noop,
}

const CREDIT_LIMIT_DISMISSED_LOCAL_STORAGE_KEY = 'lastDismissedCreditLimitPreAlert'
const CREDIT_LIMIT_CHECK_INTERVAL_IN_MS = 5 * 60 * 1000 //5 minutes
const CREDIT_LIMIT_PRE_ALERT_LIMIT = 0.8
const CREDIT_LIMIT_PRE_ALERT_INTERVAL = 24

const CreditLimitPreAlertContext = createContext<CreditLimitPreAlertValue>(defaultValue)

const CreditLimitPreAlertHandler = ({ children }) => {
  const [isNotificationShown, setIsNotificationShown] = useState(false)
  const apolloClient = useApolloClient()

  const updateLocalStorageEntry = useCallback(() => {
    localStorage.setItem(CREDIT_LIMIT_DISMISSED_LOCAL_STORAGE_KEY, new Date().toISOString())
  }, [])

  const showCreditLimitPreAlertNotification = useCallback((creditAvailable: number) => {
    setIsNotificationShown(true)
    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.CreditLimitPreAlert,
      id: 'credit-limit-pre-alert',
      options: {
        creditAvailable,
      },
    })
  }, [])

  const closeCreditLimitPreAlertShown = useCallback(() => {
    setIsNotificationShown(false)
    updateLocalStorageEntry()
  }, [updateLocalStorageEntry])

  const checkCreditRatioAndPreAlert = useCallback(async () => {
    if (isNotificationShown) {
      return
    }
    const response = await apolloClient.query<
      GetCreditUsedInfoQuery,
      GetCreditUsedInfoQueryVariables
    >({
      query: creditUsedInfoQuery,
    })
    if (!response.data.getUser.creditInfo) return

    const { creditLimit, creditUsed, creditAvailable } = response.data.getUser.creditInfo
    const creditUsedRatio = creditUsed / creditLimit

    if (creditUsedRatio >= CREDIT_LIMIT_PRE_ALERT_LIMIT) {
      showCreditLimitPreAlertNotification(creditAvailable)
    }
  }, [apolloClient, isNotificationShown, showCreditLimitPreAlertNotification])

  const showCreditLimitPreAlert = () => {
    const storedDismissDate = localStorage.getItem(CREDIT_LIMIT_DISMISSED_LOCAL_STORAGE_KEY)
    if (!storedDismissDate) {
      // no entry in local storage, we have to fetch
      checkCreditRatioAndPreAlert()
    } else {
      // check whether the time has elapsed, if so, fetch.
      const currentDate = new Date().toISOString()
      const dateFromLastAlertDismiss = Date.parse(storedDismissDate)
      const currentDateTimestamp = Date.parse(currentDate)
      const hoursDifference = (currentDateTimestamp - dateFromLastAlertDismiss) / (1000 * 60 * 60)
      if (hoursDifference >= CREDIT_LIMIT_PRE_ALERT_INTERVAL) {
        checkCreditRatioAndPreAlert()
      }
    }
  }
  // check in a time interval whether the credit limit used exceeds the limit
  useInterval(showCreditLimitPreAlert, { interval: CREDIT_LIMIT_CHECK_INTERVAL_IN_MS, lead: true })

  return (
    <CreditLimitPreAlertContext.Provider
      value={{ showCreditLimitPreAlert, closeCreditLimitPreAlertShown }}
    >
      {children}
    </CreditLimitPreAlertContext.Provider>
  )
}

export const CreditLimitPreAlertProvider = ({ children }) => {
  const isFeatureEnabled = useFeatureToggle('UseCreditLimitPreAlert')
  const { user, isLoggedIn } = useUserDataV2()

  if (
    typeof window === 'undefined' ||
    !localStorage ||
    !isLoggedIn ||
    !isFeatureEnabled ||
    !user?.permissions?.Global_showCreditWarning
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
  return <CreditLimitPreAlertHandler>{children}</CreditLimitPreAlertHandler>
}

export const useCreditLimitPreAlertContext = () => {
  return useContext(CreditLimitPreAlertContext)
}
