import { Box, ClickAwayListener } from '@mui/material'
import { CategoryTree, SubcategoryTypesEnum } from '@obeta/models/lib/models/Category'
import { useLoadCategoryTree } from '@obeta/data/lib/hooks/useLoadCategoryTree'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import styles from './AllCategory.module.scss'
import { DehaFirstCategory } from './DehaFirstCategory'
import { ObetaAndDehaSubcategoriesDesktop } from './ObetaAndDehaSubcategoriesDesktop'
import { useContext, useEffect, useRef, useState } from 'react'
import { SubCategoriesTabletAndMobile } from './SubCategoriesTabletAndMobile'
import { DivHoldingBackButton } from './DivHoldingBackButton'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { CategoryContext } from '@obeta/data/lib/hooks/useCategoriesList'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

interface ISubCatProps {
  dehaCatalogLevel0: boolean
  setDehaCatalogLevel0: (open: boolean) => void
  categoryDivWidth: number
  page?: string
}

export const SubCategoryLists: React.FC<ISubCatProps> = (props) => {
  const { dehaCatalogLevel0, setDehaCatalogLevel0, categoryDivWidth, page } = props
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { state } = useContext(CategoryContext)
  const {
    categoryElementWrapperDivoffsetLeft,
    bottomHeaderSectionDivoffsetTop,
    bottomHeaderSectionDivoffsetHeight,
    divMobileBottomHeaderSectionoffsetTop,
    divMobileBottomHeaderSectionoffsetHeight,
  } = state.categoryRefs
  const dehaFirstCategoryDiv = useRef<HTMLDivElement>(null)
  const [offsetLeftCategoryMenuItem, setOffsetLeftCategoryMenuItem] = useState<number>(0)
  const [dehaFirstCategoryDivWidth, setDehaFirstCategoryDivWidth] =
    useState<number>(categoryDivWidth)

  const [heightForScrollableSubcategoriesDesktop, setHeightForScrollableSubcategoriesDesktop] =
    useState<number>(0)
  const categoryTree = useLoadCategoryTree()
  const [mainLevelDataSelected, setMainLevelDataSelected] = useState<CategoryTree | undefined>()
  const [, setOpen] = useState<boolean>(false)
  const changeSearchParams = useChangeSearchParams()

  const show = useMobileScrollHandler({
    targetSectionHeight: 40,
    isEnabled: !mobile,
  })

  const { dimensions } = useHeaderDimensions()

  // Hide bottom section in desktop view as soon as padding of page would otherwise disappear - around 41px scrollTop
  const [
    endPositionForInfoAndTopHeaderSectionsMobile,
    setEndPositionForInfoAndTopHeaderSectionsMobile,
  ] = useState<number>(0)

  const topPositionCategoryForTablet = useRef<number>(0)

  useEffect(() => {
    if (bottomHeaderSectionDivoffsetTop && bottomHeaderSectionDivoffsetHeight) {
      topPositionCategoryForTablet.current =
        bottomHeaderSectionDivoffsetTop + bottomHeaderSectionDivoffsetHeight

      //Google Chrome calculates the window.outerHeight property wrongly, so this workaround with .innerheight is being used.
      //After this has been tested, topPositionCategoryForTablet might no longer be needed in which case it will be removed.
      setHeightForScrollableSubcategoriesDesktop(window.innerHeight)
    }

    if (divMobileBottomHeaderSectionoffsetTop && divMobileBottomHeaderSectionoffsetHeight) {
      setEndPositionForInfoAndTopHeaderSectionsMobile(
        divMobileBottomHeaderSectionoffsetTop + divMobileBottomHeaderSectionoffsetHeight
      )
    }

    window.addEventListener('scroll', () => {
      if (mobile && window.scrollY > 30) {
        //This is simply a reasonable value used to check if there is scroll
        setDehaCatalogLevel0(false)
        setMainLevelDataSelected(undefined)
        setOpen(false)
      }
    })
  }, [
    bottomHeaderSectionDivoffsetHeight,
    bottomHeaderSectionDivoffsetTop,
    divMobileBottomHeaderSectionoffsetHeight,
    divMobileBottomHeaderSectionoffsetTop,

    mobile,
    setDehaCatalogLevel0,
  ])

  useEffect(() => {
    if (!categoryElementWrapperDivoffsetLeft) return
    setOffsetLeftCategoryMenuItem(categoryElementWrapperDivoffsetLeft)
  }, [categoryElementWrapperDivoffsetLeft])

  useEffect(() => {
    if (!dehaFirstCategoryDiv.current?.offsetWidth) return
    setDehaFirstCategoryDivWidth(dehaFirstCategoryDiv.current?.offsetWidth)
  }, [dehaCatalogLevel0, dehaFirstCategoryDivWidth])

  const dehaCatalogLevel0ItemClicked = (id: string) => {
    const clickedElement = categoryTree.find((el) => {
      return id === el.id
    })
    setMainLevelDataSelected(clickedElement)
    // !desktop && setDehaCatalogLevel0(false)
  }

  useEffect(() => {
    if (!dehaCatalogLevel0) {
      setMainLevelDataSelected(undefined)
    }
  }, [dehaCatalogLevel0])

  const handleClickOnSubcategory = (p: {
    id: string
    title: string
    level: number
    subcategoryType: SubcategoryTypesEnum | undefined
    rootId: string | undefined
  }) => {
    const { id, title, level, subcategoryType, rootId } = p
    if (subcategoryType === SubcategoryTypesEnum.Obeta) {
      changeSearchParams({
        searchParams: {
          obetaCategory: { id: id, name: title, level: level, rootId: rootId },
        },
        route: 'push',
      })
    } else {
      changeSearchParams({
        searchParams: {
          dehaCategory: { id: id, name: title, level: level, rootId: rootId },
        },
        route: 'push',
      })
    }

    setOpen(false)
    setDehaCatalogLevel0(false)
    setMainLevelDataSelected(undefined)
  }

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    const targetEl = event.target && (event.target as HTMLElement | SVGElement)

    if (
      !targetEl?.closest('[data-clickawayprevented=true]') &&
      !targetEl?.closest('#categories-svg-element') &&
      !targetEl?.closest('#categories-span-element') &&
      !targetEl?.closest('#tablet-mobile-category-tab')
    ) {
      if (!page) {
        setDehaCatalogLevel0(false)
      }
      setMainLevelDataSelected(undefined)
      setOpen(false)
    }
  }

  const onClickButtonInDivHoldingBackButton = () => {
    setMainLevelDataSelected(undefined)
    setDehaCatalogLevel0(true)
  }

  if (mobile) {
    return (
      <>
        {dehaCatalogLevel0 && categoryTree && (
          <ClickAwayListener onClickAway={onClickAway}>
            <div className={styles.divWrapperMobile} data-clickawayprevented="true">
              <DehaFirstCategory
                categoryElements={categoryTree}
                dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
                endPositionForInfoAndTopHeaderSectionsMobile={
                  endPositionForInfoAndTopHeaderSectionsMobile
                }
              />
            </div>
          </ClickAwayListener>
        )}

        {mainLevelDataSelected && (
          <ClickAwayListener onClickAway={onClickAway}>
            <div
              data-clickawayprevented="true"
              className={clsx(styles.divListWrapper, styles.mobileWidth)}
              style={{
                top: endPositionForInfoAndTopHeaderSectionsMobile,
              }}
            >
              <SubCategoriesTabletAndMobile
                mainLevelDataSelected={mainLevelDataSelected}
                handleClickOnSubcategory={handleClickOnSubcategory}
                dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
                endPositionForInfoAndTopHeaderSectionsMobile={
                  endPositionForInfoAndTopHeaderSectionsMobile
                }
              />
              <DivHoldingBackButton
                mainLevelDataSelected={mainLevelDataSelected as CategoryTree}
                onClickBackButton={onClickButtonInDivHoldingBackButton}
              />
            </div>
          </ClickAwayListener>
        )}
      </>
    )
  } else if (page) {
    return (
      <>
        <ClickAwayListener onClickAway={onClickAway}>
          <Box data-clickawayprevented="true">
            {categoryTree && (desktop || tabletAll) && (
              <div
                ref={dehaFirstCategoryDiv}
                className={clsx(
                  `${desktop ? styles.homeListWrapper : styles.divListWrapper}`,
                  styles.addDropBoxShadow2,
                  {
                    [styles.divListWrapperScrollable]: desktop,
                  }
                )}
                style={{
                  left: desktop ? offsetLeftCategoryMenuItem : 0,
                  top: tabletAll
                    ? topPositionCategoryForTablet.current
                    : `calc(${dimensions.height}px + var(--obeta-safe-area-top-root))`,
                  maxHeight: desktop ? heightForScrollableSubcategoriesDesktop - 100 : '',
                }}
              >
                <DehaFirstCategory
                  categoryElements={categoryTree}
                  dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
                />
              </div>
            )}

            {dehaCatalogLevel0 && mainLevelDataSelected && desktop && (
              <ObetaAndDehaSubcategoriesDesktop
                mainLevelDataSelected={mainLevelDataSelected}
                leftOffset={offsetLeftCategoryMenuItem + dehaFirstCategoryDivWidth}
                handleClickOnSubcategory={handleClickOnSubcategory}
                heightForScrollableSubcategoriesDesktop={heightForScrollableSubcategoriesDesktop}
              />
            )}

            {show && mainLevelDataSelected && tabletAll && (
              <div
                className={clsx(styles.divListWrapper, styles.addDropBoxShadow2)}
                style={{
                  top: topPositionCategoryForTablet.current,
                  left: 0,
                }}
              >
                <SubCategoriesTabletAndMobile
                  mainLevelDataSelected={mainLevelDataSelected}
                  handleClickOnSubcategory={handleClickOnSubcategory}
                  dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
                  tabletTopPosition={topPositionCategoryForTablet.current}
                />
                <DivHoldingBackButton
                  mainLevelDataSelected={mainLevelDataSelected as CategoryTree}
                  onClickBackButton={onClickButtonInDivHoldingBackButton}
                />
              </div>
            )}
          </Box>
        </ClickAwayListener>
        {/* Let's get shady with part of the page for tablet */}
        {(dehaCatalogLevel0 || mainLevelDataSelected) && categoryTree && tabletAll && (
          <div
            className={styles.divBlur}
            style={{
              top: topPositionCategoryForTablet.current,
              left: window.innerWidth * (2 / 3),
            }}
          ></div>
        )}
      </>
    )
  }
  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box data-clickawayprevented="true">
          {show && dehaCatalogLevel0 && categoryTree && (desktop || tabletAll) && (
            <div
              ref={dehaFirstCategoryDiv}
              className={clsx(styles.divListWrapper, styles.addDropBoxShadow2, {
                [styles.divListWrapperScrollable]: desktop,
              })}
              style={{
                left: desktop ? offsetLeftCategoryMenuItem : 0,
                top: tabletAll
                  ? topPositionCategoryForTablet.current
                  : `calc(${dimensions.height}px + var(--obeta-safe-area-top-root))`,
                maxHeight: desktop ? heightForScrollableSubcategoriesDesktop - 100 : '',
              }}
            >
              <DehaFirstCategory
                categoryElements={categoryTree}
                dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
              />
            </div>
          )}

          {dehaCatalogLevel0 && mainLevelDataSelected && desktop && (
            <ObetaAndDehaSubcategoriesDesktop
              mainLevelDataSelected={mainLevelDataSelected}
              leftOffset={offsetLeftCategoryMenuItem + dehaFirstCategoryDivWidth}
              handleClickOnSubcategory={handleClickOnSubcategory}
              heightForScrollableSubcategoriesDesktop={heightForScrollableSubcategoriesDesktop}
            />
          )}

          {show && mainLevelDataSelected && tabletAll && (
            <div
              className={clsx(styles.divListWrapper, styles.addDropBoxShadow2)}
              style={{
                top: topPositionCategoryForTablet.current,
                left: 0,
              }}
            >
              <SubCategoriesTabletAndMobile
                mainLevelDataSelected={mainLevelDataSelected}
                handleClickOnSubcategory={handleClickOnSubcategory}
                dehaCatalogLevel0ItemClicked={dehaCatalogLevel0ItemClicked}
                tabletTopPosition={topPositionCategoryForTablet.current}
              />
              <DivHoldingBackButton
                mainLevelDataSelected={mainLevelDataSelected as CategoryTree}
                onClickBackButton={onClickButtonInDivHoldingBackButton}
              />
            </div>
          )}
        </Box>
      </ClickAwayListener>
      {/* Let's get shady with part of the page for tablet */}
      {(dehaCatalogLevel0 || mainLevelDataSelected) && categoryTree && tabletAll && (
        <div
          className={styles.divBlur}
          style={{
            top: topPositionCategoryForTablet.current,
            left: window.innerWidth * (2 / 3),
          }}
        ></div>
      )}
    </>
  )
}
