import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { getStoresQueryStr } from '../queries/getStores'
import { Store } from '@obeta/schema'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: Store): ICheckpoint => ({
  lastId: doc.id,
  since: doc.updatedAt,
})

export const storesv2Entity: EntityDescriptor<
  Omit<Store, '__typename' | 'deleted'>,
  ICheckpoint
> = {
  name: 'storesv2',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'storesv2',
    version: 2,
    description: 'a document representing all stores',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      company: {
        type: 'string',
      },
      country: {
        type: 'string',
      },
      region: {
        type: 'string',
      },
      address: {
        type: 'object',
      },
      phoneNumber: {
        type: 'string',
      },
      faxNumber: {
        type: 'string',
      },
      mail: {
        type: 'string',
      },
      latitude: {
        type: 'string',
      },
      longitude: {
        type: 'string',
      },
      openingHours: {
        type: 'array',
      },
      type: {
        type: 'string',
      },
      distance: {
        type: 'number',
      },
      updatedAt: {
        type: 'string',
      },
    },
  },
  subscription: null,
  pullSync: {
    batchSize: 100,
    queryBuilder: (checkpoint, limit) => {
      checkpoint = checkpoint || emptyCheckpoint
      return {
        query: getStoresQueryStr,
        variables: {
          ...checkpoint,
          batchSize: limit,
        },
      }
    },
    responseModifier: (
      documents: (Store & { _deleted: boolean })[],
      origin,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents: documents,
    }),
  },
  authentication: false,
  liveInterval: 3600 * 1000, // 1 h
}
