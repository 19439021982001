export enum ShopDomains {
  Eldis = 'eldis',
  Haeusler = 'haeusler',
  Obeta = 'obeta',
}

export const ShopFullDomains = {
  production: {
    obeta: 'https://obeta.de',
    eldis: 'https://eldis.de',
    haeusler: 'https://heinrich-haeusler.de',
  },
  staging: {
    obeta: 'https://obeta.public.staging.k8s.obeta.io',
    eldis: 'https://eldis.public.staging.k8s.obeta.io',
    haeusler: 'https://heinrich-haeusler.public.staging.k8s.obeta.io',
  },
}

export const CompanyToShopDomain = {
  '1010': ShopDomains.Obeta,
  '1030': ShopDomains.Haeusler,
  '1020': ShopDomains.Eldis,
}

export const ShopDomainToCompany = {
  [ShopDomains.Obeta]: '1010',
  [ShopDomains.Haeusler]: '1030',
  [ShopDomains.Eldis]: '1020',
}

export const DataPolicyIds = {
  obeta: '64ac14d386630',
  eldis: '64f98feb03b51',
  haeusler: '64f994e63841c',
}

export const CookieBotIds = {
  obeta: '6088c194-9674-40d5-af7e-7fcd890b9521',
  eldis: '9ca5647b-51ab-42be-8b5e-71b39edef1ce',
  haeusler: '8af8d382-ea6a-4cea-9386-7ad3ac7170b0',
}
