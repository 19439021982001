import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

interface PropsMetalQuotation {
  aluminiumQuotation: number
  copperQuotation: number
  shopCopyrightByDomain: string
}

export const SectionMetalQuotationCopyrightNote: React.FC<PropsMetalQuotation> = (
  PropsMetalQuotation
) => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const { aluminiumQuotation, copperQuotation, shopCopyrightByDomain } = PropsMetalQuotation
  const user = useUserDataV2()

  return (
    <div className={styles.sectionMetalQuotationCopyrightNote}>
      {(desktop || tabletWide) && (
        <div
          className={
            user.isLoggedIn
              ? styles.copyRightText
              : clsx(styles.copyRightText, styles.sideMarginsAuto)
          }
        >
          <Typography variant={'body'}> {t(shopCopyrightByDomain)} </Typography>
        </div>
      )}

      {user.isLoggedIn && (
        <Box>
          <div className={styles.metalQuotationTitle}>
            <Typography variant={'bodyBold'}> {t('FOOTER.CURRENT_METALQUOTATION')} </Typography>
          </div>
          <div
            className={
              !mobile
                ? clsx(styles.metalQuotationInfo, styles.marginRight14px, styles.marginLeft14px)
                : clsx(styles.metalQuotationInfo, styles.marginRight14px)
            }
          >
            <Typography variant={'body'}>
              {t('FOOTER.ALUMINIUM_QUOTATION_TEXT', {
                aluminiumQuotation: normalizePrice(aluminiumQuotation, false),
              })}
            </Typography>
          </div>
          <div className={styles.metalQuotationInfo}>
            <Typography variant={'body'}>
              {t('FOOTER.COPPER_QUOTATION_TEXT', {
                copperQuotation: normalizePrice(copperQuotation, false),
              })}
            </Typography>
          </div>
        </Box>
      )}

      {(mobile || tablet) && (
        <>
          <Divider variant={'fullWidth'} className={styles.dividerSectionMetalQuotation}></Divider>
          <div className={clsx(styles.copyRightText, styles.textCentered)}>
            <Typography variant={'xSmallText'}> {t(shopCopyrightByDomain)} </Typography>
          </div>
        </>
      )}
    </div>
  )
}
