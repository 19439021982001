import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getOxomiMetadata, loadCategories } from '@obeta/data/lib/actions'
import { GoBackOrExitAppProvider } from '@obeta/data/lib/hooks'

type AppWrapperProps = {
  isLoggedIn: boolean
  children: ReactNode
}

export const AppWrapper: React.FC<AppWrapperProps> = ({ isLoggedIn, children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(getOxomiMetadata(15 * 1000 * 60))
  }, [dispatch, isLoggedIn])

  return <GoBackOrExitAppProvider>{children}</GoBackOrExitAppProvider>
}
