import React from 'react'
import { SearchInput } from '../searchbar'
import { ITopSectionProps } from '../../types'
import styles from './TopSection.module.scss'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import { CartsControls } from '../CartsControlls'
import { Profile } from '../Profile'
import { StoreMenuItem } from '../Store'
import { SEARCH_INPUT_PLACEHOLDER } from '../../constants'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Link } from '@mui/material'
import clsx from 'clsx'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'
import { AddressInfo } from '../address-info/AddressInfo'
import { useAddressInfo } from '../address-info/useAddressInfo'

export const TopSection: React.FC<ITopSectionProps> = (props) => {
  const { isLoggedIn, logo } = props
  const { mobile } = useBreakpoints()
  const isWeb = isPlatform('web')
  const isMobileAndNotLoggedIn = !isLoggedIn && mobile

  const mobileSectionHeight = useRemToPx(parseFloat(styles.mobileFullHeight))

  const headerElement = document.getElementById('app-header')

  const show = useMobileScrollHandler({
    targetSectionHeight: mobileSectionHeight,
    isEnabled: mobile,
    options: {
      hoverSection: isWeb ? headerElement : null,
      showOnScrollUp: true,
    },
  })

  const { redirectToStartPage } = useRedirect()
  const { showAddressInfo } = useAddressInfo()

  if (!show) {
    return null
  }

  if (isMobileAndNotLoggedIn) {
    return (
      <div className={clsx(styles.topHeaderSection, styles.mobileLoggedOut)}>
        <div className={styles.logo} onClick={redirectToStartPage}>
          {logo}
        </div>
        <div className={styles.loginButton}>
          <Profile />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.topHeaderSection}>
      <div className={styles.topHeaderContent}>
        <div className={styles.logoSearchbarWrapper}>
          <Link
            href={'/'}
            onClick={(event: React.MouseEvent<HTMLElement>) => redirectToStartPage(event)}
          >
            <div className={styles.logo}>{logo}</div>
          </Link>
          {!mobile && showAddressInfo && (
            <div className={styles.addressInfo}>
              <AddressInfo />
            </div>
          )}
          {!mobile && (
            <div className={styles.searchbar}>
              <SearchInput
                placeholder={SEARCH_INPUT_PLACEHOLDER}
                showBarCodeScanner={!isPlatform('web')}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          {isLoggedIn && <StoreMenuItem />}
          <Profile />
          {isLoggedIn && <CartsControls />}
        </div>
      </div>
    </div>
  )
}
