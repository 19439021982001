import React from 'react'
import { Typography } from '@mui/material'
import { ISuggestionOptionProps, ISuggestionWrapperProps, ISuggestionItem } from './types'
import styles from './Suggestion.module.scss'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { ReactComponent as SearchIcon } from '@obeta/assets/icon/search20x20.svg'
import { ReactComponent as ClockIcon } from '@obeta/assets/icon/clock.svg'
import { ESuggestionType } from '@obeta/data/lib/hooks/useSearchDropdownData'
import clsx from 'clsx'

const ICON_BY_TYPE = {
  [ESuggestionType.Suggestion]: <SearchIcon />,
  [ESuggestionType.History]: <ClockIcon />,
}

export const SuggestionItem: React.FC<ISuggestionItem> = (props) => {
  const { item, highlighted } = props
  const variant = highlighted ? 'bodyBold' : 'body'

  return <Typography variant={variant}>{item}</Typography>
}

export const SuggestionOption: React.FC<ISuggestionOptionProps> = (props) => {
  const { optionProps, option, inputValue, selected } = props
  const matches = match(option.title, inputValue, { insideWords: true })
  const parts = parse(option.title, matches)

  return (
    <li
      {...optionProps}
      className={clsx(styles['search-suggestion-item'], {
        [styles['search-suggestion-item-selected']]: selected,
      })}
    >
      <div className={styles['search-suggestion-result']}>
        <div className={styles['search-suggestion-icon']}>
          {option?.type ? ICON_BY_TYPE[option.type] : <SearchIcon />}
        </div>
        <div>
          {parts.map((part, index) => (
            <SuggestionItem
              key={index}
              item={part.text}
              highlighted={!!inputValue && !part.highlight}
            />
          ))}
        </div>
      </div>
    </li>
  )
}

export const SuggestionWrapper: React.FC<ISuggestionWrapperProps> = (props) => {
  const { group } = props

  return (
    <div className={styles['search-suggestion-wrapper']}>
      <Typography variant="bodyBold">{group.group}</Typography>
      <div className={styles['search-suggestion-items']}>{group.children}</div>
    </div>
  )
}
