import React from 'react'
import styles from './StoreAddress.module.scss'
import Typography from '@mui/material/Typography'

interface IStoreAddressProps {
  street: string
  city: string
  variant: 'bodyBold' | 'body' | 'smallText'
}

export const StoreAddress: React.FC<IStoreAddressProps> = (props) => {
  const { street, city, variant } = props
  return (
    <div className={styles.address}>
      <Typography variant={variant}>{street}</Typography>
      <Typography variant={variant}>{city}</Typography>
    </div>
  )
}
