interface Action {
  type: OfferActionTypes
}

export enum OfferActionTypes {
  CreateDownloadableOffer = '[Offers] Create Downloadable Offer',
  CreateDownloadableOfferResult = '[Offers] Create Downloadable Offer Result',
}

export interface CreateDownloadableOfferAction extends Action {
  readonly type: OfferActionTypes.CreateDownloadableOffer
  offerId: string
}
export const createDownloadableOfferAction = (offerId: string): CreateDownloadableOfferAction => ({
  type: OfferActionTypes.CreateDownloadableOffer,
  offerId,
})
export interface CreateDownloadableOfferResultAction extends Action {
  readonly type: OfferActionTypes.CreateDownloadableOfferResult
  offerLinkCreated: boolean
}
export const getCreateDownloadableOfferResult = (
  offerLinkCreated: boolean
): CreateDownloadableOfferResultAction => ({
  type: OfferActionTypes.CreateDownloadableOfferResult,
  offerLinkCreated,
})
