export enum FastProductEntryTabState {
  FileUpload = 'FILE_UPLOAD',
  SingleEntry = 'SINGLE_ENTRY',
}

export enum SingleEntryState {
  Empty = 'EMPTY',
  Error = 'ERROR',
  Submitted = 'SUBMITTED',
}
