import { EventType, getEventSubscription, NotificationEvent, NotificationType } from './pubSub'
import { MessageType, ResponseMessage } from '@obeta/schema'

type ResponseMessageToNotificationOptions = {
  responseMessage: ResponseMessage
  notificationType?: NotificationType
  options?: NotificationEvent['options']
  id?: string
}

/**
 * Creates a notification from a {@link ResponseMessage} object
 * @param {ResponseMessage} responseMessage - received as part of a response
 * @param {string} id - the id can be used, i.e. to remove the notication later on
 */
export function responseMessageToNotification({
  responseMessage,
  id,
  notificationType,
  options,
}: ResponseMessageToNotificationOptions) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: notificationType ?? messageTypeToNotificationType(responseMessage.type),
    id: id ?? 'response-message-notification',
    options: options ?? {
      message: responseMessage.message,
      messageActions: responseMessage.actions,
    },
  })
}

export function responseMessageToUpdateUserNotification(
  responseMessage: ResponseMessage,
  action?: string,
  userId?: string
): void {
  const id = `updated-user-${new Date().getTime()}`
  responseMessageToNotification({
    responseMessage,
    id,
    notificationType: NotificationType.UpdateUser,
    options: {
      message: responseMessage.message,
      messageActions: responseMessage.actions,
      type: responseMessage.type,
      action: action ?? undefined,
      userId: userId ?? undefined,
    },
  })
}

function messageTypeToNotificationType(
  messageType: MessageType
): NotificationType.Success | NotificationType.Error {
  switch (messageType) {
    case 'Success':
      return NotificationType.Success
    case 'Error':
      return NotificationType.Error
  }
}
