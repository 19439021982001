import { LatLonPosition } from '@obeta/models/lib/models/lat-lon-position'

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const getDistanceInKM = (pos1: LatLonPosition, pos2: LatLonPosition) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(pos2.latitude - pos1.latitude) // deg2rad below
  const dLon = deg2rad(pos2.longitude - pos1.longitude)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(pos1.latitude)) *
      Math.cos(deg2rad(pos2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in km
}
