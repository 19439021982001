import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'

export enum OpenPositionsActionTypes {
  GetOpenPositions = '[OpenPositions] Get open positions ',
  GetOpenPositionsResult = '[OpenPositions] Get open positions  Result',
}

interface OpenPositionsAction {
  type: OpenPositionsActionTypes
}

export interface GetOpenPositionsAction extends OpenPositionsAction {
  readonly type: OpenPositionsActionTypes.GetOpenPositions
}

export const getOpenPositions = (): GetOpenPositionsAction => ({
  type: OpenPositionsActionTypes.GetOpenPositions,
})

export interface GetOpenPositionsResultAction extends OpenPositionsAction {
  items: OpenPosition[]
}

export const getOpenPositionsResult = (items: OpenPosition[]): GetOpenPositionsResultAction => ({
  type: OpenPositionsActionTypes.GetOpenPositionsResult,
  items,
})
