import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { useInitialUserDataV2 } from '../../hooks/useUserDataV2'

export const useUserContextUpdate = (updateLaunchDarklyContext: () => void) => {
  const userV2Data = useInitialUserDataV2()
  useEffect(() => {
    if (userV2Data?.fullUserId) {
      const { userId: datadogUserId } = datadogRum.getUser()
      const isDifferentUser = datadogUserId !== userV2Data.fullUserId
      const shouldIdentifyDatadog = !datadogUserId || isDifferentUser

      if (shouldIdentifyDatadog) {
        datadogRum.setUser({
          id: userV2Data.fullUserId,
          companyId: userV2Data.companyId,
        })
      }
    }
    updateLaunchDarklyContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userV2Data])
}
