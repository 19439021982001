import { ShopDomains } from './domains'

export const getValidAppDomain = () => {
  if (typeof window === 'undefined') return
  const hostname = window.location.hostname.toLocaleLowerCase()
  const validDomains = Object.values(ShopDomains)
  /**
   * We could use util extracts domain from hostname without TLD and "www".
   * But it won't work as expected wor TLD like ".co.uk".
   * To traverse domains array and find valid one is more reliable solution and doesn't affect
   * performance (array is very small)
   */
  return validDomains.find((d) => {
    return hostname.indexOf(d.toLocaleLowerCase()) !== -1
  })
}
