import { gql } from '@apollo/client'

const userFields = `
  customerNumber
  settings {
    defaultCartId
    defaultStoreId
    name
    profilePictureS3Path
  }
  algoliaUserToken
  isTecselectUser
  tecselectLoginUrl
  hasFreeShipping
  type
  customerCard {
    barcode,
    customerNumber,
    reasonDisabled
  }
  isActive
  email
  emailData {email, isConfirmed}
  permissions {
    Global_canReadPrices
    Address_canWrite
    Address_canRead
    Cart_canWriteAddress
    Cart_canWriteCommission
    Cart_canWriteAdditionalText
    Cart_requiredCommission
    Cart_allowedSubmitAction
    Cart_canChangePayment
    Offers_canRead
    Orders_canRead
    Projects_canManage
    User_canChangeDefaultAddress
    User_shouldReceiveOrderConfirmations
    Global_canReadBackorderItems
    Global_canReadAccountInfo
    Global_canUseInterfaces
    Global_canSeeUserManagement
    Global_canUseCustomDataSheet
    Global_showCreditWarning
    User_canReadAccounting
    Cart_maxCartValue
  }
`

export const gqlQueryUserV2 = gql`
  query getUser {
    getUser {
      ${userFields}
    }
  }
`

export const gqlUpdateUserV2 = gql`
  mutation updateUser($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      ${userFields}
    }
  }
`

export const assignCustomerCard = gql`
  mutation assignCustomerCard($input: CustomerCardInput!) {
    assignCustomerCard(input: $input) {
      type
      message
    }
  }
`
