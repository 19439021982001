import { useCallback } from 'react'
import { WebUtils } from './web-utils'
import { getWebOptions } from './utils'
import { useLoginRedirect } from '../useLoginRedirect'

export const useOauth2Web = () => {
  const { savePath } = useLoginRedirect()

  const authenticate = useCallback(
    async (options) => {
      const webOptions = await getWebOptions(options)
      if (!webOptions.appId || webOptions.appId.length === 0) {
        throw new Error('ERR_PARAM_NO_APP_ID')
      }

      if (!webOptions.authorizationBaseUrl || webOptions.authorizationBaseUrl.length === 0) {
        throw new Error('ERR_PARAM_NO_AUTHORIZATION_BASE_URL')
      }

      if (!webOptions.redirectUrl || webOptions.redirectUrl.length === 0) {
        throw new Error('ERR_PARAM_NO_REDIRECT_URL')
      }

      if (!webOptions.responseType || webOptions.responseType.length === 0) {
        throw new Error('ERR_PARAM_NO_RESPONSE_TYPE')
      }

      const authorizationUrl = WebUtils.getAuthorizationUrl(webOptions)
      if (webOptions.logsEnabled) {
        // eslint-disable-next-line no-console
        console.log('Authorization url: ' + authorizationUrl)
      }

      // we will use this state and code challenge
      // after user entered credentials and was redirected back to application
      WebUtils.saveVerificationData(webOptions.state, webOptions.pkceCodeVerifier)

      savePath()
      window.open(authorizationUrl, webOptions.windowTarget, webOptions.windowOptions)

      // at this point we expect that current window will be replaced by new window where
      // user should enter his credentials
    },
    [savePath]
  )

  return { authenticate }
}
