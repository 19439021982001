import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { ofType } from 'redux-observable'
import { catchError, defer, Observable, of, retry, switchMap } from 'rxjs'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import {
  CreateDigitalBillingEntryMutation,
  CreateDigitalBillingEntryMutationVariables,
  GetUserHasDigitalBillingEntryQuery,
  GetUserHasDigitalBillingEntryQueryVariables,
} from '@obeta/schema'
import {
  CreateDigitalBillingEntryAction,
  createDigitalBillingEntryResult,
  DigitalBillingActionTypes,
  GetDigitalBillingEntryAction,
  getDigitalBillingEntryResult,
} from '../actions/digital-billing-actions'

export const getDigitalBillingEntryGraphQl = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetDigitalBillingEntryAction>) =>
    actions$.pipe(
      ofType(DigitalBillingActionTypes.GetDigitalBillingEntry),
      switchMap(() => {
        return defer(async () => {
          const response = await apolloClient.query<
            GetUserHasDigitalBillingEntryQuery,
            GetUserHasDigitalBillingEntryQueryVariables
          >({
            query: gql`
              query getUserHasDigitalBillingEntry {
                getUserHasDigitalBillingEntry
              }
            `,
          })
          return getDigitalBillingEntryResult(response.data.getUserHasDigitalBillingEntry)
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(getDigitalBillingEntryResult(false))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(getDigitalBillingEntryResult(false))
      })
    )
}

export const createDigitalBillingEntryGraphQl = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<CreateDigitalBillingEntryAction>) =>
    actions$.pipe(
      ofType(DigitalBillingActionTypes.CreateDigitalBillingEntry),
      switchMap((action: CreateDigitalBillingEntryAction) => {
        return defer(async () => {
          const response = await apolloClient.mutate<
            CreateDigitalBillingEntryMutation,
            CreateDigitalBillingEntryMutationVariables
          >({
            mutation: gql`
              mutation createDigitalBillingEntry($input: AddUserDigitalBillingInput!) {
                createDigitalBillingEntryForUser(input: $input)
              }
            `,
            variables: {
              input: {
                billingType: action.billingType,
                email: action.email,
              },
            },
          })
          const success = response?.data?.createDigitalBillingEntryForUser ?? false
          return createDigitalBillingEntryResult(success)
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(createDigitalBillingEntryResult(false))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(createDigitalBillingEntryResult(false))
      })
    )
}

export const initDigitalBillingEpics = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return [
    getDigitalBillingEntryGraphQl(apolloClient),
    createDigitalBillingEntryGraphQl(apolloClient),
  ]
}
