import { useEffect, useState } from 'react'
import {
  getUserGraphQLIsCashCustomer,
  GetUserGraphQLIsCashCustomerResultAction,
  UserActionTypes,
} from '../actions'
import { useActionNotification } from './'
import { useDispatch } from 'react-redux'

export const useCheckIsCashCustomer = (): {
  isCashCustomer: boolean
  isLoadingCashCustomerCheck
} => {
  const dispatch = useDispatch()
  const [userIsCashCustomer, setUserIsCashCustomer] = useState(true)
  const [isLoadingCashCustomerCheck, setIsLoadingCashCustomerCheck] = useState(true)
  const waitForGetUserIsCashCustomerResultResult = useActionNotification(
    UserActionTypes.GetUserGraphQLIsCashCustomerResult
  )

  useEffect(() => {
    dispatch(getUserGraphQLIsCashCustomer())
    waitForGetUserIsCashCustomerResultResult((action: GetUserGraphQLIsCashCustomerResultAction) => {
      setUserIsCashCustomer(action.isCashCustomer)
      setIsLoadingCashCustomerCheck(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return { isCashCustomer: userIsCashCustomer, isLoadingCashCustomerCheck }
}
