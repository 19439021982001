import { useRxDB } from 'rxdb-hooks'
import { useState, useEffect } from 'react'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'

export interface IRecommendations {
  data: string[]
}

export const useSearchRecommendations = () => {
  const db = useRxDB()
  const [document, setDocument] = useState<RxLocalDocument<RxDatabase, IRecommendations> | null>(
    null
  )
  const [latestRev, setLatestRev] = useState<IRecommendations | null>(null)

  useEffect(() => {
    const getUserDocument = async () => {
      const recoms = await db.getLocal<IRecommendations>('recommendations')
      setDocument(recoms)
    }
    getUserDocument()
  }, [db])

  useEffect(() => {
    if (document) {
      const sub = document.$.subscribe((doc: RxLocalDocumentData<IRecommendations>) => {
        setLatestRev(doc.data)
      })

      return () => {
        sub.unsubscribe()
      }
    }
  }, [document])

  return latestRev
}
