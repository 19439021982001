import React, { useState } from 'react'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import styles from './AllCategory.module.scss'
import { CategoryElementProps, MobileProps } from './types'
import { Subcategories } from './Subcategories'
import { SubcategoryTypesEnum } from '@obeta/models/lib/models/Category'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'

export const SubCategoriesTabletAndMobile: React.FC<CategoryElementProps & MobileProps> = (
  props
) => {
  const { tabletAll } = useBreakpoints()
  const [value, setValue] = useState('1')
  const { mainLevelDataSelected } = props

  const widthTablet = window.innerWidth * (2 / 3)

  const handleTabClick = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box id={'tablet-mobile-category-tab'} style={tabletAll ? { width: widthTablet } : {}}>
      <TabContext value={value}>
        <Box className={styles.boxWrapperTabs}>
          <TabList
            onChange={handleTabClick}
            aria-label="tablet and mobile category tab list"
            TabIndicatorProps={{ children: <span /> }}
          >
            <Tab label="Lagersortimentskatalog" value="1" />
            <Tab label="Onlinekategorien" value="2" />
          </TabList>
        </Box>
        <TabPanel className={clsx(styles.tabPanel)} value="1">
          <Subcategories
            categoryElements={mainLevelDataSelected?.obetaSubCategories}
            subcategoryType={SubcategoryTypesEnum.Obeta}
            {...props}
          />
        </TabPanel>
        <TabPanel className={clsx(styles.tabPanel)} value="2">
          <Subcategories categoryElements={mainLevelDataSelected?.dehaSubCategories} {...props} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}
