import { Category } from '@obeta/models/lib/models/Category'
import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

export const categoriesEntity: EntityDescriptor<
  Omit<Category, 'url' | 'level' | 'parent' | 'rootId' | 'permission' | 'isDisabled'>
> = {
  name: 'categories',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'categories',
    version: 2,
    description: 'a document representing a category',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      name: {
        type: 'string',
      },
    },
  },
  subscription: null,
}
