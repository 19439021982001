import { useHistory } from './useHistoryApi'
import { usePdfFlags } from './usePdfFlags'

const LOGIN_START_URL_KEY = 'loginStartUrl'

interface ILoginRedirectResponse {
  savePath: VoidFunction
  redirectBack: VoidFunction
  clearPath: VoidFunction
  currentPath: string | null
}

export const useLoginRedirect = (): ILoginRedirectResponse => {
  const history = useHistory()
  const { isCartItemForPdf } = usePdfFlags()

  // TODO: to avoid errors when hook is called from print-service render
  if (isCartItemForPdf) {
    return {
      currentPath: '',
      savePath: () => {
        /**/
      },
      redirectBack: () => {
        /**/
      },
      clearPath: () => {
        /**/
      },
    }
  }

  const currentPath = localStorage.getItem(LOGIN_START_URL_KEY)

  const setPathToLS = (path) => localStorage.setItem(LOGIN_START_URL_KEY, path)

  const savePath = () => {
    const { pathname, search } = window.location
    const path = pathname + search
    setPathToLS(path)
  }

  const redirectBack = () => {
    if (currentPath) {
      history.replace(currentPath)
    }
  }

  const clearPath = () => setPathToLS('')

  return { savePath, redirectBack, currentPath, clearPath }
}
