import React from 'react'
import styles from './AllCategory.module.scss'
import { Button, SvgIcon, Typography } from '@mui/material'
import { ReactComponent as ChevronLeft } from 'assets/icon/designsystem/chevron_left.svg'
import { CategoryTree } from '@obeta/models/lib/models/Category'
import { CategoryIcon } from '../categories/Categories'

interface DivHoldingBackButtonProps {
  mainLevelDataSelected: CategoryTree
  onClickBackButton: () => void
}

export const DivHoldingBackButton: React.FC<DivHoldingBackButtonProps> = (props) => {
  const { mainLevelDataSelected, onClickBackButton } = props

  return (
    <div
      className={styles.divHoldingBackButton}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onClickBackButton()
      }}
    >
      <Button
        className={styles.backButton}
        variant={'outlined'}
        fullWidth={false}
        size={'small'}
        startIcon={<SvgIcon component={ChevronLeft} />}
      >
        <CategoryIcon
          catId={`hid${mainLevelDataSelected.id}`}
          imgSize="xs"
          className={styles.marginLeftHalfRem}
        />
        <Typography variant={'body'} className={styles.marginLeftHalfRem}>
          {mainLevelDataSelected.title}
        </Typography>
      </Button>
    </div>
  )
}
