import { useMemo } from 'react'
import { useEntities } from './useEntities'

export const useMarkedForNotifactionSet = () => {
  const markedCarts: { id: string }[] = useEntities('markedcarts')

  return useMemo(() => {
    const ids = markedCarts.map((cart) => {
      return cart.id
    })

    return new Set(ids)
  }, [markedCarts])
}
