import dayjs from 'dayjs'
import {
  OrderItemStateType,
  OrderOrderBy,
  OrderOrderDir,
  OrderShippingType,
  OrderType,
  ShippingType,
} from '@obeta/schema'
import { QueryParams } from '../VirtualizedList'

// View models
export type OrderItemStateViewType = OrderItemStateType | 'all'

export type OrderListFilters = {
  dateFrom?: string
  dateTo?: string
  itemStates: OrderItemStateType[]
  orderType: string[]
  purchaser: string[]
  projectName: string[]
  searchTerm: string
  shippingType: string[]
  supplierIds: string[]
  orderBy?: OrderOrderBy
  orderDir?: OrderOrderDir
}

export type OrderListURLSearchParams = {
  dateFrom?: string
  dateTo?: string
  itemStates?: string
  memo?: string
  orderType?: string
  purchaser?: string
  projectName?: string
  searchTerm?: string
  shippingType?: string
  supplierIds?: string
  orderBy?: string
  orderDir?: string
}

export const DEFAULT_ORDER_LIST_LIMIT = '16'
export const DEFAULT_ORDER_LIST_ITEM_LIMIT = '15' // depends on viewport width: for tablet wide '11', tablet small '12', mobile '7'

export const DEFAULT_ORDER_LIST_FILTERS: OrderListFilters = {
  searchTerm: '',
  dateFrom: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
  dateTo: dayjs().format('YYYY-MM-DD'),
  itemStates: [],
  orderType: [],
  purchaser: [],
  projectName: [],
  shippingType: [],
  supplierIds: [],
  orderBy: 'orderId',
  orderDir: 'DESC',
}

export const DEFAULT_ORDER_LIST_QUERY_PARAMS: QueryParams = {
  limit: '16',
  offset: '0',
}

export const FACETS_ITEM_STATES_ORDER: OrderItemStateType[] = [
  'Archived',
  'Delivered', // todo: duplicate
  'Shipped', // todo: duplicate
  'PickedUp',
  'ReadyForPickup', // todo: duplicate
  'ReadyForPickUp', // todo: duplicate
  'Invoiced',
  // 'InArrears', // todo: v2
  // 'InProgress', // todo: v2
  'InDelivery',
  'Canceled',
  'DeliveredPartially', // todo: duplicate
  'PartiallyDelivered', // todo: duplicate
  'PartiallyCollected',
  'PartiallyReadyForPickUp',
  'Credit',
  'None',
  // todo: archive missing?
]

export const FACETS_ORDER_TYPE_ORDER: OrderType[] = ['Order', 'Retoure']

export const FACETS_SHIPPING_TYPE_ORDER: ShippingType[] = [
  'DefaultPickup',
  'ExpressPickup',
  'PickupRenzbox',
  'Cargo',
  'DefaultParcel',
  'FastParcel',
]

export const ORDER_ITEM_STATE_TYPE_TRANSLATIONS: Record<OrderItemStateType, string> = {
  Archived: 'ORDERS.STATES.ARCHIVE',
  Canceled: 'ORDERS.STATES.CANCELLED',
  Credit: 'ORDERS.STATES.CREDIT',
  Delivered: 'ORDERS.STATES.SHIPPED',
  DeliveredPartially: 'ORDERS.STATES.PARTIAL_DELIVERY',
  InArrears: 'ORDERS.STATES.IN_ARREARS',
  InDelivery: 'ORDERS.STATES.IN_DELIVERY',
  InProgress: 'ORDERS.STATES.IN_PROGRESS',
  Invoiced: 'ORDERS.STATES.INVOICED',
  None: 'ORDERS.STATES.NONE',
  PartiallyCollected: 'ORDERS.STATES.PARTIAL_COLLECTION',
  PartiallyDelivered: 'ORDERS.STATES.PARTIAL_DELIVERY',
  PartiallyReadyForPickUp: 'ORDERS.STATES.PARTIALLY_READY_FOR_PICK_UP',
  PickedUp: 'ORDERS.STATES.PICKED_UP',
  ReadyForPickup: 'ORDERS.STATES.READY_FOR_PICK_UP',
  ReadyForPickUp: 'ORDERS.STATES.READY_FOR_PICK_UP',
  Shipped: 'ORDERS.STATES.SHIPPED',
}

export const ORDER_SHIPPING_TYPE_TRANSLATIONS: Record<OrderShippingType, string> = {
  Cargo: 'ORDERS.SHIPPING.TYPE.CARGO',
  DefaultParcel: 'ORDERS.SHIPPING.TYPE.DEFAULT_PARCEL',
  DefaultPickup: 'ORDERS.SHIPPING.TYPE.DEFAULT_PICKUP',
  ExpressPickup: 'ORDERS.SHIPPING.TYPE.EXPRESS_PICKUP',
  FastParcel: 'ORDERS.SHIPPING.TYPE.FAST_PARCEL',
  None: 'ORDERS.SHIPPING.TYPE.NORMAL',
}

export const ORDER_TYPE_TRANSLATIONS: Record<OrderType, string> = {
  Cash: 'ORDERS.TYPES.CASH',
  Interface: 'ORDERS.TYPES.INTERFACE',
  None: 'ORDERS.TYPES.NORMAL',
  Order: 'ORDERS.TYPES.NORMAL',
  Retoure: 'ORDERS.TYPES.RETOURE',
  Single: 'ORDERS.TYPES.SINGLE',
  Store: 'ORDERS.TYPES.STORE',
}
