import { ofType } from 'redux-observable'
import { first, take, map, tap, takeWhile } from 'rxjs'
import { useCallback } from 'react'
import { noop } from '../actions'
import { useDbUtils } from './useDbUtils'

/**
 * This hook is intended to allow to subscribe to ephemeral state, i.e. a response data of an http
 * request, that will not be stored in the local database
 *
 * An example is the orderId or list of warnings, the user receives after submitting a cart
 *
 * Based on the use case, a second count parameter can be passed to control how many emissions of this
 * action we want to receive. For adding a callback permanently, pass -1 as count. If count is positive integer,
 * the callback will be called on <count> number of emissions. If count is not being passed, only the first emission
 * will be considered. The subscription will unsubscribe automatically, once the intended number of emissions is reached
 *
 * @param type union of all defined actionTypes  throughout the app
 */
export const useActionNotification = (type: string) => {
  const { epicInit } = useDbUtils()
  const func = useCallback(
    (callback, count?: number) => {
      let obs
      epicInit.next((action$) => {
        obs = action$.pipe(
          ofType(type),
          count === -1 ? takeWhile(() => true) : count ? take(count) : first(),
          tap((action) => callback(action)),
          map(() => noop())
        )

        return obs
      })

      return () => {
        // unsubscribing should be supported, once we get rid of redux-observable
        // currently we cannot access the subscription itself, since we only get the Observable here
        // obs && obs.unsubscribe()
      }
    },
    [epicInit, type]
  )

  return func
}
