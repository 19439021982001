import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntervalInActiveTab } from '.'
import { BroadcastChannel } from 'broadcast-channel'

const isInternalOrigin = (): boolean => {
  return (
    window?.location?.origin === 'http://localhost:4200' ||
    window?.location?.origin === 'https://staging-shop-support.obeta.io' ||
    window?.location?.origin === 'https://shop-support.obeta.io'
  )
}

interface Maintenance {
  value: boolean
}

const MAINTENANCE_API_ROUTE = '/api/maintenance'
const VERSION_CHECK_INTERVAL_IN_MS = 60000 //one minute

export const useMaintenanceCheck = ({
  stopSync,
  startSync,
}: {
  stopSync(): void
  startSync(): void
}): boolean => {
  const channel = useRef<BroadcastChannel<Maintenance>>(
    new BroadcastChannel<Maintenance>('show_maintenance')
  )
  const [showMaintenance, setShowMaintenance] = useState(
    localStorage.getItem('showMaintenance')
      ? JSON.parse(localStorage.getItem('showMaintenance') as string).value
      : false
  )

  useEffect(() => {
    const handler = (data) => {
      setShowMaintenance(data.value)
    }

    channel.current = new BroadcastChannel<Maintenance>('show_maintenance')
    channel.current.addEventListener('message', handler)
    return () => {
      // when component unmounts, we remove the event handler,
      // but also close the channel
      // to cleanup as no communication can be received
      channel.current.removeEventListener('message', handler)
      channel.current.close()
    }
  }, [])

  const writeMaintenanceStatusToLocalStorage = useCallback((value: boolean) => {
    localStorage.setItem('showMaintenance', JSON.stringify({ value }))
  }, [])

  useIntervalInActiveTab(async () => {
    if (!isInternalOrigin()) {
      const response = await fetch(MAINTENANCE_API_ROUTE, { cache: 'no-store' })
      if (response.status === 200) {
        writeMaintenanceStatusToLocalStorage(true)
        setShowMaintenance(true)
        stopSync()
        channel.current.postMessage({ value: true })
      } else if (response.status === 204) {
        writeMaintenanceStatusToLocalStorage(false)
        setShowMaintenance(false)
        startSync()
        channel.current.postMessage({ value: false })
      }
    }
  }, VERSION_CHECK_INTERVAL_IN_MS)

  return showMaintenance && !isInternalOrigin()
}
