import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const entityMeta: EntityDescriptor<any> = {
  migrationStrategies: {
    1: function (oldDoc) {
      return oldDoc
    },
    2: function (oldDoc) {
      // altered schema by introducing 'affectedItem'
      return oldDoc
    },
    3: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  name: 'entitymeta',
  schema: {
    title: 'entityMetaData',
    version: 3,
    description: 'a document representing a cart',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      isFetching: {
        type: 'boolean',
      },
      isUpdating: {
        type: 'boolean',
      },
      mustRefetch: {
        type: 'boolean',
      },
      lastUpdated: {
        type: ['number', 'null'],
      },
      pagination: {
        type: 'object',
      },
      searchParams: {
        type: 'object',
      },
      hitCount: {
        type: 'number',
      },
      suppliers: {
        type: ['null', 'array'],
      },
      affectedItem: {
        type: 'string',
      },
    },
  },
  subscription: null,
}
