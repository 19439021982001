import { useTheme, Typography } from '@mui/material'
import { OpeningHours } from '@obeta/models/lib/models/opening-hours'
import { useTranslation } from 'react-i18next'
import { StatusBubble } from '../status-bubble/StatusBubble'
import styles from './Store.module.scss'
import { StoreProperty } from './StoreProperty'
import { StoreAddress } from './StoreAddress'
import { StoreWorkingHours } from './StoreWorkingHours'
import { withUnit } from '@obeta/utils/lib/withUnit'
import clsx from 'clsx'

export interface IStore {
  id: string
  name: string
  city: string
  postalCode: string
  street: string
  phone: string
  openingHours: OpeningHours[]
  availableItems: number | null
  houseNumber: string
  productUnit: string
  position: {
    latitude: string
    longitude: string
    distance: number
  }
}

export const StoreAddressCol: React.FC<{ store: IStore }> = (props) => {
  const { store } = props
  return (
    <div className={clsx(styles.column, styles.colStoreAddress)}>
      <Typography variant={'bodyBold'} className={styles.colHeader}>
        {store.name}
      </Typography>
      <StoreAddress
        street={`${store.street} ${store.houseNumber}`}
        city={`${store.postalCode} ${store.city}`}
        variant={'body'}
      />
    </div>
  )
}

export const ContactsCol: React.FC<{ store: IStore }> = (props) => {
  const { store } = props

  return (
    <div className={styles.column}>
      <div className={clsx(styles.colHeader, styles.phoneWrapper)}>
        <StoreProperty property="T" propertyValue={store.phone} />
      </div>
      <StoreWorkingHours openingHours={store.openingHours} variant={'body'} />
    </div>
  )
}

export const StoreStockCol: React.FC<{ store: IStore }> = (props) => {
  const { store } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const statusColor = store.availableItems
    ? theme.palette.success.main
    : theme.palette.secondary.dark

  return (
    <div className={styles.column}>
      <div className={styles.colHeader}>
        <StatusBubble className={styles.mdGap} color={statusColor} />
        <Typography variant="body">
          {t('ARTICLE_DETAIL.ITEMS_AVAILABLE', {
            // i18next expects a number for count, but a string is passed here
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            count: withUnit(store.availableItems || 0, store.productUnit) as any,
          })}
        </Typography>
      </div>
    </div>
  )
}

export const StoreDistanceCol: React.FC = (props) => {
  return (
    <div className={styles.column}>
      <div className={styles.colHeader}>
        <Typography variant="body" className={styles.distance}>
          {props.children}
        </Typography>
      </div>
    </div>
  )
}
