import { gql } from '@apollo/client'

export const getStoresQueryStr = `query getStores ($since: String, $lastId: String, $batchSize: Float){
    getStores(since:$since, lastId: $lastId, batchSize:$batchSize){
      id,company,country,region,
      address{
        name1,name2,zipCode,city,street,houseNumber
      },
      openingHours{
        weekday,from,to
      },
      phoneNumber,faxNumber,mail,latitude,longitude,type,distance,updatedAt,deleted
}
}`

export const GET_STORES = gql(getStoresQueryStr)
