import { QueryParams } from '../VirtualizedList'

export interface OfferDetailsFilter {
  isActive?: boolean
  orderBy?: string
  orderDir?: string
  searchTerm: string
  supplierId?: string
}

export interface OfferDetailsURLSearchParams {
  isActive?: string
  orderDir?: string
  searchTerm?: string
  supplierId?: string
}

// Constants (defaults)
export const INITIAL_OFFER_DETAILS_FILTERS: OfferDetailsFilter = {
  isActive: false,
  orderBy: 'sortItem',
  orderDir: 'ASC',
  searchTerm: '',
  supplierId: undefined,
}

export const INITIAL_OFFER_DETAILS_QUERY_PARAMS: QueryParams = {
  limit: '20',
  offset: '0',
}
