import { useState, useEffect } from 'react'
import { ArticleListItem } from '@obeta/models/lib/models/Article/ArticleListItem'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'
import { retry } from 'rxjs'

export enum RecommendationMode {
  alternative = 'alternative',
  recommendation = 'recommendation',
}

export interface RecommendationResponse {
  data: Recommendations
}
export interface Recommendations {
  hitcount: string
  oldCatalog: string
  suppliers: string
  etimFilter: string
  statusFilter: string[]
  articles: ArticleListItem[]
}

export const useArticleRelatedData = (
  articleId: string,
  relationType: RecommendationMode,
  sameSupplier = false
) => {
  const [articles, setArticles] = useState<ArticleListItem[]>([])

  useEffect(() => {
    setArticles([])
    let url = `recommendation/${articleId}/${sameSupplier ? '1' : '0'}`
    if (relationType === RecommendationMode.alternative) {
      url = `product/${articleId}/similar`
    }
    const sub = Axios.get(url)
      .pipe(retry(1))
      .subscribe((response: AxiosResponse<RecommendationResponse>) => {
        let data = response.data.data.articles || []
        data = data.map((articleListItem) => {
          articleListItem.imagePaths.path = articleListItem.imagePaths.path.replace(
            '90x90',
            '186x186'
          )
          return articleListItem
        })
        setArticles(data)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [articleId, relationType, sameSupplier])

  return articles
}
