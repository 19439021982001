import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './TableRow.module.scss'

interface IClasses<ICol> {
  cell?: string | ((column: ICol) => string)
  row?: string
}

export interface IColumn<Key extends keyof T, T extends { [P in Key]?: T[P] }> {
  width?: string
  accessor: Key
  bold?: boolean
  alignment?: 'left' | 'center' | 'right'
  format?: ((field: T[Key], data1: T) => React.ReactChild | boolean) | null
  flex?: number | 'none'
  minWidth?: number
}

interface ITableRowProps<Key extends keyof T, T extends { [P in Key]?: T[P] }> {
  columns: IColumn<Key, T>[]
  data: T
  classes?: IClasses<IColumn<Key, T>>
  className?: string
}

export const TableRow = <Key extends keyof T, T extends { [P in Key]?: T[P] }>(
  props: ITableRowProps<Key, T>
) => {
  const { columns, data, className, classes } = props

  return (
    <div className={clsx(styles.row, className, classes?.row)} role="row">
      {columns.map((col) => {
        const { format, bold, accessor, alignment = 'left', flex = 1, minWidth } = col

        let val: T[Key] | React.ReactChild | boolean = data[accessor]
        if (format) {
          val = format(val, data)
        } else {
          val = (
            <Typography noWrap variant={bold ? 'bodyBold' : 'body'}>
              {val}
            </Typography>
          )
        }

        const cellClassName =
          typeof classes?.cell === 'function' ? classes.cell(col) : classes?.cell

        return (
          <div
            className={clsx(styles.cell, styles[alignment], cellClassName)}
            role="cell"
            key={accessor.toString()}
            style={{ flex, minWidth }}
          >
            {val}
          </div>
        )
      })}
    </div>
  )
}
