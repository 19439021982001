import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { defer, Observable, of } from 'rxjs'
import { catchError, concatMap } from 'rxjs'
import { noop } from '../actions'
import {
  IInsertMessageAction,
  MessageActionTypes,
  messagesUpdated,
} from '../actions/message-actions'

const createSaveMessagesEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<IInsertMessageAction<unknown>>) => {
    return actions$.pipe(
      ofType(MessageActionTypes.Insert),
      concatMap((action: IInsertMessageAction<unknown>) => {
        return defer(async () => {
          const col = db.message
          const message = action.payload
          if (Array.isArray(message)) {
            await col.bulkUpsert(message)
          } else {
            await col.insert(message)
          }
          return messagesUpdated()
        })
      }),
      catchError((err) => {
        err.message = 'error in ' + createSaveMessagesEpic.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const initAllMessageEpics = (db: RxDatabase<CollectionsOfDatabase>) => {
  return [createSaveMessagesEpic(db)]
}
