import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

export const markedCartsEntity: EntityDescriptor<{ id: string }> = {
  name: 'markedcarts',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'markedcarts',
    version: 2,
    description: 'a document representing a cart that is marked for notification',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
    },
  },
  subscription: null,
}
