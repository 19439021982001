import { FC } from 'react'
import MaintenanceGif from 'assets/img/maintenance.gif'
import styles from './Maintenance.module.scss'

import { useMaintenanceCheck } from '@obeta/data/lib/hooks'
import { useBusinessLayer } from '@obeta/app-bootstrap'

export const Maintenance: FC = (props) => {
  const { children } = props

  const { startSync, stopSync } = useBusinessLayer()
  const showMaintenance = useMaintenanceCheck({ startSync, stopSync })

  return showMaintenance ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.textWrapper} style={{ top: 120 + (window.innerHeight - 500) / 2 }}>
        <h1 className={styles.header1}>Sorry</h1>
        <h2 className={styles.header2}>Wir sind gleich wieder erreichbar</h2>
        <span className={styles.spanElement}>Aufgrund von Wartungsarbeiten ist unsere</span>
        <br />
        <span className={styles.spanElement}>Website für kurze Zeit nicht erreichbar.</span>
      </div>
      <img
        src={MaintenanceGif}
        alt={'wartungsarbeit'}
        className={styles.maintenanceImage}
        style={{ top: (window.innerHeight - 500) / 2 }}
      />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}
