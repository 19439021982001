import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { App as AppPlugin } from '@capacitor/app'
import { ReactComponent as CloseIcon } from '@obeta/assets/icon/designsystem/close.svg'
import { useDeviceInfo } from '@obeta/data/lib/hooks/useDeviceInfo'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import styles from './SupporteOverlayContent.module.scss'
import { TertiaryIconButton } from '../../../../custom-button/CustomButton'

type SupportOverlayContentProps = {
  onClose: () => void
}

export const SupportOverlayContent = ({ onClose }: SupportOverlayContentProps) => {
  const { t } = useTranslation()
  const deviceInfo = useDeviceInfo()
  const { user } = useUserDataV2()
  const [appVersionInfo, setAppVersionInfo] = useState<string>('-')

  useAuthenticatedRoute()

  useEffect(() => {
    const getAppVersion = async () => {
      if (!isPlatform('web')) {
        const info = await AppPlugin.getInfo()
        const appVersion = `${info.version}`
        setAppVersionInfo(appVersion)
      }
    }

    getAppVersion()
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <TertiaryIconButton icon={<CloseIcon />} onClick={onClose} />

        <div className={styles.topSectionTitle}>
          <Typography variant={'boldText'}>{t('SUPPORT.FEEDBACK_SUPPORT')}</Typography>
        </div>
      </div>
      <div className={styles.contentInfo}>
        <div className={styles.category}>
          <div className={styles.categoryTitle}>
            <Typography variant={'boldText'}>{t('SUPPORT.SYSTEM_INFO')}</Typography>
          </div>
          <div className={styles.categoryItems}>
            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.OPERATION_SYSTEM')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {deviceInfo?.operatingSystem}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.OPERATION_SYSTEM_VERSION')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {deviceInfo?.osVersion}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.OBETA_APP_VERSION')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                V{appVersionInfo}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.OBETA_APPFLOW_VERSION')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {process.env.REACT_APP_APP_FLOW_BUILD_ID || '-'}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.DEVICE_MODEL')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {deviceInfo?.manufacturer}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.DEVICE_MODELNAME')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {deviceInfo?.model}
              </Typography>
            </div>

            <div className={styles.categoryItem}>
              <Typography variant={'body'} className={styles.categoryItemDescription}>
                {t('SUPPORT.SYSTEM_INFO_ITEMS.CLIENT_NUMBER')}
              </Typography>
              <Typography variant={'bodyBold'} className={styles.categoryItemValue}>
                {user?.customerNumber || '-'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
