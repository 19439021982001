import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { App as AppPlugin, BackButtonListener } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import { useHistory } from './useHistoryApi'

type CloseMenuCallback = () => void

interface IGoBackOrMinimize {
  registerCloseMenuCallback: (callback?: CloseMenuCallback) => void
  unregisterCloseMenuCallback: (callback?: CloseMenuCallback) => void
}

const GoBackOrMinimizeContext = React.createContext<IGoBackOrMinimize | null>(null)

/*
  The purpose of this provider is to listen for the system back button on Android, if available,
   and either 1)navigate back through the history, 2)close open side menus, or 3)close the app if the history stack is empty.
 */
export const GoBackOrExitAppProvider: React.FC = (props) => {
  const history = useHistory()
  const callbacks = useRef<CloseMenuCallback[]>([])

  const registerCloseMenuCallback = useCallback((callback: CloseMenuCallback) => {
    callback && callbacks.current.push(callback)
  }, [])

  const unregisterCloseMenuCallback = useCallback((callback: CloseMenuCallback) => {
    callbacks.current = callbacks.current.filter(
      (closeMnuCallback) => closeMnuCallback !== callback
    )
  }, [])

  useEffect(() => {
    if (!isPlatform('android')) return

    const handler: BackButtonListener = (e) => {
      // If the callback array is not empty, it means that one of the menus is still open, and the back button should close it first.
      if (callbacks.current.length) {
        const callback = callbacks.current[callbacks.current.length - 1] as CloseMenuCallback
        return callback()
      }

      if (e.canGoBack) return history.goBack()

      AppPlugin.exitApp()
    }

    let backButtonSubscription: PluginListenerHandle | undefined

    const runListeners = async () => {
      backButtonSubscription = await AppPlugin.addListener('backButton', handler)
    }

    runListeners()

    return () => {
      backButtonSubscription?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = useMemo(
    () => ({ registerCloseMenuCallback, unregisterCloseMenuCallback }),
    [registerCloseMenuCallback, unregisterCloseMenuCallback]
  )

  return (
    <GoBackOrMinimizeContext.Provider value={contextValue}>
      {props.children}
    </GoBackOrMinimizeContext.Provider>
  )
}

export const useBackButtonMenuClose = ({
  closeCallback,
  enabled,
}: {
  closeCallback?: CloseMenuCallback
  enabled: boolean
}) => {
  const goBackContextData = useContext(GoBackOrMinimizeContext)

  if (!goBackContextData) {
    throw new Error(
      'context is undefined. Make sure you wrapped you component with <GoBackOrMinimizeProvider />'
    )
  }

  useEffect(() => {
    if (enabled) {
      goBackContextData.registerCloseMenuCallback(closeCallback)
      return () => goBackContextData.unregisterCloseMenuCallback(closeCallback)
    }
  }, [closeCallback, goBackContextData, enabled])
}
