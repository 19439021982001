import { CartItemResultErrorCodes } from '@obeta/schema'

/**
 * Checks if incoming error code should display a warning notification that cancelled or custom products could not be added to a cart or cart template.
 * @param errorCode CartItemResultErrorCodes
 * @returns Boolean
 */
export const isCancelledOrCustomProductError = (errorCode: CartItemResultErrorCodes): boolean => {
  if (
    errorCode === 'ERROR_CANCELLED_PRODUCT' ||
    errorCode === 'ERROR_CUSTOM_PRODUCT_CAN_NOT_BE_ADDED_WITHOUT_OFFER' ||
    errorCode === 'PRODUCT_IS_CUSTOM_RESPONSE_ERROR_CODE'
  ) {
    return true
  }

  return false
}
