import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { SearchResponse } from '@obeta/models/lib/models/Search'

const OXOMI_METADATA = gql`
  query getOxomiMetaData {
    getOxomiMetaData {
      oxomiToken
      oxomiPortal
    }
  }
`

export const requestOxomiMetaData = async (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  const res = await apolloClient.query({
    query: OXOMI_METADATA,
  })
  const response: SearchResponse = res.data.getOxomiMetaData

  return response
}
