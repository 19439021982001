import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

export const getCookiebotStatisticsFlag = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window?.Cookiebot?.consent?.statistics //dont enable the localhost check here to not generate tracking information via datadog
}

export const getCookiebotMarketingFlag = () => {
  const isNativeUITarget = isUiTarget('app')

  if (isNativeUITarget) return true

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.Cookiebot?.consent?.marketing || window?.location?.origin === 'http://localhost:4200'
  )
}

export const getCookiebotPreferencesFlag = () => {
  const isNativeUITarget = isUiTarget('app')

  if (isNativeUITarget) return true

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.Cookiebot?.consent?.preferences || window?.location?.origin === 'http://localhost:4200'
  )
}
