import { gql } from '@apollo/client'

export const priceStockSearchQuery = gql`
  query getPriceAndStockDataInSearchQuery($sapIds: [String!]!, $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      sapId
      stock(warehouseIds: $warehouseIds) {
        sapId
        amount
        unit
        warehouseId
      }
      stockAvailabilityEstimate {
        sapId
        minValue
        maxValue
        error
        unit
      }
      prices {
        strikeThroughPrice
        catalogPrice
        netPrice
        tecSelect
        currency
        listPrice
      }
    }
  }
`
