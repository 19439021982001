import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as ExitIcon } from 'assets/icon/designsystem/exit.svg'
import styles from './ProfileDropdownFooter.module.scss'
import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'

export const ProfileDropdownFooter: React.FC = () => {
  const appActions = useAppActions()

  const logout = useCallback(() => {
    appActions.logout$.next(true)
  }, [appActions.logout$])

  return (
    <Button className={styles.footerButton} variant="text" color="secondary" onClick={logout}>
      <ExitIcon />
      Abmelden
    </Button>
  )
}
