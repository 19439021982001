import React from 'react'

export const composeReactRefs = <T>(...refs: Array<React.Ref<T> | undefined>) => {
  return (instance: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (typeof ref === 'object' && ref !== null && 'current' in ref) {
        ;(ref as React.MutableRefObject<T>).current = instance
      }
    })
  }
}
