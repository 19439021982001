import React, { createContext, useContext, useState } from 'react'

// Models
import { INITIAL_OFFER_DETAILS_QUERY_PARAMS } from '@obeta/models/lib/models/Offer/OfferDetailsViewModels'
import { OfferItemsFacets } from '@obeta/schema'
import { OfferItemForDetailsPage } from '@obeta/models/lib/schema-models/offer-details'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'

const initialEmptyFunction = () => {
  //
}

export const SEARCH_OFFER_ITEMS_LIMIT = '20'

export const INITIAL_OFFER_ITEMS_FILTERS = {
  isActive: false,
  supplierId: null,
}

interface OfferDetailsContextValue {
  facets: OfferItemsFacets
  notCancelledItemCount: number
  offerItems: OfferItemForDetailsPage[]
  queryParams: QueryParams
  search: string
  totalItemCount: number
  setFacets: (facets: OfferItemsFacets) => void
  setNotCancelledItemCount: React.Dispatch<React.SetStateAction<number>>
  setOfferItems: React.Dispatch<React.SetStateAction<OfferItemForDetailsPage[]>>
  setQueryParams: (queryParams: QueryParams) => void
  setSearch: (search: string) => void
  setTotalItemCount: React.Dispatch<React.SetStateAction<number>>
}

const OfferDetailsContext = createContext<OfferDetailsContextValue>({
  facets: {
    supplier: [],
  },
  notCancelledItemCount: 0,
  offerItems: [],
  queryParams: INITIAL_OFFER_DETAILS_QUERY_PARAMS,
  search: '',
  totalItemCount: 0,
  setFacets: initialEmptyFunction,
  setNotCancelledItemCount: initialEmptyFunction,
  setOfferItems: initialEmptyFunction,
  setQueryParams: initialEmptyFunction,
  setSearch: initialEmptyFunction,
  setTotalItemCount: initialEmptyFunction,
})

export const OfferDetailsContextProvider = ({ children }) => {
  const [facets, setFacets] = useState<OfferItemsFacets>({
    supplier: [],
  })
  const [notCancelledItemCount, setNotCancelledItemCount] = useState<number>(0)
  const [offerItems, setOfferItems] = useState<OfferItemForDetailsPage[]>([])
  const [queryParams, setQueryParams] = useState<QueryParams>(INITIAL_OFFER_DETAILS_QUERY_PARAMS)
  const [search, setSearch] = useState<string>('')

  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  return (
    <OfferDetailsContext.Provider
      value={{
        facets,
        notCancelledItemCount,
        offerItems,
        queryParams,
        search,
        totalItemCount,
        setFacets,
        setNotCancelledItemCount,
        setOfferItems,
        setQueryParams,
        setSearch,
        setTotalItemCount,
      }}
    >
      {children}
    </OfferDetailsContext.Provider>
  )
}

export const useOfferDetailsContext = () => {
  return useContext(OfferDetailsContext)
}
