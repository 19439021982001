import React from 'react'
import clsx from 'clsx'
import { Stack } from '@mui/material'
import styles from './NotificationPendingIcon.module.scss'

export const NotificationPendingIcon = () => (
  <Stack width={'1.25rem'} height={'1.25rem'} alignItems={'center'} justifyContent={'center'}>
    <div className={clsx(styles.circleLoader, styles.spin)} />
  </Stack>
)
