import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeSearchHistory } from '../actions/article-actions'
import { useSetState } from './useSetState'
import { useChangeSearchParams } from './useChangeSearchParams'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'

export const useSearchArticles = (
  searchParams?: ArticleSearchParams,
  onSearchConfirmed?: ((term: string) => void) | null
) => {
  const dispatch = useDispatch()
  const changeSearchParams = useChangeSearchParams()
  const [searchText, setSearchText, getSearchText] = useSetState<string>('')

  useEffect(() => {
    if (searchParams?.searchString) {
      setSearchText(searchParams?.searchString)
    } else {
      setSearchText('')
    }
  }, [searchParams?.searchString, setSearchText])

  const handleSubmit = async () => {
    const textToSubmit = await getSearchText()
    if (onSearchConfirmed) {
      onSearchConfirmed(textToSubmit)
    } else {
      //default
      const params: ArticleSearchParams = {
        ...searchParams,
        searchString: textToSubmit,
      }
      changeSearchParams({
        searchParams: params,
        route: 'push',
      })
    }

    if (textToSubmit && textToSubmit.trim() !== '') {
      dispatch(changeSearchHistory(textToSubmit))
      return textToSubmit
    }
  }

  const handleTextChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentTerm = (e.target as HTMLInputElement).value //we always get the value from here to not miss the last char because of searchinput beeing debounced
    setSearchText(currentTerm)
  }
  const handleSetSearchValue = (value: string) => setSearchText(value)

  const clear = () => {
    setSearchText('')
  }

  return {
    searchText,
    setSearchText,
    handleSubmit,
    handleTextChange,
    clear,
    handleSetSearchValue,
  }
}
