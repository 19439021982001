import { defaultLocale } from '../variables'
import { parseUnformattedNum } from '../parse-num'

const defaultFormatter = new Intl.NumberFormat(defaultLocale, { minimumFractionDigits: 2 })

export const normalizeNumber = (
  number: string | number = '',
  fractionDigits = 2,
  locale = defaultLocale
) => {
  let formatter: Intl.NumberFormat
  try {
    formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })
  } catch (err) {
    formatter = defaultFormatter
  }

  let p
  if (typeof number === 'number') {
    p = formatter.format(number)
  } else {
    p = formatter.format(parseUnformattedNum(number))
  }

  return `${p}`
}
