import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { ofType } from 'redux-observable'
import {
  GetRaffleInformationAction,
  getRaffleInformationResult,
  RaffleActionTypes,
  TakePartInRaffleAction,
  takePartInRaffleResult,
} from '../actions/raffle-actions'
import { catchError, defer, Observable, of, retry, switchMap } from 'rxjs'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import {
  CreateRaffleEntryForUserMutation,
  CreateRaffleEntryForUserMutationVariables,
  GetUserHasRaffleEntryQuery,
  GetUserHasRaffleEntryQueryVariables,
} from '@obeta/schema'

export const getRaffleInformationGraphQl = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetRaffleInformationAction>) =>
    actions$.pipe(
      ofType(RaffleActionTypes.GetRaffleInformation),
      switchMap((action: GetRaffleInformationAction) => {
        return defer(async () => {
          const response = await apolloClient.query<
            GetUserHasRaffleEntryQuery,
            GetUserHasRaffleEntryQueryVariables
          >({
            query: gql`
              query getUserHasRaffleEntry {
                getUserHasRaffleEntry
              }
            `,
          })
          return getRaffleInformationResult(response.data.getUserHasRaffleEntry)
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(getRaffleInformationResult(false))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(getRaffleInformationResult(false))
      })
    )
}

export const takePartInRaffleGraphQl = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<TakePartInRaffleAction>) =>
    actions$.pipe(
      ofType(RaffleActionTypes.TakePartInRaffle),
      switchMap((action: TakePartInRaffleAction) => {
        return defer(async () => {
          const response = await apolloClient.mutate<
            CreateRaffleEntryForUserMutation,
            CreateRaffleEntryForUserMutationVariables
          >({
            mutation: gql`
              mutation createRaffleEntryForUser {
                createRaffleEntryForUser
              }
            `,
          })
          const success = response?.data?.createRaffleEntryForUser ?? false
          return takePartInRaffleResult(success)
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(takePartInRaffleResult(false))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(takePartInRaffleResult(false))
      })
    )
}

export const initRaffleEpics = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return [getRaffleInformationGraphQl(apolloClient), takePartInRaffleGraphQl(apolloClient)]
}
