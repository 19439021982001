import React, { useCallback } from 'react'
import { MenuItemColor } from '../dropdown/types'
import { ReactComponent as AccountCircle } from 'assets/icon/designsystem/account_circle.svg'
import { SvgIcon, CircularProgress } from '@mui/material'
import { ProfileDropdownFooter } from './ProfileDropdownFooter'
import { ProfileDropdownHeader } from './ProfileDropdownHeader'
import { DropdownMenu } from '../dropdown'
import { useUserAddressesV2 } from '@obeta/data/lib/hooks/useUserAddressesV2'
import { IListOption } from '../../types'
import { Category } from '@obeta/models/lib/models/Category'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { TertiaryButton, TertiaryResponsiveIconButton } from '../../../custom-button/CustomButton'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'
import { useProfileDropdownOptions } from './useProfileDropdownOptions'
import { useLogin } from '@obeta/data/lib/hooks/useLogin'

const LOGIN_TEXT = 'Jetzt anmelden'
const TARGET_BLANK = '_blank'

export const Profile: React.FC = () => {
  const { mainAddress } = useUserAddressesV2()
  const { desktop, tabletWide } = useBreakpoints()
  const history = useHistory()
  const { isLoggedIn } = useUserDataV2()
  const { startLogin, attemptedLoginDuringLogout } = useLogin()
  const profileDropdownOptions = useProfileDropdownOptions()

  const handleLogin = () => startLogin()

  const userInitials = mainAddress?.address.name1
    .split(' ')
    .filter(Boolean) // Filter array to remove all empty strings
    .slice(0, 2)
    .map((name) => name[0]?.toUpperCase())
    .join('')

  const accountText = desktop ? userInitials : null

  const ProfileFooter = <ProfileDropdownFooter />
  const ProfileHeader = <ProfileDropdownHeader />

  const handleMenuItemClick = (option: IListOption | Category) => {
    if (!option.url) return

    if ('target' in option && option.target === TARGET_BLANK) {
      window.open(option.url, TARGET_BLANK)
      return
    }

    history.push(option.url)
  }

  const hideArrow = (!desktop && !tabletWide) || (desktop && !isLoggedIn)

  const getDropddownTriggerButton = useCallback(
    (onClick, ArrowIcon) =>
      desktop ? (
        <TertiaryButton
          data-testid="logged-in"
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={AccountCircle} />}
          rightIcon={<SvgIcon component={ArrowIcon} />}
          onClick={onClick}
        >
          {accountText ?? <CircularProgress size={16} sx={{ display: 'flex', color: '#bebebe' }} />}
        </TertiaryButton>
      ) : (
        <TertiaryResponsiveIconButton onClick={onClick} icon={<AccountCircle />} />
      ),
    [accountText, desktop]
  )

  if (!isLoggedIn) {
    return attemptedLoginDuringLogout ? (
      <CircularProgress size={16} sx={{ display: 'flex', color: '#bebebe' }} />
    ) : (
      <TertiaryButton
        size={desktop ? 'small' : 'large'}
        leftIcon={<SvgIcon component={AccountCircle} />}
        onClick={handleLogin}
        data-testid="login-button"
      >
        {LOGIN_TEXT}
      </TertiaryButton>
    )
  }

  return (
    <DropdownMenu
      color={MenuItemColor.Black}
      header={ProfileHeader}
      footer={ProfileFooter}
      withBorder={false}
      options={profileDropdownOptions}
      onMenuItemClick={handleMenuItemClick}
      hideArrow={hideArrow}
      renderOpenedTrigger={(onClick) => getDropddownTriggerButton(onClick, ArrowUp)}
      renderClosedTrigger={(onClick) => getDropddownTriggerButton(onClick, ArrowDown)}
    />
  )
}
