import { hasWindow } from './ssr'
import { defaultLocale } from './variables'

export const getLocale = () => {
  if (hasWindow()) {
    return navigator.language
  } else {
    return defaultLocale
  }
}
