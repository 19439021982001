import { gql } from '@apollo/client'

export const creditUsedInfoQuery = gql`
  query getCreditUsedInfo {
    getUser {
      creditInfo {
        creditLimit
        creditUsed
        creditAvailable
      }
    }
  }
`
