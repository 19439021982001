import { CartItemResultErrorCodes } from '@obeta/schema'

/**
 * Checks if incoming error code should display a warning notification that unpurchaseable products could not be added to a cart or cart template.
 * @param errorCode CartItemResultErrorCodes
 * @returns Boolean
 */
export const isUnpurchasableProductError = (errorCode: CartItemResultErrorCodes): boolean => {
  if (
    errorCode === 'ERROR_CART_NOT_FOUND' ||
    errorCode === 'ERROR_CART_ITEM_NOT_FOR_SALE' ||
    errorCode === 'ERROR_CART_ITEM_NOT_FOUND' ||
    errorCode === 'ERROR_IDS_ITEM_NOT_FOUND' ||
    errorCode === 'ERROR_IS_PROMOTION_PRODUCT' ||
    errorCode === 'ERROR_NOT_FOUND' ||
    errorCode === 'ERROR_UNKNOWN_PRODUCT_SAP_ID' ||
    errorCode === 'ERROR_UNKNOWN_OFFER_ID'
  ) {
    return true
  }
  return false
}
