import React, { useContext, useEffect, useReducer } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import dayjs from 'dayjs'
import {
  DeliverySlipPDFCreatedOptions,
  GenericOptions,
  OrderConfirmationPDFCreatedOptions,
  OrderFailureReason,
  SapInvoiceCreatedOptions,
  SapOfferPdfCreatedOptions,
  SmallConstructionDocumentationCreatedOptions,
} from '@obeta/models/lib/models/Notification/Notification'
import {
  AnnouncementEvent,
  EventType,
  getEventSubscription,
  NotificationEvent,
  NotificationType,
} from '@obeta/utils/lib/pubSub'
import {
  Notification,
  NotificationUndo,
  NotificationWithActions,
} from '../notifications/Notification'
import { getOrderFailureNotificationProps } from '../notifications/getOrderFailureNotificationProps'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icon/designsystem/check_circle.svg'
import { ReactComponent as ContactSupportIcon } from 'assets/icon/designsystem/contact_support.svg'
import { ReactComponent as PrintIcon } from 'assets/icon/designsystem/print.svg'
import { TFunction, useTranslation } from 'react-i18next'
import { SwitchTargetCartToast as BaseSwitchTagetCartToast } from '../toast/switchTargetCartToast'
import {
  addArticleToCart,
  removeArticleFromCart,
  updateCartArticleAmount,
} from '@obeta/data/lib/actions/cart-actions'
import { saveCustomerSettings } from '@obeta/data/lib/actions/customer-actions'
import { useDispatch } from 'react-redux'
import { useUserData } from '@obeta/data/lib/hooks/useUserData'
import { CustomerSettings } from '@obeta/data/lib/epics/customer-effects'
import { ArticleAddOptions, ArticleRing } from '@obeta/models/lib/models/Article/ArticleListItem'
import { setRefValue } from '@obeta/utils/lib/setRefValue'
import { useHistory, useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { NotificationStyledBlock } from '../notifications/NotificationStyledBlock'
import { NotificationMultiRow } from '../notifications/NotificationMultiRow'
import { NotificationPendingIcon } from '../notifications/NotificationPendingIcon'
import {
  globalNotificationsReducer,
  initialGlobalNotificationsState,
} from './GlobalNotificationsReducer'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { BarcodeContext } from '@obeta/data/lib/hooks/useBarcodeState'
import styles from './GlobalNotification.module.scss'
// Assets
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'
import { SnackbarNotification } from '../snackbar/Snackbar'
import { GenericToast } from '../toast/genericToast'
import { ReactComponent as PdfIcon } from 'assets/icon/pdf-file-format-symbol.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import { ShoppingCartItemResult } from '@obeta/schema'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useFrontendClientId } from '@obeta/data/lib/hooks/useFrontendClientId'
import { BroadcastChannel } from 'broadcast-channel'
import { useTriggerEmailTokenRefresh } from '@obeta/data/lib/hooks/useUserUpdate'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'
import { useCreditLimitPreAlertContext } from '@obeta/data/lib/stores/useCreditLimitPreAlertContext'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

interface ISwitchTargetCartToastProps {
  options: ArticleAddOptions
  id: string
  removeProcessInfo: (id: string) => void
  open: boolean
}

const CHECKOUT_AND_ORDER_PATHS: string[] = [
  ShopRoutes.CreateOrderPage,
  ShopRoutes.ShoppingCartDetails,
  ShopRoutes.ShoppingCartCheckout,
]

const CHECKOUT_PATHS: string[] = [ShopRoutes.ShoppingCartDetails, ShopRoutes.ShoppingCartCheckout]

const SwitchTargetCartToast: React.FC<ISwitchTargetCartToastProps> = (props) => {
  const { options: opts, id, removeProcessInfo, open } = props
  const userData = useUserData()
  const dispatch = useDispatch()

  return (
    <BaseSwitchTagetCartToast
      open={open}
      key={id}
      event={opts}
      onDidDismiss={() => removeProcessInfo(id)}
      onChangeChoosen={(cart) => {
        if (opts?.currentAmountInCart) {
          dispatch(
            updateCartArticleAmount(
              opts?.shoppingCartId,
              opts?.articleId,
              opts?.currentAmountInCart
            )
          )
        } else {
          dispatch(removeArticleFromCart(opts?.shoppingCartId || '', opts?.articleId || ''))
        }
        dispatch(
          addArticleToCart({
            amount: opts?.addedAmount || 0,
            articleId: opts?.articleId || '',
            articleName: opts?.articleName || '',
            cart: cart,
            ring: opts?.articleRing || ArticleRing.Null,
          })
        )
        if (userData.isLoggedIn) {
          // obeta decided that we should activate the cart
          // if the user decides to put articles there
          // the assumption is, that the user wants to change the active cart
          // and it should be as convenient as possible
          dispatch(
            saveCustomerSettings({
              ...(userData.user?.user.customerSettings as CustomerSettings),
              defaultProjectId: cart.id,
            })
          )
        }
      }}
    />
  )
}

export interface QueueEl {
  // uid is generated in the backend and retrieved via message
  // optional since not every notification is triggered backend wise
  uid?: string
  // id is generated in the frontend
  id: string
  event: NotificationEvent
}

/**
 * @param reset - remove all notifications from screen
 */
export interface IGlobalNotificationsApi {
  reset: () => void
}

const dummyHandler = () => {
  //
}

function getNotificationDeleteArticleMultiple(
  info: QueueEl,
  index: number,
  removeProcessInfo: (id: string) => void,
  t: TFunction
) {
  const cartEmpty = 'cartEmpty' in info.event.options ? info.event.options.cartEmpty : false
  let itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
  if (typeof itemCount === 'string') {
    itemCount = parseInt(itemCount, 10)
  }
  const rows = [
    <Box key="article-deleted">
      <Typography variant={'bodyBold'}>{String(itemCount)} Artikel</Typography>
      <Typography variant={'body'}>
        {t(
          itemCount > 1 ? 'SHOPPING_CART.ARTICLE_DELETED_MULTIPLE' : 'SHOPPING_CART.ARTICLE_DELETED'
        )}
      </Typography>
    </Box>,
  ]
  if (cartEmpty) {
    rows.push(t('SHOPPING_CART.CART_EMPTY'))
  }
  return (
    <NotificationUndo
      id={info.event.id}
      key={info.event.id}
      closeAfterMs={8000}
      closeHandler={removeProcessInfo}
      customIcon={DeleteIcon}
      variant="infoLight"
      undoHandler={dummyHandler}
    >
      <NotificationMultiRow rows={rows} />
    </NotificationUndo>
  )
}

function getNotificationCartMove(
  info: QueueEl,
  index: number,
  removeProcessInfo: (id: string) => void,
  t: TFunction
) {
  const cartEmpty = 'cartEmpty' in info.event.options ? info.event.options.cartEmpty : false
  const cartName = 'cartName' in info.event.options ? info.event.options.cartName : ''
  let itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
  if (typeof itemCount === 'string') {
    itemCount = parseInt(itemCount, 10)
  }
  const includingOffer =
    'includingOffer' in info.event.options ? info.event.options.includingOffer : false
  return (
    <NotificationUndo
      id={info.event.id}
      key={`${info.event.id}-${index}`}
      closeHandler={removeProcessInfo}
      variant="infoLight"
      closeAfterMs={8000}
      undoHandler={dummyHandler}
    >
      <Box>
        <Typography variant={'bodyBold'}>
          {String(itemCount)} {t('SHOPPING_CART.ARTICLE_MOVED_NOTIFICATION.0')}&nbsp;
        </Typography>
        <Typography variant={'body'}>
          {includingOffer ? `${t('SHOPPING_CART.INCLUDING_OFFER_ABBREVIATED')} ` : ''}
        </Typography>
        <Typography variant={'body'}>
          {itemCount > 1
            ? t('SHOPPING_CART.ARTICLE_MOVED_NOTIFICATION.2')
            : t('SHOPPING_CART.ARTICLE_MOVED_NOTIFICATION.1')}
        </Typography>
        <Typography variant={'bodyBold'}> {cartName} </Typography>
        <Typography variant={'body'}>
          {t('SHOPPING_CART.ARTICLE_MOVED_NOTIFICATION.3')}
          {cartEmpty ? ` ${t('SHOPPING_CART.CART_EMPTY')}` : ''}
        </Typography>
      </Box>
    </NotificationUndo>
  )
}

function getNotificationTemplateAddError(
  info: QueueEl,
  index: number,
  removeProcessInfo: (id: string) => void
) {
  let itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
  if (typeof itemCount === 'string') {
    itemCount = parseInt(itemCount, 10)
  }
  const templateName = 'templateName' in info.event.options ? info.event.options.templateName : ''
  return (
    <Notification
      key={`${info.event.id}-${index}`}
      id={info.event.id}
      closeHandler={removeProcessInfo}
      variant="infoLight"
      closeAfterMs={8000}
    >
      <Box component="span">
        <Typography variant={'bodyBold'}>{String(itemCount)} Artikel&nbsp;</Typography>
        <Typography variant={'body'}>
          {itemCount > 1 ? 'können nicht zur Merkliste' : 'kann nicht zur Merkliste'}
        </Typography>
        <Typography variant={'bodyBold'}> {templateName}</Typography>
        <Typography variant={'body'}> hinzugefügt werden. </Typography>
      </Box>
    </Notification>
  )
}

function getNotificationTemplateAdd(
  info: QueueEl,
  index: number,
  removeProcessInfo: (id: string) => void
) {
  let itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
  if (typeof itemCount === 'string') {
    itemCount = parseInt(itemCount, 10)
  }
  const templateName = 'templateName' in info.event.options ? info.event.options.templateName : ''
  return (
    <NotificationUndo
      key={`${info.event.id}-${index}`}
      id={info.event.id}
      closeHandler={removeProcessInfo}
      variant="success"
      closeAfterMs={8000}
      undoHandler={dummyHandler}
    >
      <Box component="span">
        <Typography variant={'bodyBold'}>{String(itemCount)} Artikel&nbsp;</Typography>
        <Typography variant={'body'}>
          {itemCount > 1 ? 'wurden zu Ihrer Merkliste' : 'wurde zu Ihrer Merkliste'}
        </Typography>
        <Typography variant={'bodyBold'}> {templateName}</Typography>
        <Typography variant={'body'}> hinzugefügt. </Typography>
      </Box>
    </NotificationUndo>
  )
}

export const GlobalNotifications = React.forwardRef<IGlobalNotificationsApi>(
  function GlobalNotifications(_, ref) {
    const [globalNotificationsState, globalNotificationsDispatch] = useReducer(
      globalNotificationsReducer,
      initialGlobalNotificationsState
    )
    const { t } = useTranslation()
    const location = useLocation()
    const stores: StoreV2[] = useEntities('storesv2')
    const { scan } = useContext(BarcodeContext)
    // will be used in the second part (MR part two)
    const frontendClientIdLocal = useFrontendClientId()
    const { dimensions } = useHeaderDimensions()
    const history = useHistory()
    const dispatchTokenRefresh = useTriggerEmailTokenRefresh()
    const { closeCreditLimitPreAlertShown } = useCreditLimitPreAlertContext()

    const { accountingPhoneNumber, servicePhoneNumber, shopSupportPhoneNumber } =
      useCompanyContactDetailsContext()

    const channelNotificationUidProcessor = new BroadcastChannel<{
      action: 'remove-notification'
      uid: string
    }>('notification-uid-processor')

    useEffect(() => {
      const api = {
        reset: () => globalNotificationsDispatch({ type: 'UPDATE_QUEUE', payload: [] }),
      }

      setRefValue(ref, api)
    }, [ref])

    useEffect(() => {
      const sub = getEventSubscription().subscribe((event) => {
        // if we already display an alert with the same id, we just ignore new alerts until
        // the current one is discarded -> we don't want them to stack
        // I think this is some kind of workaround and this should be fixed.
        // TODO: whole notification object interface should be refactored.
        if (
          event.id &&
          (event.type === EventType.Alert ||
            event.type === EventType.Toast ||
            event.type === EventType.Snackbar ||
            event.notificationType === NotificationType.OrderSuccess ||
            event.notificationType === NotificationType.OrderFailure ||
            event.notificationType === NotificationType.OrderIdMissingInResponse)
        ) {
          const existingEvent = globalNotificationsState.queue.find((ev) => ev.id === event.id)
          if (existingEvent) {
            return globalNotificationsState.queue
          }
        }

        const notification: QueueEl = {
          id: event.id,
          event: event,
        }

        if (event.uid) {
          notification.uid = event.uid
        }

        let queue = globalNotificationsState.queue.concat(notification)

        // types that were used in "obeta" before notifications were consolidated
        const legacyTypes = [NotificationType.Toast, NotificationType.Alert]
        if (legacyTypes.indexOf(event.notificationType) >= 0) {
          //we only ever show one "switchcart option", as with another add the user decided that the last one is ok
          let foundFirstSwitchCart = false

          queue = queue
            .reverse()
            .filter((els) => {
              if ('opts' in els.event.options) {
                if (foundFirstSwitchCart) {
                  return false
                }
                foundFirstSwitchCart = true
              }
              return true
            })
            .reverse()
        }
        globalNotificationsDispatch({ type: 'UPDATE_QUEUE', payload: queue })
      })
      return () => {
        sub.unsubscribe()
      }
    }, [globalNotificationsState.queue])

    const removeProcessInfo = (id: string) => {
      globalNotificationsDispatch({ type: 'REMOVE_NOTIFICATION', payload: id })
    }

    const removeNotification = (cartId: string, type: NotificationType) => {
      const notificationId = `${cartId}-${type}`
      if (
        globalNotificationsState.queue.some((queueElement) => queueElement.id === notificationId)
      ) {
        removeProcessInfo(notificationId)
      }
    }

    const removeNotificationById = (notificationId: string) => {
      if (
        globalNotificationsState.queue.some((queueElement) => queueElement.id === notificationId)
      ) {
        removeProcessInfo(notificationId)
      }
    }

    const removeNotificationByUid = (uid?: string) => {
      if (
        uid !== undefined &&
        globalNotificationsState.queue.some(
          (queueElement) => queueElement.uid !== undefined && queueElement.uid === uid
        )
      ) {
        globalNotificationsDispatch({ type: 'REMOVE_NOTIFICATION_BY_UID', payload: uid })
      }
    }

    useEffect(() => {
      channelNotificationUidProcessor.onmessage = (data) => {
        if (data.action === 'remove-notification') {
          removeNotificationByUid(data.uid)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalNotificationsState.queue])

    const userData = useUserData()
    const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

    // TODO: should be refactored
    const globalNotifications = globalNotificationsState.queue.map((info, index) => {
      const frontendClientIdFromMessage = info.event.frontendClientId
      const isFrontendClientIdMatch = frontendClientIdFromMessage === frontendClientIdLocal

      switch (info.event.notificationType) {
        case NotificationType.Toast: {
          const options = info.event.options as AnnouncementEvent
          const opts = options.opts
          if (opts) {
            return (
              <SwitchTargetCartToast
                open={true}
                options={opts}
                id={info.id}
                removeProcessInfo={removeProcessInfo}
              />
            )
          }

          return (
            <GenericToast
              open={true}
              key={info.id}
              onClose={() => removeProcessInfo(info.id)}
              autoHideDuration={5000}
              message={options.message}
              header={options.title}
            />
          )
        }
        case NotificationType.AddCartTemplatesToCartTemplate: {
          const originTemplateName =
            'originTemplateNames' in info.event.options
              ? info.event.options.originTemplateNames[0]
              : ''
          const targetTemplateName =
            'targetTemplateName' in info.event.options ? info.event.options.targetTemplateName : ''
          return (
            <NotificationUndo
              key={info.event.id}
              id={info.event.id}
              variant="infoLight"
              closeHandler={removeProcessInfo}
              closeAfterMs={8000}
              customIcon={InfoIcon}
              undoHandler={dummyHandler}
            >
              <Typography variant="body">
                {t('TEMPLATES_CONTEXT_MENU.ADD_TO_CART_TEMPLATE_SUCCESS.1')}
              </Typography>
              <Typography variant="bodyBold"> {originTemplateName} </Typography>
              <Typography variant="body">
                {t('TEMPLATES_CONTEXT_MENU.ADD_TO_CART_TEMPLATE_SUCCESS.2')}
              </Typography>
              <Typography variant="bodyBold"> {targetTemplateName} </Typography>
              <Typography variant="body">
                {t('TEMPLATES_CONTEXT_MENU.ADD_TO_CART_TEMPLATE_SUCCESS.3')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.AddCartTemplateItemsToCart: {
          const successItemCount =
            'successItemCount' in info.event.options ? info.event.options.successItemCount : ''
          const cartName = 'cartName' in info.event.options ? info.event.options.cartName : ''
          return isAddToCartProcessIndicator ? null : (
            <NotificationUndo
              key={info.event.id}
              id={info.event.id}
              variant="success"
              closeHandler={removeProcessInfo}
              closeAfterMs={8000}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {successItemCount}{' '}
                {t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.ARTICLE')}
              </Typography>
              <Typography variant="body">
                {successItemCount > 1
                  ? t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.PLURAL')
                  : t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.SINGLE')}
              </Typography>
              <Typography variant="bodyBold"> {cartName} </Typography>
              <Typography variant="body">
                {t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.MOVED')}
              </Typography>
            </NotificationUndo>
          )
        }

        case NotificationType.AddCartTemplateItemsToCartTemplate: {
          const successItemCount =
            'successItemCount' in info.event.options ? info.event.options.successItemCount : ''
          const targetTemplateName =
            'targetTemplateName' in info.event.options ? info.event.options.targetTemplateName : ''
          return (
            <NotificationUndo
              key={info.event.id}
              id={info.event.id}
              variant="success"
              closeHandler={removeProcessInfo}
              closeAfterMs={8000}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {successItemCount}{' '}
                {t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_TEMPLATE_NOTIFICATION.ARTICLE')}
              </Typography>
              <Typography variant="body">
                {successItemCount > 1
                  ? t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_TEMPLATE_NOTIFICATION.PLURAL')
                  : t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_TEMPLATE_NOTIFICATION.SINGLE')}
              </Typography>
              <Typography variant="bodyBold"> {targetTemplateName} </Typography>
              <Typography variant="body">
                {t('TEMPLATES.ADD_CART_TEMPLATE_ITEMS_TO_CART_TEMPLATE_NOTIFICATION.ADDED')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.AddCartTemplateItemsUnpurchaseableProductsToCartWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t('TEMPLATES.NOTIFICATIONS.ADD_UNPURCHASEABLE_PRODUCTS_WARNING.HEADING_CART')}
              </Typography>
              <Typography variant="body">
                {t('TEMPLATES.NOTIFICATIONS.ADD_UNPURCHASEABLE_PRODUCTS_WARNING.BODY')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.AddCartTemplateItemsUnpurchaseableProductsToCartTemplateWarning: {
          const targetTemplateName =
            'targetTemplateName' in info.event.options ? info.event.options.targetTemplateName : ''
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t(
                  'TEMPLATES.NOTIFICATIONS.ADD_UNPURCHASEABLE_PRODUCTS_WARNING.HEADING_CART_TEMPLATE',
                  {
                    targetTemplateName,
                  }
                )}
              </Typography>
              <Typography variant="body">
                {t('TEMPLATES.NOTIFICATIONS.ADD_UNPURCHASEABLE_PRODUCTS_WARNING.BODY')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.Alert: {
          // TODO: should be refactored
          const options = info.event.options as AnnouncementEvent

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              variant="info"
              closeHandler={() => removeProcessInfo(info.id)}
            >
              <Typography variant="body">{options.message}</Typography>
            </Notification>
          )
        }
        case NotificationType.Success: {
          const options = info.event.options as GenericOptions
          return (
            <Notification
              id={info.event.id}
              key={info.event.id}
              variant="success"
              closeHandler={removeProcessInfo}
              closeAfterMs={8000}
            >
              <NotificationStyledBlock heading={options.heading} body={options.body} />
            </Notification>
          )
        }
        case NotificationType.Error: {
          const options = info.event.options as AnnouncementEvent

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              variant="error"
              closeHandler={() => removeProcessInfo(info.id)}
            >
              <Typography variant="body">{options.message}</Typography>
            </Notification>
          )
        }
        case NotificationType.Warning: {
          const options = info.event.options as AnnouncementEvent

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              variant="warning"
              closeHandler={() => removeProcessInfo(info.id)}
            >
              <Typography variant="body">{options.message}</Typography>
            </Notification>
          )
        }
        case NotificationType.DeleteArticleSingle: {
          const cartEmpty = 'cartEmpty' in info.event.options ? info.event.options.cartEmpty : false
          const productTitle =
            'productTitle' in info.event.options ? info.event.options.productTitle : ''
          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              variant="infoLight"
              customIcon={DeleteIcon}
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Box component="span">
                <Typography variant={'bodyBold'}>{productTitle}</Typography>
                <Typography variant={'body'}> wurde aus Ihrem Warenkorb gelöscht.</Typography>
              </Box>
              {cartEmpty && (
                <Typography variant={'body'}>{t('SHOPPING_CART.CART_EMPTY')}</Typography>
              )}
            </NotificationUndo>
          )
        }
        case NotificationType.DeleteArticleMultiple:
          if ('cartEmpty' in info.event.options && info.event.options.cartEmpty) {
            return getNotificationDeleteArticleMultiple(info, index, removeProcessInfo, t)
          }
          break // notification will be triggered as part of selectionBarBoundNotifications
        case NotificationType.EmptyCart:
          if ('cartName' in info.event.options && info.event.options.cartName) {
            const cartId = 'cartId' in info.event.options ? info.event.options.cartId : ''
            const cartName = 'cartName' in info.event.options ? info.event.options.cartName : ''
            return (
              <NotificationUndo
                key={info.event.id}
                id={info.event.id}
                closeAfterMs={8000}
                customIcon={DeleteIcon}
                closeHandler={removeProcessInfo}
                undoHandler={dummyHandler}
                variant="infoLight"
              >
                <NotificationMultiRow
                  rows={[
                    <>
                      <Typography variant={'body'}>Warenkorb</Typography>
                      <Typography variant={'bodyBold'}>{cartName ?? cartId}</Typography>
                      <Typography variant={'body'}> wurde geleert und zurück gesetzt.</Typography>
                    </>,
                    <Typography variant={'body'}>{t('SHOPPING_CART.CART_EMPTY')}</Typography>,
                  ]}
                />
              </NotificationUndo>
            )
          }
          break // notification will be triggered as part of selectionBarBoundNotifications
        case NotificationType.CartMove:
          if ('cartEmpty' in info.event.options && info.event.options.cartEmpty) {
            return getNotificationCartMove(info, index, removeProcessInfo, t)
          }
          break // notification will be triggered as part of selectionBarBoundNotifications
        case NotificationType.CartApproval: {
          if (
            location.pathname.startsWith('/shopping-cart-checkout') ||
            location.pathname.startsWith('/create-order')
          ) {
            removeProcessInfo(info.event.id)
          } else {
            const emailAddress =
              'emailAddress' in info.event.options ? info.event.options.emailAddress : ''
            const onPrintCart =
              'onPrintCart' in info.event.options ? info.event.options.onPrintCart : undefined
            if (emailAddress && onPrintCart) {
              return (
                <NotificationWithActions
                  key={info.event.id}
                  id={info.event.id}
                  closeHandler={removeProcessInfo}
                  variant="info"
                  customIcon={CheckCircleIcon}
                  actions={[
                    {
                      type: 'tertiary',
                      icon: <PrintIcon />,
                      text: 'Gemeldeten Warenkorb drucken',
                      handler: onPrintCart,
                    },
                  ]}
                >
                  <NotificationMultiRow
                    rows={[
                      <Typography variant={'bodyBold'}>{t('SHOPPING_CART.THANKS')}</Typography>,
                      <Typography variant={'body'}>
                        {t('SHOPPING_CART.CHECKOUT.NOTIFIED_CART', { emailAddress })}{' '}
                        {t('SHOPPING_CART.CHECKOUT.NOTIFIED_CART_WARNING')}
                      </Typography>,
                    ]}
                  />
                </NotificationWithActions>
              )
            }
          }
          return null
        }
        case NotificationType.DeleteAddress: {
          if (!location.pathname.startsWith('/shopping-cart-checkout')) {
            removeNotificationById(info.event.id)
          }
          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              customIcon={DeleteIcon}
              variant="infoLight"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <NotificationStyledBlock heading={t('ADDRESSES.DELETED_SUCCESSFULLY')} />
            </NotificationUndo>
          )
        }
        case NotificationType.GenericAddDiverseProductsOrCancelledItemsToCartTemplateWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t(
                  'TEMPLATES.NOTIFICATIONS.FAILED_TO_ADD_ALL_PRODUCTS_TO_CART_TEMPLATE_WARNING.HEADING'
                )}
              </Typography>
              <Typography variant="body">
                {t(
                  'TEMPLATES.NOTIFICATIONS.FAILED_TO_ADD_ALL_PRODUCTS_TO_CART_TEMPLATE_WARNING.BODY_DIVERSE_PRODUCTS_OR_CANCELLED'
                )}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.GenericAddNonPurchasableProductsToCartTemplateWarning: {
          const rows = [
            <Typography key={`${info.event.id}-heading`} variant="bodyBold">
              {t(
                'TEMPLATES.NOTIFICATIONS.FAILED_TO_ADD_ALL_PRODUCTS_TO_CART_TEMPLATE_WARNING.HEADING'
              )}
            </Typography>,
            <Typography key={`${info.event.id}-body`} variant="body">
              {t(
                'TEMPLATES.NOTIFICATIONS.FAILED_TO_ADD_ALL_PRODUCTS_TO_CART_TEMPLATE_WARNING.BODY_NON_PURCHASABLE_PRODUCTS'
              )}
            </Typography>,
          ]
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <NotificationMultiRow rows={rows} />
            </NotificationUndo>
          )
        }
        case NotificationType.OrderSuccess: {
          if (!userData.isLoggedIn) {
            return null
          }
          if ('cartId' in info.event.options && info.event.options.cartId !== undefined) {
            removeNotification(info.event.options.cartId, NotificationType.OrderPending)
          }
          if (
            !location.pathname.startsWith('/shopping-cart-checkout') &&
            !location.pathname.startsWith('/create-order')
          ) {
            const shippingType =
              'shippingType' in info.event.options
                ? info.event.options.shippingType
                : ShippingType.Cargo

            const storeName =
              'storeId' in info.event.options
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  stores.find((store) => store.id === info.event.options.storeId)?.address.name1 ??
                  ''
                : ''
            const pickupNumber =
              'pickupNumber' in info.event.options ? info.event.options.pickupNumber : ''
            const orderTime = 'time' in info.event.options ? info.event.options.time : ''
            const orderDate = 'date' in info.event.options ? info.event.options.date : ''
            const orderId = 'orderId' in info.event.options ? info.event.options.orderId : ''
            const rows = [
              <>
                <Typography variant="bodyBold">
                  {t('SHOPPING_CART.DETAILS.ORDER_THANKS_TITLE')}
                </Typography>
                <Typography variant="body">
                  {shippingType === ShippingType.ExpressPickup
                    ? t('SHOPPING_CART.CHECKOUT.NOTIFICATION.ORDER_WILL_BE_PROCESSED_XPRESS', {
                        storeName: storeName,
                      })
                    : t('SHOPPING_CART.CHECKOUT.NOTIFICATION.ORDER_WILL_BE_PROCESSED')}
                </Typography>
              </>,
            ]

            if (shippingType !== ShippingType.ExpressPickup) {
              rows.push(
                <>
                  <Stack direction={'row'} gap="0.25rem">
                    <Typography variant="body">Auftragsnr:</Typography>
                    <Typography variant="bodyBold">{orderId}</Typography>
                  </Stack>
                  <Stack direction={'row'} gap="0.25rem">
                    <Typography variant="body">Datum:</Typography>
                    <Typography variant="bodyBold">{orderDate}</Typography>
                  </Stack>
                  <Stack direction={'row'} gap="0.25rem">
                    <Typography variant="body">Uhrzeit:</Typography>
                    <Typography variant="bodyBold">{orderTime}</Typography>
                  </Stack>
                </>
              )
            } else {
              rows.push(
                <>
                  <Stack direction={'row'} gap="0.25rem">
                    <Typography variant="body">Nr:</Typography>
                    <Typography variant="bodyBold">{pickupNumber}</Typography>
                  </Stack>
                  <Stack direction={'row'} gap="0.25rem">
                    <Typography variant="body">{t('SHOPPING_CART.CHECKOUT.PICKUP')}:</Typography>
                    <Typography variant="bodyBold">
                      {t('SHOPPING_CART.CHECKOUT.EXPRESS_COLLECTION_TIME')}
                    </Typography>
                  </Stack>
                </>
              )
            }

            return (
              <Notification
                key={info.event.id}
                id={info.event.id}
                customIcon={CheckCircleIcon}
                variant="success"
                closeHandler={removeProcessInfo}
                closeAfterMs={8000}
              >
                <NotificationMultiRow rows={rows} />
              </Notification>
            )
          }
          return null
        }
        case NotificationType.OfferNoPermission: {
          return (
            <Notification
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              variant="error"
              closeHandler={removeProcessInfo}
            >
              <Typography variant="body">{t('OFFERS.NO_PERMISSION')}</Typography>
            </Notification>
          )
        }
        case NotificationType.OfferAddDiverseProductsOrCancelledItemsToCartWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t('OFFERS.NOTIFICATIONS.ADD_ITEMS_TO_CART_WARNING.HEADING')}
              </Typography>
              <Typography variant="body">
                {t(
                  'OFFERS.NOTIFICATIONS.ADD_ITEMS_TO_CART_WARNING.BODY_DIVERSE_PRODUCTS_OR_CANCELLED'
                )}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.OfferAddDiverseProductsToCartTemplateWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t('OFFERS.NOTIFICATIONS.ADD_DIVERSE_PRODUCTS_TO_CART_TEMPLATE_WARNING.HEADING')}
              </Typography>
              <Typography variant="body">
                {t('OFFERS.NOTIFICATIONS.ADD_DIVERSE_PRODUCTS_TO_CART_TEMPLATE_WARNING.BODY')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.OfferUpdate: {
          if (location.pathname.startsWith('/offer-list')) {
            return <SnackbarNotification open={true} key={info.event.id}></SnackbarNotification>
          }
          return null
        }
        case NotificationType.OrderUpdate: {
          if (location.pathname.startsWith('/order-details')) {
            return <SnackbarNotification open={true} key={info.event.id}></SnackbarNotification>
          }
          return null
        }
        case NotificationType.AddOrderItemsToCart: {
          const count =
            'successItemCount' in info.event.options ? info.event.options.successItemCount : 0

          return isAddToCartProcessIndicator ? null : (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeHandler={removeProcessInfo}
              variant="success"
              closeAfterMs={8000}
              undoHandler={dummyHandler}
            >
              <Box component="span">
                <Typography variant={'bodyBold'}>
                  {t('COMMON.ACTIONS.ADD_ARTICLES_TO_CART_SUCCESS_1', {
                    count,
                  })}
                </Typography>
                <Typography variant={'body'}>
                  {t(
                    count === 1
                      ? 'COMMON.ACTIONS.ADD_ARTICLES_TO_CART_SUCCESS_2'
                      : 'COMMON.ACTIONS.ADD_ARTICLES_TO_CART_SUCCESS_2_PLURAL'
                  )}
                </Typography>
              </Box>
            </NotificationUndo>
          )
        }
        case NotificationType.AddOrderItemsToCartTemplate: {
          const count =
            'successItemCount' in info.event.options ? info.event.options.successItemCount : 0
          const templateName =
            'targetTemplateName' in info.event.options ? info.event.options.targetTemplateName : ''

          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeHandler={removeProcessInfo}
              variant="success"
              closeAfterMs={8000}
              undoHandler={dummyHandler}
            >
              <Box component="span">
                <Typography variant={'bodyBold'}>
                  {t('COMMON.ACTIONS.ADD_ARTICLES_TO_CART_TEMPLATE_SUCCESS_1', {
                    count,
                  })}
                </Typography>
                <Typography variant={'body'}>
                  {t(
                    count === 1
                      ? 'COMMON.ACTIONS.ADD_ARTICLES_TO_CART_TEMPLATE_SUCCESS_2'
                      : 'COMMON.ACTIONS.ADD_ARTICLES_TO_CART_TEMPLATE_SUCCESS_2_PLURAL'
                  )}
                </Typography>
                <Typography variant={'bodyBold'}>{templateName}</Typography>
                <Typography variant={'body'}>
                  {t('COMMON.ACTIONS.ADD_ARTICLES_TO_CART_TEMPLATE_SUCCESS_3')}
                </Typography>
              </Box>
            </NotificationUndo>
          )
        }
        case NotificationType.OrderAddDiverseArticlesToCartWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t('ORDERS.DETAILS.ACTIONS.ADD_DIVERSE_ARTICLES_TO_CART_WARNING.TITLE')}
              </Typography>
              <Typography variant="body">
                {t('ORDERS.DETAILS.ACTIONS.ADD_DIVERSE_ARTICLES_TO_CART_WARNING.BODY')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.OrderAddUnpurchasableArticlesToCartWarning: {
          const rows = [
            <Typography key={`${info.event.id}-heading`} variant="bodyBold">
              {t('ORDERS.DETAILS.ACTIONS.ADD_UNPURCHASABLE_ARTICLES_TO_CART_WARNING.TITLE')}
            </Typography>,
            <Typography key={`${info.event.id}-body`} variant="body">
              {t('ORDERS.DETAILS.ACTIONS.ADD_UNPURCHASABLE_ARTICLES_TO_CART_WARNING.BODY')}
            </Typography>,
          ]
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <NotificationMultiRow rows={rows} />
            </NotificationUndo>
          )
        }
        case NotificationType.OrderAddDiverseArticlesToCartTemplateWarning: {
          return (
            <NotificationUndo
              closeAfterMs={8000}
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="warning"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant="bodyBold">
                {t('ORDERS.DETAILS.ACTIONS.ADD_DIVERSE_ARTICLES_TO_CART_TEMPLATE_WARNING.TITLE')}
              </Typography>
              <Typography variant="body">
                {t('ORDERS.DETAILS.ACTIONS.ADD_DIVERSE_ARTICLES_TO_CART_TEMPLATE_WARNING.BODY')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.CartHasItemsNoPickupPossible: {
          return (
            <NotificationWithActions
              actions={[
                {
                  type: 'secondary',
                  text: t('SHOPPING_CART.ADAPT_CART'),
                  handler: () => {
                    history.push(`${ShopRoutes.ShoppingCartDetails}/${info.event.id}`)
                    removeProcessInfo(info.event.id)
                  },
                },
              ]}
              key={info.event.id}
              id={info.event.id}
              customIcon={InfoIcon}
              variant="error"
              closeHandler={removeProcessInfo}
            >
              <Typography variant="body">
                {t('SHOPPING_CART.CART_HAS_ITEMS_NO_PICKUP_POSSIBLE')}
              </Typography>
            </NotificationWithActions>
          )
        }
        case NotificationType.OrderPending: {
          if (!userData.isLoggedIn) {
            return null
          }
          if (
            !location.pathname.startsWith('/shopping-cart-checkout') &&
            !location.pathname.startsWith('/create-order')
          ) {
            const positionTotal =
              'positionTotal' in info.event.options ? info.event.options.positionTotal : ''
            const shippingDate =
              'shippingDate' in info.event.options ? info.event.options.shippingDate : ''
            const shoppingCartPrices =
              'shoppingCartPrices' in info.event.options
                ? info.event.options.shoppingCartPrices
                : {
                    net: 0,
                    metal: 0,
                    shipping: 0,
                    vat: 0,
                    netSum: 0,
                    totalSum: 0,
                    currency: '',
                    tecselect: 0,
                  }
            const shippingType =
              'shippingType' in info.event.options
                ? info.event.options.shippingType
                : ShippingType.Cargo
            const rows = [
              <Typography variant={'bodyBold'}>
                {shippingType === ShippingType.ExpressPickup
                  ? t('SHOPPING_CART.CHECKOUT.XPRESS_ORDER_IS_BEING_CREATED')
                  : `${t('SHOPPING_CART.CHECKOUT.ORDER_IS_BEING_CREATED')}...`}
              </Typography>,
              <Stack direction={'row'} flexWrap={'wrap'} gap="0 1rem">
                <Box>
                  <Typography variant={'body'}>
                    {t('SHOPPING_CART.CHECKOUT.NUMBER_OF_POSITIONS')}:&nbsp;
                  </Typography>
                  <Typography variant={'bodyBold'}>{positionTotal}</Typography>
                </Box>
                <Box>
                  <Typography variant={'body'}>
                    {t('SHOPPING_CART.OVERALL_VALUE')}:&nbsp;
                  </Typography>
                  <Typography variant={'bodyBold'}>
                    {normalizePrice(shoppingCartPrices.netSum, shoppingCartPrices.currency)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant={'body'}>{t('SHOPPING_CART.CHECKOUT.SUM')}:&nbsp;</Typography>
                  <Typography variant={'bodyBold'}>
                    {normalizePrice(shoppingCartPrices.totalSum, shoppingCartPrices.currency)}
                  </Typography>
                </Box>
                {shippingType === ShippingType.ExpressPickup && (
                  <Box>
                    <Typography variant={'body'}>
                      {t('SHOPPING_CART.CHECKOUT.PICKUP')}:&nbsp;
                    </Typography>
                    <Typography variant={'bodyBold'}>
                      {t('SHOPPING_CART.CHECKOUT.EXPRESS_COLLECTION_TIME')}
                    </Typography>
                  </Box>
                )}
                {(shippingType === ShippingType.DefaultPickup ||
                  shippingType === ShippingType.PickupRenzbox) && (
                  <Box>
                    <Typography variant={'body'}>
                      {t('SHOPPING_CART.PREFERRED_COLLECTION_DATE')}:&nbsp;
                    </Typography>
                    <Typography variant={'bodyBold'}>
                      {dayjs(shippingDate).format('DD.MM.YYYY')}
                    </Typography>
                  </Box>
                )}
                {(shippingType === ShippingType.Cargo ||
                  shippingType === ShippingType.DefaultParcel ||
                  shippingType === ShippingType.FastParcel) && (
                  <Box>
                    <Typography variant={'body'}>
                      {t('SHOPPING_CART.PREFERRED_DELIVERY_DATE')}:&nbsp;
                    </Typography>
                    <Typography variant={'bodyBold'}>
                      {dayjs(shippingDate).format('DD.MM.YYYY')}
                    </Typography>
                  </Box>
                )}
              </Stack>,
            ]

            return (
              <Notification
                id={info.event.id}
                key={info.event.id}
                variant="success"
                customIcon={NotificationPendingIcon}
                closeHandler={removeProcessInfo}
              >
                <NotificationMultiRow rows={rows} />
              </Notification>
            )
          }
          return null
        }
        case NotificationType.OrderFailure: {
          const reason = 'reason' in info.event.options && info.event.options.reason
          const action = 'action' in info.event.options && info.event.options.action
          const id = 'id' in info.event && info.event.id

          if (!userData.isLoggedIn || !reason) {
            return null
          }
          const isCreditLimitExceededNotification =
            reason === OrderFailureReason.CreditLimitExceeded

          const { heading, body, contactInfo } = getOrderFailureNotificationProps(
            reason,
            accountingPhoneNumber,
            servicePhoneNumber,
            shopSupportPhoneNumber,
            t
          )
          const showOrderingBlocked = reason === OrderFailureReason.OrderingBlocked
          const showNoPermissionGranted = reason === OrderFailureReason.NoPermissionGranted
          if ('cartId' in info.event.options && info.event.options.cartId !== undefined) {
            removeNotification(info.event.options.cartId, NotificationType.OrderPending)
          }

          const isCheckoutOrOrderPath = CHECKOUT_AND_ORDER_PATHS.some((path) =>
            location.pathname.startsWith(path)
          )
          const isCheckoutPath = CHECKOUT_PATHS.some((path) => location.pathname.startsWith(path))
          const isRemoveEvent =
            (isCreditLimitExceededNotification || showOrderingBlocked) && action === 'REMOVE'

          if (isRemoveEvent && typeof id === 'string') {
            const result = id.replace(/^REMOVE::/, '')
            removeNotificationById(result)
            removeNotificationById(id)
            return null
          }

          if (!isCreditLimitExceededNotification && isCheckoutOrOrderPath) return null // do not show order failure notifications other than credit limit exceeded on other pages than checkout and create-order
          if (isCreditLimitExceededNotification && !isCheckoutPath) return null // show order credit limit exceeded only on checkout page

          return (
            <Notification
              id={info.event.id}
              variant="error"
              closeHandler={
                showOrderingBlocked
                  ? (id) => {
                      localStorage.setItem('skipOrderingBlockedNotification', '1')
                      removeProcessInfo(id)
                    }
                  : removeProcessInfo
              }
            >
              {!showNoPermissionGranted && <Typography variant={'bodyBold'}>{heading}</Typography>}
              <Typography variant={'body'}>{body}</Typography>
              {!showNoPermissionGranted && (
                <Typography variant={'bodyBold'}>{contactInfo}</Typography>
              )}
            </Notification>
          )
        }
        case NotificationType.OrderIdMissingInResponse: {
          if (!userData.isLoggedIn) {
            return null
          }
          if (
            !location.pathname.startsWith('/shopping-cart-checkout') &&
            !location.pathname.startsWith('/create-order')
          ) {
            const reason = 'reason' in info.event.options && info.event.options.reason
            if (!userData.isLoggedIn || !reason) {
              return null
            }
            const { heading, contactInfo } = getOrderFailureNotificationProps(
              OrderFailureReason.Custom,
              accountingPhoneNumber,
              servicePhoneNumber,
              shopSupportPhoneNumber,
              t
            )
            return (
              <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
                <Typography variant={'bodyBold'}>{heading}</Typography>
                <Typography variant={'body'}>{reason}</Typography>
                <Typography variant={'bodyBold'}>{contactInfo}</Typography>
              </Notification>
            )
          }
          break
        }
        case NotificationType.OrderLimbo: {
          if ('cartId' in info.event.options && info.event.options.cartId !== undefined) {
            removeNotification(info.event.options.cartId, NotificationType.OrderPending)
          }
          if (!userData.isLoggedIn) {
            return null
          }
          const heading = 'heading' in info.event.options ? info.event.options.heading : ''
          const body = 'body' in info.event.options ? info.event.options.body : ''
          const contact = 'contact' in info.event.options ? info.event.options.contact : ''

          return (
            <Notification
              id={info.event.id}
              closeHandler={removeProcessInfo}
              customIcon={ContactSupportIcon}
              variant="infoLight"
            >
              <NotificationMultiRow
                rows={[
                  <Typography variant={'bodyBold'}>{heading}</Typography>,
                  <>
                    <Typography variant={'body'}>{body}</Typography>
                    <Typography variant={'bodyBold'}>{contact}</Typography>
                  </>,
                ]}
              />
            </Notification>
          )
        }
        case NotificationType.AmountRoundUp:
          return (
            <Notification
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeAfterMs={8000}
              variant="warning"
              customIcon={CartIcon}
              closeHandler={removeProcessInfo}
            >
              <NotificationStyledBlock
                heading={
                  'amountRoundUpNotificationHeading' in info.event.options
                    ? info.event.options.amountRoundUpNotificationHeading
                    : ''
                }
                body={
                  'amountRoundUpNotificationBody' in info.event.options
                    ? info.event.options.amountRoundUpNotificationBody
                    : ''
                }
              />
            </Notification>
          )
        case NotificationType.ElbridgeNotFoundItems: {
          const items = 'items' in info.event.options ? info.event.options.items : []
          return (
            <Notification
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeAfterMs={60000}
              variant="warning"
              closeHandler={removeProcessInfo}
            >
              <NotificationMultiRow
                rows={[
                  <Typography variant={'body'}>
                    {t('ELBRIDGE.NOT_FOUND_ITEMS_NOTIFICATION_0')}
                  </Typography>,
                  <Typography variant={'body'}>
                    {t('ELBRIDGE.NOT_FOUND_ITEMS_NOTIFICATION_1')}
                  </Typography>,
                  <Stack direction={'column'} gap="0.25rem">
                    {items.map((item) => {
                      const messageParts: string[] = []
                      if (item.errorDetails.ean) {
                        messageParts.push(`ean: ${item.errorDetails.ean}`)
                      }
                      if (item.errorDetails.supplierId) {
                        messageParts.push(`supplierId: ${item.errorDetails.supplierId}`)
                      }
                      if (item.errorDetails.supplierArticleId) {
                        messageParts.push(
                          `supplierArticleId: ${item.errorDetails.supplierArticleId}`
                        )
                      }

                      return <Typography variant={'body'}>{messageParts.join(', ')}</Typography>
                    })}
                  </Stack>,
                ]}
              />
            </Notification>
          )
        }
        case NotificationType.AddToCartFromIdsXmlItemsNotFound: {
          const items = 'items' in info.event.options ? info.event.options.items : []
          return (
            <Notification
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeAfterMs={60000}
              variant="warning"
              closeHandler={removeProcessInfo}
            >
              <NotificationMultiRow
                rows={[
                  <Stack direction={'column'} gap="0.25rem">
                    {(items as ShoppingCartItemResult[]).map((item) => (
                      <Typography variant={'body'}>{item.errorMessage}</Typography>
                    ))}
                  </Stack>,
                ]}
              />
            </Notification>
          )
        }
        case NotificationType.AddToCart: {
          const options = info.event.options as AnnouncementEvent
          return isAddToCartProcessIndicator ? null : (
            <Notification
              id={info.event.id}
              key={info.event.id}
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
              variant="success"
            >
              <Typography variant={'bodyBold'}>
                {options.title as string}
                <span style={{ fontWeight: 'normal' }}>
                  {t('SHOPPING_CART.ARTICLE_ADDED_TO_CART_PART2')}
                </span>
              </Typography>
            </Notification>
          )
        }

        case NotificationType.AddToCartMultiple: {
          const cartTitle =
            'cartTitle' in info.event.options ? info.event.options.cartTitle : undefined

          return isAddToCartProcessIndicator ? null : (
            <Notification
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeHandler={removeProcessInfo}
              variant="success"
              closeAfterMs={8000}
            >
              <Typography variant={'bodyBold'}>
                {'itemCount' in info.event.options ? info.event.options.itemCount : 0}{' '}
                {t('SHOPPING_CART.MULTIPLE_ARTICLES_ADDED_TO_CART.0')}
              </Typography>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.MULTIPLE_ARTICLES_ADDED_TO_CART.1')}{' '}
              </Typography>
              {cartTitle && <Typography variant={'bodyBold'}>{cartTitle} </Typography>}
              <Typography variant={'body'}>
                {t('SHOPPING_CART.MULTIPLE_ARTICLES_ADDED_TO_CART.2')}
              </Typography>
            </Notification>
          )
        }

        case NotificationType.AddOrReplaceOfferInCart: {
          return isAddToCartProcessIndicator ? null : (
            <NotificationUndo
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeHandler={removeProcessInfo}
              variant="success"
              closeAfterMs={8000}
              undoHandler={dummyHandler}
            >
              <Typography variant={'bodyBold'}>
                {t('OFFERS.ADD_OR_REPLACE_OFFER_IN_CART.0', {
                  offerName: 'offerName' in info.event.options ? info.event.options.offerName : '',
                })}
              </Typography>
              <Typography variant={'body'}>
                {' '}
                {t('OFFERS.ADD_OR_REPLACE_OFFER_IN_CART.1')}
              </Typography>
            </NotificationUndo>
          )
        }

        case NotificationType.AddSelectedCartTemplateItemsToCart: {
          const cartName = 'cartName' in info.event.options ? info.event.options.cartName : ''
          let itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : ''
          if (typeof itemCount === 'string') {
            itemCount = parseInt(itemCount, 10)
          }
          return isAddToCartProcessIndicator ? null : (
            <NotificationUndo
              id={info.event.id}
              key={info.event.id}
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
              variant="success"
              undoHandler={dummyHandler}
            >
              <Typography variant={'bodyBold'}>{`${itemCount} ${t(
                'TEMPLATES.ADD_SELECTED_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.0'
              )}`}</Typography>
              <Typography variant={'body'}>
                {itemCount > 1
                  ? t('TEMPLATES.ADD_SELECTED_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.1')
                  : t('TEMPLATES.ADD_SELECTED_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.2')}
              </Typography>
              <Typography variant={'bodyBold'}>{cartName}</Typography>
              <Typography variant={'body'}>
                {t('TEMPLATES.ADD_SELECTED_CART_TEMPLATE_ITEMS_TO_CART_NOTIFICATION.3')}
              </Typography>
            </NotificationUndo>
          )
        }

        case NotificationType.AddCartTemplateToCart: {
          const cartTemplateName =
            'cartTemplateName' in info.event.options ? info.event.options.cartTemplateName : ''
          const cartName = 'cartName' in info.event.options ? info.event.options.cartName : ''
          return isAddToCartProcessIndicator ? null : (
            <NotificationUndo
              id={info.event.id}
              key={info.event.id}
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
              variant="success"
              undoHandler={dummyHandler}
            >
              <Typography variant={'body'}>
                {t('TEMPLATES.ADD_CART_TEMPLATE_TO_CART_NOTIFICATION.0')}
              </Typography>
              <Typography variant={'bodyBold'}>{cartTemplateName}</Typography>
              <Typography variant={'body'}>
                {t('TEMPLATES.ADD_CART_TEMPLATE_TO_CART_NOTIFICATION.1')}
              </Typography>
              <Typography variant={'bodyBold'}>{cartName}</Typography>
              <Typography variant={'body'}>
                {t('TEMPLATES.ADD_CART_TEMPLATE_TO_CART_NOTIFICATION.2')}
              </Typography>
            </NotificationUndo>
          )
        }

        case NotificationType.AddOfferToCartTemplate: {
          const cartTemplateName =
            'cartTemplateName' in info.event.options ? info.event.options.cartTemplateName : ''
          const offerName = 'offerName' in info.event.options ? info.event.options.offerName : ''
          return (
            <NotificationUndo
              id={info.event.id}
              key={info.event.id}
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
              variant="success"
              undoHandler={dummyHandler}
            >
              <Typography variant={'body'}>
                {t('OFFERS.ADD_OFFER_TO_CART_TEMPLATE_NOTIFICATION.0')}
              </Typography>
              <Typography variant={'bodyBold'}>{offerName}</Typography>
              <Typography variant={'body'}>
                {t('OFFERS.ADD_OFFER_TO_CART_TEMPLATE_NOTIFICATION.1')}
              </Typography>
              <Typography variant={'bodyBold'}>{cartTemplateName}</Typography>
              <Typography variant={'body'}>
                {t('OFFERS.ADD_OFFER_TO_CART_TEMPLATE_NOTIFICATION.2')}
              </Typography>
            </NotificationUndo>
          )
        }

        case NotificationType.TemplateAdd: {
          if (!location.pathname.startsWith('/shopping-cart-details')) {
            return getNotificationTemplateAdd(info, index, removeProcessInfo)
          }
          break // notification triggered as part of selectionBarBoundNotifications
        }
        case NotificationType.CustomProductToTemplateAdd: {
          if (!location.pathname.startsWith('/shopping-cart-details')) {
            return getNotificationTemplateAddError(info, index, removeProcessInfo)
          }
          break // notification triggered as part of selectionBarBoundNotifications
        }
        case NotificationType.DeleteCartTemplateSingle:
          if (!location.pathname.startsWith('/shopping-cart-details')) {
            const templateName =
              'templateName' in info.event.options ? info.event.options.templateName : ''
            return (
              <NotificationUndo
                key={`${info.event.id}-${index}`}
                id={info.event.id}
                variant="infoLight"
                closeHandler={removeProcessInfo}
                closeAfterMs={8000}
                customIcon={DeleteIcon}
                undoHandler={dummyHandler}
              >
                <Typography variant={'body'}>Die Merkliste</Typography>
                <Typography variant={'bodyBold'}> {templateName} </Typography>
                <Typography variant={'body'}>wurde gelöscht.</Typography>
              </NotificationUndo>
            )
          }
          break // notification triggered as part of selectionBarBoundNotifications
        case NotificationType.DeleteCartTemplateMultiple:
          if (!location.pathname.startsWith('/shopping-cart-details')) {
            const itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
            return (
              <NotificationUndo
                key={`${info.event.id}-${index}`}
                id={info.event.id}
                closeHandler={removeProcessInfo}
                variant="infoLight"
                customIcon={DeleteIcon}
                closeAfterMs={8000}
                undoHandler={dummyHandler}
              >
                <Typography variant={'bodyBold'}>
                  {itemCount} {'Merklisten'}
                </Typography>
                <Typography variant={'body'}>wurden gelöscht.</Typography>
              </NotificationUndo>
            )
          }
          break // notification triggered as part of selectionBarBoundNotifications
        case NotificationType.DeleteCartTemplateItemSingle: {
          const cartTemplateEmpty =
            'cartTemplateEmpty' in info.event.options ? info.event.options.cartTemplateEmpty : false
          const productTitle =
            'productTitle' in info.event.options ? info.event.options.productTitle : ''
          const cartTemplateTitle =
            'cartTemplateTitle' in info.event.options ? info.event.options.cartTemplateTitle : ''

          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              customIcon={DeleteIcon}
              closeAfterMs={8000}
              variant="infoLight"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant={'bodyBold'}>{productTitle}</Typography>
              <Typography variant={'body'}>{t('TEMPLATES.ITEM_DELETED.0')}</Typography>
              <Typography variant={'bodyBold'}>{cartTemplateTitle}</Typography>
              <Typography variant={'body'}>{t('TEMPLATES.ITEM_DELETED.1')}</Typography>
              {cartTemplateEmpty && (
                <Typography variant={'body'}>{t('TEMPLATES.CART_TEMPLATE_EMPTY')}</Typography>
              )}
            </NotificationUndo>
          )
        }
        case NotificationType.DeleteCartTemplateItems: {
          const itemCount = 'itemCount' in info.event.options ? info.event.options.itemCount : 0
          const cartTemplateEmpty =
            'cartTemplateEmpty' in info.event.options ? info.event.options.cartTemplateEmpty : false
          const cartTemplateName =
            'cartTemplateName' in info.event.options ? info.event.options.cartTemplateName : ''

          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              customIcon={DeleteIcon}
              closeAfterMs={8000}
              variant="infoLight"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant={'bodyBold'}>{itemCount} Artikel</Typography>
              <Typography variant={'body'}>
                {itemCount > 1
                  ? t('TEMPLATES.ITEM_DELETED_MULTIPLE')
                  : t('TEMPLATES.ITEM_DELETED.0')}
              </Typography>
              <Typography variant={'bodyBold'}> {cartTemplateName} </Typography>
              <Typography variant={'body'}>{t('TEMPLATES.ITEM_DELETED.1')}</Typography>
              {cartTemplateEmpty && (
                <Typography variant={'body'}>{t('TEMPLATES.CART_TEMPLATE_EMPTY')}</Typography>
              )}
            </NotificationUndo>
          )
        }
        case NotificationType.CartTemplateVisibilitySetToPublic: {
          const templateName =
            'templateName' in info.event.options ? info.event.options.templateName : ''
          return (
            <Notification
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeAfterMs={8000}
              variant="infoLight"
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>{t('TEMPLATES.VISIBILITY_SET_TO_PUBLIC.0')}</Typography>
              <Typography variant={'bodyBold'}> {templateName} </Typography>
              <Typography variant={'body'}>{t('TEMPLATES.VISIBILITY_SET_TO_PUBLIC.1')}</Typography>
            </Notification>
          )
        }
        case NotificationType.CartTemplateVisibilitySetToPrivate: {
          const templateName =
            'templateName' in info.event.options ? info.event.options.templateName : ''
          return (
            <Notification
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeAfterMs={8000}
              variant="infoLight"
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>{t('TEMPLATES.VISIBILITY_SET_TO_PRIVATE.0')}</Typography>
              <Typography variant={'bodyBold'}> {templateName} </Typography>
              <Typography variant={'body'}>{t('TEMPLATES.VISIBILITY_SET_TO_PRIVATE.1')}</Typography>
            </Notification>
          )
        }
        case NotificationType.CartTemplateVisibilitySetToPrivateAccessDenied: {
          const templateName =
            'templateName' in info.event.options ? info.event.options.templateName : ''
          return (
            <Notification
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeAfterMs={8000}
              variant="infoLight"
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>
                {t('TEMPLATES.VISIBILITY_SET_TO_PRIVATE_ACCESS_DENIED.0')}
              </Typography>
              <Typography variant={'bodyBold'}> {templateName} </Typography>
              <Typography variant={'body'}>
                {t('TEMPLATES.VISIBILITY_SET_TO_PRIVATE_ACCESS_DENIED.1')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.CartTemplateCreated: {
          const templateName =
            'cartTemplateName' in info.event.options ? info.event.options.cartTemplateName : ''
          return (
            <NotificationUndo
              key={`${info.event.id}-${index}`}
              id={info.event.id}
              closeAfterMs={8000}
              variant="success"
              closeHandler={removeProcessInfo}
              undoHandler={dummyHandler}
            >
              <Typography variant={'body'}>
                {t('TEMPLATES.CART_TEMPLATE_CREATED_NOTIFICATION.0')}
              </Typography>
              <Typography variant={'bodyBold'}> {templateName} </Typography>
              <Typography variant={'body'}>
                {t('TEMPLATES.CART_TEMPLATE_CREATED_NOTIFICATION.1')}
              </Typography>
            </NotificationUndo>
          )
        }
        case NotificationType.DuplicateCartTemplateSingle:
          if (!location.pathname.startsWith('/shopping-cart-details')) {
            const templateName =
              'templateName' in info.event.options ? info.event.options.templateName : ''
            const templateNameDuplicate = templateName ? `${templateName} Kopie` : ''
            return (
              <NotificationUndo
                key={info.event.id}
                id={info.event.id}
                variant="infoLight"
                closeHandler={removeProcessInfo}
                closeAfterMs={8000}
                customIcon={InfoIcon}
                undoHandler={dummyHandler}
              >
                <Typography variant="body">
                  {t('TEMPLATES_CONTEXT_MENU.DUPLICATE_SUCCESS.1')}
                </Typography>
                <Typography variant="bodyBold"> {templateName} </Typography>
                <Typography variant="body">
                  {t('TEMPLATES_CONTEXT_MENU.DUPLICATE_SUCCESS.2')}
                </Typography>
                <Typography variant="bodyBold"> {templateNameDuplicate} </Typography>
                <Typography variant="body">
                  {t('TEMPLATES_CONTEXT_MENU.DUPLICATE_SUCCESS.3')}
                </Typography>
              </NotificationUndo>
            )
          }
          break // notification triggered as part of selectionBarBoundNotifications
        case NotificationType.SapOfferPdfCreated: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          const options = info.event.options as SapOfferPdfCreatedOptions
          return (
            <NotificationWithActions
              closeHandler={removeProcessInfo}
              key={info.event.id}
              id={info.event.id}
              variant="success"
              actions={[
                {
                  type: 'secondary',
                  icon: <PdfIcon />,
                  text: t('NOTIFICATION.OPEN_PDF'),
                  handler: () => {
                    window.open(options.fileUrl, '_blank')
                  },
                },
              ]}
            >
              <Typography variant={'body'}>{t('OFFERS.PDF_NOTIFICATION.OFFER_READY_1')}</Typography>
              <Typography variant={'bodyBold'}>{options.offerId}</Typography>
              <Typography variant={'body'}>{t('OFFERS.PDF_NOTIFICATION.OFFER_READY_2')}</Typography>
            </NotificationWithActions>
          )
        }
        case NotificationType.SapInvoicePending: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          return (
            <Notification
              id={info.event.id}
              variant="success"
              customIcon={NotificationPendingIcon}
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>{t('MY_ACCOUNTING.PENDING_COPIES')}</Typography>
            </Notification>
          )
        }
        case NotificationType.DeliverySlipPDFPending: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          return (
            <Notification
              id={info.event.id}
              variant="success"
              customIcon={NotificationPendingIcon}
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.PENDING_DELIVERY_SLIP')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.OrderConfirmationPDFPending: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          return (
            <Notification
              id={info.event.id}
              variant="success"
              customIcon={NotificationPendingIcon}
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.PENDING_ORDER_CONFIRMATION')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.SapOfferPdfFailed: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'bodyBold'}>
                {t('OFFERS.PDF_NOTIFICATION.OFFER_ERROR')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.DeliverySlipPDFFailed: {
          removeNotificationById('delivery-slip-pending')
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.DELIVERY_SLIP_ERROR')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.OrderConfirmationPDFFailed: {
          removeNotificationById('order-confirmation-pending')
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.ORDER_CONFIRMATION_ERROR')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.SmallConstructionDocumentationPDFPending: {
          return (
            <Notification
              id={info.event.id}
              customIcon={NotificationPendingIcon}
              variant="success"
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.PENDING_SMALL_CONSTRUCTION_DOCUMENTATION')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.SmallConstructionDocumentationPDFCreated: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('small-construction-documentation-pending')
          const options = info.event.options as SmallConstructionDocumentationCreatedOptions
          const notificationRows = [
            <Typography variant={'body'}>
              {t('ORDERS.NOTIFICATION.SMALL_CONSTRUCTION_DOCUMENTATION_READY')}
            </Typography>,
          ]

          return (
            <NotificationWithActions
              closeHandler={removeProcessInfo}
              key={info.event.id}
              id={info.event.id}
              variant="success"
              actions={[
                {
                  type: 'secondary',
                  icon: <PdfIcon />,
                  text: t('NOTIFICATION.OPEN_PDF'),
                  handler: () => {
                    window.open(options.fileUrl, '_blank')
                    if (info.event.uid) {
                      removeNotificationByUid(info.event.uid)
                      channelNotificationUidProcessor.postMessage({
                        action: 'remove-notification',
                        uid: info.event.uid,
                      })
                    }
                  },
                },
              ]}
            >
              <NotificationMultiRow rows={notificationRows} />
            </NotificationWithActions>
          )
        }
        case NotificationType.SmallConstructionDocumentationPDFFailed: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('small-construction-documentation-pending')
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'body'}>
                {t('ORDERS.NOTIFICATION.SMALL_CONSTRUCTION_DOCUMENTATION_ERROR')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.SapInvoiceCreated: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('sap-invoice-pending')
          const options = info.event.options as SapInvoiceCreatedOptions
          const isZip = options.invoiceIds !== undefined
          const missingInvoiceIds = options.missingInvoiceIds
          let icon, iconText, notificationText
          if (isZip) {
            icon = <DownloadIcon />
            iconText = t('NOTIFICATION.OPEN_ZIP')
            notificationText = t('ORDERS.NOTIFICATION.INVOICES_READY')
          } else {
            icon = <PdfIcon />
            iconText = t('NOTIFICATION.OPEN_PDF')
            notificationText = t('ORDERS.NOTIFICATION.INVOICE_READY')
          }

          const notificationRows = [<Typography variant={'body'}>{notificationText}</Typography>]

          if (missingInvoiceIds && missingInvoiceIds.length !== 0) {
            notificationRows.push(
              <Typography variant={'body'}>{`${t(
                'MY_ACCOUNTING.MISSING_INVOICE_IDS'
              )} ${missingInvoiceIds.join(', ')}`}</Typography>
            )
          }

          return (
            <NotificationWithActions
              closeHandler={removeProcessInfo}
              key={info.event.id}
              id={info.event.id}
              variant="success"
              actions={[
                {
                  type: 'secondary',
                  icon: icon,
                  text: iconText,
                  handler: () => {
                    window.open(options.fileUrl, '_blank')
                    if (info.event.uid) {
                      removeNotificationByUid(info.event.uid)
                      channelNotificationUidProcessor.postMessage({
                        action: 'remove-notification',
                        uid: info.event.uid,
                      })
                    }
                  },
                },
              ]}
            >
              <NotificationMultiRow rows={notificationRows} />
            </NotificationWithActions>
          )
        }
        case NotificationType.DeliverySlipPDFCreated: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('delivery-slip-pending')
          const options = info.event.options as DeliverySlipPDFCreatedOptions
          const notificationRows = [
            <Typography variant={'body'}>
              {t('ORDERS.NOTIFICATION.DELIVERY_SLIP_READY')}
            </Typography>,
          ]

          return (
            <NotificationWithActions
              closeHandler={removeProcessInfo}
              key={info.event.id}
              id={info.event.id}
              variant="success"
              actions={[
                {
                  type: 'secondary',
                  icon: <PdfIcon />,
                  text: t('NOTIFICATION.OPEN_PDF'),
                  handler: () => {
                    window.open(options.fileUrl, '_blank')
                    removeProcessInfo(info.event.id)
                  },
                },
              ]}
            >
              <NotificationMultiRow rows={notificationRows} />
            </NotificationWithActions>
          )
        }
        case NotificationType.OrderConfirmationPDFCreated: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('order-confirmation-pending')
          const options = info.event.options as OrderConfirmationPDFCreatedOptions
          const notificationRows = [
            <Typography variant={'body'}>
              {t('ORDERS.NOTIFICATION.ORDER_CONFIRMATION_READY')}
            </Typography>,
          ]

          return (
            <NotificationWithActions
              closeHandler={removeProcessInfo}
              key={info.event.id}
              id={info.event.id}
              variant="success"
              actions={[
                {
                  type: 'secondary',
                  icon: <PdfIcon />,
                  text: t('NOTIFICATION.OPEN_PDF'),
                  handler: () => {
                    window.open(options.fileUrl, '_blank')
                    removeProcessInfo(info.event.id)
                  },
                },
              ]}
            >
              <NotificationMultiRow rows={notificationRows} />
            </NotificationWithActions>
          )
        }
        case NotificationType.SapInvoiceFailed: {
          if (!isFrontendClientIdMatch) {
            return null
          }
          removeNotificationById('sap-invoice-pending')
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'body'}>{t('ORDERS.NOTIFICATION.INVOICE_ERROR')}</Typography>
            </Notification>
          )
        }
        case NotificationType.OfferNotValid: {
          if (!userData.isLoggedIn) {
            return null
          }
          const changedCartItemIds =
            'changedCartItemIds' in info.event.options && info.event.options.changedCartItemIds
          return (
            <Notification id={info.event.id} variant="error" closeHandler={removeProcessInfo}>
              <Typography variant={'bodyBold'}>
                {t('OFFERS.MANIPULATED_OFFER_IN_CART', {
                  changedOfferCartItemIds: changedCartItemIds,
                })}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.OrderNotFound:
          return (
            <Notification
              id={info.event.id}
              key={`${info.event.id}-${index}`}
              closeAfterMs={8000}
              variant="error"
              customIcon={DeleteIcon}
              closeHandler={removeProcessInfo}
            >
              <Typography variant={'body'}>{t('ORDERS.NOTIFICATION.ORDER_DELETED.0')}</Typography>
              <Typography variant={'bodyBold'}>
                {'orderId' in info.event.options ? info.event.options.orderId : ''}
              </Typography>
              <Typography>{t('ORDERS.NOTIFICATION.ORDER_DELETED.1')}</Typography>
            </Notification>
          )
        case NotificationType.UpdateUser: {
          if (
            'type' in info.event.options &&
            info.event.options.type === 'Error' &&
            'action' in info.event.options &&
            info.event.options.action === 'ConfirmEmail' &&
            'userId' in info.event.options &&
            info.event.options.userId
          ) {
            const userId = info.event.options.userId
            const handleClick = (userId: string) => {
              dispatchTokenRefresh({ userId: userId, subUserId: userId })
            }
            return (
              <NotificationWithActions
                key={info.event.id}
                id={info.event.id}
                variant={'error'}
                closeHandler={removeProcessInfo}
                closeAfterMs={8000}
                actions={[
                  {
                    type: 'secondary',
                    text: t(
                      'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ACCOUNT_DETAILS.EMAIL_ADDRESS_SEND_CONFIRM_AGAIN'
                    ),
                    handler: () => handleClick(userId),
                  },
                ]}
              >
                <Typography variant={'body'}>
                  {'message' in info.event.options ? info.event.options.message : ''}
                </Typography>
              </NotificationWithActions>
            )
          } else if ('messageActions' in info.event.options && info.event.options.messageActions) {
            const buttonText = info.event.options.messageActions[0].text
            const buttonLink = info.event.options.messageActions[0].url
            const handleClick = (url: string) => {
              history.push(url)
            }
            return (
              <NotificationWithActions
                key={info.event.id}
                id={info.event.id}
                variant={
                  'type' in info.event.options && info.event.options.type === 'Success'
                    ? 'success'
                    : 'error'
                }
                closeHandler={removeProcessInfo}
                closeAfterMs={8000}
                actions={[
                  {
                    type: 'secondary',
                    text: buttonText,
                    handler: () => handleClick(buttonLink),
                  },
                ]}
              >
                <Typography variant={'body'}>
                  {'message' in info.event.options ? info.event.options.message : ''}
                </Typography>
              </NotificationWithActions>
            )
          } else {
            return (
              <Notification
                key={info.event.id}
                id={info.event.id}
                variant={
                  'type' in info.event.options && info.event.options.type === 'Success'
                    ? 'success'
                    : 'error'
                }
                closeHandler={removeProcessInfo}
                closeAfterMs={3000}
              >
                <Typography variant={'body'}>
                  {'message' in info.event.options ? info.event.options.message : ''}
                </Typography>
              </Notification>
            )
          }
        }
        case NotificationType.FastProductEntryFileUploadErrorCommon: {
          removeNotificationById('fast-product-entry-file-upload-processing')

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              variant="error"
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
            >
              <Typography>
                {t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.FILE_UPLOAD.ERRORS.COMMON')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.FastProductEntryFileUploadErrorNotFound: {
          removeNotificationById('fast-product-entry-file-upload-processing')

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              variant="error"
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
            >
              <Typography variant="bodyBold">
                {t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.FILE_UPLOAD.ERRORS.NOT_FOUND')}
              </Typography>
            </Notification>
          )
        }
        case NotificationType.FastProductEntryFileUploadProcessing:
          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              customIcon={NotificationPendingIcon}
              variant="success"
              closeHandler={removeProcessInfo}
            >
              <Typography variant="bodyBold">
                {'name' in info.event.options ? info.event.options.name : ''}
              </Typography>
              <Typography>
                {t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.FILE_UPLOAD.PROCESSING')}
              </Typography>
            </Notification>
          )
        case NotificationType.FastProductEntryProductDelete:
          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              customIcon={DeleteIcon}
              variant="infoLight"
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
            >
              <Typography variant="bodyBold">
                {'name' in info.event.options ? info.event.options.name : ''}
              </Typography>
              <Typography>{t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.DELETE_PRODUCT')}</Typography>
            </Notification>
          )
        case NotificationType.FastProductEntryFileUploadSuccess: {
          removeNotificationById('fast-product-entry-file-upload-processing')

          const count =
            'count' in info.event.options && info.event.options.count
              ? (info.event.options.count as number)
              : 0
          const ids =
            'ids' in info.event.options && info.event.options.ids
              ? (info.event.options.ids as string[])
              : []

          if (count < 1) return null

          return (
            <Notification
              key={info.event.id}
              id={info.event.id}
              customIcon={InfoIcon}
              variant="warning"
              closeAfterMs={8000}
              closeHandler={removeProcessInfo}
            >
              <Typography variant="bodyBold">
                {t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.PRODUCTS_NOT_FOUND.1', {
                  count,
                })}
              </Typography>
              <Typography>
                {count > 1
                  ? t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.PRODUCTS_NOT_FOUND.2_MULTI')
                  : t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.PRODUCTS_NOT_FOUND.2_SINGLE')}
              </Typography>
              <Typography variant="bodyBold">{ids.join(', ')}</Typography>
              {count > ids.length && (
                <Typography>
                  {t('FAST_PRODUCT_ENTRY.NOTIFICATIONS.PRODUCTS_NOT_FOUND.3', {
                    count: count - ids.length,
                  })}
                </Typography>
              )}
            </Notification>
          )
        }
        case NotificationType.CreditLimitPreAlert: {
          const creditAvailable =
            'creditAvailable' in info.event.options && info.event.options.creditAvailable
              ? (info.event.options.creditAvailable as number)
              : 0
          return (
            <NotificationWithActions
              key={info.event.id}
              id={info.event.id}
              customIcon={CartIcon}
              variant="infoLight"
              closeHandler={(id) => {
                removeProcessInfo(id)
                closeCreditLimitPreAlertShown()
              }}
              actions={[
                {
                  type: 'secondary',
                  text: t('MY_ACCOUNTING.TITLE'),
                  handler: () => history.push(ShopRoutes.MyAccounting),
                },
              ]}
            >
              <Box component="span">
                <Typography variant={'bodyBold'}>
                  {t('MY_ACCOUNTING.CREDIT_LIMIT_PRE_ALERT.0')}
                </Typography>
                <Typography variant={'body'}>
                  {t('MY_ACCOUNTING.CREDIT_LIMIT_PRE_ALERT.1')}
                </Typography>
                <Typography variant={'bodyBold'}>{`${normalizePrice(
                  creditAvailable,
                  '€'
                )}.`}</Typography>
              </Box>
            </NotificationWithActions>
          )
        }
        default: {
          // allows to detect what cases of NotificationType are not handled
          const unrecognized = info.event.notificationType
          console.warn(`Notification type: ${unrecognized} was not handled in GlobalNotification`)
          return null
        }
      }
      return null
    })

    const selectionBarBoundNotifications = globalNotificationsState.queue.map((info, index) => {
      switch (info.event.notificationType) {
        case NotificationType.DeleteArticleMultiple:
          if (!('cartEmpty' in info.event.options) || !info.event.options.cartEmpty) {
            return getNotificationDeleteArticleMultiple(info, index, removeProcessInfo, t)
          }
          break // notification triggered as part of globalNotifications
        case NotificationType.CartMove:
          if (!('cartEmpty' in info.event.options) || !info.event.options.cartEmpty) {
            return getNotificationCartMove(info, index, removeProcessInfo, t)
          }
          break // notification triggered as part of globalNotifications
        case NotificationType.TemplateAdd:
          if (location.pathname.startsWith('/shopping-cart-details')) {
            return getNotificationTemplateAdd(info, index, removeProcessInfo)
          }
          break // notification triggered as part of globalNotifications
        case NotificationType.CustomProductToTemplateAdd: {
          if (location.pathname.startsWith('/shopping-cart-details')) {
            return getNotificationTemplateAddError(info, index, removeProcessInfo)
          }
          break
        }
        default:
          return null
      }
      return null
    })

    return !scan ? (
      <>
        <div
          className={styles.globalNotificationWrapper}
          style={{
            top: `calc(${dimensions.height}px + 4px + var(--obeta-safe-area-top-root))`,
          }}
        >
          {globalNotifications}
        </div>
        <div className={styles.selectionBarNotificationsWrapper}>
          {selectionBarBoundNotifications}
        </div>
      </>
    ) : null
  }
)
