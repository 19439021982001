import React, { useContext } from 'react'

export interface IPdfFlags {
  isCartItemForPdf: boolean
}

const defaultFlags = {
  isCartItemForPdf: false,
}

// TODO: to avoid errors when hook is called from print-service render
export const PdfFlagsContext = React.createContext<IPdfFlags>(defaultFlags)

export const usePdfFlags = () => useContext(PdfFlagsContext)
