import { useState, useEffect } from 'react'

export const mdQuery = '(min-width: 720px)'
export const belowMdQuery = '(max-width: 767.98px)'
export const belowMdHeightQuery = '(max-height: 767.98px)'
export const aboveMdQuery = '(min-width: 768px)'
export const belowSmQuery = '(max-width: 575.98px)'
export const landscapeBelowSmQuery = `${belowMdQuery} and (orientation: landscape)`
export const landscapeAboveMdQuery = `${aboveMdQuery} and (orientation: landscape)`

export const belowMdWidthQueryPortrait = '(max-width: 767.98px) and (orientation: portrait)'
export const belowMdHeightQueryLandscape = '(max-height: 767.98px) and (orientation: landscape)'

export const useMediaQuery = (query: string) => {
  const defaultMatches = false,
    supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'
  const [match, setMatch] = useState(() => {
    if (!supportMatchMedia) {
      return defaultMatches
    }
    return window.matchMedia(query).matches

    // Once the component is mounted, we rely on the
    // event listeners to return the correct matches value.
  })

  useEffect(() => {
    let active = true

    if (!supportMatchMedia) {
      return undefined
    }

    const queryList = window.matchMedia(query)
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches)
      }
    }
    updateMatch()
    queryList.addListener(updateMatch)
    return () => {
      active = false
      queryList.removeListener(updateMatch)
    }
  }, [query, supportMatchMedia])

  return match
}
