import { gql } from '@apollo/client'

export const UPDATE_ORDER_META_DATA_MUTATION = gql`
  mutation updateOrderMetaData($input: UpdateOrderMetaDataInput!) {
    updateOrderMetaData(input: $input) {
      success
      errorCode
      errorMessage
      orderId
    }
  }
`
