import { useMemo } from 'react'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useEntities } from './useEntities'
import { useUserDataV2 } from './useUserDataV2'
import { useSessionCart } from './useSessionCart'
import { getSessionContext } from '@obeta/utils/lib/session-context'

interface IShoppingCards {
  activeCartItemsCount: number
  activeCart: ShoppingCartV2 | undefined
  carts: ShoppingCartV2[]
  activeCarts: ShoppingCartV2[]
  activeCartId: string | undefined
  activeCartsCount: number
  cartsCount: number
}

export const useShoppingCartsV2 = (): IShoppingCards => {
  const { user } = useUserDataV2()
  const sessionCart = useSessionCart()
  const shoppingCarts = useEntities<ShoppingCartV2>('cartsv2')
  const sessionContext = getSessionContext()
  const activeSessionCartId =
    sessionCart && sessionContext?.type === 'oci' ? sessionCart.id : user?.settings?.defaultCartId
  const activeCartId = sessionCart ? activeSessionCartId : user?.settings?.defaultCartId
  return useMemo(() => {
    const activeCarts = shoppingCarts.filter(
      (cart) => cart.id === activeCartId || !!cart.items.length
    )
    const activeCartsCount = activeCarts.length
    const activeCart = shoppingCarts.find((cart) => cart.id === activeCartId)
    const activeCartItemsCount = activeCart?.items.length || 0
    const cartsCount = shoppingCarts.length
    return {
      activeCart,
      activeCarts,
      activeCartItemsCount,
      carts: shoppingCarts,
      activeCartId,
      activeCartsCount,
      cartsCount,
    }
  }, [activeCartId, shoppingCarts])
}
