import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import clsx from 'clsx'
import React from 'react'
import styles from './NavigationOverlay.module.scss'

interface INavigationOverlayProps {
  open: boolean
  onClose?: () => void
  onExited?: () => void
  className?: string
  classes?: { paper?: string }
  topOffset?: number
  slideUp?: boolean
}

export const NavigationOverlay: React.FC<INavigationOverlayProps> = (props) => {
  const {
    className,
    open,
    onClose,
    topOffset,
    onExited,
    classes,
    slideUp = false,
    children,
  } = props

  return (
    <Modal
      open={open}
      onClose={() => {
        /** for backward compatability with old IonPopover do not pass event object to onClose */
        onClose?.()
      }}
      style={{ top: `${topOffset}px` }}
      sx={{ '.MuiModal-backdrop': { top: `${topOffset}px` } }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <Slide direction={slideUp ? 'up' : 'left'} in={open} onExited={onExited} unmountOnExit>
        <div className={clsx(styles.wrapper, className, { [styles.slideUpAppearance]: slideUp })}>
          <Paper className={clsx(styles.paper, classes?.paper)}>{children}</Paper>
        </div>
      </Slide>
    </Modal>
  )
}
