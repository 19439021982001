const SESSION_CONTEXT_STORAGE_KEY = 'sessionContext'

export type SessionType = 'oci' | 'ids'

type SessionContext = {
  type: SessionType
  id: string
  [key: string]: unknown
}

export const createSessionContext = ({ type, id, ...rest }: SessionContext) => {
  if (typeof window === 'undefined' || !localStorage) return
  const sessionContextValue = { type, id, ...rest }
  localStorage.setItem(SESSION_CONTEXT_STORAGE_KEY, JSON.stringify(sessionContextValue))
}

export const getSessionContext = () => {
  if (typeof window === 'undefined' || !localStorage) return
  const contextAsString = localStorage.getItem(SESSION_CONTEXT_STORAGE_KEY)
  if (!contextAsString) return
  return JSON.parse(contextAsString) as SessionContext
}

export const clearSessionContext = () => {
  if (typeof window === 'undefined' || !localStorage) return
  localStorage.removeItem(SESSION_CONTEXT_STORAGE_KEY)
}
