import React, { RefObject } from 'react'
import { TopSection } from './components/TopSection'
import { BottomSection } from './components/BottomSection'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import clsx from 'clsx'
import { InfoSection } from '../header/components/InfoSection/InfoSection'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as ObetaSymbol } from 'assets/icon/obetasymbol.svg'
import { ReactComponent as ObetaFullLogo } from 'assets/icon/obeta-full-logo.svg'
import { useShopInfo } from '@obeta/data/lib/hooks/useShopInfo'
import styles from './Header.module.scss'
import { ApolloProvider } from '@apollo/client'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

/**
 *
 * @param props.fullLogo - logo with name of domain. rendered in desktop mode.
 * @returns
 */
const Logo: React.FC<{ fullLogo: JSX.Element | null }> = (props) => {
  const { fullLogo } = props
  const { mobile, tabletAll } = useBreakpoints()

  if (mobile || tabletAll) {
    const dim = mobile ? 32 : 40
    return <ObetaSymbol width={dim} height={dim} />
  }

  return fullLogo
}

interface IHeaderProps {
  isUserAuthorized: boolean
  className?: string
  logo: JSX.Element | null
}

const Header = React.forwardRef<HTMLDivElement, IHeaderProps>(function Header(props, ref) {
  const { isUserAuthorized, logo, className } = props
  const apolloClient = useStrapiGql()
  useHeaderDimensions(ref as RefObject<HTMLElement>)

  return (
    <div ref={ref} className={clsx(styles.headerContainer, className)} id="app-header">
      <ApolloProvider client={apolloClient}>
        <InfoSection isLoggedIn={isUserAuthorized} />
      </ApolloProvider>

      <TopSection logo={logo} isLoggedIn={isUserAuthorized} />
      <BottomSection isLoggedIn={isUserAuthorized} />
    </div>
  )
})

export const ShopHeader = React.forwardRef<HTMLDivElement>((_, ref) => {
  const user = useUserDataV2()
  const { FullLogo } = useShopInfo()

  return (
    <Header
      ref={ref}
      className={styles.shopHeader}
      isUserAuthorized={user.isLoggedIn}
      logo={<Logo fullLogo={<FullLogo />} />}
    />
  )
})

export const ObetaHeader = React.forwardRef<HTMLDivElement>((props, ref) => {
  const user = useUserDataV2()

  return (
    <Header
      ref={ref}
      className={styles.appHeader}
      logo={<Logo fullLogo={<ObetaFullLogo />} />}
      isUserAuthorized={user.isLoggedIn}
      {...props}
    />
  )
})
