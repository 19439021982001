import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { getValidAppDomain } from '@obeta/utils/lib/getValidAppDomain'
import Axios from 'axios'
import { AccessTokenResponse } from './types'
import { WebOptions } from './web-options'
import { WebUtils } from './web-utils'
import { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

interface UserDataResponse {
  aud: string[]
  auth_time: number
  iat: number
  iss: string
  rat: number
  sub: string
  userId: string
}

export const requestAccessToken = async (webOptions: WebOptions, authorizationCode: string) => {
  try {
    const response = await Axios.post<AccessTokenResponse>(
      webOptions.accessTokenEndpoint,
      WebUtils.getTokenEndpointData(webOptions, authorizationCode),
      {
        headers: {
          accept: 'application/json',
          'cache-control': 'no-cache',
          'content-type': 'application/x-www-form-urlencoded',
        },
      }
    )

    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('ERR_GENERAL: See client logs. It might be CORS. Status text: ' + error.message)
    throw new Error('ERR_GENERAL')
  }
}

export const requestUserData = async (
  resourceUrl: string,
  accessToken: string,
  aditionalHeader?: Record<string, string> | null,
  logsEnabled?: boolean
): Promise<UserDataResponse> => {
  try {
    const response = await fetch(resourceUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...aditionalHeader,
      },
    })

    return await response.json()
  } catch (error) {
    if (logsEnabled) {
      // eslint-disable-next-line no-console
      console.log('ERR_GENERAL: ' + error.message)
    }
    throw new Error('ERR_GENERAL')
  }
}

export const getWebOptions = async (options: OAuth2AuthenticateOptions): Promise<WebOptions> => {
  // this is special handling for the support team
  // we detect support mode by these specified domains and redirect the login to the internal impersonation login
  if (isPlatform('web')) {
    // @TODO: What is mobile-support.obeta.io?
    // @TODO: replace with envs?

    const redirectBaseUrl = `${window.location.protocol}//${window.location.host}`
    const pathname = window.location.pathname
    // check if length is greater than 1, because the pathname is at least "/" (one character)
    const shouldRedirectToDedicatedPage = pathname.length > 1
    //shop redirects to another hydra client then the app, the apps redirect url is not configured to be a specific url
    const redirectUrl =
      shouldRedirectToDedicatedPage && !isUiTarget('app')
        ? `${redirectBaseUrl}/redirect`
        : redirectBaseUrl

    if (
      ['mobile-support.obeta.io', 'shop-support.obeta.io', 'staging-shop-support.obeta.io'].indexOf(
        window.location.host
      ) !== -1
    ) {
      // remove online scope to prevent issuing refreshTokens
      options.scope = 'openid'

      if (options.web) {
        // overwrite oauth2 params to use the support login for a impersonation token
        options.web.appId = 'support'
        options.web.redirectUrl = redirectUrl
        options.web.additionalParameters = {
          support_mode: 'enabled',
        }
      }
    } else if (options.web) {
      options.web.redirectUrl = redirectUrl
      options.web.additionalParameters = {
        ...options.web.additionalParameters,
        domain: getValidAppDomain() || '',
      }
    }
  }
  const opts = await WebUtils.buildWebOptions(options)
  return opts
}
