import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { Stock } from '@obeta/schema'

export const stocksEntity: EntityDescriptor<{ sapId: string; stock: Stock[] }> = {
  name: 'stocks',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'stocks',
    version: 2,
    description: 'a document representing a stock entry',
    type: 'object',
    primaryKey: 'sapId',
    properties: {
      sapId: {
        type: 'string',
        maxLength: 100,
      },
      stock: {
        type: 'array',
      },
    },
  },
}
