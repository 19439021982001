import { useState } from 'react'
import React from 'react'

export interface BarcodeProps {
  scan: boolean
  setScan?: React.Dispatch<React.SetStateAction<boolean>>
}

export const BarcodeContext = React.createContext<BarcodeProps>({
  scan: false,
})
export const useBarcodeState = (): BarcodeProps => {
  const [scan, setScan] = useState(false)
  return { scan, setScan }
}
