import React from 'react'

import { ReactComponent as hid120 } from 'assets/icon/categories/Ueberspannungsschutz.svg'
import { ReactComponent as hid130 } from 'assets/icon/categories/AbzweigkaestenSchalterdosen.svg'
import { ReactComponent as hid145 } from 'assets/icon/categories/RohrKanal.svg'
import { ReactComponent as hid160 } from 'assets/icon/categories/Befestigungsmaterial.svg'
import { ReactComponent as hid175 } from 'assets/icon/categories/Gebaeudeautomation.svg'
import { ReactComponent as hid188 } from 'assets/icon/categories/Bewegungsmelder.svg'
import { ReactComponent as hid200 } from 'assets/icon/categories/CEE.svg'
import { ReactComponent as hid320 } from 'assets/icon/categories/Sicherungen.svg'
import { ReactComponent as hid380 } from 'assets/icon/categories/VerteilerZaehlerschraenke.svg'
import { ReactComponent as hid397 } from 'assets/icon/categories/Gegensprechanlage.svg'
import { ReactComponent as hid400 } from 'assets/icon/categories/Netzwerktechnik.svg'
import { ReactComponent as hid410 } from 'assets/icon/categories/Antennentechnik.svg'
import { ReactComponent as hid940 } from 'assets/icon/categories/Werkzeuge.svg'
import { ReactComponent as hid970 } from 'assets/icon/categories/Arbeitsschutz.svg'
import { ReactComponent as hid1362062116679 } from 'assets/icon/categories/Schaltermaterial.svg'

//Main Deha Categories
import { ReactComponent as hid1 } from 'assets/icon/categories/KabelLeitungen.svg'
import { ReactComponent as hid2 } from 'assets/icon/categories/SchaltermaterialUnterputz.svg'
import { ReactComponent as hid3 } from 'assets/icon/categories/Befehlsgeraete.svg'
import { ReactComponent as hid4 } from 'assets/icon/categories/Leuchtmittel.svg'
import { ReactComponent as hid5 } from 'assets/icon/categories/Leuchten.svg'
import { ReactComponent as hid6 } from 'assets/icon/categories/Haustechnik.svg'
import { ReactComponent as hid7 } from 'assets/icon/categories/Hausgeraete.svg'
import { ReactComponent as hid8 } from 'assets/icon/categories/UnterhaltungselektronikTelekommunikation.svg'

import styles from './Categories.module.scss'
import clsx from 'clsx'
import { Box, useTheme } from '@mui/material'

const iconMapper = {
  //first we have simplified online categories
  hid1: hid1,
  hid2: hid2,
  hid3: hid3,
  hid4: hid4,
  hid5: hid5,
  hid6: hid6,
  hid7: hid7,
  hid8: hid8,
  /**
   * starting here we have catalog categories as they are used in print too
   * some match the online cats but a lot dont
   */
  hid105: hid1,
  hid120: hid120,
  hid130: hid130,
  hid145: hid145,
  hid160: hid160,
  hid175: hid175,
  hid180: hid2,
  hid188: hid188,
  hid200: hid200,
  hid320: hid320,
  hid350: hid3,
  hid380: hid380,
  hid385: hid7,
  hid390: hid6,
  hid397: hid397,
  hid400: hid400,
  hid405: hid8,
  hid410: hid410,
  hid420: hid4,
  hid500: hid5,
  hid940: hid940,
  hid970: hid970,
  hid1362062116679: hid1362062116679,
}

const colorMapping = {
  hid105: '#EA5B2D',
  hid120: '#004662',
  hid130: '#E30715',
  hid145: '#EE7303',
  hid160: '#006029',
  hid1362062116679: '#0078B3',
  hid175: '#8E223A',
  hid180: '#005DAA',
  hid188: '#E6A550',
  hid200: '#75971A',
  hid320: '#00918E',
  hid350: '#C64647',
  hid380: '#0088C0',
  hid385: '#A863A4',
  hid390: '#3BAA36',
  hid397: '#22BADD',
  hid400: '#169D64',
  hid405: '#9B1C57',
  hid410: '#00638B',
  hid420: '#FFCD17',
  hid500: '#F39300',
  hid940: '#006D7E',
  hid970: '#9A5B4F',
}

export const getCategoryIconPath = (categoryId: string): string => {
  return iconMapper[categoryId]
}
export const getCategoryIconBGColour = (categoryId: string): string => {
  return colorMapping[categoryId]
}

interface CategoryIconProps {
  catId: string
  className?: string
  size?: 'xs' | 'md' | 'lg'
  imgSize?: 'xs' | 'md' | 'lg'
  onCategoryClicked?: (id: string, name: string) => void
}

export const CategoryIcon: React.FC<CategoryIconProps> = (props) => {
  const theme = useTheme()

  const { size = 'xs', imgSize, catId, className } = props

  const iconSrc = getCategoryIconPath(catId)
  if (!iconSrc) {
    /**
     * Make sure to hide this component if there's no icon for user's "catId"
     */
    return null
  }
  const IconComp = getCategoryIconPath(catId)
  const bgColor = getCategoryIconBGColour(catId)
  const catColor = bgColor ? theme.palette.white.main : theme.palette.grayVariant.dark
  return (
    <Box
      color={catColor}
      bgcolor={bgColor}
      className={clsx(styles.categoryIconWrap, styles[size], className)}
    >
      <Box className={clsx(styles.categoryIcon, imgSize ? styles[`${imgSize}Img`] : undefined)}>
        <IconComp />
      </Box>
    </Box>
  )
}
