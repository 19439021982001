import {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { CellMeasurerCache } from 'react-virtualized'
import { useOfferDetailsContext } from './useOfferDetailsContext'
import { useOrderListContext } from './useOrderListContext'
import { useOrderDetailsInArrearsContext } from './useOrderDetailsInArrearsContext'

const initialEmptyFunction = () => {
  //
}

type ContextType = {
  offerDetailsCache: MutableRefObject<CellMeasurerCache>
  offerDetailsLastScrollY: MutableRefObject<number>
  orderListCache: MutableRefObject<CellMeasurerCache>
  orderListLastScrollY: MutableRefObject<number>
  orderInArrearsCache: MutableRefObject<CellMeasurerCache>
  orderInArrearsLastScrollY: MutableRefObject<number>
  resetOrderListCache: () => void
}

const INITIAL_OFFER_DETAILS_ROW_HEIGHT = 200
const INITIAL_OFFER_DETAILS_CACHE = {
  defaultHeight: INITIAL_OFFER_DETAILS_ROW_HEIGHT,
  fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
  minHeight: INITIAL_OFFER_DETAILS_ROW_HEIGHT, // expected minimum row height
}

const INITIAL_ORDER_LIST_CACHE = {
  fixedWidth: true,
}
const INITIAL_ORDER_IN_ARREARS_CACHE = {
  fixedWidth: true,
}

export const ScrollRestorationContext = createContext<ContextType>({
  offerDetailsCache: { current: new CellMeasurerCache(INITIAL_OFFER_DETAILS_CACHE) },
  offerDetailsLastScrollY: { current: 0 },
  orderListCache: { current: new CellMeasurerCache(INITIAL_ORDER_LIST_CACHE) },
  orderListLastScrollY: { current: 0 },
  orderInArrearsCache: { current: new CellMeasurerCache(INITIAL_ORDER_IN_ARREARS_CACHE) },
  orderInArrearsLastScrollY: { current: 0 },
  resetOrderListCache: initialEmptyFunction,
})

export const ScrollRestorationProvider = ({ children }) => {
  const { search: offerDetailsSearch } = useOfferDetailsContext()
  const { search: orderListSearch } = useOrderListContext()
  const { search: orderInArrearsSearch } = useOrderDetailsInArrearsContext()

  const offerDetailsCache = useRef<CellMeasurerCache>(
    new CellMeasurerCache(INITIAL_OFFER_DETAILS_CACHE)
  )
  const offerDetailsLastScrollY = useRef<number>(0)
  const orderListCache = useRef<CellMeasurerCache>(new CellMeasurerCache(INITIAL_ORDER_LIST_CACHE))
  const orderListLastScrollY = useRef<number>(0)
  const orderInArrearsCache = useRef<CellMeasurerCache>(
    new CellMeasurerCache(INITIAL_ORDER_LIST_CACHE)
  )
  const orderInArrearsLastScrollY = useRef<number>(0)

  const resetOrderListCache = useCallback(() => {
    orderListCache.current = new CellMeasurerCache(INITIAL_ORDER_LIST_CACHE)
  }, [])

  const value: ContextType = useMemo(
    () => ({
      offerDetailsCache,
      offerDetailsLastScrollY,
      orderListCache,
      orderListLastScrollY,
      orderInArrearsCache,
      orderInArrearsLastScrollY,
      resetOrderListCache,
    }),
    [
      offerDetailsCache,
      offerDetailsLastScrollY,
      orderListCache,
      orderListLastScrollY,
      orderInArrearsCache,
      orderInArrearsLastScrollY,
      resetOrderListCache,
    ]
  )

  // Discard order list scroll restoration cache if search changed
  useEffect(() => {
    offerDetailsCache.current = new CellMeasurerCache(INITIAL_OFFER_DETAILS_CACHE)
  }, [offerDetailsSearch])

  useEffect(() => {
    resetOrderListCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderListSearch])

  useEffect(() => {
    orderInArrearsCache.current = new CellMeasurerCache(INITIAL_ORDER_LIST_CACHE)
    //
  }, [orderInArrearsSearch])

  return (
    <ScrollRestorationContext.Provider value={value}>{children}</ScrollRestorationContext.Provider>
  )
}

export const useScrollRestorationContext = () => {
  return useContext(ScrollRestorationContext)
}
