import { ProductAggregate } from '../Article/Shop/Product'
import { ShippingData } from '../ShoppingCart/ShippingData'

export interface Order {
  id: string
  orderMetaData: OrderMetaData
  orderPrices: OrderPrices
  items: OrderItem[]
  orderType: OrderType
  shippingData: ShippingData
  collector: string
  reference: string
  commission: string
  createdAt: string
  offerId: string
}

export interface OrderItem {
  id: string
  product?: ProductAggregate
  sapId: string
  amount: number
  title: string
  unit: string
  supplierId: string
  supplierName: string
  supplierArticleId: string
  orderItemPrices: OrderItemPrices
}

export class OrderMetaData {
  orderName: string
  projectId: string
  projectName: string
}

export class OrderPrices {
  priceTotal: number
}

export enum OrderType {
  Order = 'Order',
  Retoure = 'Retoure',
}

export class OrderItemPrices {
  priceNet: number
  priceTotal: number
  priceMetal: number
}
