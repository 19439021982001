import React, { useState } from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import styles from './CartContentItem.module.scss'
import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { TextSkeleton } from '../../../text-skeleton/TextSkeleton'

interface ICartContentItemProps {
  item: ShoppingCartItem
  isLoading?: boolean
}

export const CartContentItem: React.FC<ICartContentItemProps> = (props) => {
  const { item, isLoading } = props
  const { product } = item
  const [errorOnLoad, setErrorOnLoad] = useState<boolean>(false)
  const src = product.imageData.images[0]?.large

  return (
    <div className={styles['item-wrapper']}>
      <div
        className={clsx(styles['item-image'], {
          [styles['product-image-broken']]: errorOnLoad || !src,
        })}
      >
        <img
          className={styles['product-image']}
          src={src}
          alt={`Art-Nr. ${product.dehaId}`}
          onError={() => setErrorOnLoad(true)}
        />
      </div>
      <div className={styles['item-info-wrapper']}>
        <Typography className={styles['item-title']} variant="bodyBold">
          {isLoading ? <TextSkeleton height={20} /> : product.title}
        </Typography>
        <Typography variant="smallText" className={styles['item-article-id']}>
          Art-Nr. {product.dehaId}
        </Typography>
      </div>
    </div>
  )
}
