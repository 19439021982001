export class WebOptions {
  appId: string
  authorizationBaseUrl: string
  accessTokenEndpoint: string
  resourceUrl: string
  responseType: string
  scope: string
  state: string
  redirectUrl: string
  logsEnabled: boolean
  windowOptions: string
  windowTarget = '_blank'
  windowReplace: boolean | undefined

  pkceEnabled: boolean
  pkceCodeVerifier: string
  pkceCodeChallenge: string
  pkceCodeChallengeMethod: string

  additionalParameters: { [key: string]: string }
  additionalResourceHeaders: { [key: string]: string }
}
