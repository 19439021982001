/**
 * NX_UI_TARGET env var contains the build target (app|web) to distinguish native platforms and web in the runtime
 * This env var can be considered for ui components in order to make some alterations between natives and web
 * The reason of this env var is that we have the app build for a web version (for support team) and we can't rely on capacitor's platform detection
 * */
export const isUiTarget = (uiTarget: 'app' | 'web') => {
  //TODO remove the slice, when NX_ vars won't be wrapped in extra quotes
  /** NX_UI_TARGET is wrapped in extra quotes for some reason, so we trim them */
  return (process.env.NX_UI_TARGET || '').slice(1, -1) === uiTarget
}
