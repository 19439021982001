import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Radio,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as ExtraTourIcon } from 'assets/icon/shoppingCartCheckout/sondertour.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { CheckoutEditAddressOverlay } from './CheckoutEditAddressOverlay'
import { UserAddressType, UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import styles from './UserAddressCard.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Confirm } from '../alert-and-confirm/Confirm'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'

interface Props {
  userAddress: UserAddressV2
  createUserAddress: (address: AddressV2, customName: string) => void
  deleteUserAddress: (addressId: string) => void
  updateUserAddress: (addressId: string, address: AddressV2, customName: string) => void
  userCanWriteAddresses: boolean
}

export const UserAddressCard: React.FC<Props> = (props) => {
  const [showEditAddressOverlay, setShowEditAddressOverlay] = useState(false)
  const [showAddressDeleteConfirmation, setShowAddressDeleteConfirmation] = useState(false)
  const {
    userAddress,
    createUserAddress,
    deleteUserAddress,
    updateUserAddress,
    userCanWriteAddresses,
  } = props
  const { t } = useTranslation()

  const editAddress = () => {
    setShowEditAddressOverlay(true)
  }

  const handleDeleteAddress = () => {
    setShowAddressDeleteConfirmation(false)
    deleteUserAddress(userAddress.addressId)
  }

  const handleCancelDeleteAddress = () => {
    setShowAddressDeleteConfirmation(false)
  }

  const isSpecialTour = userAddress.addressType === UserAddressType.SpecialTour

  return (
    <Card elevation={0} className={styles.card}>
      <CardContent className={clsx(styles.cardContent)}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box className={styles.headerBox}>
              <span>
                {!isSpecialTour && (
                  <FormControlLabel
                    className={styles.formControlLabel}
                    value={userAddress.addressId}
                    control={<Radio />}
                    label={<Typography variant={'bodyBold'}>{userAddress.customName}</Typography>}
                  />
                )}
                {isSpecialTour && (
                  <FormControlLabel
                    value={userAddress.addressId}
                    control={<Radio />}
                    label={<ExtraTourIcon />}
                    className={clsx(styles.formControlLabel, styles.halfLineHeight)}
                  />
                )}
              </span>
              {userAddress.addressType === UserAddressType.CustomerAddress &&
                userCanWriteAddresses && (
                  <span>
                    <IconButton
                      onClick={editAddress}
                      disableRipple
                      disableFocusRipple
                      color={'secondary'}
                      className={styles.editButton}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setShowAddressDeleteConfirmation(true)}
                      disableRipple
                      disableFocusRipple
                      color={'secondary'}
                      className={styles.deleteButton}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.contentBox}>
              <Typography variant={'body'}>{userAddress.address.name1}</Typography>
              <Typography variant={'body'}>{userAddress.address.name2}</Typography>
              <Typography variant={'body'}>
                {userAddress.address.street + ' ' + userAddress.address.houseNumber}
              </Typography>
              <Typography variant={'body'}>
                {userAddress.address.zipCode + ' ' + userAddress.address.city}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {showEditAddressOverlay && (
        <CheckoutEditAddressOverlay
          showEditAddressOverlay={showEditAddressOverlay}
          setShowEditAddressOverlay={setShowEditAddressOverlay}
          addressData={userAddress}
          createUserAddress={createUserAddress}
          updateUserAddress={updateUserAddress}
        />
      )}
      {showAddressDeleteConfirmation && (
        <Confirm
          heading={t('ADDRESSES.DELETE_ADDRESS')}
          body={t('ADDRESSES.CONFIRM.DELETE_ADDRESS')}
          handleConfirm={handleDeleteAddress}
          handleCancel={handleCancelDeleteAddress}
          openConfirmDialog={true}
        />
      )}
    </Card>
  )
}
