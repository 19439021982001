import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import styles from './CartListItem.module.scss'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'

interface ICartListItemProps {
  cart: ShoppingCartV2
  isActive: boolean
  isMarked: boolean
}

export const CartListItem: React.FC<ICartListItemProps> = (props) => {
  const { cart, isActive, isMarked } = props
  const { getSessionCartName, isSessionCart } = useSessionCartName()
  const classNames = clsx(styles['cart-list-item-wrapper'], {
    [styles['hovered-cart']]: !isActive,
    [styles['active-cart']]: isActive,
    [styles['marked-cart']]: isMarked,
  })
  const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name

  return (
    <div className={classNames}>
      <Typography variant="bodyBold" className={styles['cart-name']}>
        {cartName} ({cart.items.length})
      </Typography>
      {isMarked && (
        <Typography className={styles['additional-text']} variant="smallText">
          Marked by USER_NAME
        </Typography>
      )}
    </div>
  )
}
