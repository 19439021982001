import { createContext, useContext } from 'react'
import { ShopDomainToCompany } from '@obeta/utils/lib/domains'
import { useCompanyContactDetails } from '../hooks/useCompanyContactDetails'
import { useAppDomain } from '../hooks/useAppDomain'

const CompanyContactDetailsContext = createContext({
  accountingEmailAddress: '',
  accountingPhoneNumber: '',
  servicePhoneNumber: '',
  serviceEmailAddress: '',
  shopSupportPhoneNumber: '',
  shopSupportEmailAddress: '',
})

export const CompanyContactDetailsProvider = ({ children }) => {
  const domain = useAppDomain()
  const contactData = useCompanyContactDetails(ShopDomainToCompany[domain])

  return (
    <CompanyContactDetailsContext.Provider value={contactData}>
      {children}
    </CompanyContactDetailsContext.Provider>
  )
}

export const useCompanyContactDetailsContext = () => {
  return useContext(CompanyContactDetailsContext)
}
