import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import clsx from 'clsx'
import React from 'react'
import { useLayoutEffect, useRef, useState } from 'react'
import styles from './Scrollbar.module.scss'

interface IScrollbarProps extends React.HTMLAttributes<HTMLDivElement> {
  ident?: 'xs'
  size?: 'xs' | 'md'
}

export const Scrollbar = React.forwardRef<HTMLDivElement, IScrollbarProps>((props, ref) => {
  const { className, ident, size = 'xs', children, ...restProps } = props
  const [scrollable, setScrollable] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const c = containerRef.current
    if (!c) {
      return
    }

    setScrollable(c.offsetHeight < c.scrollHeight)
  }, [children])

  return (
    <div
      ref={composeReactRefs(containerRef, ref)}
      className={clsx(
        styles.scrollbar,
        styles[`${size}Size`],
        styles[`${ident}Ident`],
        { [styles.notScrollable]: !scrollable },
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  )
})
