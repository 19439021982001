import { ShopRoutes } from '@obeta/utils/lib/variables'
import { TFunction } from 'react-i18next'

interface LinkDescriptor {
  url: string
  name: string
  hideOnMobile?: boolean
}
interface LinkGroup {
  name: string
  hideOnMobile?: boolean
  items: LinkDescriptor[]
}

export const prepareFooterLinks = (t: TFunction, isNewSupplierOverview): LinkGroup[] => [
  {
    name: 'Obeta',
    hideOnMobile: false,
    items: [
      { url: 'https://wir.obeta.de', name: t('FOOTER.BUSINESS') },
      { url: 'https://wir.obeta.de/karriere', name: t('FOOTER.CAREER') },
      { url: '/stores', name: t('FOOTER.LOCATIONS') },
      {
        url: 'https://wir.obeta.de/verantwortung',
        name: t('FOOTER.RESPONSIBILITY'),
      },
      { url: '/about-us', name: t('FOOTER.ABOUT_US') },
      { url: '/privacy-protection', name: t('FOOTER.PRIVACY') },
      { url: '/terms-and-conditions', name: t('FOOTER.TERMS') },
    ],
  },
  {
    name: 'Service',
    hideOnMobile: false,
    items: [
      { url: '/services', name: t('FOOTER.BENEFITS'), hideOnMobile: true },
      {
        url: ShopRoutes.RentArticlesNew,
        name: t('FOOTER.RENTAL_DEVICES'),
        hideOnMobile: false,
      },
      { url: ShopRoutes.DOP, name: t('FOOTER.DOP'), hideOnMobile: true },
      {
        url: 'https://download.anydesk.com/AnyDesk.exe',
        name: t('FOOTER.REMOTE_MAINTENANCE'),
        hideOnMobile: true,
      },
    ],
  },
  {
    name: t('FOOTER.CURRENTLY_NEWSWORTHY'),
    hideOnMobile: false,
    items: [
      { url: '/events', name: t('FOOTER.EVENTS') },
      {
        url: '/advertising',
        name: t('FOOTER.ADVERTISEMENT'),
      },
      { url: '/news', name: t('FOOTER.NEWS') },
      { url: 'https://roadmap.obeta.io/', name: t('FOOTER.ROADMAP') },
      {
        url: 'https://hws.obeta.de/',
        name: t('FOOTER.TRADESMAN_SOFTWARE'),
      },
    ],
  },
  /* //TODO: REPLACE HARDCODED LINKS WITH ENVS, WHEN SHOP-FRONTEND IS MAIN SHOP' */
  {
    name: 'Obeta-Gruppe',
    hideOnMobile: false,
    items: [
      { url: 'https://www.obeta.de', name: 'Obeta' },
      { url: 'https://eldis.de', name: 'Eldis' },
      { url: process.env.NEXT_PUBLIC_HAEUSLER_URL as string, name: 'Häusler' },
      { url: 'https://stroom.berlin/', name: 'Stroom' },
      { url: 'https://technilog-ludwigsfelde.de/', name: 'Technilog' },
      { url: 'https://coditorei.de/', name: 'Coditorei' },
    ],
  },
  {
    name: 'Partner',
    hideOnMobile: false,
    items: [
      { url: 'https://www.tecselect.de/', name: 'TECSELECT' },
      { url: 'https://plus.voltimum.de/', name: t('FOOTER.VOLTIMUMPLUS') },
      { url: 'https://www.zveh.de/', name: 'ZVEH' },
      { url: 'https://veg.de/', name: 'VEG' },
      { url: 'https://www.deha.de/', name: 'DEHA' },
      { url: 'https://idee-europe.com/', name: 'IDEE' },
      {
        url: isNewSupplierOverview ? ShopRoutes.SuppliersOverviewPage : ShopRoutes.Suppliers,
        name: t('FOOTER.SUPPLIERS'),
      },
    ],
  },
]
