import { CategoryElementProps, MobileProps } from './types'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import clsx from 'clsx'
import styles from './AllCategory.module.scss'
import { CategoryIcon } from '../categories/Categories'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'

export const DehaFirstCategory: React.FC<CategoryElementProps & MobileProps> = (props) => {
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const {
    categoryElements,
    dehaCatalogLevel0ItemClicked,
    endPositionForInfoAndTopHeaderSectionsMobile = 0,
  } = props

  let width
  if (tabletAll) {
    width = window.innerWidth * (2 / 3)
  }

  let height
  if (tabletAll) {
    height = window.innerHeight
  }
  if (mobile) {
    height = window.innerHeight - endPositionForInfoAndTopHeaderSectionsMobile
  }

  return (
    <List
      className={clsx({ [styles.categoryList]: desktop }, styles.noPadding, styles.noHover, {
        [styles.categoryListPropertiesMobile]: mobile,
      })}
      style={tabletAll || mobile ? { width: width, height: height } : {}}
    >
      {categoryElements &&
        categoryElements.map((treeElement) => {
          return (
            <ListItem
              id={treeElement.id}
              key={treeElement.id}
              className={clsx({
                [styles.dehaCategoryListItem]: desktop,
                [styles.paddingTopAndBottomOneRem]: desktop,
                [styles.listItemMobile]: !desktop,
                [styles.marginTopOneAndHalfRem]: treeElement.id === '1' && !desktop,
              })}
            >
              <CategoryIcon catId={`hid${treeElement.id}`} imgSize="xs" />
              <ListItemButton
                className={clsx(styles.listItemButton, {
                  [styles.paddingLeftHalfRem]: !desktop,
                  [styles.noPaddingRight]: !desktop,
                })}
                onClick={() =>
                  dehaCatalogLevel0ItemClicked && dehaCatalogLevel0ItemClicked(treeElement.id)
                }
              >
                <ListItemText
                  primary={treeElement.title}
                  primaryTypographyProps={{ variant: 'bodyBold' }}
                  className={clsx(styles.listItemText, { [styles.fontWeight400]: !desktop })}
                />
                {!desktop && <ChevronRight />}
              </ListItemButton>
            </ListItem>
          )
        })}
    </List>
  )
}
