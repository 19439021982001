import { useRxDB } from 'rxdb-hooks'
import { useState, useEffect } from 'react'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'

export interface SearchHistory {
  history: string[]
  hey?: number
}

export const useSearchHistory = (): SearchHistory => {
  const db = useRxDB()
  const [document, setDocument] = useState<RxLocalDocument<RxDatabase, SearchHistory> | null>(null)
  const [latestRev, setLatestRev] = useState<SearchHistory | null>(null)

  useEffect(() => {
    const getUserDocument = async () => {
      const hist = await db.getLocal<SearchHistory>('searchHistory')
      setDocument(hist)
    }
    getUserDocument()
  }, [db])

  useEffect(() => {
    if (document) {
      const sub = document.$.subscribe((doc: RxLocalDocumentData<SearchHistory>) => {
        setLatestRev(doc.data)
      })

      return () => {
        sub.unsubscribe()
      }
    }
  }, [document])

  if (!latestRev) {
    return {
      history: [],
    }
  }
  return latestRev
}
