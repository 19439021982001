import { createContext, useCallback, useContext, useState } from 'react'
import dayjs from 'dayjs'

type PushDebugMessage = (message: string) => void
type SessionContextDebugContextValue = {
  debugMessages: string[]
  pushSessionContextDebugMessage: PushDebugMessage
  clearDebugMessages: () => void
}

const SessionContextDebugContext = createContext<SessionContextDebugContextValue>({
  debugMessages: [],
  clearDebugMessages: () => {
    // noop
  },
  pushSessionContextDebugMessage: () => {
    // noop
  },
})

export const SessionContextDebugProvider = ({ children }) => {
  const [debugMessages, setDebugMessages] = useState<string[]>([])
  const pushSessionContextDebugMessage = useCallback<PushDebugMessage>((message) => {
    const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss')
    const messageWithTimestamp = `[${timestamp}] ${message}`
    setDebugMessages((prevDebugMessages) => [...prevDebugMessages, messageWithTimestamp])
  }, [])
  const clearDebugMessages = useCallback(() => setDebugMessages([]), [])
  return (
    <SessionContextDebugContext.Provider
      value={{
        debugMessages,
        pushSessionContextDebugMessage,
        clearDebugMessages,
      }}
    >
      {children}
    </SessionContextDebugContext.Provider>
  )
}

export const useSessionContextDebugContext = () => {
  return useContext(SessionContextDebugContext)
}
