import { AddressV2 } from '../ShoppingCart/AddressV2'
import { OpeningHours } from '../'

export interface StoreV2 {
  id: string
  company: string
  country: string
  region: string
  address: AddressV2
  phoneNumber: string
  faxNumber: string
  mail: string
  latitude: string
  longitude: string
  openingHours: OpeningHours[]
  type: StoreV2Types
  distance: number
  updatedAt: string
}

export enum StoreV2Types {
  normal = 'normal',
  pickup = 'pickup',
  renzbox = 'renzbox',
}
