import { gql } from '@apollo/client'

export const GET_SHOPPING_CARTS = gql`
  query getShoppingCarts($since: String, $lastId: String, $batchSize: Float) {
    getShoppingCarts(since: $since, lastId: $lastId, batchSize: $batchSize) {
      id
      name
      offerId
      remark
      commission
      phone
      shippingData {
        isCompleteDelivery
        isCompleteDeliveryPossible
        deliveryAddress {
          name1
          name2
          zipCode
          street
          houseNumber
          city
        }
        storeId
        addressId
        shippingType
        shippingDate
      }
      paymentMethod
      items {
        id
        sapId
        amount
        position
        offerItemPosition
        product {
          sapId
          dehaId
          type
          isCurrentlyNotAvailable
          unit
          isoUnit
          minimumAmount
          isCutProduct
          isSendable
          title
          oxomiId
          supplierId
          dehaProductGroupId
          isPvArticle
          isForSale
          isDiscontinued
          replacementArticleSapId
          images {
            url
            width
          }
          imageData {
            images {
              large
            }
          }
          supplierImageData {
            large
          }
          priceDimension
        }
        isPromotionItem
      }
      updatedAt
      deleted
      promotionId
    }
  }
`
