import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { CategoryTree } from '@obeta/models/lib/models/Category'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { useStartPageData } from './useStartPageDataProvider'

const loadCategoryTreeQuery = gql`
  query getCategoryTree {
    getCategoryTree {
      id
      title
      indexName
      dehaSubCategories {
        id
        title
        indexName
      }
      obetaSubCategories {
        id
        title
        indexName
      }
    }
  }
`
export const useLoadCategoryTree = (): CategoryTree[] => {
  const { categoryTree, setCategoryTree } = useStartPageData()
  const client = useApolloClient()

  useEffect(() => {
    if (categoryTree.length > 0) return
    const loadCategoryTree = async () => {
      try {
        const response = await client.query({
          query: loadCategoryTreeQuery,
        })
        if (response.data?.getCategoryTree) {
          setCategoryTree(response.data.getCategoryTree)
        }
      } catch (error) {
        error.message = 'Apollo client throws error in loadCategoryTree ' + error.message
        handleError(error)
      }
    }

    loadCategoryTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  return categoryTree
}
