import { ofType } from 'redux-observable'
import {
  OfferActionTypes,
  CreateDownloadableOfferAction,
  getCreateDownloadableOfferResult,
} from '../actions'
import { switchMap, catchError, retry, Observable, mergeMap, of, defer } from 'rxjs'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'

export const createDownloadableOfferGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<CreateDownloadableOfferAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.CreateDownloadableOffer),
      switchMap((action: CreateDownloadableOfferAction) =>
        defer(async () => {
          const response = await apolloClient.query({
            query: gql`
              query getOfferPdf($offerId: String!) {
                getOfferPdf(offerId: $offerId)
              }
            `,
            variables: {
              offerId: action.offerId,
            },
          })
          return response.data.getOfferPdf
        }).pipe(
          retry(1),
          mergeMap((result: boolean) => of(getCreateDownloadableOfferResult(result))),
          catchError((error) => {
            error.message =
              'error while creating downloadable link for offer ' +
              action.offerId +
              ' ' +
              error.message
            handleError(error)
            return of(getCreateDownloadableOfferResult(false))
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(getCreateDownloadableOfferResult(false))
      })
    )
}

export const initAllOfferEpics = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return [createDownloadableOfferGraphQLEffect(apolloClient)]
}
