import './CartsSelects.scss'
import React, { MouseEventHandler } from 'react'
import { ButtonBase, Typography } from '@mui/material'
import clsx from 'clsx'

interface ICartsSelectProps {
  count: number
  onSelect?: MouseEventHandler<HTMLButtonElement>
  withIndicator?: boolean
}

export const CartsSelect: React.FC<ICartsSelectProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onSelect}
      classes={{
        root: clsx('carts-select', { 'carts-select--with-indicator': props.withIndicator }),
      }}
    >
      <Typography
        variant="bodyBold"
        classes={{
          root: clsx('carts-color'),
        }}
      >
        {props.count} WK
      </Typography>
    </ButtonBase>
  )
}
