import { useMemo } from 'react'
import { useMarkedForNotifactionSet } from './useMarkedForNotificationSet'
import { useShoppingCartsV2 } from './useShoppingCartsV2'

export const useMarkedCarts = () => {
  const { activeCarts } = useShoppingCartsV2()
  const set = useMarkedForNotifactionSet()
  const markedCarts = useMemo(() => {
    return activeCarts.filter((cart) => {
      return set.has(cart.id)
    })
  }, [activeCarts, set])

  return { markedCarts, markedCartsIds: set }
}
