import { gql } from '@apollo/client'

export const OFFER_ITEM_TITLES = gql`
  query getOfferItemTitles($input: GetOfferItemTitlesInput!) {
    getOfferItemTitles(input: $input) {
      sapId
      offerItemPosition
      title
    }
  }
`
