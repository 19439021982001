import React from 'react'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Typography, SvgIcon, useTheme } from '@mui/material'
import styles from './Categories.module.scss'
import { useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { TertiaryResponsiveIconButton } from '../../../custom-button/CustomButton'

interface Props {
  setDehaCatalogLevel0?: React.Dispatch<React.SetStateAction<boolean>>
  dehaCatalogLevel0: boolean
}

export const Categories: React.FC<Props> = (props: Props) => {
  const { setDehaCatalogLevel0, dehaCatalogLevel0 } = props

  const location = useLocation()
  const { mobile, desktop } = useBreakpoints()
  const theme = useTheme()

  const handleClick = () => {
    if (location.pathname === '/' && desktop) return
    setDehaCatalogLevel0?.(!dehaCatalogLevel0)
  }

  if (!mobile) {
    return (
      <Typography
        variant="bodyBold"
        className={styles.categories}
        onClick={handleClick}
        id="categories-span-element" //this id is needed to properly toggle the category, as ther is a clickaway listener in the subCategoriesList elswise the categories will be toggled off by the click away listener, and immidiatley backon by this button
      >
        Kategorien
        {dehaCatalogLevel0 && location.pathname === '/' && !desktop && (
          <SvgIcon htmlColor={theme.palette.white.main} component={ArrowUp} />
        )}
        {dehaCatalogLevel0 && location.pathname !== '/' && (
          <SvgIcon htmlColor={theme.palette.white.main} component={ArrowUp} />
        )}
        {!dehaCatalogLevel0 && location.pathname === '/' && !desktop && (
          <SvgIcon
            htmlColor={theme.palette.white.main}
            component={ArrowDown}
            id="categories-svg-element"
          />
        )}
        {!dehaCatalogLevel0 && location.pathname !== '/' && (
          <SvgIcon
            htmlColor={theme.palette.white.main}
            component={ArrowDown}
            id="categories-svg-element"
          />
        )}
      </Typography>
    )
  } else {
    return (
      <TertiaryResponsiveIconButton
        id="categories-span-element" //this id is needed to properly toggle the category, as ther is a clickaway listener in the subCategoriesList elswise the categories will be toggled off by the click away listener, and immidiatley backon by this button
        onClick={handleClick}
        icon={dehaCatalogLevel0 ? <ArrowUp /> : <ArrowDown />}
      />
    )
  }
}
