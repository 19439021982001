import { useEffect, useRef } from 'react'

export function useInterval<P extends () => void>(
  callback: P,
  { interval, lead }: { interval: number; lead?: boolean }
): void {
  const savedCallback = useRef<P | null>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) savedCallback.current()
    }

    lead && tick()

    if (interval !== null) {
      const id = setInterval(tick, interval)

      return () => clearInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval])
}
