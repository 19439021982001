export enum DigitalBillingActionTypes {
  GetDigitalBillingEntry = '[DigitalBilling] Get digital billing Entry',
  GetDigitalBillingEntryResult = '[DigitalBilling] Get digital billing Entry Result',
  CreateDigitalBillingEntry = '[DigitalBilling] Create digital billing entry',
  CreateDigitalBillingEntryResult = '[DigitalBilling] Create digital billing entry Result',
}

interface DigitalBillingAction {
  type: DigitalBillingActionTypes
}

export interface GetDigitalBillingEntryAction extends DigitalBillingAction {
  readonly type: DigitalBillingActionTypes.GetDigitalBillingEntry
}

export const getDigitalBillingEntry = (): GetDigitalBillingEntryAction => ({
  type: DigitalBillingActionTypes.GetDigitalBillingEntry,
})

export interface GetDigitalBillingEntryResultAction extends DigitalBillingAction {
  alreadyRegistered: boolean
}

export const getDigitalBillingEntryResult = (
  alreadyRegistered: boolean
): GetDigitalBillingEntryResultAction => ({
  type: DigitalBillingActionTypes.GetDigitalBillingEntryResult,
  alreadyRegistered,
})

export interface CreateDigitalBillingEntryAction extends DigitalBillingAction {
  readonly type: DigitalBillingActionTypes.CreateDigitalBillingEntry
  billingType: string
  email: string
}

export const createDigitalBillingEntry = (
  billingType: string,
  email: string
): CreateDigitalBillingEntryAction => ({
  type: DigitalBillingActionTypes.CreateDigitalBillingEntry,
  billingType,
  email,
})
export interface CreateDigitalBillingEntryResultAction extends DigitalBillingAction {
  success: boolean
}

export const createDigitalBillingEntryResult = (
  success: boolean
): CreateDigitalBillingEntryResultAction => ({
  type: DigitalBillingActionTypes.CreateDigitalBillingEntryResult,
  success,
})
