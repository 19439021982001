import { useParams } from './useHistoryApi'

/**
 * Most of our gql request for products use shape this for variables
 * So, it makes to define it like this
 * @typedef {object} IProductIds
 */
export interface IProductIds {
  obetaIds?: string[]
  sapIds?: string[]
  dehaIds?: string[]
}
interface IParsedProductId {
  prefix: string
  productId: string
}
export enum EProductIdType {
  Obeta = 'obeta',
  Deha = 'deha',
}

export const isIdStartsWith = (subString: EProductIdType, id: string): boolean =>
  id.startsWith(subString)

export const parseProductId = (id: string): IParsedProductId => {
  const [prefix, productId] = id.split('-')
  return { prefix, productId }
}

export const getCurrentProductIds = (id): IProductIds => {
  if (!id) return {}

  if (isIdStartsWith(EProductIdType.Obeta, id)) {
    const { productId } = parseProductId(id)
    return {
      obetaIds: [productId],
    }
  }
  if (isIdStartsWith(EProductIdType.Deha, id)) {
    const { productId } = parseProductId(id)
    return {
      dehaIds: [productId],
    }
  }

  return { sapIds: [id] }
}

/**
 * this is tmp solution ( see also getCurrentProductIds above )
 * We're migrating from using ArticleListItem model to ProductAggretate model for products.
 * Before migration is finished we must use both sapId and obetaId to request products
 * because sapId doesn't exist in ArticleListItem model.
 * We use router here to parse url and get product id.
 * @returns {IProductIds}
 */
export const useCurrentProductIds = (): IProductIds => {
  const { id } = useParams<{ id: string }>()
  return getCurrentProductIds(id)
}
