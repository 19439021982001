import { useCallback, useEffect, useRef, useState } from 'react'
import { useRxDB } from 'rxdb-hooks'
import { OAuth2AuthenticateOptions, OAuth2Client } from '@byteowls/capacitor-oauth2'
import { SecureStoragePlugin } from '@atroo/capacitor-secure-storage-plugin'
import { useAppActions } from './useAppActions'
import { RxDatabase } from 'rxdb'
import { useObservable, useSubscription } from 'observable-hooks'
import {
  BehaviorSubject,
  defer,
  exhaustMap,
  filter,
  firstValueFrom,
  retry,
  switchMap,
  take,
} from 'rxjs'
import { useOauth2Web } from './oath2/useOauth2Web'
import { CustomerMetaData } from './useUserData'
import {
  ANDROID_REDIRECT_URL,
  APP_ID,
  AUTH_BASE_URL,
  IOS_REDIRECTURL,
} from '@obeta/utils/lib/config'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useUserDataV2 } from './useUserDataV2'
import { getWebOptions, requestAccessToken, requestUserData } from './oath2/utils'
import { useLocation } from './useHistoryApi'
import { useLoginRedirect } from './useLoginRedirect'
import { WebUtils } from './oath2/web-utils'
import { datadogRum } from '@datadog/browser-rum'
import { fetchPayloadFromToken } from '@obeta/utils/lib/session-based-login-helpers'
import { requestTokenIfExpired } from '@obeta/utils/lib/requestTokenIfExpired'
import {
  clearSessionContext,
  createSessionContext,
  getSessionContext,
  SessionType,
} from '@obeta/utils/lib/session-context'
import { useRouter } from 'next/router'
import { checkIsLogoutInProgress } from '@obeta/utils/lib/isLogoutInProgressUtils'
import { useSessionContextDebugContext } from '../stores/useSessionContextDebugContext'

const options: OAuth2AuthenticateOptions = {
  authorizationBaseUrl: AUTH_BASE_URL + '/oauth2/auth',
  accessTokenEndpoint: AUTH_BASE_URL + '/oauth2/token',
  scope: 'offline openid',
  resourceUrl: AUTH_BASE_URL + '/userinfo',
  logsEnabled: true,
  responseType: 'code',
  pkceEnabled: true,
  web: {
    appId: APP_ID,
    //accessTokenEndpoint: '', // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
    //resourceUrl: '',
    redirectUrl: '',
    windowOptions: 'height=600,width=600,left=0,top=0',
    windowTarget: '_self',
  },
  android: {
    appId: APP_ID,
    responseType: 'code', // if you configured a android app in google dev console the value must be "code"
    redirectUrl: ANDROID_REDIRECT_URL, // package name from google dev console
  },
  ios: {
    appId: APP_ID,
    responseType: 'code', // if you configured a ios app in google dev console the value must be "code"
    redirectUrl: IOS_REDIRECTURL, // Bundle ID from google dev console
  },
}

interface CatalogSessionContextOptions {
  sessionType: SessionType
  hookUrl?: string
}

const saveUserMeta = async (db: RxDatabase, sub: string) => {
  const splits = sub.split('-')
  const companyId = splits.shift()
  const userId = splits.join('-')

  const doc = await db.getLocal<CustomerMetaData>('usermeta')
  // purpose of the isLoggedIn flag is to boradcast this status to all tabs
  // that are opened, it will trigger fetching the latest token from SecureStorage,
  // so all tabs are in the same state
  await doc?.incrementalModify((doc) => {
    doc.isLoggedIn = true
    doc.companyId = companyId || null
    doc.userId = userId
    return doc
  })
}

const catalogUserSessionIdSubject = new BehaviorSubject<string | undefined>(undefined)

export const useLoginListeners = (catalogUserSessionId?: string) => {
  const appActions = useAppActions()
  const { tokens, allTokensExist, metaDataReady } = useUserDataV2()
  const location = useLocation()
  const db = useRxDB()
  const router = useRouter()
  const { pushSessionContextDebugMessage } = useSessionContextDebugContext()

  catalogUserSessionIdSubject.next(catalogUserSessionId)

  const locationSearch = location.search
  const { redirectBack, clearPath, currentPath } = useLoginRedirect()
  /**
   * In case tokensExist (both access and refresh tokens)
   * We don't need make user to enter password to get these tokens.
   * All we need to do is fetch user meta. After we receive data
   * login process can be completed.
   */
  const userMeta$ = useObservable(
    ($inputs) =>
      $inputs.pipe(
        filter(([tokens, allTokensExist, metaDataReady]) => {
          return Boolean(tokens && allTokensExist && metaDataReady)
        }),
        take(1),
        switchMap(([tokens]) =>
          defer(async () => {
            const sessionContext = getSessionContext()
            if (sessionContext) {
              if ('userId' in sessionContext) {
                pushSessionContextDebugMessage(`save user meta - userId: ${sessionContext.userId}`)
                await saveUserMeta(db, sessionContext.userId as string)
              }
              return
            }
            if (!tokens?.accessToken) {
              throw new Error('no token present')
            }

            let maybeUpdatedAccessToken = tokens?.accessToken
            const updatedTokens = await requestTokenIfExpired(appActions, tokens)
            if (updatedTokens?.accessToken) {
              maybeUpdatedAccessToken = updatedTokens?.accessToken
            }

            const resp = await fetch(AUTH_BASE_URL + '/userinfo', {
              headers: {
                Authorization: `bearer ${maybeUpdatedAccessToken}`,
              },
            })
            const json = await resp.json()
            if (json.error) {
              throw json
            }

            await saveUserMeta(db, json.sub)
          }).pipe(retry(3))
        )
      ),
    [tokens, allTokensExist, metaDataReady]
  )

  useSubscription(
    userMeta$,
    () => {
      //
    },
    async (error) => {
      if (error.status_code === 401) {
        appActions.tokens$.next(null)
        clearSessionContext()
        try {
          await SecureStoragePlugin.clear()
        } catch (err) {
          /*Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Failed to clear secure storage',
          })

          Sentry.captureException(err) */
          datadogRum.addError(err)
          // TODO replace former Sentry-Code with DataDog
        }
      }
    }
  )

  catalogUserSessionIdSubject.subscribe((newCatalogUserSessionId) => {
    // Whenever catalogUserSessionId changes, update it in the observable
    catalogUserSessionId = newCatalogUserSessionId
  })

  // Create a BehaviorSubject to manage catalogUserSessionId
  // Subscribe to catalogUserSessionId changes
  const authentication$ = useObservable(
    ($inputs) => {
      return $inputs.pipe(
        exhaustMap(async ([locationSearch]) => {
          if (!isPlatform('web')) {
            // only for web
            return
          }

          let accessToken = ''
          let refreshToken = ''
          let userDataSub = ''
          const sessionId = await firstValueFrom(catalogUserSessionIdSubject)
          if (sessionId) {
            pushSessionContextDebugMessage(`sessionId: ${sessionId}`)
            //TODO: Which key is 'cap_sec_auth' exactly? It does not occur in project (anymore?).
            localStorage.removeItem('cap_sec_auth')
            pushSessionContextDebugMessage('fetch tokens by sessionId')
            try {
              const response = await fetch(
                `${process.env.NEXT_PUBLIC_LOGIN_APP_BASE_URL}/api/session/token`,
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: new URLSearchParams({ sessionId }),
                }
              )
              pushSessionContextDebugMessage('tokens retrieved')
              const data = await response.json()
              accessToken = data.accessToken
              pushSessionContextDebugMessage('parse payload from token')
              const payloadData = fetchPayloadFromToken(accessToken)
              userDataSub = payloadData.userId
              pushSessionContextDebugMessage(`userDataSub ${payloadData.userId}`)
            } catch (error) {
              pushSessionContextDebugMessage(`error on fetching tokens`)
              pushSessionContextDebugMessage(JSON.stringify(error))
            }
            const [, queryParamString] = router.asPath.split('?')
            const base64Options = new URLSearchParams(queryParamString).get('options')
            let catalogSessionContext: CatalogSessionContextOptions = {
              sessionType: 'oci',
            }
            if (base64Options) {
              pushSessionContextDebugMessage(`set session context from incoming options`)
              catalogSessionContext = JSON.parse(atob(base64Options))
            }
            const sessionContext = {
              type: catalogSessionContext.sessionType,
              id: sessionId,
              userId: userDataSub,
              ...catalogSessionContext,
            }
            createSessionContext(sessionContext)
            pushSessionContextDebugMessage(
              `session context created (json: ${JSON.stringify(sessionContext)}`
            )
          } else {
            const webOptions = await getWebOptions(options)
            const params = new URLSearchParams(locationSearch)
            const paramsState = params.get('state')
            const paramsScope = params.get('scope')
            if (!paramsState || !paramsScope) {
              /**
               * Let's assume that parsed search params is not what we need.
               * It's something else. Stop authenctication process.
               */
              return
            }

            if (!webOptions.accessTokenEndpoint) {
              throw new Error('Implicit flow is not implemented')
            }

            if (paramsState !== webOptions.state) {
              if (webOptions.logsEnabled) {
                /* eslint-disable no-console */
                console.log('State from web options: ' + webOptions.state)
                console.log('State returned from provider: ' + paramsState)
                /* eslint-enable no-console */
              }
              throw new Error('ERR_STATES_NOT_MATCH')
            }

            const authorizationCode = params.get('code')
            if (!authorizationCode) {
              throw new Error('ERR_NO_AUTHORIZATION_CODE')
            }

            const accessTokenResponse = await requestAccessToken(webOptions, authorizationCode)
            accessToken = accessTokenResponse['access_token']
            refreshToken = accessTokenResponse['refresh_token']

            const userData = await requestUserData(
              webOptions.resourceUrl,
              accessTokenResponse['access_token'],
              webOptions.additionalResourceHeaders,
              webOptions.logsEnabled
            )
            userDataSub = userData.sub
          }

          WebUtils.clearVerificationData()

          await SecureStoragePlugin.set({
            key: 'auth',
            value: JSON.stringify({ accessToken, refreshToken }),
            accessibility: 'afterFirstUnlock',
          })
          appActions.tokens$.next({ accessToken, refreshToken })
          /**
           * @deprecated this should be removed once app.obeta.de is not in use anymore
           *
             we will always force a new session here to ensure, that the php session
            and the hydra token belong to the same user to prevent stale session data
            that will show the wrong user when logging out and logging in in short time
            on the same device

            abusing APP_ID to detect the app is a dirty workaround and should not never be used
            elsewhere
          */
          if (APP_ID === 'mobile-app') {
            await appActions.refreshSession()
          }
          await saveUserMeta(db, userDataSub)

          if (currentPath) {
            redirectBack()
            clearPath()
          }
        })
      )
    },
    [locationSearch]
  )

  useSubscription(
    authentication$,
    (data) => {
      //
    },
    (error) => {
      // Sentry.captureException(error) // TODO replace former Sentry-Code with DataDog
      WebUtils.clearVerificationData()
    }
  )
}

export const useLogin = () => {
  const db = useRxDB()
  const isMounted = useRef(true)
  const appActions = useAppActions()
  const [attemptedLoginDuringLogout, setAttemptedLoginDuringLogout] = useState(false)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const { authenticate } = useOauth2Web()

  const startLogin = useCallback(async () => {
    // cancel the login operation until the db collections is cleared.
    if (await checkIsLogoutInProgress()) {
      setAttemptedLoginDuringLogout(true)
      return
    }

    localStorage.removeItem('skipOrderingBlockedNotification')
    if (isPlatform('web')) {
      await authenticate(options)
      return
    }

    // this will be executed in native only

    try {
      datadogRum.addAction('login', {
        message: 'starting native login flow',
      })
      const response = await OAuth2Client.authenticate(options)
      const accessToken = response.access_token_response['access_token']
      const refreshToken = response.access_token_response['refresh_token']
      datadogRum.addAction('login', {
        message: 'received tokens',
        hasAccessToken: !!accessToken,
        hasRefreshToken: !!refreshToken,
      })
      await SecureStoragePlugin.set({
        key: 'auth',
        value: JSON.stringify({ accessToken, refreshToken }),
        accessibility: 'afterFirstUnlock',
      })
      datadogRum.addAction('login', {
        message: 'tokens stored in secure storage',
      })
      appActions.tokens$.next({ accessToken, refreshToken })
      // we will always force a new session here to ensure, that the php session
      // and the hydra token belong to the same user to prevent stale session data
      // that will show the wrong user when logging out and logging in in short time
      // on the same device
      await appActions.refreshSession()
      datadogRum.addAction('login', {
        message: 'created legacy php session',
      })
      await saveUserMeta(db, response.sub)
    } catch (error) {
      datadogRum.addError(error)
    }
  }, [appActions, authenticate, db])

  useEffect(() => {
    const subscription = appActions.logoutFinished$.subscribe(async () => {
      if (attemptedLoginDuringLogout) {
        await startLogin()
        setAttemptedLoginDuringLogout(false)
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [attemptedLoginDuringLogout, appActions.logoutFinished$, startLogin])

  return {
    startLogin,
    attemptedLoginDuringLogout,
  }
}
