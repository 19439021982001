import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { NavigationOverlay } from '../navigation-overlay/NavigationOverlay'
import styles from './MenuWrapper.module.scss'
import clsx from 'clsx'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { PluginListenerHandle } from '@capacitor/core'
import { useBackButtonMenuClose } from '@obeta/data/lib/hooks'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  onMenuClosed?: () => void
  className?: string
  fullWidth?: boolean
  topOffset?: number
  slideUp?: boolean
}

export const MenuWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    show,
    onMenuClosed,
    topOffset,
    slideUp,
    children,
    className,
    fullWidth = false,
    ...rest
  } = props
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  useBackButtonMenuClose({ closeCallback: onMenuClosed, enabled: show })

  useEffect(() => {
    // TODO: use useKeyboardHeight instead
    if (!isPlatform('web')) {
      let showListener: PluginListenerHandle | undefined
      let hideListener: PluginListenerHandle | undefined

      const runKeyboardListeners = async () => {
        showListener = await Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
          setKeyboardHeight(info.keyboardHeight)
        })
        hideListener = await Keyboard.addListener('keyboardDidHide', () => setKeyboardHeight(0))
      }

      runKeyboardListeners()

      return () => {
        showListener?.remove()
        hideListener?.remove()
      }
    }
  }, [setKeyboardHeight])

  function closeMenu() {
    if (onMenuClosed) {
      onMenuClosed()
      setKeyboardHeight(0)
    }
  }

  return (
    <NavigationOverlay
      open={show}
      topOffset={topOffset}
      onClose={closeMenu}
      classes={{ paper: styles.paper }}
      className={clsx(styles.root, 'popover-wrapper', className, {
        [styles.fullWidth]: fullWidth,
      })} // add popover-wrapper className for compatability purposes.
      slideUp={slideUp}
    >
      <div className={styles.wrapper} style={{ paddingBottom: keyboardHeight }} {...rest}>
        {children}
      </div>
    </NavigationOverlay>
  )
}
