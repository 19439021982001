import React from 'react'
import { Snackbar, SnackbarOrigin } from '@mui/material'
import { ReactComponent as RefreshIcon } from 'assets/icon/designsystem/refresh.svg'
import styles from './Snackbar.module.scss'
import { useTranslation } from 'react-i18next'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface SnackbarProps {
  open: boolean
  key: string
  options?: string[]
}
interface State extends SnackbarOrigin {
  open: boolean
}
export const SnackbarNotification: React.FC<SnackbarProps> = (props) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const [state, setState] = React.useState<State>({
    open: true,
    vertical: 'bottom',
    horizontal: 'right',
  })
  const { vertical, horizontal, open } = state

  // only when close button is clicked
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setState({ ...state, open: false })
    window.location.reload()
  }

  const action = (
    <TertiaryIconButton
      onClick={handleClose}
      icon={<RefreshIcon />}
      size={mobile ? 'large' : 'small'}
    >
      {t('SNACKBAR.REFRESH')}
    </TertiaryIconButton>
  )

  return (
    <Snackbar
      className={clsx(styles.snackbar, mobile && styles.snackbarMobile)}
      key={props.key}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      message={t('SNACKBAR.CHANGES')}
      action={action}
    />
  )
}
