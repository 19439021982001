import { InMemoryCache } from '@apollo/client'

export const getInMemoryCache = () => {
  return new InMemoryCache({
    typePolicies: {
      ProductAggregate: {
        keyFields: ['sapId'],
      },
    },
  })
}
