import { QueueEl } from './GlobalNotifications'

type GlobalNotificationState = { queue: QueueEl[] }

type GlobalNotificationAction =
  | { type: 'REMOVE_NOTIFICATION'; payload: string }
  | { type: 'REMOVE_NOTIFICATION_BY_UID'; payload: string }
  | { type: 'UPDATE_QUEUE'; payload: QueueEl[] }

function removeElementFromQueueByIndex(
  backupQueue: QueueEl[],
  index: number,
  state: GlobalNotificationState
) {
  backupQueue.splice(index, 1)
  const newProcessInfoQueue = ([] as QueueEl[]).concat(backupQueue)
  return {
    ...state,
    queue: newProcessInfoQueue,
  }
}

export const globalNotificationsReducer = (
  state: GlobalNotificationState,
  action: GlobalNotificationAction
): GlobalNotificationState => {
  switch (action.type) {
    case 'UPDATE_QUEUE':
      return { ...state, queue: action.payload }

    case 'REMOVE_NOTIFICATION': {
      const idFromPayload = action.payload
      const idx = state.queue.findIndex((val) => val.id === idFromPayload)
      const backupQueue = [...state.queue]
      if (idx !== -1) {
        return removeElementFromQueueByIndex(backupQueue, idx, state)
      }

      return state
    }
    case 'REMOVE_NOTIFICATION_BY_UID': {
      const uidFromPayload = action.payload
      const idx = state.queue.findIndex((val) => val.uid === uidFromPayload)
      const backupQueue = [...state.queue]
      if (idx !== -1) {
        return removeElementFromQueueByIndex(backupQueue, idx, state)
      }

      return state
    }
  }
}

export const initialGlobalNotificationsState: GlobalNotificationState = { queue: [] }
