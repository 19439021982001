import React from 'react'
import styles from './DefaultStoreInfo.module.scss'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import Typography from '@mui/material/Typography'
import { StoreAddress } from './StoreAddress'
import { StoreProperty } from './StoreProperty'
import { StoreWorkingHours } from './StoreWorkingHours'

interface IDefaultStoreInfoProps {
  store: StoreV2 | undefined
}

export const DefaultStoreInfo: React.FC<IDefaultStoreInfoProps> = (props) => {
  const { store } = props

  if (!store) {
    return (
      <div className={styles.container}>
        <Typography variant="bodyBold" className={styles.emptyStoreHeader}>
          Store is not selected
        </Typography>
      </div>
    )
  }

  const { address, phoneNumber, faxNumber, mail, openingHours } = store
  const streetString = `${address.street} ${address.houseNumber}`
  const cityString = `${address.zipCode} ${address.city}`

  return (
    <div className={styles.container}>
      <Typography variant="smallTextBold">{address.name1}</Typography>
      <div className={styles.address}>
        <StoreAddress street={streetString} city={cityString} variant={'smallText'} />
      </div>
      <div className={styles.contacts}>
        <StoreProperty property="T" propertyValue={phoneNumber} variant={'smallText'} />
        <StoreProperty property="F" propertyValue={faxNumber} variant={'smallText'} />
        <StoreProperty property="M" propertyValue={mail} variant={'smallText'} />
      </div>
      <StoreWorkingHours openingHours={openingHours} variant={'smallText'} />
    </div>
  )
}
