import { Location, Path } from 'history-types'
import { UrlObject } from 'url'
import { compressToEncodedURIComponent } from 'lz-string'
import set from 'lodash.set'

export const buildNextRouterState = (path: Path | Location, state?: unknown) => {
  if (state) {
    let url: UrlObject | string = path
    state = compressToEncodedURIComponent(JSON.stringify(state))
    if (typeof url === 'string') {
      url = { pathname: url, query: { state: state as string } }
    } else {
      set(url, 'query', state)
    }

    return url
  }

  return path
}
