import { useRxDB } from 'rxdb-hooks'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useEffect, useRef, useState } from 'react'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'

/**
 * @deprecated dont use this hook anymore directly it binds multiple calls to rxdb updates, use useUserDataV2 instead and destruct the user property from that response
 * @returns
 */
export const useUserV2 = (): UserV2 | null => {
  const db = useRxDB()
  const [userV2Document, setUserV2Document] = useState<RxLocalDocument<RxDatabase, UserV2> | null>(
    null
  )
  const isUnmountRef = useRef(false)
  const [userV2, setUserV2] = useState<UserV2 | null>(null)

  useEffect(() => {
    return () => {
      isUnmountRef.current = true
    }
  }, [])

  useEffect(() => {
    const getUserV2Document = async () => {
      const userv2 = await db.getLocal<UserV2>('userv2')

      if (isUnmountRef.current) return

      setUserV2Document(userv2)
    }
    getUserV2Document()
  }, [db])

  useEffect(() => {
    if (userV2Document) {
      const userV2Data = userV2Document.$.subscribe((doc: RxLocalDocumentData<UserV2>) => {
        setUserV2(doc.data)
      })

      return () => {
        userV2Data.unsubscribe()
      }
    }
  }, [userV2Document])

  return userV2
}
