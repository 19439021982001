import React from 'react'
import styles from './StoreProperty.module.scss'
import Typography from '@mui/material/Typography'

interface IStoreProps {
  property: string
  propertyValue: string
  variant?: 'smallText' | 'body'
}

export const StoreProperty: React.FC<IStoreProps> = (props) => {
  const { property, propertyValue, variant = 'body' } = props
  return (
    <div className={styles.propertyContainer}>
      <Typography variant={`${variant}Bold`} className={styles.property}>
        {property}
      </Typography>
      <Typography variant={variant}>{propertyValue}</Typography>
    </div>
  )
}
