import { categoriesEntity } from './categories'
import { messageEntity } from './message'
import { markedCartsEntity } from './markedForNotificationCarts'
import { cartsv2Entity } from './cartsv2'
import { offersv2Entity } from './offersv2'
import { cartTemplatesEntity } from './cartTemplates'
import { storesv2Entity } from './storesv2'
import { EntityDescriptor, ObetaModels } from '@obeta/models/lib/models/Db/index'
import { stocksEntity } from './stocks'
import { userAddressesV2Entity } from './userAddressesv2'
import { cartsv2PricesEntity } from './cartsv2Prices'
import { openPositionsEntity } from './openPositions'
import { clientEntity } from './client'
import { stockAvailabilityEstimatesEntity } from './stockAvailabilityEstimates'

export * from './categories'
export * from './client'
export * from './entityMetaData'
export * from './message'
export * from './markedForNotificationCarts'
export * from './cartsv2'
export * from './cartTemplates'
export * from './offersv2'
export * from './openPositions'
export * from './storesv2'
export * from './stocks'
export * from './userAddressesv2'
export * from './cartsv2Prices'
export * from './stockAvailabilityEstimates'

export const syncEntities: EntityDescriptor[] = [
  categoriesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  messageEntity as unknown as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  markedCartsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartsv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartTemplatesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  offersv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  storesv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  stocksEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  userAddressesV2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartsv2PricesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
  openPositionsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
  clientEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
  stockAvailabilityEstimatesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
]
