import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'

export interface UserAction {
  type: UserActionTypes
}

export enum UserActionTypes {
  CreateUserAddressGraphQL = '[User] Create User Address via GraphQL',
  CreateUserAddressGraphQLResult = '[User] Create User Address via GraphQL Result',
  UpdateUserAddressGraphQL = '[User] Update User Address via GraphQL',
  UpdateUserAddressGraphQLResult = '[User] Update User Address via GraphQL Result',
  DeleteUserAddressGraphQL = '[User] Delete User Address via GraphQL',
  DeleteUserAddressGraphQLResult = '[User] Delete User Address via GraphQL Result',
  GetUserGraphQLIsCashCustomer = '[User] Get User Is Cash Customer GraphQL',
  GetUserGraphQLIsCashCustomerResult = '[User] Get User Is Cash Customer GraphQL Result',
}

export interface GetUserGraphQLIsCashCustomerAction extends UserAction {
  readonly type: UserActionTypes.GetUserGraphQLIsCashCustomer
}

export const getUserGraphQLIsCashCustomer = (): GetUserGraphQLIsCashCustomerAction => ({
  type: UserActionTypes.GetUserGraphQLIsCashCustomer,
})

export interface GetUserGraphQLIsCashCustomerResultAction extends UserAction {
  readonly type: UserActionTypes.GetUserGraphQLIsCashCustomerResult
  isCashCustomer: boolean
}

export const getUserGraphQLIsCashCustomerResult = (
  isCashCustomer: boolean
): GetUserGraphQLIsCashCustomerResultAction => ({
  type: UserActionTypes.GetUserGraphQLIsCashCustomerResult,
  isCashCustomer,
})

export interface CreateUserAddressGraphQLAction extends UserAction {
  readonly type: UserActionTypes.CreateUserAddressGraphQL
  address: AddressV2
  customName: string
}

export const createUserAddressGraphQL = (
  address: AddressV2,
  customName: string
): CreateUserAddressGraphQLAction => ({
  type: UserActionTypes.CreateUserAddressGraphQL,
  address,
  customName,
})

export interface CreateUserAddressGraphQLResultAction extends UserAction {
  readonly type: UserActionTypes.CreateUserAddressGraphQLResult
  success: boolean
  addressId: string
  errorCode?: string
  errorMessage?: string
}

export const createUserAddressGraphQLResult = (
  success: boolean,
  addressId: string,
  errorCode?: string,
  errorMessage?: string
): CreateUserAddressGraphQLResultAction => ({
  type: UserActionTypes.CreateUserAddressGraphQLResult,
  success,
  addressId,
  errorCode,
  errorMessage,
})

export interface UpdateUserAddressGraphQLAction extends UserAction {
  readonly type: UserActionTypes.UpdateUserAddressGraphQL
  addressId: string
  address: AddressV2
  customName: string
}

export const updateUserAddressGraphQL = (
  addressId: string,
  address: AddressV2,
  customName: string
): UpdateUserAddressGraphQLAction => ({
  type: UserActionTypes.UpdateUserAddressGraphQL,
  addressId,
  address,
  customName,
})

export interface UpdateUserAddressGraphQLResultAction extends UserAction {
  readonly type: UserActionTypes.UpdateUserAddressGraphQLResult
  success: boolean
  errorCode?: string
  errorMessage?: string
  addressResult?: UserAddressV2[]
}

export const updateUserAddressGraphQLResult = (
  success: boolean,
  errorCode?: string,
  errorMessage?: string,
  addressResult?: UserAddressV2[]
): UpdateUserAddressGraphQLResultAction => ({
  type: UserActionTypes.UpdateUserAddressGraphQLResult,
  success,
  errorCode,
  errorMessage,
  addressResult,
})

export interface DeleteUserAddressGraphQLAction extends UserAction {
  readonly type: UserActionTypes.DeleteUserAddressGraphQL
  addressId: string
}

export const deleteUserAddressGraphQL = (addressId: string): DeleteUserAddressGraphQLAction => ({
  type: UserActionTypes.DeleteUserAddressGraphQL,
  addressId,
})

export interface DeleteUserAddressGraphQLResultAction extends UserAction {
  readonly type: UserActionTypes.DeleteUserAddressGraphQLResult
  success: boolean
  errorCode?: string
  errorMessage?: string
  addressResult?: UserAddressV2[]
}

export const deleteUserAddressGraphQLResult = (
  success: boolean,
  errorCode?: string,
  errorMessage?: string,
  addressResult?: UserAddressV2[]
): DeleteUserAddressGraphQLResultAction => ({
  type: UserActionTypes.DeleteUserAddressGraphQLResult,
  success,
  errorCode,
  errorMessage,
  addressResult,
})
