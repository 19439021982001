import { ShopDomains } from '@obeta/utils/lib/domains'
import { useLayoutEffect, useState } from 'react'
import { getValidAppDomain } from '@obeta/utils/lib/getValidAppDomain'

export const useAppDomain = () => {
  // make sure to use ShopDomains.Obeta as fallback
  const [domain, setDomain] = useState<ShopDomains>(getValidAppDomain() ?? ShopDomains.Obeta)

  useLayoutEffect(() => {
    const validDomain = getValidAppDomain()

    if (!validDomain) {
      return
    }

    setDomain(validDomain as ShopDomains)
  }, [])

  return domain
}
