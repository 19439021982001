import { useEffect } from 'react'
import { DeclarationOfPerformance } from '@obeta/models/lib/models/Leistungen/DeclarationOfPerformance'
import { gql, useLazyQuery, ApolloError } from '@apollo/client'

interface SearchParams {
  articleId?: string
  dopNumber?: string
}

export enum Status {
  initial,
  loading,
  emptyResult,
  error,
  success,
}

interface Result {
  status: Status
  error?: ApolloError
  dops: DeclarationOfPerformance[]
}

const qry = gql`
  query getDOPs($articleId: String, $dopNumber: String) {
    getDOPs(dopNumber: $dopNumber, articleId: $articleId) {
      dop
      manufacturerName
      filename
    }
  }
`

export const useDOP = (params: SearchParams): Result => {
  const [loadDops, { loading, data, error }] = useLazyQuery(qry)

  useEffect(() => {
    if (params.articleId || params.dopNumber) {
      loadDops({
        variables: params,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.articleId, params.dopNumber])

  let status = Status.initial
  if (loading) {
    status = Status.loading
  } else if (error) {
    status = Status.error
  } else if (data?.getDOPs.length === 0) {
    status = Status.emptyResult
  } else if (data?.getDOPs.length > 0) {
    status = Status.success
  }

  return {
    status,
    error,
    dops: data?.getDOPs ? data?.getDOPs : [],
  }
}
