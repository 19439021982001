import { Preferences } from '@capacitor/preferences'

const IS_LOGOUT_IN_PROGRESS_KEY = 'isLogoutInProgress'

export const checkIsLogoutInProgress = async () => {
  const { value } = await Preferences.get({ key: IS_LOGOUT_IN_PROGRESS_KEY })
  return value === 'true'
}

export const setLogoutInProgress = async (flag) => {
  await Preferences.set({
    key: IS_LOGOUT_IN_PROGRESS_KEY,
    value: JSON.stringify(flag),
  })
}
