import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { ShoppingCartForDropdown, ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'

const CART_ID_SESSION_CART_IDENTIFIER = '-sid'

type UseSessionCartName = () => {
  getSessionCartName: (cart: ShoppingCartV2 | ShoppingCartForDropdown) => string
  isSessionCart: (cart: ShoppingCartV2 | ShoppingCartForDropdown) => boolean
}

export const useSessionCartName: UseSessionCartName = () => {
  const { t } = useTranslation()
  const sessionContext = getSessionContext()

  const isSessionCart = useCallback(
    (cart: ShoppingCartV2 | ShoppingCartForDropdown) =>
      cart.id.includes(CART_ID_SESSION_CART_IDENTIFIER),
    []
  )
  const getSessionCartName = useCallback(
    (cart: ShoppingCartV2 | ShoppingCartForDropdown) => {
      if (isSessionCart(cart) && sessionContext) {
        switch (sessionContext.type) {
          case 'ids':
            return t('SHOPPING_CART.SHOPPING_CART_NAME_IDS')
          case 'oci':
            return t('SHOPPING_CART.SHOPPING_CART_NAME_OCI')
          default:
            return cart.name
        }
      } else {
        return cart.name
      }
    },
    [isSessionCart, sessionContext, t]
  )

  return { getSessionCartName, isSessionCart }
}
