import { useEffect, useState } from 'react'
import { MetalTypes, MetalV2 } from '@obeta/models/lib/models'
import { gql, useLazyQuery } from '@apollo/client'
import { useUserDataV2 } from './useUserDataV2'

const loadMetalQuotationsQuery = gql`
  query getMetalQuotations {
    getMetalQuotations {
      metalType
      price
    }
  }
`

const POLL_TIME = 10 * 60 * 1000 // 10 min

export const useMetalQuotations = () => {
  const [aluminium, setAluminium] = useState<number>(0)
  const [copper, setCopper] = useState<number>(0)
  const [loadMetalData, { data: metalData }] = useLazyQuery(loadMetalQuotationsQuery)
  const { isLoggedIn } = useUserDataV2()

  useEffect(() => {
    if (!isLoggedIn) return

    const load = async () => await loadMetalData()

    let timerId: ReturnType<typeof setTimeout>

    const startTimer = () => {
      timerId = setTimeout(async () => {
        await load()
        startTimer()
      }, POLL_TIME)
    }

    load().then(startTimer)

    return () => clearTimeout(timerId)
  }, [isLoggedIn, loadMetalData])

  useEffect(() => {
    metalData?.getMetalQuotations.forEach((metalData: MetalV2) => {
      metalData.metalType === MetalTypes.Aluminium && setAluminium(metalData.price)
      metalData.metalType === MetalTypes.Copper && setCopper(metalData.price)
    })
  }, [metalData])

  return { aluminium, copper }
}
