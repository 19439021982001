import { useEffect, useRef, useState } from 'react'
import { useHistory } from './useHistoryApi'

interface IMobileScrollHandlerProps {
  targetSectionHeight: number
  scrollToggleThreshold?: number
  isEnabled: boolean
  options?: {
    hoverSection?: HTMLElement | null // show element on hover if exist
    showOnScrollUp?: boolean
  }
}

const SCROLL_THRESHOLD = 15

export const useMobileScrollHandler = ({
  targetSectionHeight,
  isEnabled,
  options,
  scrollToggleThreshold = SCROLL_THRESHOLD,
}: IMobileScrollHandlerProps) => {
  const [isSectionVisible, setSectionVisible] = useState(true)
  const lastScrollTop = useRef(0)
  const isMouseOver = useRef(false)

  const history = useHistory()
  const { hoverSection, showOnScrollUp } = options || {}

  const cumulativeScrollUp = useRef(0)
  const cumulativeScrollDown = useRef(0)

  useEffect(() => {
    // We should reset header state when redirected/navigate to new page that may not has scroll at all.
    setSectionVisible(true)
  }, [history?.location?.pathname])

  useEffect(() => {
    if (isEnabled) {
      /*
       * Function to determine if the user has entered the "bouncing" scroll zone on MacOS / iOS.
       * Bouncing zone is an area that allows the user to scroll content beyond its end, creating a "bounce" effect.
       */
      const isBouncingScrollZone = (scrollEl: Window) => {
        const scrollTop = scrollEl.scrollY
        const scrollHeight = document.documentElement.scrollHeight
        const offsetHeight = scrollEl.innerHeight

        return scrollTop >= scrollHeight - offsetHeight - 10
      }

      const handleScrollDown = (scrollDelta: number) => {
        cumulativeScrollDown.current += scrollDelta
        cumulativeScrollUp.current = 0

        if (cumulativeScrollDown.current > scrollToggleThreshold) {
          setSectionVisible(false)
          cumulativeScrollDown.current = 0
        }
      }

      const handleScrollUp = (scrollDelta: number) => {
        cumulativeScrollUp.current += Math.abs(scrollDelta)
        cumulativeScrollDown.current = 0

        if (cumulativeScrollUp.current > scrollToggleThreshold) {
          setSectionVisible(true)
          cumulativeScrollUp.current = 0
        }
      }

      const scrollEl = window

      const scrollHandler = () => {
        if (isBouncingScrollZone(scrollEl)) return

        const scrollTop = scrollEl.scrollY
        const scrollDelta = scrollTop - lastScrollTop.current

        if (targetSectionHeight > scrollTop) {
          setSectionVisible(true)
        } else {
          if (scrollDelta > 0) {
            handleScrollDown(scrollDelta)
          } else if (scrollDelta < 0 && showOnScrollUp) {
            handleScrollUp(scrollDelta)
          }
        }

        lastScrollTop.current = scrollTop
      }

      const handleMouseOver = () => {
        if (!isMouseOver.current) {
          isMouseOver.current = true
          setSectionVisible(true)
        }
      }

      const handleMouseOut = () => {
        if (isMouseOver.current) isMouseOver.current = false
      }

      scrollEl.addEventListener('scroll', scrollHandler)

      if (hoverSection) {
        hoverSection.addEventListener('mouseover', handleMouseOver)
        hoverSection.addEventListener('mouseout', handleMouseOut)
      }

      return () => {
        scrollEl.removeEventListener('scroll', scrollHandler)

        if (hoverSection) {
          hoverSection.removeEventListener('mouseover', handleMouseOver)
          hoverSection.removeEventListener('mouseout', handleMouseOver)
        }
      }
    } else {
      setSectionVisible(true)
    }
  }, [
    targetSectionHeight,
    isSectionVisible,
    isEnabled,
    hoverSection,
    showOnScrollUp,
    scrollToggleThreshold,
  ])

  return isSectionVisible
}
