export const withUnit = (
  val: string | number | null | undefined,
  unit: string | number | null | undefined,
  parentheses?: boolean
): string => {
  if (!val) {
    return ''
  }

  if (!unit) {
    return val.toString()
  }

  if (parentheses) {
    unit = `(${unit})`
  }

  return `${val} ${unit}`
}
