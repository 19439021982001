export const validateContextValue = <T>(
  value: T | null | undefined,
  contextName: string,
  hookName = 'useContext'
): T => {
  if (!value) {
    throw new Error(
      `${contextName} context is undefined, please verify you are calling ${hookName} as child of a <${contextName}> component`
    )
  }

  return value
}
