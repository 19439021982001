import Axios from 'axios-observable'
import { firstValueFrom } from 'rxjs'
import { AppActions } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import type { CustomerMetaData } from '@obeta/data/lib/hooks/useUserData'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { requestTokenIfExpired } from '@obeta/utils/lib/requestTokenIfExpired'

export const initAxiosShop = (
  db: RxDatabase<CollectionsOfDatabase>,
  maintenanceSubject$,
  appActions: AppActions
) => {
  Axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL

  Axios.interceptors.request.use(async function (config) {
    if (config.headers && 'Authorization' in config.headers) {
      const authorization = config.headers.Authorization as string
      const [, accessToken] = authorization.split(' ')
      let maybeUpdatedAccessToken
      if (accessToken) {
        maybeUpdatedAccessToken = accessToken
        const tokens = await firstValueFrom(appActions.tokens$)
        const updatedTokens = await requestTokenIfExpired(appActions, tokens)
        if (updatedTokens?.accessToken) {
          maybeUpdatedAccessToken = updatedTokens?.accessToken
        }

        if (maybeUpdatedAccessToken) {
          config.headers.Authorization = `bearer ${maybeUpdatedAccessToken}`
        }
      }
    }

    return config
  })

  Axios.interceptors.response.use(
    function (response) {
      maintenanceSubject$?.next('')
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error) => {
      const originalRequest = error.config
      if (error.response && error.response.status === 501) {
        const message =
          error.response.data?.message || 'Die Systeme sind momentan nicht erreichbar.'
        maintenanceSubject$?.next(message)

        return
      } else {
        maintenanceSubject$?.next('')
      }
      if (error.response && error.response.status === 401) {
        const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
        const isLoggedIn = userMeta?.get('isLoggedIn')
        if (!isLoggedIn) {
          return
        }

        // logout may result in 401, since this means, that the session is invalid
        // and there is nothing more to do
        if (originalRequest.url?.indexOf('logout') !== -1) {
          return
        }

        //if we cannot refresh the session, we should not create an endless loop
        // and break here
        const success = await appActions.requestNewToken()
        if (!success) {
          return
        }
        trackCustom('debug-axios-endless-request-loop')
        const updatedTokens = await firstValueFrom(appActions.tokens$)
        if (updatedTokens?.accessToken) {
          originalRequest.headers.Authorization = `bearer ${updatedTokens?.accessToken}`
          trackCustom('debug-axios-401-token-refreshed')
        }
        return firstValueFrom(Axios.request({ ...originalRequest }))
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      throw error
    }
  )
}
