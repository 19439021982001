import { ObetaCategory } from '@obeta/models/lib/models/Category'

export interface ArticleAction {
  type: ArticleActionTypes
}

export enum ArticleActionTypes {
  SearchRecommendations = '[Search] Recommendations',
  ChangeSearchHistory = '[Search] Change Search History',
  DeleteSearchHistoryEnty = '[Search] Delete Search History Entry',
  LoadCategories = '[Category] Load Categories',
  LoadCategoriesResult = '[Category] Load Categories Result',
  GetOxomiMetaData = '[Product] Get Oxomi Metadata',
  GetOxomiMetaDataResult = '[Product] Get Oxomi Metadata Result',
  GetStock = '[Product] Get Stock',
  GetStockResult = '[Product] Get Stock Result',
  GetStockAvailabilityEstimates = '[Product] Get Stock Availability Estimates',
  GetStockAvailabilityEstimatesResult = '[Product] Get Stock AvailabilityEstimates Result',
}

export interface GetStockAction extends ArticleAction {
  type: ArticleActionTypes.GetStock
  pollInterval?: number
  sapIds: string[]
  storeIds: string[]
  batchSize?: number
}
export const getStock = (sapIds: string[], storeIds: string[]): GetStockAction => ({
  type: ArticleActionTypes.GetStock,
  sapIds,
  storeIds,
})

export interface GetStockResultAction extends ArticleAction {
  type: ArticleActionTypes.GetStockResult
}
export const getStockResult = (): GetStockResultAction => ({
  type: ArticleActionTypes.GetStockResult,
})

export interface GetStockAvailabilityEstimatesAction extends ArticleAction {
  type: ArticleActionTypes.GetStockAvailabilityEstimates
  pollInterval?: number
  sapIds: string[]
  batchSize?: number
}
export const getStockAvailabilityEstimates = (
  sapIds: string[]
): GetStockAvailabilityEstimatesAction => ({
  type: ArticleActionTypes.GetStockAvailabilityEstimates,
  sapIds,
})

export interface GetStockAvailabilityEstimatesResultAction extends ArticleAction {
  type: ArticleActionTypes.GetStockAvailabilityEstimatesResult
}
export const getStockAvailabilityEstimatesResult =
  (): GetStockAvailabilityEstimatesResultAction => ({
    type: ArticleActionTypes.GetStockAvailabilityEstimatesResult,
  })

export interface GetOxomiMetadataAction extends ArticleAction {
  type: ArticleActionTypes.GetOxomiMetaData
  pollInterval?: number
}
export const getOxomiMetadata = (pollInterval?: number): GetOxomiMetadataAction => ({
  type: ArticleActionTypes.GetOxomiMetaData,
  pollInterval,
})

export interface GetOxomiMetadataResultAction extends ArticleAction {
  type: ArticleActionTypes.GetOxomiMetaDataResult
}
export const getOxomiMetadataResult = (): GetOxomiMetadataResultAction => ({
  type: ArticleActionTypes.GetOxomiMetaDataResult,
})

export interface ChangeSearchHistoryAction extends ArticleAction {
  type: ArticleActionTypes.ChangeSearchHistory
  query: string
}
export const changeSearchHistory = (query: string): ChangeSearchHistoryAction => ({
  type: ArticleActionTypes.ChangeSearchHistory,
  query,
})

export interface DeleteSearchHistoryItemAction extends ArticleAction {
  type: ArticleActionTypes.DeleteSearchHistoryEnty
  term: string
}
export const deleteSearchHistoryEntry = (term: string): DeleteSearchHistoryItemAction => ({
  type: ArticleActionTypes.DeleteSearchHistoryEnty,
  term,
})

export interface SearchRecommendationsAction extends ArticleAction {
  type: ArticleActionTypes.SearchRecommendations
  searchText: string
}

export const searchRecommendations = (searchText: string): SearchRecommendationsAction => ({
  type: ArticleActionTypes.SearchRecommendations,
  searchText,
})

export interface LoadCategoriesAction extends ArticleAction {
  type: ArticleActionTypes.LoadCategories
}

export const loadCategories = (): LoadCategoriesAction => ({
  type: ArticleActionTypes.LoadCategories,
})

export interface LoadCategoriesResultAction extends ArticleAction {
  type: ArticleActionTypes.LoadCategoriesResult
  categories?: ObetaCategory[]
  error?: Error
}

export const loadCategoriesResult = (
  categories?: ObetaCategory[],
  error?: Error
): LoadCategoriesResultAction => ({
  type: ArticleActionTypes.LoadCategoriesResult,
  categories,
  error,
})
