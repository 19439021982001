import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { ShoppingCartContextEnum } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import {
  ShippingOptions,
  ShippingGroup,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import { useUserDataV2 } from './useUserDataV2'

export interface ShippingOptionsQueryParams {
  cartId: string
  shippingGroup: ShippingGroup
}

const loadShippingOptionsQuery = gql`
  query getShippingOptions($cartId: String!, $shippingGroup: ShippingGroup!) {
    getShippingOptions(cartId: $cartId, shippingGroup: $shippingGroup) {
      paymentMethods
      isEnabled
      isDefault
      storeId
      shippingType
      disabledReason
      deliveryAddress {
        name1
        name2
        name3
        name4
        city
        zipCode
        street
        houseNumber
        country
      }
      shippingCosts {
        freeDeliveryThreshold
        total
        currency
      }
      shippingDates {
        date
        time
      }
    }
  }
`
export const useLoadShippingOptions = (
  queryParams: ShippingOptionsQueryParams,
  context: ShoppingCartContextEnum,
  storeId?: string,
  deliveryAddress?: DeliveryAddressV2 | null | string
): ShippingOptions[] => {
  const [shippingOptions, setShippingOptions] = useState<ShippingOptions[]>([])

  const user = useUserDataV2()
  const client = useApolloClient()

  useEffect(() => {
    let timer
    const loadShippingOptions = async () => {
      const timeoutValue = context === ShoppingCartContextEnum.CHECKOUT_DELIVERY ? 2500 : 0

      timer = setTimeout(async () => {
        const response = await client.query({
          query: loadShippingOptionsQuery,
          variables: {
            cartId: queryParams.cartId,
            shippingGroup: queryParams.shippingGroup,
          },
        })

        if (response.data?.getShippingOptions) {
          setShippingOptions(response.data.getShippingOptions)
        }
      }, timeoutValue)
    }

    if (user.userId) {
      loadShippingOptions()
    }
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, storeId, deliveryAddress, client, queryParams.shippingGroup, user])

  return shippingOptions
}
