import { useEffect } from 'react'
import { checkIsLogoutInProgress } from '@obeta/utils/lib/isLogoutInProgressUtils'
import { useAppActions } from './useAppActions'

export const useLogoutCleanup = (isLoggedIn: boolean) => {
  const appActions = useAppActions()

  // In case the user managed to close the tab before we cleaned the database. (just to be on the safe side when open app first time )
  useEffect(() => {
    const logoutCleanup = async () => {
      if (!isLoggedIn && (await checkIsLogoutInProgress())) {
        appActions.logout$.next(true)
      }
    }

    logoutCleanup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
