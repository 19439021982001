import React from 'react'
import { Typography } from '@mui/material'
import { BaseNotificationRow } from './BaseNotificationLayout'
import styles from './NotificationStyledBlock.module.scss'

type Props = {
  heading: string
  body?: string
}

/** Only use as children of {@link Notification}
 *
 */
export const NotificationStyledBlock: React.FC<Props> = ({ heading, body }) => {
  return (
    <div className={styles.container}>
      <BaseNotificationRow>
        <Typography variant={'bodyBold'}>{heading}</Typography>
      </BaseNotificationRow>
      {body && (
        <BaseNotificationRow>
          <Typography variant={'body'}>{body}</Typography>
        </BaseNotificationRow>
      )}
    </div>
  )
}
