import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { ofType } from 'redux-observable'
import { catchError, defer, mergeMap, Observable, of, retry, switchMap } from 'rxjs'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  CreateUserAddressGraphQLAction,
  createUserAddressGraphQLResult,
  DeleteUserAddressGraphQLAction,
  deleteUserAddressGraphQLResult,
  GetUserGraphQLIsCashCustomerAction,
  getUserGraphQLIsCashCustomerResult,
  UpdateUserAddressGraphQLAction,
  updateUserAddressGraphQLResult,
  UserActionTypes,
} from '../actions/user-actions'
import {
  createUserAddress,
  deleteUserAddress,
  updateUserAddress,
} from '../entities/userAddressesQueryProps'
import {
  UserAddressCreateOutput,
  UserAddressDeleteOutput,
  UserAddressUpdateOutput,
} from '@obeta/models/lib/models/Users/UserV2'
import { GetCollectionSync } from '@obeta/models/lib/models/Db/index'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { trackCustom } from '@obeta/utils/lib/tracking'

export const createUserAddressEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getCollectionSync: GetCollectionSync
) => {
  return (actions$: Observable<CreateUserAddressGraphQLAction>) =>
    actions$.pipe(
      ofType(UserActionTypes.CreateUserAddressGraphQL),
      switchMap((action: CreateUserAddressGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<UserAddressCreateOutput>({
            mutation: createUserAddress,
            variables: {
              input: {
                address: action.address,
                customName: action.customName,
              },
            },
          })
          const sync = getCollectionSync('useraddressesv2')
          trackCustom('resync-useraddressesv2-createUserAddressEffect')
          await sync?.reSync()
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: UserAddressCreateOutput) =>
            of(
              createUserAddressGraphQLResult(
                result.createUserAddress.handleResult.success,
                result.createUserAddress.handleResult.addressId ?? '',
                result.createUserAddress.handleResult.errorCode,
                result.createUserAddress.handleResult.errorMessage
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + createUserAddressEffect.name + ' ' + error.message
            handleError(error)
            return of(
              createUserAddressGraphQLResult(
                false,
                error.handleResult.errorCode,
                error.handleResult.errorMessage
              )
            )
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createUserAddressEffect.name + ' ' + error.message
        handleError(error)
        return of(createUserAddressGraphQLResult(false, '', ''))
      })
    )
}

export const deleteUserAddressEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getCollectionSync: GetCollectionSync
) => {
  return (actions$: Observable<DeleteUserAddressGraphQLAction>) =>
    actions$.pipe(
      ofType(UserActionTypes.DeleteUserAddressGraphQL),
      switchMap((action: DeleteUserAddressGraphQLAction) =>
        defer(async () => {
          const collection = db.useraddressesv2
          const backupUserAddress = await collection.findOne(action.addressId).exec()
          if (backupUserAddress) {
            await backupUserAddress.remove()
          }
          const response = await apolloClient.mutate<UserAddressDeleteOutput>({
            mutation: deleteUserAddress,
            variables: {
              input: {
                addressId: action.addressId,
              },
            },
          })

          if (!response.data?.deleteUserAddress.handleResult?.success && backupUserAddress) {
            await collection.upsert(backupUserAddress.toJSON())
          }

          if (response?.data?.deleteUserAddress?.handleResult?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteAddress,
              id: action.addressId,
              options: {
                id: action.addressId,
              },
            })
          }

          const sync = getCollectionSync('useraddressesv2')
          trackCustom('resync-useraddressesv2-deleteUserAddressEffect')
          await sync?.reSync()
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: UserAddressDeleteOutput) =>
            of(deleteUserAddressGraphQLResult(result.deleteUserAddress.handleResult?.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + deleteUserAddressEffect.name + ' ' + error.message
            handleError(error)
            return of(deleteUserAddressGraphQLResult(false, error, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + deleteUserAddressEffect.name + ' ' + error.message
        handleError(error)
        return of(deleteUserAddressGraphQLResult(false, error, error))
      })
    )
}

export const updateUserAddressEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getCollectionSync: GetCollectionSync
) => {
  return (actions$: Observable<UpdateUserAddressGraphQLAction>) =>
    actions$.pipe(
      ofType(UserActionTypes.UpdateUserAddressGraphQL),
      switchMap((action: UpdateUserAddressGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<UserAddressUpdateOutput>({
            mutation: updateUserAddress,
            variables: {
              input: {
                addressId: action.addressId,
                address: action.address,
                customName: action.customName,
              },
            },
          })
          const sync = getCollectionSync('useraddressesv2')
          trackCustom('resync-useraddressesv2-updateUserAddressEffect')
          await sync?.reSync()
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: UserAddressUpdateOutput) =>
            of(updateUserAddressGraphQLResult(result.updateUserAddress.handleResult.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + updateUserAddressEffect.name + ' ' + error.message
            handleError(error)
            return of(
              updateUserAddressGraphQLResult(
                false,
                error.handleResult.errorCode,
                error.handleResult.errorMessage
              )
            )
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + updateUserAddressEffect.name + ' ' + error.message
        handleError(error)
        return of(updateUserAddressGraphQLResult(false, '', '', undefined))
      })
    )
}

export const getUserIsCashCustomer = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetUserGraphQLIsCashCustomerAction>) =>
    actions$.pipe(
      ofType(UserActionTypes.GetUserGraphQLIsCashCustomer),
      switchMap((action: GetUserGraphQLIsCashCustomerAction) =>
        defer(async () => {
          const response = await apolloClient.query({
            query: gql`
              query getUserIsCashCustomer {
                getUserIsCashCustomer
              }
            `,
          })
          return response.data.getUserIsCashCustomer
        }).pipe(
          retry(1),
          mergeMap((result: boolean) => of(getUserGraphQLIsCashCustomerResult(result))),
          catchError((error) => {
            error.message =
              'error while processing ' + getUserIsCashCustomer.name + ' ' + error.message
            handleError(error)
            return of(getUserGraphQLIsCashCustomerResult(true))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + getUserIsCashCustomer.name + ' ' + error.message
        handleError(error)
        return of(getUserGraphQLIsCashCustomerResult(true))
      })
    )
}

export const initAllUserEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getCollectionSync: GetCollectionSync
) => {
  return [
    createUserAddressEffect(db, apolloClient, getCollectionSync),
    deleteUserAddressEffect(db, apolloClient, getCollectionSync),
    updateUserAddressEffect(db, apolloClient, getCollectionSync),
    getUserIsCashCustomer(apolloClient),
  ]
}
