import { useMemo, useState, Fragment, FC } from 'react'
import { Typography, Divider, ListItemButton, ListItemText, Link, LinkProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { clickedObetaLinks } from './Footer'
import { prepareFooterLinks } from './FooterLinks'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const ListOfLinksForMobileAndTabletPortrait: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const isNewSupplierOverview = useFeatureToggle('UseNewSupplierOverview')

  const [openGroups, setOpenGroups] = useState<Set<number>>(new Set())

  const links = useMemo(() => {
    return prepareFooterLinks(t, isNewSupplierOverview)
  }, [t, isNewSupplierOverview])

  return (
    <List component="nav">
      {links
        .filter((group) => !group.hideOnMobile)
        .map((group, idx) => (
          <Fragment key={idx}>
            <ListItemButton
              className={styles.linkListItem}
              onClick={() => {
                openGroups.has(idx) ? openGroups.delete(idx) : openGroups.add(idx)
                setOpenGroups(new Set(openGroups))
              }}
            >
              <ListItemText
                primary={<Typography variant={'bodyBold'}> {group.name} </Typography>}
              />
              {openGroups.has(idx) ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openGroups.has(idx)} timeout="auto" unmountOnExit>
              {group.items
                .filter((el) => !el.hideOnMobile)
                .map((el, idx) => {
                  const isExternal = el.url?.match(/^(http|https):\/\//i) !== null
                  const linkAttr: Partial<LinkProps> = {
                    underline: 'none',
                    color: 'inherit',
                    href: el.url,
                  }
                  if (isExternal) {
                    linkAttr.rel = 'noopener'
                    linkAttr.target = '_blank'
                  } else {
                    linkAttr.onClick = (e: React.MouseEvent) =>
                      clickedObetaLinks(e, history, el.url)
                  }
                  return (
                    <ListItemButton key={idx} className={styles.linkListItem}>
                      <ListItemText
                        primary={
                          <Typography variant={'body'}>
                            <Link {...linkAttr}>{el.name}</Link>
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  )
                })}
            </Collapse>
            <Divider className={styles.divider} />
          </Fragment>
        ))}
    </List>
  )
}
