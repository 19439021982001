import { gql } from '@apollo/client'
import { print } from 'graphql'
import { EntityDescriptor } from '@obeta/models/lib/models'
import { CartTemplate } from '@obeta/schema'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: CartTemplate): ICheckpoint => ({
  lastId: doc.id,
  since: doc.updatedAt,
})

export const cartTemplatesEntity: EntityDescriptor<Omit<CartTemplate, '__typename' | 'deleted'>> = {
  name: 'carttemplates',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // altered schema by introducing 'itemCount' - cartTemplateItems no longer persisted in rxDB
      if (oldDoc?.cartTemplateItems) {
        const itemCount = (oldDoc.cartTemplateItems as unknown[]).length || 0
        delete oldDoc.cartTemplateItems
        return { ...oldDoc, itemCount: oldDoc.itemCount || itemCount }
      }
      return { ...oldDoc, itemCount: oldDoc.itemCount || 0 }
    },
    3: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
    4: function (oldDoc) {
      // altered schema by introducing 'ownerId' and 'ownerName'
      return { ...oldDoc, ownerId: oldDoc.userId, ownerName: oldDoc.userName }
    },
  },
  schema: {
    title: 'carttemplates',
    version: 4,
    description: 'a document representing a template in the context of the checkout',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      userId: {
        type: 'string',
      },
      ownerId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      isDefault: {
        type: 'boolean',
      },
      isFavourite: {
        type: 'boolean',
      },
      isShared: {
        type: 'boolean',
      },
      ownerName: {
        type: 'string',
      },
      userName: {
        type: 'string',
      },
      cartTemplateItems: {
        type: 'array',
      },
      itemCount: {
        type: 'number',
      },
      // RFC3339 date-time string
      updatedAt: {
        type: 'string',
      },
      createdAt: {
        type: 'string',
      },
    },
  },
  subscription: null,
  pullSync: {
    batchSize: 500,
    queryBuilder: (checkpoint: { [x: string]: unknown }, limit) => {
      checkpoint = (checkpoint || emptyCheckpoint) as unknown as { [x: string]: unknown }

      const documentNode = gql`
        query getTemplates($since: String, $lastId: String, $batchSize: Float) {
          getCartTemplates(since: $since, lastId: $lastId, batchSize: $batchSize) {
            id
            userId
            ownerId
            name
            ownerName
            isDefault
            isFavourite
            isShared
            userName
            updatedAt
            createdAt
            deleted
            itemCount
          }
        }
      `

      const query = print(documentNode)

      return {
        query,
        variables: {
          ...checkpoint,
          batchSize: limit,
        },
      }
    },
    responseModifier: (
      documents: (CartTemplate & { _deleted: boolean })[],
      origin,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents,
    }),
  },
  authentication: true,
  liveInterval: 5 * 60 * 1000, // 5 min
}
