import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

export const clientEntity: EntityDescriptor<Omit<{ frontendClientId: string }, '__typename'>> = {
  name: 'client',
  schema: {
    title: 'client',
    version: 0,
    description: 'client information',
    type: 'object',
    primaryKey: 'frontendClientId',
    properties: {
      frontendClientId: { type: 'string', maxLength: 100 },
    },
  },
}
