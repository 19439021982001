import { useEffect, useState } from 'react'
import {
  Geolocation,
  Position as GeolocationPosition,
  PositionOptions as GeolocationOptions,
} from '@capacitor/geolocation'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

export enum PositionStatus {
  NONE,
  REQUESTING,
  FAILED,
  NOT_GRANTED,
  SUCCESS,
}

// Permission types:
// granted: Every permission in this alias has been granted by the end user (or prompting is not necessary).
// denied: One or more permissions in this alias have been denied by the end user. (denied always)
// prompt: The end user should be prompted for permission, because it has neither been granted nor denied.
// prompt-with-rationale: The end user has denied permission before, but has not blocked the prompt yet. (hint has shown, but the user denied)
export enum PermissionStatus {
  DENIED = 'prompt-with-rationale',
  DENIED_ALWAYS = 'denied',
  NOT_REQUESTED = 'prompt',
  GRANTED = 'granted',
}

const getStatusByPermission = (perm: string) => {
  const isAccessDeniedAlways = perm === PermissionStatus.DENIED_ALWAYS
  const isAccessDeniedOnce = perm === PermissionStatus.DENIED
  const isAccessGranted = perm === PermissionStatus.GRANTED

  return { isAuthed: isAccessGranted, isEnabled: !isAccessDeniedAlways && !isAccessDeniedOnce }
}

const defaultGeoOptions: GeolocationOptions = {
  timeout: 10000,
  enableHighAccuracy: true,
}

const getPositionPermisions = async () => {
  if (isPlatform('web')) {
    // TODO this can be more finegrained by checking for the permission api in the web
    return { isAuthed: true, isEnabled: true }
  }
  try {
    const { location } = await Geolocation.checkPermissions()
    const isAccessDeniedAlways = location === PermissionStatus.DENIED_ALWAYS
    const isAccessGranted = location === PermissionStatus.GRANTED

    if (isAccessDeniedAlways || isAccessGranted) {
      return getStatusByPermission(location)
    }

    const { location: currentGeoPermission } = await Geolocation.requestPermissions()
    return getStatusByPermission(currentGeoPermission)
  } catch (e) {
    return { isAuthed: false, isEnabled: false }
  }
}

/**
 * position watching is much faster on iOS than requesting it
 * @see https://github.com/ionic-team/capacitor/issues/1893#issuecomment-528320906
 */
const iOSFastPosition = async (options = defaultGeoOptions) => {
  return new Promise<GeolocationPosition | null>((resolve, reject) => {
    const id = Geolocation.watchPosition(options, async (position, err) => {
      const watchId = await id
      Geolocation.clearWatch({ id: watchId })
      if (err) {
        return reject(err)
      }
      resolve(position)
    })
  })
}

export const useUserPosition = (
  onlyFetchPositionIfAuthorized = false,
  options?: PositionOptions
) => {
  const [permissions, setPermissions] = useState({
    isAuthed: false,
    isEnabled: false,
  })

  const [positionStatus, setPositionStatus] = useState<PositionStatus>(PositionStatus.NONE)

  const [position, setPosition] = useState<GeolocationPosition | null>(null)

  /** Check if geo api is available  */
  useEffect(() => {
    let unmounted = false

    const onResume = () => {
      const check = async () => {
        const flgs = await getPositionPermisions()
        /*Sentry.addBreadcrumb({
          category: 'location',
          message: 'location status',
          data: { ...flgs },
        })*/ // TODO replace former Sentry-Code with DataDog
        if (unmounted) {
          return
        }

        setPermissions(flgs)
      }
      check()
    }
    document.addEventListener('resume', onResume, false)
    onResume()

    return () => {
      unmounted = true
      document.removeEventListener('resume', onResume, false)
    }
  }, [])

  /** Get position */
  useEffect(() => {
    if (
      (permissions.isEnabled && !onlyFetchPositionIfAuthorized) ||
      (permissions.isEnabled && onlyFetchPositionIfAuthorized && permissions.isAuthed)
    ) {
      /**
       * position watching is much faster on iOS than requesting it
       * @see https://github.com/ionic-team/capacitor/issues/1893#issuecomment-528320906
       */
      const check = async () => {
        const opts = { ...defaultGeoOptions, ...options }
        try {
          setPositionStatus(PositionStatus.REQUESTING)
          let pos: GeolocationPosition | null
          if (isPlatform('ios') && !isPlatform('web')) {
            pos = await iOSFastPosition(opts)
          } else {
            pos = await Geolocation.getCurrentPosition(opts)
          }
          setPosition(pos)
          setPositionStatus(PositionStatus.SUCCESS)
        } catch (error) {
          /**
            * PositionError.prototype.PERMISSION_DENIED = PositionError.PERMISSION_DENIED = 1;
              PositionError.prototype.POSITION_UNAVAILABLE = PositionError.POSITION_UNAVAILABLE = 2;
              PositionError.prototype.TIMEOUT = PositionError.TIMEOUT = 3;
          */
          //storeMode = STOREMODE.ALL
          // Sentry.captureException(error) // TODO replace former Sentry-Code with DataDog
          if (error.code === 1) {
            setPermissions((baseState) => {
              return {
                ...baseState,
                isAuthed: false,
              }
            })
            setPositionStatus(PositionStatus.NOT_GRANTED)
          } else {
            setPositionStatus(PositionStatus.FAILED)
          }
        }
      }
      check()
    }
  }, [permissions.isEnabled, permissions.isAuthed, onlyFetchPositionIfAuthorized, options])

  return { position, positionStatus, permissions }
}
