import clsx from 'clsx'
import styles from './SelectionBar.module.scss'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'

const SELECTION_BAR_CONTAINER_ID = 'selection-bar-container'

/**
 * Get selection bar container element (to display react portal content).
 * @returns Selection bar container element
 */
export const getSelectionBarContainer = (): Element | null => {
  return document.getElementById(SELECTION_BAR_CONTAINER_ID) as Element | null
}

export const SelectionBarContainer = ({ className }: { className?: string }) => {
  const keyboardHeight = useKeyboardHeight()
  return (
    <div
      id={SELECTION_BAR_CONTAINER_ID}
      className={clsx(styles.selectionBarContainerWrapper, className)}
      style={{ bottom: keyboardHeight > 0 ? keyboardHeight : undefined }}
    ></div>
  )
}
