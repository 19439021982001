import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import React, { useContext, useEffect, useRef } from 'react'
import { CellMeasurerCache } from 'react-virtualized'

interface Props {
  cache: React.MutableRefObject<CellMeasurerCache | null>
  lastScrollY: React.MutableRefObject<number>
}

const ScrollRestorationContext = React.createContext<Props>({
  cache: { current: null },
  lastScrollY: { current: 0 },
})

export const useScrollRestoration = () => {
  return useContext(ScrollRestorationContext)
}

export const SearchResultScrollRestorationProvider: React.FC = (props) => {
  const cache = useRef<CellMeasurerCache | null>(null)
  const lastScrollY = useRef(0)

  const { searchParams } = useArticlesSearchProvider()

  useEffect(() => {
    //if search changed, we discard our scrollrestoration cache as their are new results with new measurements
    cache.current = null
    lastScrollY.current = 0
  }, [searchParams?.id])

  return (
    <ScrollRestorationContext.Provider value={{ cache, lastScrollY }}>
      {props.children}
    </ScrollRestorationContext.Provider>
  )
}
