import { ShopDomains } from '@obeta/utils/lib/domains'
import { useCompanyDetection } from './useCompanyDetection'
import {
  ShopCopyrightByDomain,
  ShopEmailByDomain,
  ShopFullLogoByDomain,
  ShopNameByDomain,
} from '@obeta/utils/lib/shopInfoByDomain'

export const useShopInfo = (domain?: ShopDomains) => {
  const company = useCompanyDetection()
  const companyIdentifier = domain ?? company

  const FullLogo = ShopFullLogoByDomain[companyIdentifier]
  const EmailAddress = ShopEmailByDomain[companyIdentifier]
  const CopyRight = ShopCopyrightByDomain[companyIdentifier]
  const ShopName = ShopNameByDomain[companyIdentifier]
  return { FullLogo, EmailAddress, CopyRight, ShopName }
}
