import { DocumentNode, gql } from '@apollo/client'

export const updateShoppingCartMetaDataMutation: DocumentNode = gql`
  mutation updateShoppingCartMetaData($input: ShoppingCartUpdateMetaDataInput!) {
    updateShoppingCartMetaData(input: $input) {
      updateResults {
        success
        errorMessage
      }
    }
  }
`
export const updateShoppingCartItems: DocumentNode = gql`
  mutation updateShoppingCartItems($cartId: String!, $cartItemPatches: [CartItemPatch!]!) {
    updateShoppingCartItems(input: { cartId: $cartId, cartItemPatches: $cartItemPatches }) {
      updateResults {
        success
        errorMessage
      }
    }
  }
`

export const addItemsToCart = gql`
  mutation AddItemsToCart($input: ShoppingCartAddItemsInput!) {
    addShoppingCartItems(input: $input) {
      updateResults {
        success
        errorCode
        errorMessage
      }
    }
  }
`

export const moveItemsToCart: DocumentNode = gql`
  mutation moveItemsToCart($input: ShoppingCartAddItemsInput!) {
    addShoppingCartItems(input: $input) {
      updateResults {
        success
        errorMessage
        itemResults {
          success
          errorMessage
          errorCode
        }
      }
    }
  }
`

export const deleteShoppingCartItems: DocumentNode = gql`
  mutation deleteShoppingCartItems($input: ShoppingCartDeleteItemsInput!) {
    deleteShoppingCartItems(input: $input) {
      updateResults {
        success
        itemResults {
          success
          errorMessage
        }
      }
    }
  }
`

export const notifyShoppingCart: DocumentNode = gql`
  mutation notifyShoppingCartInsteadOfSubmitOrder($input: ShoppingCartInput!) {
    notifyShoppingCart(input: $input) {
      notificationEmail
      errorMessage
      errorCode
    }
  }
`

export const submitShoppingCart: DocumentNode = gql`
  mutation submitShoppingCart($input: ShoppingCartInput!) {
    submitShoppingCart(input: $input) {
      success
      errorMessage
      errorCode
    }
  }
`

export const verifyOfferInShoppingCart: DocumentNode = gql`
  query verifyOfferInShoppingCart($input: VerifyOfferInShoppingCartInput!) {
    verifyOfferInShoppingCart(input: $input) {
      success
      result {
        removedRelations
        removedCartItems
        addedRelations
      }
    }
  }
`
