import { OfferFilter, OfferFlag, OfferOrderBy, OffersInput } from '@obeta/schema'

// Frontend display types
export enum OfferListPageAction {
  DisplaySelect = 'DISPLAY_SELECT',
  FlagSelect = 'FLAG_SELECT',
  OrderBy = 'ORDER_BY',
  Search = 'SEARCH',
  ToggleFavorite = 'TOGGLE_FAVORITE',
}

export type OfferDisplayViewType = Extract<OfferFilter, 'isFavorite'> | 'all'
export type OfferFlagViewType = OfferFlag | 'all'

export interface OfferListViewFilters {
  flag: OfferFlagViewType
  sortBy: OfferOrderBy
}

export interface OffersInputWithAction extends OffersInput {
  action?: OfferListPageAction
}
