import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'
import { OpenPosition } from '@obeta/schema'

export const openPositionsEntity: EntityDescriptor<
  Omit<OpenPosition, '__typename'> & { initialPosition: number }
> = {
  name: 'openpositions',
  schema: {
    title: 'openpositions',
    version: 2,
    description: 'a document representing an open position',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: { type: 'string', maxLength: 100 },
      invoiceAmount: { type: 'float' },
      invoiceDueDate: { type: 'string' },
      invoiceFromDate: { type: 'string' },
      isDiscount: { type: 'boolean' },
      isOverdue: { type: 'boolean' },
      openAmount: { type: 'float' },
      receipt: { type: 'string' },
      receiptType: { type: 'string' },
      initialPosition: { type: 'number' },
      discountDate: { type: 'string' },
      dunningLevel: { type: 'number' },
    },
  },
  migrationStrategies: {
    1: function (oldDoc) {
      return {
        ...oldDoc,
        dunningLevel: oldDoc.dunningLevel || 0,
        discountDate: oldDoc.discountDate || '',
      }
    },
    2: function (oldDoc) {
      return {
        ...oldDoc,
        id: oldDoc.id || '',
      }
    },
  },
}
