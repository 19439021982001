import { ArticleSearchArea, ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { isEmpty } from '../isEmpty'
import { constructCategoryFilter } from './facets'
import { getFacetByWarehouseId } from '../getFacetByWarehouseId'

/**
 * https://www.algolia.com/doc/api-reference/api-parameters/filters/
 * @param searchParams
 * @returns
 */
export const buildFilterParams = (searchParams: ArticleSearchParams, warehouseId: string) => {
  const filter: string[] = []

  if (searchParams.suppliers && !isEmpty(searchParams.suppliers)) {
    const suppliersFilters: string[] = []
    for (const supValue in searchParams.suppliers) {
      const sup = searchParams.suppliers[supValue]
      suppliersFilters.push(`supplierFilterName:"${sup.name}"`)
    }

    const list = `(${suppliersFilters.join(' OR ')})`
    filter.push(list)
  }

  if (searchParams.fundings) {
    filter.push(`eligibleFor:"${searchParams.fundings}"`)
  }

  const ids: string[] = []

  if (searchParams.productIds && searchParams.productIds.length > 0) {
    ids.push(
      `(${searchParams.productIds
        .map((id) => {
          return `sapId:${id}`
        })
        .join(' OR ')})`
    )
  }
  if (searchParams.productDehaIds && searchParams.productDehaIds.length > 0) {
    ids.push(
      `(${searchParams.productDehaIds
        .map((id) => {
          return `dehaId:${id}`
        })
        .join(' OR ')})`
    )
  }

  if (ids.length > 0) {
    filter.push(`(${ids.map((id) => id).join(' OR ')})`)
  }

  if (searchParams.obetaCategory) {
    const catFacet = constructCategoryFilter(searchParams.obetaCategory, 'obetaCatalogIds')
    if (catFacet) {
      filter.push(catFacet)
    }
  }
  if (searchParams.dehaCategory) {
    const onlineCatFacet = constructCategoryFilter(searchParams.dehaCategory, 'dehaCatalogIds')
    if (onlineCatFacet) {
      filter.push(onlineCatFacet)
    }
  }

  if (searchParams.selectedEtim?.id && searchParams.selectedEtim.id !== '0') {
    filter.push(`zEtimData.class.id:${searchParams.selectedEtim?.id}`)
  }

  const warehouseFacet = getFacetByWarehouseId(warehouseId)
  if (searchParams.articleSearchArea === ArticleSearchArea.IMMEDIATELY_AVAILABLE) {
    filter.push(`${warehouseFacet}:true`)
  }

  if (searchParams.minListPrice !== undefined && searchParams.maxListPrice !== undefined) {
    filter.push(`listPrice: ${searchParams.minListPrice || 0} TO ${searchParams.maxListPrice || 0}`)
  }

  searchParams.etim?.forEach((el) => {
    let operator = ':'
    if (el.facet?.name.endsWith('_MAX')) {
      operator = '<='
    } else if (el.facet?.name.endsWith('_MIN')) {
      operator = '>='
    }
    let val = `${el.facet?.name}${operator}`

    // we cannot yet reliably use the facet type to detect strings, because in ArticleDetailPage
    // we still receive legacy data, which does not include the correct facet type
    if (
      (el.facet?.meta.type !== 'range' &&
        el.value !== 'true' &&
        el.value !== 'false' &&
        `${el.value}`.match(/[a-z,]/i) !== null) ||
      `${el.value}`.match(/^\d+:\d+$/)
    ) {
      val += `"${el.value}"`
    } else {
      val += el.value
    }
    filter.push(val)
  })

  return filter.join(' AND ')
}
