import { datadogRum } from '@datadog/browser-rum'

type TrackOptions = {
  type: 'VIEW' | 'CLICK' | 'CUSTOM'
  name?: 'string'
  context?: object
}
type Track = (options: TrackOptions) => void

const track: Track = ({ name, type, context }) => {
  const actionName = `${type}_${name}`
  if (name && name.length > 0) {
    datadogRum.addAction(actionName, context)
  }
}

export const trackClick = (name, context?) => track({ name, context, type: 'CLICK' })
export const trackView = (name, context?) => track({ name, context, type: 'VIEW' })
export const trackCustom = (name, context?) => track({ name, context, type: 'CUSTOM' })
