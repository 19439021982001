import React from 'react'
import { Typography, useTheme, Box, Link, Theme } from '@mui/material'
import styles from './InfoSection.module.scss'
import topSectionStyles from './../TopSection/TopSection.module.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import { IHeaderInfo, useInfoHeader } from '@obeta/data/lib/hooks/useInfoHeader'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/notification_warning.svg'
import { ReactComponent as ErrorIcon } from 'assets/icon/designsystem/notification_error.svg'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { isInternalLink } from '@obeta/utils/lib/isInternalLink'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

interface IInfoSectionProps {
  isLoggedIn: boolean
}

interface IInfoSectionApiBasedProps {
  headerInfo: IHeaderInfo
  theme: Theme
}

const InfoSectionApiBased = ({ headerInfo, theme }: IInfoSectionApiBasedProps) => {
  const history = useHistory()

  const goToInternalLink = (event) => {
    if (headerInfo.url) {
      event.preventDefault()
      history.push(headerInfo.url)
    }
  }

  return (
    <>
      <Box className={styles.icon}>
        {headerInfo.type === 'INFO' ? (
          <ErrorIcon className={styles.error} />
        ) : (
          <WarningIcon className={styles.warning} />
        )}
      </Box>
      {headerInfo.url ? (
        <Link
          className={styles.link}
          rel="noopener"
          color="inherit"
          underline="always"
          target={headerInfo.openInNewTab ? '_blank' : '_self'}
          href={headerInfo.url}
          onClick={
            isInternalLink(headerInfo.url) && !headerInfo.openInNewTab
              ? goToInternalLink
              : undefined
          }
        >
          <Typography
            color={theme.palette.secondary.main}
            variant="bodyBold"
            className={styles.text}
          >
            {headerInfo.message}
          </Typography>
        </Link>
      ) : (
        <Typography color={theme.palette.secondary.main} variant="bodyBold" className={styles.text}>
          {headerInfo.message}
        </Typography>
      )}
    </>
  )
}

export const InfoSection: React.FC<IInfoSectionProps> = ({ isLoggedIn }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { mobile } = useBreakpoints()

  const topSectionHeight = useRemToPx(parseFloat(topSectionStyles.mobileFullHeight))
  const infoHeight = useRemToPx(parseFloat(styles.infoSectionWrapperHeight))

  const show = useMobileScrollHandler({
    targetSectionHeight: mobile ? topSectionHeight : infoHeight,
    isEnabled: true,
    options: { showOnScrollUp: true },
  })

  const headerInfo = useInfoHeader({ skip: !show, isLoggedIn })

  if (!show || (!headerInfo && isLoggedIn) || (!headerInfo && mobile)) {
    return null
  }

  return (
    <div className={clsx(styles.infoSectionWrapper, { [styles.withInfoMessage]: !!headerInfo })}>
      <div className={clsx(styles.infoSection, { [styles.withInfoMessage]: !!headerInfo })}>
        {headerInfo ? (
          <InfoSectionApiBased headerInfo={headerInfo} theme={theme} />
        ) : (
          <>
            <Typography color={theme.palette.secondary.main} variant="bodyBold">
              {t('HEADER.ARTICLE_AMOUNT')}
            </Typography>
            <Typography color={theme.palette.secondary.main} variant="bodyBold">
              {t('HEADER.STORE_AMOUNT')}
            </Typography>
            <Typography color={theme.palette.secondary.main} variant="bodyBold">
              {t('HEADER.BRAND_AMOUNT')}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}
