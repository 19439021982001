import React, { useState } from 'react'
import { ReactComponent as ShoppingCart } from 'assets/icon/designsystem/cart.svg'
import { Box, ButtonBase, SvgIcon, Typography } from '@mui/material'
import { CartsSelect } from '../../../carts-select/CartsSelect'
import { useMarkedForNotifactionSet } from '@obeta/data/lib/hooks/useMarkedForNotificationSet'
import styles from './CartsControlls.module.scss'
import clsx from 'clsx'
import { CartsMenu } from '../../../carts-menu'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

export const CartsControls: React.FC = () => {
  const history = useHistory()
  const [showCartsMenu, setShowCartsMenu] = useState<boolean>(false)
  const { activeCartItemsCount, activeCart, activeCartId, activeCartsCount } = useShoppingCartsV2()
  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null)
  const articleAmount = activeCartItemsCount < 1000 ? activeCartItemsCount : '>1k'
  const markedCartsSet = useMarkedForNotifactionSet()
  const hasMarkedCarts = !!markedCartsSet.size

  const onShowCartsMenu = (event) => {
    setMenuEl(event.currentTarget)
    setShowCartsMenu(true)
  }
  const onCloseCartsMenu = () => {
    setMenuEl(null)
    setShowCartsMenu(false)
  }

  const openActiveCart = () => {
    history.push(`/shopping-cart-details/${activeCartId}`)
  }

  const areAllCartsEmpty: boolean = activeCartsCount <= 1 && !activeCart?.items?.length

  return (
    <div className={styles.cartWrapper}>
      <ButtonBase
        data-testid="cart-controls-cart"
        className={styles.cart}
        onClick={openActiveCart}
        disabled={areAllCartsEmpty}
      >
        <Box className={styles.icon}>
          <SvgIcon component={ShoppingCart} />
        </Box>
        <Typography
          data-testid="cart-controls-active-cart-amount"
          className={styles.articlesCount}
          variant="bodyBold"
        >
          {articleAmount} Artikel
        </Typography>
      </ButtonBase>
      <div
        className={clsx(styles.cartsSelector, {
          [styles['cartsSelector--ordarable']]: hasMarkedCarts,
        })}
      >
        <CartsSelect
          withIndicator={hasMarkedCarts}
          count={activeCartsCount}
          onSelect={onShowCartsMenu}
        />
      </div>
      <CartsMenu open={showCartsMenu} onClose={onCloseCartsMenu} anchorEl={menuEl} />
    </div>
  )
}
