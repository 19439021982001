import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './RefreshDialog.module.scss'

interface Props {
  refresh: () => void
  onClose: () => void
  open: boolean
}
export const RefreshDialog: React.FC<Props> = ({ refresh, open, onClose }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open}>
      <DialogTitle className={styles.title}>
        <Typography variant={'boldText'}>{t('INFO.REFRESH_DIALOG.TITLE')}</Typography>
        <Button
          className={styles.close}
          variant={'tertiary'}
          startIcon={<SvgIcon component={CloseIcon} color="secondary" />}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant={'body'}>{t('INFO.REFRESH_DIALOG.TEXT')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={refresh}>
          {t('INFO.REFRESH_DIALOG.BUTTON')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
