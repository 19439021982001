import { UserV2Data } from '@obeta/models/lib/models/Users/UserV2'
import { LDContext } from 'launchdarkly-js-client-sdk'
import { getCookiebotStatisticsFlag } from './getCookiebotFlags'

const UNKNOWN_USER_CONTEXT = { kind: 'user', key: 'unknown_user' }

export const buildLaunchDarklyContext = (user?: UserV2Data): LDContext | undefined => {
  const isLocalDev = window?.location?.origin === 'http://localhost:4200'

  if (user?.fullUserId) {
    return {
      kind: 'user',
      key: user.fullUserId,
      custom: {
        customerId: user.userId ?? '',
        origin: 'web',
      },
    }
  } else if (isLocalDev || getCookiebotStatisticsFlag()) {
    return UNKNOWN_USER_CONTEXT
  }
}
