import { useEntities } from './useEntities'
import { LatLonPosition } from '@obeta/models/lib/models/lat-lon-position'
import { useState, useEffect } from 'react'
import { PositionStatus, useUserPosition } from './useUserPosition'
import { getDistanceInKM } from '@obeta/utils/lib/distance'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'

export enum FilterMode {
  NEAREST = 'NEAREST',
  ALL = 'ALL',
}

const filterStoresByIds = (
  stores: StoreV2[],
  storeIds: string[],
  pos?: LatLonPosition
): StoreV2[] => {
  const sortedStores = sortStores(stores, pos)

  const filteredStores: StoreV2[] = []
  for (const store of sortedStores) {
    for (let index = 0; index < storeIds.length; index++) {
      if (store.id === `${storeIds[index]}`) {
        filteredStores.push(store)
      }
    }
  }
  return filteredStores
}

const sortStores = (stores: StoreV2[], pos?: LatLonPosition): StoreV2[] => {
  if (pos) {
    for (const store of stores) {
      store.distance = getDistanceInKM(
        pos,
        new LatLonPosition(parseFloat(store.latitude), parseFloat(store.longitude))
      )
    }
    const sortedStores = stores.sort((store1, store2) => store1.distance - store2.distance)
    for (const store of sortedStores) {
      store.distance = Math.floor(store.distance * 10) / 10
    }
    return sortedStores
  } else {
    return stores
  }
}

interface Options {
  onlyFetchPositionIfAuthorized?: boolean
  filterByIds?: string[]
}

const geoOptions = {
  timeout: 100000,
}

export const useStoreFinder = ({ onlyFetchPositionIfAuthorized = false, filterByIds }: Options) => {
  const stores: StoreV2[] = useEntities('storesv2')

  const [sortedStores, setSortedStores] = useState<StoreV2[]>([])

  const { position, positionStatus, permissions } = useUserPosition(
    onlyFetchPositionIfAuthorized,
    geoOptions
  )

  useEffect(() => {
    // To prevent unnecessary re-renders when the position changes, we shouldn't update the state when it's being requested
    if (stores.length && positionStatus !== PositionStatus.REQUESTING) {
      if (filterByIds) {
        setSortedStores(filterStoresByIds(stores, filterByIds, position?.coords))
      } else {
        setSortedStores([...sortStores(stores, position?.coords)])
      }
    }
  }, [position, stores, filterByIds, positionStatus])

  return { flags: permissions, stores: sortedStores, position: position?.coords, positionStatus }
}
