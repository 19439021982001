import { Skeleton } from '@mui/material'

export const TextSkeleton: React.FC<React.ComponentProps<typeof Skeleton>> = (props) => {
  const {
    width = '100%',
    height = 24,
    variant = 'rectangular',
    animation = 'wave',
    ...restProps
  } = props

  return (
    <Skeleton
      width={width}
      height={height}
      variant={variant}
      animation={animation}
      {...restProps}
    />
  )
}
