import { gql, useApolloClient } from '@apollo/client'
import { CompanyContactDetails } from '@obeta/models/lib/models/Company/CompanyContactDetails'
import {
  GetCompanyContactDetailsQuery,
  GetCompanyContactDetailsQueryVariables,
} from '@obeta/schema'
import { useEffect, useState } from 'react'
import { trackCustom } from '@obeta/utils/lib/tracking'

export const COMPANY_CONTACT_DETAILS = gql`
  query getCompanyContactDetails($input: CompanyInput!) {
    getCompanyBaseData(input: $input) {
      accountingEmailAddress
      accountingPhoneNumber
      servicePhoneNumber
      serviceEmailAddress
      shopSupportPhoneNumber
      shopSupportEmailAddress
    }
  }
`

export const useCompanyContactDetails = (companyId: string): CompanyContactDetails => {
  const [companyContactDetails, setCompanyContactDetails] = useState<CompanyContactDetails>({
    accountingEmailAddress: '',
    accountingPhoneNumber: '',
    servicePhoneNumber: '',
    serviceEmailAddress: '',
    shopSupportPhoneNumber: '',
    shopSupportEmailAddress: '',
  })
  const client = useApolloClient()

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const { data } = await client.query<
            GetCompanyContactDetailsQuery,
            GetCompanyContactDetailsQueryVariables
          >({
            query: COMPANY_CONTACT_DETAILS,
            variables: { input: { companyId: companyId } },
          })
          setCompanyContactDetails(data.getCompanyBaseData)
        } catch {
          trackCustom('company-contact-details-error')
        }
      }

      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return companyContactDetails
}
