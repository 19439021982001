import React, { useContext } from 'react'
import { Box, Divider, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SmsIcon } from 'assets/icon/designsystem/sms.svg'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail.svg'
import { ReactComponent as InstagramLogo } from 'assets/icon/designsystem/instagram.svg'
import { ReactComponent as YoutubeLogo } from 'assets/icon/designsystem/youtube-logotype.svg'
import { ReactComponent as AppStoreLogo } from 'assets/icon/designsystem/appstore.svg'
import { ReactComponent as GooglePlayLogo } from 'assets/icon/designsystem/googleplay.svg'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SectionMetalQuotationCopyrightNote } from './SectionMetalQuotationCopyrightNote'
import { ConsolidatedHistory } from '@obeta/models/lib/models/History'
import { LogoAndIconsAndContactUsTabletLandscape } from './LogoAndIconsAndContactUsTabletLandscape'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useShopInfo } from '@obeta/data/lib/hooks/useShopInfo'
import { ListOfLinksForMobileAndTabletPortrait } from './ListOfLinksForMobileAndTabletPortrait'
import { ListOfLinksForDesktopAndTabletLandscape } from './ListOfLinksForDesktopAndTabletLandscape'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'
import { useMetalQuotations } from '@obeta/data/lib/hooks/useMetalQuotations'
import { SocketContext, SocketState } from '../sockets/Sockets'
import { ShopLink } from '../link/ShopLink'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { TertiaryIconButton } from '../custom-button/CustomButton'

export const clickedObetaLinks = (
  e: React.MouseEvent,
  history: ConsolidatedHistory,
  url: string
) => {
  if (!e.getModifierState('Control')) {
    e.preventDefault()
    history.push(url)
  }
}

export const Footer: React.FC = () => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const { aluminium, copper } = useMetalQuotations()

  const { redirectToStartPage } = useRedirect()
  const history = useHistory()

  const { FullLogo, EmailAddress, CopyRight } = useShopInfo()
  const socketConnectionState = useContext(SocketContext)

  return (
    <div className={styles.outerDiv}>
      <Divider className={styles.dividerGray} variant={'fullWidth'} />
      <div className={styles.wrapper}>
        <div className={styles.footerContainer}>
          <div className={styles.divLogoAndIcons}>
            {!tabletWide && (
              <Box className={styles.boxLogos}>
                <Link
                  underline="none"
                  color="inherit"
                  href={'/'}
                  onClick={(event: React.MouseEvent) => redirectToStartPage(event)}
                >
                  <FullLogo className={styles.obetaLogoColor} data-url="/" />
                </Link>
                {(mobile || tablet) && (
                  <div>
                    <Link
                      rel="noopener"
                      target="_blank"
                      href="https://www.instagram.com/obeta_electro/"
                    >
                      <InstagramLogo className={styles.instagramAndYoutubeLogos} />
                    </Link>
                    <Link
                      rel="noopener"
                      target="_blank"
                      href="https://www.youtube.com/user/obetaelectro"
                    >
                      <YoutubeLogo
                        className={clsx(styles.instagramAndYoutubeLogos, styles.marginLeftHalfRem)}
                      />
                    </Link>
                  </div>
                )}
              </Box>
            )}

            {desktop && (
              <Typography variant={'bodyBold'} className={styles.marginForKontakt}>
                {t('FOOTER.CONTACT')}
              </Typography>
            )}

            {!tabletWide && (
              <Box className={styles.boxIconFollowedByText}>
                <TertiaryIconButton
                  icon={<SmsIcon />}
                  onClick={() => history.push(ShopRoutes.Contact)}
                >
                  {t('FOOTER.CONTACT_PERSON')}
                </TertiaryIconButton>
              </Box>
            )}

            {!tabletWide && (
              <Box className={styles.boxIconFollowedByText}>
                <Link target="_blank" href={`mailto: ${EmailAddress}`}>
                  {' '}
                  <MailIcon className={styles.SmsIcon} />{' '}
                </Link>
                <Typography variant={'bodyBold'} className={styles.textAfterIcon}>
                  <Link
                    underline="none"
                    color="inherit"
                    target="_blank"
                    href={`mailto: ${EmailAddress}`}
                  >
                    {t('FOOTER.CONTACT_US')}
                  </Link>
                </Typography>
              </Box>
            )}

            {desktop && (
              <Box>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://www.instagram.com/obeta_electro/"
                  className={styles.verticallyAligned}
                >
                  <InstagramLogo className={styles.instagramAndYoutubeLogos} />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://www.youtube.com/user/obetaelectro"
                >
                  <YoutubeLogo
                    className={clsx(styles.instagramAndYoutubeLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://itunes.apple.com/de/app/obeta-app/id1299005066?mt=8&at=10l6Xd"
                >
                  <AppStoreLogo
                    className={clsx(styles.appStoreAndGooglePlayLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=de.obeta.shop"
                >
                  <GooglePlayLogo
                    className={clsx(styles.appStoreAndGooglePlayLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
              </Box>
            )}
          </div>
          {tabletWide && <LogoAndIconsAndContactUsTabletLandscape />}

          {(mobile || tablet) && (
            <div className={styles.divLogoAndIcons}>
              <Divider className={styles.divider} />
            </div>
          )}

          <div className={styles.mainDivLinks}>
            {(mobile || tablet) && <ListOfLinksForMobileAndTabletPortrait />}

            {(desktop || tabletWide) && <ListOfLinksForDesktopAndTabletLandscape />}
          </div>
        </div>
        <SectionMetalQuotationCopyrightNote
          aluminiumQuotation={aluminium}
          copperQuotation={copper}
          shopCopyrightByDomain={CopyRight}
        />
      </div>
      {socketConnectionState === SocketState.ConnectionError && (
        <div className={styles.socketErrorContainer}>
          <Typography variant={'xSmallText'}>
            <ShopLink href="/news/websocket">{t('FOOTER.NO_SOCKET_CONNECTION')}</ShopLink>
          </Typography>
        </div>
      )}
    </div>
  )
}
