import { createContext, useContext } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'

export interface IGoggleMapsContextData {
  isLoaded: boolean
}

export const GoggleMapsContext = createContext<IGoggleMapsContextData>({
  isLoaded: false,
})

const INCLUDING_LIBRARIES: Libraries = ['places']

export const GoggleMapsContextProvider = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey:
      process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    id: 'google-map-script',
    libraries: INCLUDING_LIBRARIES,
  })

  return (
    <GoggleMapsContext.Provider
      value={{
        isLoaded,
      }}
    >
      {children}
    </GoggleMapsContext.Provider>
  )
}

export const useGoogleMapsProvider = () => {
  const data = useContext(GoggleMapsContext)

  return data
}
