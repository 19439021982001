import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'
import { IMenuItem } from '../../types'
import styles from './OverlayMenuItem.module.scss'

interface IOverlayMenuItemProps {
  item: IMenuItem
  onOpenPage: (item: IMenuItem) => void
}

export const OverlayMenuItem: React.FC<IOverlayMenuItemProps> = ({ item, onOpenPage }) => {
  const [isSubitemsVisibe, setSubitemsVisible] = useState(false)

  const { title } = item
  const hasOptions = !!item.options?.length

  const openItem = () => {
    if (item.options) return setSubitemsVisible((prevVisible) => !prevVisible)
    onOpenPage(item)
  }

  return (
    <>
      <div className={styles['content-item-wrapper']} onClick={openItem}>
        <div className={styles['item-wrapper']}>
          <div className={styles['title-wrapper']}>
            <Typography variant={hasOptions ? 'bodyBold' : 'body'}>{title}</Typography>
          </div>
          {hasOptions && (
            <ChevronRight
              style={{
                transform: isSubitemsVisibe ? 'rotate(-90deg)' : 'rotate(90deg)',
              }}
            />
          )}
        </div>
      </div>
      {isSubitemsVisibe && (
        <div className={styles.subItems}>
          {item?.options?.map((optionItem, idx) => (
            <OverlayMenuItem item={optionItem} onOpenPage={onOpenPage} key={idx} />
          ))}
        </div>
      )}
    </>
  )
}
