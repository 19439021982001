const regex = /^(\d*\D+[^A-Z]) ([^a-z]?\D*\d+.*)$/

export const splittAddress = (address: string): string[] | null => {
  return regex.exec(address)
}

export const checkSplittAddress = (address: string): boolean => {
  const numberRegex = /^[0-9].*/
  const addressSplit = regex.exec(address)
  return addressSplit?.length === 3 && numberRegex.exec(addressSplit[2]) !== null
}
