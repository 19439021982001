import React, { useCallback, useState } from 'react'
import { Button, SvgIcon, Typography } from '@mui/material'
import { HeaderSelectStore } from '../../../select-store/SelectStore'
import { ReactComponent as Edit } from 'assets/icon/edit.svg'
import { ReactComponent as Location } from 'assets/icon/designsystem/place_location.svg'
import { MenuItemColor } from '../dropdown/types'
import styles from './StoreMenuItem.module.scss'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { DefaultStoreInfo } from '../../../store/DefaultStoreInfo'
import { DropdownMenu } from '../dropdown'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { TertiaryButton, TertiaryResponsiveIconButton } from '../../../custom-button/CustomButton'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'

export const StoreMenuItem = () => {
  const history = useHistory()
  const { selectedStore: store } = useUserSelectedStore()
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const [isStoreModalOpen, setStoreModalOpen] = useState<boolean>(false)
  const toggleStoreModal = () => setStoreModalOpen((prevState) => !prevState)

  const Footer = (
    <div className={styles.footerWrapper}>
      <Button
        className={styles.footerButton}
        onClick={toggleStoreModal}
        variant="text"
        color="secondary"
      >
        <Edit width={16} height={16} />
        <Typography variant="bodyBold" className={styles.editButtonText}>
          {t('SHOPPING_CART.CHANGE_BRANCH')}
        </Typography>
      </Button>
      <Button
        className={styles.footerButton}
        onClick={() => history.push('/stores')}
        variant="text"
        color="secondary"
      >
        <Location width={16} height={16} />
        <Typography variant="bodyBold" className={styles.editButtonText}>
          Alle Standorte anzeigen
        </Typography>
      </Button>
    </div>
  )

  const getDropddownTriggerButton = useCallback(
    (onClick, ArrowIcon) =>
      desktop ? (
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={Location} />}
          rightIcon={<SvgIcon component={ArrowIcon} />}
          onClick={onClick}
        >
          {t('MAIN.STORE')}
        </TertiaryButton>
      ) : (
        <TertiaryResponsiveIconButton icon={<Location />} onClick={onClick} />
      ),
    [desktop, t]
  )

  return (
    <>
      <DropdownMenu
        color={MenuItemColor.Black}
        footer={Footer}
        renderClosedTrigger={(click) => getDropddownTriggerButton(click, ArrowDown)}
        renderOpenedTrigger={(click) => getDropddownTriggerButton(click, ArrowUp)}
      >
        <div className={styles.myStoreWrapper}>
          <Typography variant={'bodyBold'} className={styles.myStore}>
            {t('HEADER.MY_STORE')}
          </Typography>
          <Typography variant={'smallText'}>{t('HEADER.ARTICLE_STOCK_FOR_STORE')}</Typography>
        </div>
        <DefaultStoreInfo store={store} />
      </DropdownMenu>
      <HeaderSelectStore
        productUnit=""
        productTitle="Title"
        selectStoreOpen={isStoreModalOpen}
        setSelectStoreOpen={setStoreModalOpen}
      />
    </>
  )
}
