import React, { useContext } from 'react'
import { IDbUtils } from '@obeta/models/lib/models/Db/index'

export const DbUtilsContext = React.createContext<IDbUtils | null>(null)

export const useDbUtils = () => {
  const state = useContext(DbUtilsContext)
  if (!state) {
    throw new Error(
      'DbUtilsContext is undefined. Make sure you wrapped your app with <DbUtilsProvider/>'
    )
  }

  return state
}
