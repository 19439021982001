import { useSearchHistory } from './useSearchHistory'
import { useSearchSuggestionsGQL } from './useSearchSuggestionsGQl'
import { Category } from '@obeta/models/lib/models/Category'
import { useEntities } from './useEntities'

export enum ESuggestionGroup {
  Category = 'Zu Kategorien',
  Suggestion = 'Suchvorschläge',
}

export enum ESuggestionType {
  Category = 'category',
  Suggestion = 'suggestion',
  History = 'history',
}

export type SuggestionItem = {
  title: string
  group: ESuggestionGroup
  type?: ESuggestionType
  icon?: string
  id?: string
}

export const useSearchDropdownData = (searchText: string): SuggestionItem[] => {
  const { history } = useSearchHistory()
  const categories = useEntities<Category>('categories')
  const suggestions = useSearchSuggestionsGQL(searchText.substring(0, 50))

  const suggestionsItems = suggestions.map((item) => ({
    title: item,
    type: ESuggestionType.Suggestion,
    group: ESuggestionGroup.Suggestion,
  }))

  const searchRequestsHistory = history
    .map((item) => ({
      title: item,
      type: ESuggestionType.History,
      group: ESuggestionGroup.Suggestion,
    }))
    .reverse()

  const categoriesSuggestions = searchText
    ? categories
        .filter((item) => {
          return item.name.toLowerCase().includes(searchText)
        })
        .map((item) => ({
          title: item.name,
          type: ESuggestionType.Category,
          group: ESuggestionGroup.Category,
          id: item.id,
        }))
    : []

  return [...searchRequestsHistory, ...suggestionsItems, ...categoriesSuggestions]
}
