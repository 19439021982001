import { useTranslation } from 'react-i18next'
import React, { FunctionComponent, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { BaseNotificationLayout, BaseNotificationRow } from './BaseNotificationLayout'
import {
  SecondaryButton,
  SecondaryLightButton,
  TertiaryButton,
} from '../custom-button/CustomButton'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { ReactComponent as HighlightOffIcon } from 'assets/icon/designsystem/highlight_off.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as ReturnIcon } from 'assets/icon/vector.svg'
import styles from './Notification.module.scss'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const notificationVariantKeys = ['success', 'info', 'infoLight', 'error', 'warning'] as const

type NotificationVariant = typeof notificationVariantKeys[number]

const notificationVariants: Record<
  NotificationVariant,
  { icon: FunctionComponent; color: string }
> = {
  success: {
    icon: CheckIcon,
    color: 'success',
  },
  info: {
    icon: InfoIcon,
    color: 'secondary',
  },
  infoLight: {
    icon: InfoIcon,
    color: 'darkGray55K',
  },
  error: {
    icon: HighlightOffIcon,
    color: 'primary',
  },
  warning: {
    icon: InfoIcon,
    color: 'warning',
  },
}

interface Props {
  id: string
  closeHandler?: (id: string) => void
  maximumWidth?: string
  variant: NotificationVariant
  closeAfterMs?: number
  customIcon?: FunctionComponent
  children: React.ReactNode
  actionEl?: React.ReactNode
}

/** Notification component to render everything as a Notification passed as children
 * There is also a predefined content component {@link NotificationStyledBlock} with no custom styling required
 * and a component {@link NotificationMultiRow} which renders multiple elements a row layout
 */
const NotificationBase: React.FC<Props> = ({
  id,
  closeHandler,
  maximumWidth,
  actionEl,
  variant,
  closeAfterMs,
  children,
  customIcon,
}) => {
  const handleClose = () => {
    if (closeHandler) {
      closeHandler(id)
    }
  }
  useEffect(() => {
    if (closeAfterMs) {
      const timer = setTimeout(() => {
        handleClose()
      }, closeAfterMs)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { color, icon } = notificationVariants[variant]

  let childComponentName = 'unknown'
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type && typeof child.type !== 'string') {
      childComponentName = child.type.name || 'unknown'
    }
  })

  const isNotificationStyledBlockChildren = childComponentName === 'NotificationStyledBlock'
  const isNotificationMultiRowChildren = childComponentName === 'NotificationMultiRow'

  return (
    <Box maxWidth={maximumWidth} className={styles.container} data-testid="Notification">
      <BaseNotificationLayout
        id={id}
        color={color}
        closeHandler={handleClose}
        Icon={customIcon ?? icon}
        actionEl={actionEl}
      >
        {(isNotificationStyledBlockChildren || isNotificationMultiRowChildren) && children}
        {!isNotificationStyledBlockChildren && !isNotificationMultiRowChildren && (
          <BaseNotificationRow>{children}</BaseNotificationRow>
        )}
      </BaseNotificationLayout>
    </Box>
  )
}

type NotificationUndoProps = Props & { undoHandler: () => void }
export const NotificationUndo: React.FC<NotificationUndoProps> = (props) => {
  const { t } = useTranslation()
  const isUndoButtonEnabled = useFeatureToggle('NOTIFICATION_UNDO_BUTTON')

  return (
    <NotificationBase
      {...props}
      actionEl={
        isUndoButtonEnabled ? (
          <Stack direction="row" gap="0.5rem" alignItems={'center'} justifyContent={'flex-start'}>
            <SecondaryLightButton disabled leftIcon={<ReturnIcon />} onClick={props.undoHandler}>
              {t('NOTIFICATION.UNDO')}
            </SecondaryLightButton>
          </Stack>
        ) : undefined
      }
    >
      {props.children}
    </NotificationBase>
  )
}

type NotificationProps = Omit<Props, 'actionEl'>
export const Notification: React.FC<NotificationProps> = (props) => {
  return <NotificationBase {...props}>{props.children}</NotificationBase>
}

type NotificationAction = {
  type: 'tertiary' | 'secondary'
  text: string
  icon?: JSX.Element
  handler: () => void
}
type NotificationWithActionsProps = Omit<Props, 'actionEl'> & { actions: NotificationAction[] }

export const NotificationWithActions: React.FC<NotificationWithActionsProps> = (props) => {
  return (
    <NotificationBase
      {...props}
      actionEl={
        <Stack direction="row" gap="0.5rem" alignItems={'center'} justifyContent={'flex-start'}>
          {props.actions.map(({ type, text, icon, handler }) => {
            if (type === 'secondary') {
              return (
                <SecondaryButton key="secondary" leftIcon={icon} onClick={handler}>
                  {text}
                </SecondaryButton>
              )
            }
            return (
              <TertiaryButton key="tertiary" leftIcon={icon} onClick={handler}>
                {text}
              </TertiaryButton>
            )
          })}
        </Stack>
      }
    >
      {props.children}
    </NotificationBase>
  )
}
