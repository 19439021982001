import { useRef, FC, KeyboardEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { InputAdornment, OutlinedTextFieldProps, TextField } from '@mui/material'
import clsx from 'clsx'

// Assets
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as SearchIcon } from 'assets/icon/designsystem/search.svg'

// Styles
import styles from './SearchField.module.scss'

// UI
import { TertiaryIconButton } from '../custom-button/CustomButton'

interface SearchFieldClasses {
  notchedOutline?: string
}

export interface ISearchFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant' | 'classes' | 'onChange'> {
  classes?: SearchFieldClasses
  value?: string
  onChange?: (value: string) => void
  onReset?: () => void
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void
  inputClassName?: string
  startAdornment?: ReactNode
}

export const SearchField: FC<ISearchFieldProps> = (props) => {
  const { t } = useTranslation()
  const {
    id,
    placeholder = t<string>('MAIN.SEARCH'),
    className,
    inputClassName,
    startAdornment,
    onKeyUp,
    classes,
    value,
    onChange,
    onReset,
    ...rest
  } = props
  const textFieldRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      textFieldRef.current?.blur()
    }
  }

  return (
    <TextField
      id={id}
      inputRef={textFieldRef}
      onKeyUp={onKeyUp ?? handleKeyDown}
      variant="outlined"
      placeholder={placeholder}
      className={clsx('outlined-lightGray', styles.field, className)}
      InputProps={{
        inputMode: 'search',
        className: clsx(styles.inputRoot, inputClassName),
        classes: {
          input: styles.input,
          notchedOutline: classes?.notchedOutline,
        },
        endAdornment:
          value && value.length > 0 ? (
            <InputAdornment className={styles.startAdornment} position="end">
              <TertiaryIconButton icon={<CloseIcon />} onClick={onReset} />
            </InputAdornment>
          ) : undefined,
        startAdornment:
          startAdornment !== undefined ? (
            startAdornment
          ) : (
            <InputAdornment position="start" className={styles.startAdornment}>
              <SearchIcon className={styles.searchIcon} />
            </InputAdornment>
          ),
      }}
      value={value}
      onChange={(event) => onChange?.(event.target.value)}
      {...rest}
    />
  )
}
