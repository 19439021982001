import { useMutation, gql } from '@apollo/client'
import { useEffect } from 'react'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { useRxDB } from 'rxdb-hooks'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useUserDataV2 } from './useUserDataV2'
import { PluginListenerHandle } from '@capacitor/core'

const updateQry = gql`
  mutation saveToken($token: String!) {
    saveToken(token: $token)
  }
`

export function usePushNotifications() {
  const [saveToken] = useMutation(updateQry)
  const db = useRxDB()

  const { isLoggedIn } = useUserDataV2()

  useEffect(() => {
    if (isPlatform('web') || !isLoggedIn) return

    let regListener: PluginListenerHandle | undefined

    const runTokenReceivedListener = async () => {
      regListener = await FirebaseMessaging.addListener('tokenReceived', async (tokenResult) => {
        saveToken({ variables: { token: tokenResult.token } })
        await db.upsertLocal('pushToken', { token: tokenResult.token })
      })
    }

    runTokenReceivedListener()

    return () => {
      regListener?.remove()
    }
  }, [saveToken, db, isLoggedIn])
}
