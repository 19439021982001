import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'

type SearchSuggestionResponse = string[]

const suggestSearchTermsQuery = gql`
  query SuggestSearchTerms($query: String!) {
    suggestSearchTerms(searchString: $query) {
      suggestions
    }
  }
`

export const useSearchSuggestionsGQL = (searchString: string): SearchSuggestionResponse => {
  const client = useApolloClient()
  const [suggestions, setSuggestions] = useState<SearchSuggestionResponse>([])
  const typingDelayInMs = 280
  const typingMinChars = 3
  const awaitTimeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
  const [query, setQuery] = useState(searchString)

  const getSuggestions = async () => {
    const response = await client.query({
      query: suggestSearchTermsQuery,
      variables: {
        query,
      },
    })
    const result = response.data.suggestSearchTerms.suggestions
    setSuggestions(result)
  }

  useEffect(() => {
    if (searchString.length >= typingMinChars) {
      awaitTimeout(typingDelayInMs).then(() => {
        //wait for user typing
        setQuery(searchString)
      })
    }
  }, [searchString, client])

  useEffect(() => {
    if (searchString === query && query !== '') {
      // no change -> fire for suggestions
      getSuggestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return searchString ? suggestions : []
}
