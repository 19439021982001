import { gql } from '@apollo/client'

export const CREATE_NEW_TEMPLATE = gql`
  mutation CreateNewTemplate($input: CartTemplateCreateInput!) {
    createTemplate(input: $input) {
      success
      errorCode
      errorMessage
      templateId
    }
  }
`
