import { useEffect, useState } from 'react'
import { Network } from '@capacitor/network'

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(true)
  useEffect(() => {
    let listener

    const runListeners = async () => {
      Network.getStatus().then((status) => {
        setIsOnline(status.connected)
      })
      listener = await Network.addListener('networkStatusChange', (status) => {
        /*Sentry.addBreadcrumb({
          category: 'online',
          message: 'online status changed',
          data: { ...status },
        })*/ // TODO replace former Sentry-Code with DataDog
        setIsOnline(status.connected)
      })
    }

    runListeners()
    return () => {
      listener?.remove()
    }
  }, [])
  return isOnline
}
