import { CompanyToShopDomain } from '@obeta/utils/lib/domains'
import { useUserDataV2 } from './useUserDataV2'
import { useAppDomain } from './useAppDomain'

type CompanyId = keyof typeof CompanyToShopDomain
export type Company = typeof CompanyToShopDomain[CompanyId]
const companyIds = Object.keys(CompanyToShopDomain)

type Hook = () => Company
export const useCompanyDetection: Hook = () => {
  const { companyId } = useUserDataV2()
  const domain = useAppDomain()

  if (!companyId) {
    return domain
  }

  if (!companyIds.includes(companyId)) {
    throw new Error(
      `Unknown company id detected: ${companyId}\nOne of ${companyIds.join(',')} expected.`
    )
  }

  return CompanyToShopDomain[companyId]
}
