import { useSubscription } from 'observable-hooks'
import { useAppActions } from './useAppActions'
import { useHistory } from './useHistoryApi'

export const useLogoutRedirect = (route = '/') => {
  const history = useHistory()
  const { logoutFinished$ } = useAppActions()
  useSubscription(logoutFinished$, () => {
    history.push(route)
  })
}
