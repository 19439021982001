import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Modal, SvgIcon, Tab, Tabs, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useShoppingCartById } from '@obeta/data/lib/hooks/useShoppingCartById'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import styles from './AddressInfoModal.module.scss'
import { AddressManagementForm } from '../../../shoppingcart/AddressManagementModal'
import { TertiaryButton } from '../../../custom-button/CustomButton'
import { StoreManagementForm } from '../../../shoppingcart/StoreManagementModal'
import { useAddressActions } from '@obeta/data/lib/hooks/user/useAddressActions'

type Props = {
  close: () => void
  open: boolean
  shippingGroup: ShippingGroup
  userAddresses: UserAddressV2[]
}

type AddressInfoModalHeadProps = {
  close: () => void
  shippingGroupTab: ShippingGroup
  setShippingGroupTab: (value: ShippingGroup) => void
}
const AddressInfoModalHead: FC<AddressInfoModalHeadProps> = ({
  close,
  shippingGroupTab,
  setShippingGroupTab,
}) => {
  const { t } = useTranslation()
  const { user } = useUserDataV2()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '')

  if (!cart) return null

  return (
    <>
      <div className={clsx(styles.heading)}>
        <Typography variant={'headline4Bold'} color={'text.primary'} className={styles.titleWide}>
          {t('HEADER.ADDRESS_MODAL.TITLE')}
        </Typography>
        <TertiaryButton
          leftIcon={<SvgIcon component={CloseIcon} fontSize={'large'} />}
          onClick={close}
        />
      </div>
      <div className={styles.infoText}>
        <Typography>{t('HEADER.ADDRESS_MODAL.INFO', { name: cart.name })}</Typography>
      </div>
      <Tabs
        className={styles.titleUnderline}
        value={shippingGroupTab}
        onChange={(event, value: ShippingGroup) => setShippingGroupTab(value)}
        aria-label="user management page tabs"
      >
        <Tab
          value={ShippingGroup.Pickup}
          className={styles.showShippingGroupTab}
          label={t('ADDRESSES.SHIPPING_GROUP_SELECTION.PICKUP')}
        />
        <Tab
          value={ShippingGroup.Delivery}
          className={styles.showShippingGroupTab}
          label={t('ADDRESSES.SHIPPING_GROUP_SELECTION.DELIVERY')}
        />
      </Tabs>
    </>
  )
}

export const AddressInfoModal: FC<Props> = ({ close, open, shippingGroup, userAddresses }) => {
  const { user } = useUserDataV2()
  const canWriteAddress = user?.permissions.Cart_canWriteAddress
  const [shippingGroupTab, setShippingGroupTab] = useState<ShippingGroup>(shippingGroup)
  const {
    createUserAddress,
    deleteUserAddress,
    onSelectUserAddressId,
    selectedDeliveryAddressId,
    updateUserAddress,
    store,
    selectStoreId,
  } = useAddressActions()
  return (
    <Modal open={open} onClose={close}>
      <div className={styles.container}>
        {shippingGroupTab === ShippingGroup.Delivery && (
          <AddressManagementForm
            head={
              <AddressInfoModalHead
                close={close}
                shippingGroupTab={shippingGroupTab}
                setShippingGroupTab={setShippingGroupTab}
              />
            }
            createUserAddress={createUserAddress}
            deleteUserAddress={deleteUserAddress}
            onSelectUserAddressId={onSelectUserAddressId}
            selectedDeliveryAddressId={
              shippingGroup === ShippingGroup.Delivery ? selectedDeliveryAddressId ?? '' : ''
            }
            updateUserAddress={updateUserAddress}
            userAddresses={userAddresses}
            userCanWriteAddresses={Boolean(canWriteAddress)}
            onSubmit={close}
            wide
          />
        )}
        {shippingGroupTab === ShippingGroup.Pickup && (
          <StoreManagementForm
            head={
              <AddressInfoModalHead
                close={close}
                shippingGroupTab={shippingGroupTab}
                setShippingGroupTab={setShippingGroupTab}
              />
            }
            onSelectStoreId={selectStoreId}
            selectedStoreId={store?.id}
            onSubmit={close}
            wide
          />
        )}
      </div>
    </Modal>
  )
}
