import {
  ApolloClient,
  FetchResult,
  from as linkFrom,
  fromPromise,
  HttpLink,
  Observable as ZenObservable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { hasWindow } from '@obeta/utils/lib/ssr'
import { catchError, filter, map, take, tap } from 'rxjs'
import { firstValueFrom, from, of } from 'rxjs'
import { AppActions } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { isAuthenticationError } from '@obeta/utils/lib/isAuthenticationError'
import { getInMemoryCache } from './getInMemoryCache'
import { datadogRum } from '@datadog/browser-rum'
import { requestTokenIfExpired } from '@obeta/utils/lib/requestTokenIfExpired'
import { RxDatabase } from 'rxdb'

export const initApollo = (
  graphqlUrl: string,
  appActions: AppActions,
  isStrapi = false,
  db: RxDatabase
) => {
  let isRefreshing = false
  let pendingRequests: Array<() => void> = []

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
  }

  const withToken = setContext(async (request, prevContext) => {
    const tokens = await firstValueFrom(appActions.tokens$)
    let accessToken = tokens?.accessToken
    if (accessToken) {
      const updatedTokens = await requestTokenIfExpired(appActions, tokens)
      if (updatedTokens?.accessToken) {
        accessToken = updatedTokens?.accessToken
      }

      const ociUserSessionId = JSON.parse(localStorage.getItem('ociUserSessionId') as string)

      const client = await db.getLocal('client')
      const frontendClientId = client?.get('frontendClientId')

      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
          ...(ociUserSessionId && isStrapi ? { isOciAuth: true } : {}),
          ...(!isStrapi ? { 'x-frontend-client-id': frontendClientId } : {}),
        },
      }
    }
  })

  /**
   * Thing to note about onError link: if you retry op 2 time and it still fails -> 3 request won't be sent
   * TODO: set isRefreshing to false after second failed attempt and clear pending requests array.
   * otherwise token won't be refreshed until user refreshes page.
   * https://www.apollographql.com/docs/react/data/error-handling#retrying-operations
   */
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    let forward$:
      | ZenObservable<
          FetchResult<Record<string, unknown>, Record<string, unknown>, Record<string, unknown>>
        >
      | undefined
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (isAuthenticationError(error)) {
          if (!isRefreshing) {
            isRefreshing = true
            forward$ = new ZenObservable((observer) => {
              from(appActions.requestNewToken())
                .pipe(
                  take(1),
                  map(() => {
                    resolvePendingRequests()
                    return {}
                  }),
                  catchError(() => {
                    pendingRequests = []
                    return of(false)
                  }),
                  tap(() => {
                    isRefreshing = false
                  }),
                  filter((value) => Boolean(value))
                )
                .subscribe(observer)
              appActions.requestNewToken()
            }).flatMap(() => {
              return forward(operation)
            })
          } else {
            // Will only emit once promise is resolved
            forward$ = fromPromise(
              new Promise((resolve) => {
                pendingRequests.push(() => resolve({}))
              })
            )
          }
        } else {
          datadogRum.addError(error)
        }
      })
    } else if (networkError) {
      datadogRum.addError(networkError)
    }
    if (forward$ !== undefined) {
      forward$ = forward$.flatMap(() => {
        return forward(operation)
      })
    }

    return forward$
  })

  const httpLink = new HttpLink({
    uri: graphqlUrl,
  })

  const links = [errorLink, withToken, httpLink]

  return new ApolloClient({
    ssrMode: !hasWindow(), // set to true for SSR
    link: linkFrom(links),
    cache: getInMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  })
}
