import { catchError, retry, concatMap, Observable, of, defer, mergeMap } from 'rxjs'
import {
  OrderActionTypes,
  UpdateOrderMetaDataGraphQLAction,
  UpdateOrderMetaDataGraphQLResult,
  UpdateOrderMetaDataGraphQLResultAction,
} from '../actions/order-actions'

import { Pagination, SearchParams } from '@obeta/models/lib/models/Meta/Meta'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import dayjs from 'dayjs'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { UPDATE_ORDER_META_DATA_MUTATION } from '../entities/updateOrderMetaDataMutationProps'

export const getDefaultDateSpan = () => ({
  start: dayjs().subtract(6, 'months'),
  end: dayjs(),
  text: 'letztes ½ Jahr',
})

export enum SEARCH_TYPE {
  ALL_ORDERS = '1',
  BACK_ORDERS = '2',
  RETURN_SHIPMENTS = '3',
}

export const ORDER_SORT_IDS: { [key: string]: number } = {
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERDATE_DESC': 6,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERDATE_ASC': 5,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_ASC': 1,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_DESC': 2,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.COMMISSION_ASC': 3,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.COMMISSION_DESC': 4,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERID_ASC': 7,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERID_DESC': 8,
}

export const RETURNSHIPPMENTS_SORT_IDS: { [key: string]: number } = {
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.RETURN_NO_ASC': 1,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.RETURN_NO_DESC': 2,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.DATE_ASC': 3,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.DATE_DESC': 4,
}

export const defaultSortType =
  ORDER_SORT_IDS['ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_ASC']

export const buildUrl = (
  params: SearchParams,
  pagination: Pagination,
  searchType: string,
  page: number
): string => {
  return (
    'orders/' +
    (params.startDate ? params.startDate : 0) +
    '/' +
    (params.endDate ? params.endDate : 0) +
    '/' +
    (params.sortOption ? ORDER_SORT_IDS[params.sortOption] : defaultSortType) +
    '/' +
    page +
    '/' +
    pagination.itemsPerPage +
    '/' +
    (params.supplier ? params.supplier.id : '0') +
    '/' +
    searchType
  )
}

export const updateOrderMetaDataGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateOrderMetaDataGraphQLAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.UpdateOrderMetaDataGraphQL),
      concatMap((action: UpdateOrderMetaDataGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate({
            mutation: UPDATE_ORDER_META_DATA_MUTATION,
            variables: {
              input: action.input,
            },
            fetchPolicy: 'no-cache',
          })
          return response.data?.updateOrderMetaData
        }).pipe(
          retry(1),
          mergeMap((result: UpdateOrderMetaDataGraphQLResultAction) =>
            of(
              UpdateOrderMetaDataGraphQLResult(
                result.success,
                result.errorCode,
                result.errorMessage,
                result.orderId
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              updateOrderMetaDataGraphQLEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(UpdateOrderMetaDataGraphQLResult(false, '', '', error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + updateOrderMetaDataGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(UpdateOrderMetaDataGraphQLResult(false, '', '', error))
      })
    )
}

export const initAllOrderEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [updateOrderMetaDataGraphQLEffect(db, apolloClient)]
}
