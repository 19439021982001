import md5 from 'md5'

export const withMd5Id = <Data extends { id?: string }>(data: Data): Data & { id: string } => {
  const cpy = { ...data }
  delete cpy.id

  const id = md5(JSON.stringify(cpy))

  return {
    ...data,
    id,
  }
}
