import { OxomiMetaData } from '@obeta/models/lib/models/Oxomi'

export interface IParams {
  supplierId: string
  oxomiId: string
  oxomiMetaData: OxomiMetaData
}

const genParams = (p: IParams) => {
  const { supplierId, oxomiId, oxomiMetaData } = p

  return `supplierNumber=${supplierId}&item=${oxomiId}&portal=${oxomiMetaData.portal}&user=obeta&accessToken=${oxomiMetaData.token}`
}

export const genOxomiAttachmentsUrl = (p: IParams) => {
  const params = genParams(p)
  return `https://oxomi.com/service/json/item/attachments?${params}`
}

export const genOxomiImagesUrl = (p: IParams) => {
  const params = genParams(p)
  return `https://oxomi.com/service/json/item/images?${params}`
}
