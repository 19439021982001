import { Category } from '@obeta/models/lib/models/Category'
import { Facet, FacetElement, HierarchicalFacetElement } from '@obeta/models/lib/models/Search'

export type CategoryType = 'obetaCatalogIds' | 'dehaCatalogIds'

export interface CategoryFacetsResult {
  obetaCatalog: HierarchicalFacetElement[] | undefined
  topLevelCategories: FacetElement[]
}

export const constructCategoryFacet = (
  categoryLevel: number | null | undefined,
  type: CategoryType = 'obetaCatalogIds'
) => {
  let level = 0
  const facets: string[] = []
  if (typeof categoryLevel === 'number') {
    level = categoryLevel
    facets.push(`${type}.lvl${level}`, `${type}.lvl${level + 1}`)
  } else {
    facets.push(`${type}.lvl${level}`)
  }

  const createCatalog = (facets: Facet[]) => {
    const topLevelFacets = facets.find((facet) => facet.name === `${type}.lvl${level}`)
    const topLevelCategories = topLevelFacets?.values || []
    const topLevelFacet = topLevelFacets?.values[0]
    const subLevelFacets = facets.find((facet) => facet.name === `${type}.lvl${level + 1}`)
    const subLevelFacetValues = subLevelFacets?.values || []

    const obetaCatalog: HierarchicalFacetElement[] | undefined = topLevelFacet && [
      { ...topLevelFacet, subFacets: subLevelFacetValues },
    ]

    return {
      obetaCatalog,
      topLevelCategories,
    }
  }

  return {
    facets,
    createCatalog,
  }
}

export const constructCategoryFilter = (
  category?: Category,
  type: CategoryType = 'obetaCatalogIds'
) => {
  if (!category || category.id === '0') {
    return null
  }

  if (category.id.startsWith('hid')) {
    return `${type}.lvl0:"${category.id.slice(3)}"`
  }

  if (typeof category.level !== 'number') {
    return null
  }

  return `${type}.lvl${category.level}:"${category.id}"`
}
