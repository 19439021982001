import { useUserDataV2 } from './useUserDataV2'
import { useLogin } from './useLogin'
import { useEffect, useRef } from 'react'

export const useAuthenticatedRoute = () => {
  const { isLoggedIn, user } = useUserDataV2()
  const { startLogin } = useLogin()
  const hasLoggedInRef = useRef(isLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      hasLoggedInRef.current = isLoggedIn
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!hasLoggedInRef.current && user !== null) {
      const login = async () => {
        await startLogin()
      }
      login()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user])
}
