import React, { FC, ReactNode, useEffect, useState } from 'react'
import styles from './AddressManagementModal.module.scss'
import { FormControl, Modal, RadioGroup, SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as AddIcon } from 'assets/icon/designsystem/add.svg'
import { UserAddressCard } from './UserAddressCard'
import { UserAddressType, UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { CheckoutAddAddressOverlay } from './CheckoutAddAddressOverlay'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { DarkButton, SecondaryLightButton, TertiaryButton } from '../custom-button/CustomButton'
import { clsx } from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Hooks
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useLocalSearchControlled } from '@obeta/data/lib/hooks/useLocalSearchControlled'

// UI
import { SearchField } from '../search-field/SearchField'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'

interface Props {
  isOpen: boolean
  createUserAddress: (address: AddressV2, customName: string) => void
  deleteUserAddress: (addressId: string) => void
  updateUserAddress: (addressId: string, address: AddressV2, customName: string) => void
  selectedDeliveryAddressId: string
  onSelectUserAddressId: (addressId: string) => void
  setCheckoutAddressSearchBox: (i: boolean) => void
  userAddresses: UserAddressV2[]
  userCanWriteAddresses: boolean
}
const searchKeys = [
  'customName',
  'address.name1',
  'address.name2',
  'address.street',
  'address.zipCode',
  'address.city',
  'address.country',
]

export const AddressManagementForm: FC<
  Omit<Props, 'isOpen' | 'setCheckoutAddressSearchBox'> & {
    head: ReactNode
    onSubmit?: () => void
    setCheckoutAddressSearchBox?: (value: boolean) => void
    wide?: boolean
  }
> = ({
  createUserAddress,
  deleteUserAddress,
  updateUserAddress,
  selectedDeliveryAddressId,
  onSelectUserAddressId,
  setCheckoutAddressSearchBox,
  userAddresses,
  userCanWriteAddresses,
  wide,
  head,
  onSubmit,
}) => {
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { search } = useLocalSearchControlled()

  const keyboardHeight = useKeyboardHeight()

  const sortAddresses = (adresses: UserAddressV2[]) => {
    return adresses.sort((address1, address2) => {
      const isMain = (address) => address.addressType === UserAddressType.MainAddress
      const isSpecial = (address) => address.addressType === UserAddressType.SpecialTour

      const sortOrder = address1.address.name1 < address2.address.name1 ? -1 : 1
      const bothMain = isMain(address1) && isMain(address2)
      const bothSpecial = isSpecial(address1) && isSpecial(address2)

      if (bothMain || bothSpecial) return sortOrder

      if (isMain(address2)) return 1
      if (isMain(address1)) return -1
      if (isSpecial(address2)) return 1
      if (isSpecial(address1)) return -1

      return sortOrder
    })
  }

  const [filteredUserAddresses, setFilteredUserAddresses] = useState<UserAddressV2[]>(
    sortAddresses(userAddresses)
  )
  const [showAddAddressOverlay, setShowAddAddressOverlay] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [temporarySelection, setTemporarySelection] = useState(selectedDeliveryAddressId)
  const { t } = useTranslation()

  userAddresses.forEach(function (userAddress) {
    if (userAddress.addressType === UserAddressType.MainAddress) {
      userAddress.customName = t('ADDRESSES.MAIN_ADDRESS')
    }
    if (userAddress.addressType === UserAddressType.DeliveryAddress) {
      userAddress.customName = t('ADDRESSES.DELIVERY_ADDRESS')
    }
    if (userAddress.addressType === UserAddressType.SpecialTour) {
      userAddress.customName = t('ADDRESSES.SPECIAL_TOUR')
    }
  })

  useEffect(() => {
    setTemporarySelection(selectedDeliveryAddressId)
  }, [selectedDeliveryAddressId])

  // Search sorted user addresses by search text and keys
  useDebouncedEffect(
    () => {
      setFilteredUserAddresses(search(sortAddresses(userAddresses), searchText, searchKeys))
    },
    [searchKeys, searchText, userAddresses],
    500
  )

  const handleAddAddress = () => {
    setShowAddAddressOverlay(true)
  }

  const handleSelectAddress = (event) => {
    setTemporarySelection(event.target.value)
  }

  const handleSubmit = () => {
    onSelectUserAddressId(temporarySelection)
    // close Address modal
    if (setCheckoutAddressSearchBox) setCheckoutAddressSearchBox(false)
    if (onSubmit) onSubmit()
  }
  return (
    <div
      className={clsx(
        styles.outerGrid,
        mobile && styles.outerGridMobileSpecific,
        tabletAll && styles.outerGridTabletSpecific,
        desktop && styles.outerGridDesktopSpecific,
        wide && styles.wide
      )}
    >
      <div className={styles.headerBox}>
        {head}
        <div className={styles.searchBoxWrapper}></div>
        <SearchField
          id="searchBox"
          placeholder={t('ADDRESSES.SEARCH')}
          value={searchText}
          onChange={setSearchText}
          onReset={() => setSearchText('')}
        />
      </div>

      <div className={styles.gridHoldingUserAddressGridAndDivider}>
        <div
          className={clsx(
            styles.overlaysScrollbar,
            filteredUserAddresses.length < 5 ? styles.scrollbarShort : styles.scrollbarLong
          )}
        >
          <FormControl className={styles.formControl}>
            <RadioGroup
              defaultValue={temporarySelection}
              className={styles.radioGroup}
              onChange={handleSelectAddress}
              value={temporarySelection}
            >
              <div className={styles.addressCardGrid}>
                {sortAddresses(filteredUserAddresses).map((userAddress: UserAddressV2) => {
                  return (
                    <div key={userAddress.addressId} className={styles.addressCard}>
                      <UserAddressCard
                        userAddress={userAddress}
                        createUserAddress={createUserAddress}
                        deleteUserAddress={deleteUserAddress}
                        updateUserAddress={updateUserAddress}
                        userCanWriteAddresses={userCanWriteAddresses}
                      />
                    </div>
                  )
                })}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.dividerWrapper}>
          <LightGrayDivider />
        </div>
      </div>
      <div className={styles.dividerWrapperBottom}>
        <LightGrayDivider />
      </div>
      <div className={styles.footerGrid} style={{ paddingBottom: keyboardHeight }}>
        <SecondaryLightButton
          disabled={!userCanWriteAddresses}
          leftIcon={<SvgIcon component={AddIcon} fontSize={'large'} />}
          onClick={handleAddAddress}
        >
          {t('SHOPPING_CART.CHECKOUT.ADD_DELIVERY_ADDRESS')}
        </SecondaryLightButton>
        <DarkButton onClick={handleSubmit}>
          {t('SHOPPING_CART.CHECKOUT.SELECT_DELIVERY_ADDRESS')}
        </DarkButton>
      </div>
      {showAddAddressOverlay && createUserAddress && (
        <CheckoutAddAddressOverlay
          createUserAddress={createUserAddress}
          showAddAddressOverlay={showAddAddressOverlay}
          setShowAddAddressOverlay={setShowAddAddressOverlay}
        />
      )}
    </div>
  )
}

export const AddressManagementModal: FC<Props> = (props) => {
  const {
    isOpen,
    createUserAddress,
    deleteUserAddress,
    updateUserAddress,
    selectedDeliveryAddressId,
    onSelectUserAddressId,
    setCheckoutAddressSearchBox,
    userAddresses,
    userCanWriteAddresses,
  } = props
  const { t } = useTranslation()

  const handleClose = () => {
    setCheckoutAddressSearchBox(false)
  }

  return (
    <Modal
      open={isOpen || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <AddressManagementForm
        head={
          <div className={clsx(styles.heading)}>
            <Typography variant={'headline4Bold'} color={'text.primary'}>
              {t('ADDRESSES.ADDRESS_MANAGEMENT')}
            </Typography>
            <TertiaryButton
              leftIcon={<SvgIcon component={CloseIcon} fontSize={'large'} />}
              onClick={handleClose}
            />
          </div>
        }
        createUserAddress={createUserAddress}
        deleteUserAddress={deleteUserAddress}
        updateUserAddress={updateUserAddress}
        selectedDeliveryAddressId={selectedDeliveryAddressId}
        onSelectUserAddressId={onSelectUserAddressId}
        setCheckoutAddressSearchBox={setCheckoutAddressSearchBox}
        userAddresses={userAddresses}
        userCanWriteAddresses={userCanWriteAddresses}
      />
    </Modal>
  )
}
