import { createContext, useState, useEffect, useRef, useContext } from 'react'
import { MainStockId } from '@obeta/utils/lib/variables'
import { GetCurrentWarehouseQuery, GetCurrentWarehouseQueryVariables } from '@obeta/schema'
import { useApolloClient } from '@apollo/client'
import { useUserDataV2 } from '../hooks/useUserDataV2'
import { useShoppingCartById } from '../hooks/useShoppingCartById'
import { warehouseQuery } from '../queries/warehouse'

const WarehouseContext = createContext({ warehouseId: MainStockId })

export const WarehouseProvider = ({ children }) => {
  const [warehouseId, setWarehouseId] = useState(MainStockId)
  const client = useApolloClient()
  const { user } = useUserDataV2()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '')
  const prevZipCode = useRef(cart?.shippingData.deliveryAddress.zipCode)

  useEffect(
    () => {
      async function fetchWarehouse(cartId) {
        const res = await client.query<GetCurrentWarehouseQuery, GetCurrentWarehouseQueryVariables>(
          {
            query: warehouseQuery,
            variables: { input: { cartId: cartId } },
          }
        )
        if (warehouseId !== res.data?.getCurrentWarehouse) {
          prevZipCode.current = cart?.shippingData.deliveryAddress.zipCode
          setWarehouseId(
            res.data?.getCurrentWarehouse !== undefined
              ? res.data?.getCurrentWarehouse
              : MainStockId
          )
        }
      }
      if (
        cart &&
        !cart.isUpdating &&
        prevZipCode.current !== cart?.shippingData.deliveryAddress.zipCode
      ) {
        fetchWarehouse(cart.id)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart?.isUpdating, cart?.id]
  )

  return (
    <WarehouseContext.Provider value={{ warehouseId: warehouseId }}>
      {children}
    </WarehouseContext.Provider>
  )
}

export const useWarehouseContext = () => {
  return useContext(WarehouseContext)
}
