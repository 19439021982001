/**
 * Split array on chunks by chunkSize
 * @param array
 * @param chunkSize
 * @returns
 */
export const chunks = <T>(array: T[], chunkSize: number) => {
  const c: T[][] = []

  const numberOfChunks = Math.ceil(array.length / chunkSize)
  for (let i = 0; i < numberOfChunks; i += 1) {
    c.push(array.slice(i * chunkSize, (i + 1) * chunkSize))
  }

  return c
}
