import { Category } from '@obeta/models/lib/models/Category'
import React from 'react'
import { IListOption, IToolsList } from '../../types'

export interface IDropdownMenuTriggerProps {
  title?: React.ReactNode
  /**
   * @default true
   */
  withBorder?: boolean
  /**
   * @default MenuItemColor.White
   */
  color?: MenuItemColor
  /**
   * @default false
   */
  hideArrow?: boolean

  /**
   * @default span ( TODO: leave only button type and check that iw works correct in all places )
   */
  triggerComponent?: 'span' | 'button'

  classes?: Record<'titleContainerClassName', string>
}
export interface IDropdownMenuItemProps {
  header?: React.ReactNode
  footer?: React.ReactNode
  onMenuItemClick?: (toolsListOption: IListOption | Category) => void
  options?: (IListOption | Category)[]
  subList?: IToolsList
  /**
   * @default false
   */
  isMobileDropdown?: boolean

  /**
   * @default 0
   */
  additionalOffset?: number
  /**
   * @default true
   */
  withSeparator?: boolean
  keepDefaultLeftPosition?: boolean
  topSpacingToAnchorEl?: string
  renderClosedTrigger?: (onClick: React.MouseEventHandler) => React.ReactNode
  renderOpenedTrigger?: (onClick: React.MouseEventHandler) => React.ReactNode
}

export enum MenuItemColor {
  White = 'white',
  Black = 'black',
}
