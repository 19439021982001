import { useState, useEffect } from 'react'
import { useRxDB } from 'rxdb-hooks'

export const useFrontendClientId = () => {
  const [frontendClientId, setFrontendClientId] = useState(null)
  const db = useRxDB()

  useEffect(() => {
    const getFrontendClientId = async () => {
      const client = await db.getLocal('client')
      const id = client?.get('frontendClientId')
      setFrontendClientId(id)
    }

    getFrontendClientId()
  }, [db])

  return frontendClientId
}
