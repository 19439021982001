import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ReactComponent as ArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import { ReactComponent as CheckboxBorderIcon } from 'assets/icon/designsystem/check_box_outline_blank.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icon/designsystem/check_box.svg'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'

const theme = createTheme({
  spacing: (factor: number) => `${factor}rem`,
  // mobile unter 632px, tablet zwischen 632 px und 1199 pixel, desktop ab 1200 px
  breakpoints: {
    values: {
      xs: parseInt(scssVariables.screenXsMin),
      sm: parseInt(scssVariables.screenSmMin),
      md: parseInt(scssVariables.screenMdMin),
      lg: parseInt(scssVariables.screenLgMin),
      xl: parseInt(scssVariables.screenXlMin),
    },
  },
  palette: {
    primary: {
      main: scssVariables.red100pct,
    },
    secondary: {
      main: scssVariables.darkGray,
      dark: scssVariables.darkGray34K,
      light: scssVariables.lightGray,
    },
    warning: {
      main: scssVariables.orange,
    },
    success: {
      main: scssVariables.green,
    },
    white: {
      main: scssVariables.white,
    },
    text: {
      primary: scssVariables.darkGray,
      secondary: scssVariables.darkGray55K,
      disabled: scssVariables.darkGray34K,
    },
    blackVariant: {
      black: scssVariables.black,
      black10pct: scssVariables.black10pct,
      black15pct: scssVariables.black15pct,
    },
    grayVariant: {
      dark: scssVariables.darkGray,
      dark55K: scssVariables.darkGray55K,
      dark34K: scssVariables.darkGray34K,
      dark21K: scssVariables.darkGray21K,
      light: scssVariables.lightGray,
      light8K: scssVariables.lightGray8K,
      light5K: scssVariables.lightGray5K,
      light3K: scssVariables.lightGray3K,
    },
    orangeVariant: {
      orange: scssVariables.orange,
      orange89pct: scssVariables.orange89pct,
      orange55pct: scssVariables.orange55pct,
      orange34pct: scssVariables.orange34pct,
    },
    redVariant: {
      red100pct: scssVariables.red100pct,
      red89pct: scssVariables.red89pct,
      red55pct: scssVariables.red55pct,
      red34pct: scssVariables.red34pct,
    },
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontSize: '2.5rem',
      lineHeight: '3rem',
      fontWeight: 900,
      letterSpacing: '0.025rem',
    },
    h2: {
      fontSize: '2.125rem',
      lineHeight: '2.375rem',
      fontWeight: 400,
      letterSpacing: '0.025rem',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      fontWeight: 900,
      letterSpacing: '0.025rem',
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      letterSpacing: '0.025rem',
    },
    subTitle: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      fontFamily: 'Lato',
    },
    boldText: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    body: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      fontFamily: 'Lato',
    },
    bodyBold: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    smallText: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'Lato',
    },
    smallTextLineThrough: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'Lato',
      textDecoration: 'line-through',
    },
    smallTextBold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    xSmallText: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'Lato',
    },
    xSmallTextLineThrough: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'Lato',
      textDecoration: 'line-through',
    },
    xSmallTextBold: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    headline3Bold: {
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
    },
    headline2Bold: {
      fontFamily: 'Lato',
      fontWeight: 900,
      fontSize: '2.125rem',
      lineHeight: '2.375rem',
      letterSpacing: '0.025rem',
    },
    headline4Bold: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.025rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          line-height: normal;
        }
      `,
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          /**
           * These are custom "variants" for TextField.
           * Unfortunately we can't create actual variants for TextField: mui doesn't allow this
           * I decided to use workaround: className.
           * Unfortunately className can't be typed (it always will be narrowed to "string")
           * TODO: If mui adds support for custom TextField variants
           * styleOverrides should be refactored for TextField
           */
          '&.outlined-lightGray': {
            '& fieldset': {
              borderColor: scssVariables.lightGray,
            },
          },
          '&.outlined-darkGray34K': {
            '& fieldset': {
              borderColor: scssVariables.darkGray34K,
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: scssVariables.red100pct,
          },
          textTransform: 'none',
          fontFamily: 'Lato',
          fontSize: '0.875rem',
          fontWeight: 'bold',
          padding: '0',
          marginRight: '1.5rem',
          color: scssVariables.darkGray,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          '&.MuiTabs-indicator': {
            backgroundColor: scssVariables.red100pct,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.3,
          },
          '&:active': {
            opacity: 0.55,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'tertiary' },
          style: {
            height: '1.25rem',
            color: scssVariables.darkGray,
            backgroundColor: 'none',
            ':outlined': {
              backgroundColor: 'none',
            },
            ':hover': {
              backgroundColor: 'transparent',
              boxShadow: '0',
            },
            ':active': {
              backgroundColor: 'none',
              transitionDuration: '0s',
            },
            ':disabled': {
              backgroundColor: 'none',
              color: scssVariables.darkGray55K,
            },
            icon: {
              fontSize: 20,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: 700,
          textAlign: 'center',
          padding: '0.375rem 0.75rem 0.375rem 0.75rem',
        },
        contained: {
          boxShadow: 'none',
          height: '2rem',
          ':hover': {
            boxShadow: '4px 4px 8px 0px #0000001A',
          },
          '&.Mui-disabled': {
            backgroundColor: scssVariables.darkGray34K,
            color: scssVariables.white,
          },
        },
        containedPrimary: {
          backgroundColor: scssVariables.red100pct,
          ':hover': {
            backgroundColor: scssVariables.red100pct,
          },
          ':active': {
            backgroundColor: scssVariables.red89pct,
          },
        },
        containedSecondary: {
          color: scssVariables.white,
          backgroundColor: scssVariables.darkGray,
          ':hover': {
            backgroundColor: scssVariables.darkGray,
          },
          ':active': {
            backgroundColor: scssVariables.darkGray55K,
            transitionDuration: '0s',
          },
        },
        outlined: {
          borderColor: scssVariables.darkGray,
          color: scssVariables.darkGray,
          backgroundColor: scssVariables.white,
          height: '2rem',
          ':hover': {
            boxShadow: '4px 4px 8px 0px #0000001A',
            borderColor: scssVariables.darkGray,
            color: scssVariables.darkGray,
            backgroundColor: scssVariables.white,
          },
          ':active': {
            borderColor: scssVariables.darkGray55K,
            color: scssVariables.darkGray55K,
            transitionDuration: '0s',
          },
          '&.Mui-disabled': {
            borderColor: scssVariables.lightGray,
            color: scssVariables.darkGray34K,
          },
        },
        iconSizeSmall: {
          '& > *:first-of-type': {
            // overwrites default 18px set by MUI https://github.com/mui-org/material-ui/blob/79ebf71d31cca2c73143891960ea54ec1132bdeb/packages/material-ui/src/Button/Button.js#L243
            // NIT: to avoid console warning about unsafe when doing server-side rendering we use first-of-type instead first-child
            // Redefines the font of buttons that use "small" props
            fontSize: 20,
          },
        },
        startIcon: {
          ':hover': {
            color: scssVariables.dark,
          },
        },
        endIcon: {
          ':hover': {
            color: scssVariables.darkGray55K,
          },
        },
      },
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxBorderIcon color={scssVariables.darkGray34K} />,
        checkedIcon: <CheckboxIcon color={scssVariables.red100pct} />,
        disableRipple: true,
        indeterminateIcon: <CheckboxBorderIcon />,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // equivalent to boldText
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: 700,
          fontFamily: 'Lato',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        icon: {
          transform: 'scale(0.8333)',
          top: 'calc(14%)',
          color: scssVariables.darkGray,
        },
        iconOpen: {
          transform: 'scale(0.8333)',
          top: 'calc(14%)',
          color: scssVariables.darkGray,
        },
      },

      defaultProps: {
        IconComponent: ArrowDownIcon,
        fullWidth: false,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          '&.Mui-selected': {
            backgroundColor: scssVariables.white,
            '&:hover': {
              backgroundColor: scssVariables.lightGray5K,
            },
            '&.Mui-focusVisible': {
              backgroundColor: scssVariables.white,
              '&:hover': {
                backgroundColor: scssVariables.lightGray5K,
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          backgroundColor: scssVariables.white,
          selected: {
            backgroundColor: scssVariables.white,
            color: scssVariables.lightGray5K,
          },
          '&:focused': {
            backgroundColor: scssVariables.white,
            '&:hover': {
              backgroundColor: scssVariables.lightGray5K,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: scssVariables.white,
          height: '2rem',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: scssVariables.darkGray,
            borderWidth: '1px',
          },
        },
        input: {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
      defaultProps: {
        color: 'secondary', // TODO Update props to allow hex values to be provided as well
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        disableFocusListener: true,
        disableTouchListener: true,
      },
      styleOverrides: {
        tooltipArrow: {
          boxShadow: '0px 0px 15px 0px #00000026',
          backgroundColor: scssVariables.white,
          color: scssVariables.darkGray,
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 400,
          padding: '0.5rem',
        },
        arrow: {
          color: scssVariables.white,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: 'none',
        },
        elevation1: {
          //correspondes to Dropshadow 1 in Figma
          boxShadow: '0px 0px 5px 0px ' + scssVariables.black10pct,
        },
        elevation2: {
          // corresponds to Dropshadow 2 in Figma
          boxShadow: '0 0 15px 0 ' + scssVariables.black15pct,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '0.375rem',
          '&:hover': {
            backgroundColor: scssVariables.lightGray,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: scssVariables.darkGray,
        variant: 'body',
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeMedium': {
            fontSize: '1.25rem',
          },
        },
        /* Styles applied to the root element if `fontSize="small"`. */
        fontSizeSmall: {
          fontSize: '1rem',
        },
        /* Styles applied to the root element if `fontSize="large"`. */
        fontSizeLarge: {
          fontSize: '1.5rem',
        },
      },
    },
  },
})

export const MuiThemeProvider: React.FC = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
