import { EntityDescriptor, ShoppingCartPricesV2 } from '@obeta/models/lib/models/Db/index'

export const cartsv2PricesEntity: EntityDescriptor<ShoppingCartPricesV2> = {
  name: 'cartsv2prices',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'cartsv2prices',
    version: 2,
    description: 'a document representing a cartsv2prices entry',
    type: 'object',
    primaryKey: 'cartId',
    properties: {
      cartId: {
        type: 'string',
        maxLength: 100,
      },
      prices: {
        type: 'object',
      },
    },
  },
}
