interface LoginAction {
  type: LoginActionTypes
}

export enum LoginActionTypes {
  CompanyRequest = '[Login] Send Company Request',
  CompanyRequestSuccess = '[Login] Successful Company Request',
  CompanyRequestError = '[Login] Failed Company Request',
  LoginInitializeData = '[Login] Initialize Data',
}

export interface CompanyRequestAction extends LoginAction {
  readonly type: LoginActionTypes.CompanyRequest
  userId: string
}

export const companyRequest = (userId: string): CompanyRequestAction => ({
  type: LoginActionTypes.CompanyRequest,
  userId,
})

export interface CompanyRequestSuccessAction extends LoginAction {
  readonly type: LoginActionTypes.CompanyRequestSuccess
  companies: string[]
}

export const companyRequestSuccess = (companies: string[]): CompanyRequestSuccessAction => ({
  type: LoginActionTypes.CompanyRequestSuccess,
  companies,
})

export interface CompanyRequestErrorAction extends LoginAction {
  readonly type: LoginActionTypes.CompanyRequestError
  error: Error
}

export const companyRequestError = (error: Error): CompanyRequestErrorAction => ({
  type: LoginActionTypes.CompanyRequestError,
  error,
})

export interface LoginInitializeDataAction extends LoginAction {
  readonly type: LoginActionTypes.LoginInitializeData
}

export const loginInitializeData = (): LoginInitializeDataAction => ({
  type: LoginActionTypes.LoginInitializeData,
})

export type LoginActionsUnion =
  | CompanyRequestAction
  | CompanyRequestSuccessAction
  | CompanyRequestErrorAction
  | LoginInitializeDataAction
