import { useDispatch } from 'react-redux'
import { updateUserSettings } from '../actions'
import { useUserDataV2 } from './useUserDataV2'

export const useSelectShoppingCart = (onSelect?: () => void) => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useUserDataV2()

  return (cartId: string) => {
    if (isLoggedIn) {
      dispatch(
        updateUserSettings({
          defaultCartId: cartId,
        })
      )
      onSelect?.()
    }
  }
}
