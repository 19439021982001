import React, { useState, FC, ReactNode } from 'react'
import styles from './StoreManagementModal.module.scss'
import { FormControl, Modal, RadioGroup, SvgIcon, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import { CheckoutStoreCard } from './CheckoutStoreCard'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { StoreV2, StoreV2Types } from '@obeta/models/lib/models/Stores/StoreV2'
import { useCheckIsCashCustomer } from '@obeta/data/lib/hooks/useCheckIsCashCustomer'
import { useStoreFinder } from '@obeta/data/lib/hooks/useStoreFinder'
import { useTranslation } from 'react-i18next'
import { clsx } from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Hooks
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useLocalSearchControlled } from '@obeta/data/lib/hooks/useLocalSearchControlled'

// UI
import { SearchField } from '../search-field/SearchField'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'

interface Props {
  checkoutStoreSearchBox: boolean
  selectedShippingType?: ShippingType
  selectedStoreId: string
  onSelectStoreId: (storeId: string) => void
  setCheckoutStoreSearchBox: (i: boolean) => void
}
const searchKeys = [
  'address.name1',
  'address.name2',
  'address.street',
  'address.zipCode',
  'address.city',
]

export const StoreManagementForm: FC<{
  shippingType?: ShippingType
  selectedStoreId?: string
  onSelectStoreId: (storeId: string) => void
  setCheckoutStoreSearchBox?: (i: boolean) => void
  head: ReactNode
  wide?: boolean
  onSubmit?: () => void
}> = ({
  shippingType,
  selectedStoreId,
  onSelectStoreId,
  setCheckoutStoreSearchBox,
  head,
  wide,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { search } = useLocalSearchControlled()
  const { stores: customStores } = useStoreFinder({})
  const keyboardHeight = useKeyboardHeight()

  const isCashCustomer = useCheckIsCashCustomer()
  const [filteredStores, setFilteredStores] = useState<StoreV2[]>(customStores)
  const [searchText, setSearchText] = useState<string>('')
  const [storeIdChosenViaRadioButton, setStoreIdChosenViaRadioButton] = useState(selectedStoreId)

  // Search stores by search text and keys
  useDebouncedEffect(
    () => {
      setFilteredStores(search(customStores, searchText, searchKeys))
    },
    [customStores, searchKeys, searchText],
    500
  )
  const handleSelectStore = (event) => {
    setStoreIdChosenViaRadioButton(event.target.value)
  }

  const handleSubmit = () => {
    if (storeIdChosenViaRadioButton) onSelectStoreId(storeIdChosenViaRadioButton)
    // close StoreManagementModal
    if (setCheckoutStoreSearchBox) setCheckoutStoreSearchBox(false)
    if (onSubmit) onSubmit()
  }

  return (
    <div
      className={clsx(
        styles.outerGrid,
        mobile && styles.outerGridMobileSpecific,
        tabletAll && styles.outerGridTabletSpecific,
        desktop && styles.outerGridDesktopSpecific,
        wide && styles.wide
      )}
    >
      <div className={styles.headerBox}>
        {head}
        <div className={styles.searchBoxWrapper}>
          <SearchField
            id="searchBox"
            placeholder={t('STORES.SEARCH_NAME_ADDRESS')}
            value={searchText}
            onChange={setSearchText}
            onReset={() => setSearchText('')}
          />
        </div>
      </div>

      <div className={styles.gridHoldingUserAddressGridAndDivider}>
        <div className={styles.overlaysScrollbar}>
          <FormControl className={styles.formControl}>
            <RadioGroup
              defaultValue={selectedStoreId}
              className={styles.radioGroup}
              onChange={handleSelectStore}
            >
              <div className={styles.storeCardGrid}>
                {filteredStores.map((store: StoreV2) => {
                  if (store.type === StoreV2Types.renzbox && isCashCustomer) {
                    return false
                  }
                  if (
                    (store.type === StoreV2Types.renzbox || store.type === StoreV2Types.pickup) &&
                    shippingType === ShippingType.ExpressPickup
                  ) {
                    return false
                  }
                  return (
                    <div key={store.id} className={styles.storeCard}>
                      <CheckoutStoreCard storeV2={store} />
                    </div>
                  )
                })}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.dividerWrapper}>
          <LightGrayDivider />
        </div>
      </div>
      <div className={styles.dividerWrapperBottom}>
        <LightGrayDivider />
      </div>
      <div className={styles.footerGrid} style={{ paddingBottom: keyboardHeight }}>
        <DarkButton fullWidth={false} onClick={handleSubmit}>
          {t('STORES.SUBMIT')}
        </DarkButton>
      </div>
    </div>
  )
}

export const StoreManagementModal: React.FC<Props> = (props) => {
  const {
    checkoutStoreSearchBox,
    selectedStoreId,
    onSelectStoreId,
    selectedShippingType,
    setCheckoutStoreSearchBox,
  } = props
  const { t } = useTranslation()

  const handleClose = () => {
    if (setCheckoutStoreSearchBox) {
      setCheckoutStoreSearchBox(false)
    }
  }

  return (
    <Modal
      open={checkoutStoreSearchBox || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StoreManagementForm
        head={
          <div className={styles.heading}>
            <Typography variant={'h4'} color={'text.primary'}>
              {t('STORES.SITE_SELECTION')}
            </Typography>
            <TertiaryButton
              leftIcon={<SvgIcon component={CloseIcon} fontSize={'large'} />}
              onClick={handleClose}
            />
          </div>
        }
        shippingType={selectedShippingType}
        onSelectStoreId={onSelectStoreId}
        setCheckoutStoreSearchBox={setCheckoutStoreSearchBox}
        selectedStoreId={selectedStoreId}
      />
    </Modal>
  )
}
