import {
  AddCartTemplateItemsToCartTemplateInput,
  AddOrderItemsToCartTemplateInput,
  CartTemplate,
  CartTemplateAddItemsInput,
  CartTemplateAddOfferInput,
  CartTemplateItem,
  CartTemplateDeleteInput,
  CartTemplateDuplicateInput,
  CartTemplateUpdateInput,
  CartTemplateAddToCartTemplateInput,
  MultiSelectionSearch,
  ProductAggregate,
  AddOrderItemsInArrearsToCartTemplateInput,
} from '@obeta/schema'
import {
  CartTemplateForDetailsPage,
  MaybeCompleteCartTemplateDetailsItem,
} from '@obeta/models/lib/schema-models/cart-template-details'

interface TemplateAction {
  type: TemplateActionTypes
}

export enum TemplateActionTypes {
  AddArticleToTemplateGraphQL = '[OrderTemplates] Add Article to Template via GraphQL',
  AddArticleToTemplateGraphQLResult = '[OrderTemplates] Add Article to Template via GraphQL Result',
  AddCartTemplatesToCartTemplateGraphQL = '[OrderTemplates] Add CartTemplates to CartTemplate via GraphQL',
  AddCartTemplatesToCartTemplateGraphQLResult = '[OrderTemplates] Add CartTemplates to CartTemplate via GraphQL Result',
  AddCartTemplateItemsToCartTemplateGraphQL = '[Cart Templates] Add CartTemplate Items to CartTemplate via GraphQL',
  AddCartTemplateItemsToCartTemplateGraphQLResult = '[Cart Templates] Add CartTemplate Items to CartTemplate via GraphQL Result',
  AddOrderItemsToCartTemplateGraphQL = '[Cart Templates] Add Order Items to CartTemplate via GraphQL',
  AddOrderItemsToCartTemplateGraphQLResult = '[Cart Templates] Add Order Items to CartTemplate via GraphQL Result',
  AddOrderItemsInArrearsToCartTemplateGraphQL = '[Cart Templates] Add Order Items in Arrears to CartTemplate via GraphQL',
  AddOrderItemsInArrearsToCartTemplateGraphQLResult = '[Cart Templates] Add Order Items to CartTemplate via GraphQL Result',
  AddOfferToCartTemplateGraphQL = '[OrderTemplates] Add Offer to CartTemplate via GraphQL',
  AddOfferToCartTemplateGraphQLResult = '[OrderTemplates] Add Offer to CartTemplate via GraphQL Result',
  DeleteCartTemplateGraphQL = '[OrderTemplates] Delete CartTemplate via GraphQL',
  DeleteCartTemplateGraphQLResult = '[OrderTemplates] Delete CartTemplate via GraphQL Result',
  DeleteCartTemplateItemsGraphQL = '[OrderTemplates] Delete CartTemplate Items via GraphQL',
  DeleteCartTemplateItemsGraphQLBatch = '[Cart Templates] Batch delete CartTemplate Items via GraphQL',
  DeleteCartTemplateItemsGraphQLResult = '[Cart Templates] Delete CartTemplate Items via GraphQL Result',
  DeleteCartTemplateItemsGraphQLResultBatch = '[Cart Templates] Batch delete CartTemplate Items via GraphQL Result',
  DuplicateCartTemplateGraphQL = '[OrderTemplates] Duplicate a (Cart) Template via GraphQL',
  DuplicateCartTemplateGraphQLResult = '[OrderTemplates] Duplicate a (Cart) Template via GraphQL Result',
  GetCartTemplateItemProductsGraphQL = '[OrderTemplates] Get products for (Cart) Template items via GraphQL',
  GetCartTemplateItemImagesGraphQL = '[OrderTemplates] Get products images for (Cart) Template items via GraphQL',
  GetCartTemplateItemImagesGraphQLResult = '[OrderTemplates] Get product images for (Cart) Template items via GraphQL Result',
  CreateNewTemplateGql = '[OrderTemplates] Create New Template Gql',
  CreateNewTemplateGqlResult = '[OrderTemplates] Create New Template Gql Result',
  UpdateCartTemplateGraphQL = 'Update a (Cart) Template via GraphQL',
  UpdateCartTemplateGraphQLResult = 'Update a (Cart) Template via GraphQL Result',
  UpdateCartTemplateItemAmountByIdGraphQL = '[OrderTemplates] Update CartTemplate Item Amount By Id',
  UpdateCartTemplateItemAmountByIdGraphQLResult = '[OrderTemplates] Update CartTemplate Item Amount Result',
  GetCartTemplateItemsGraphQL = '[OrderTemplates] Get items for (Cart) Template',
  GetCartTemplateItemsGraphQLResult = '[OrderTemplates] Get items for (Cart) Template Result',
}

export interface CreateNewTemplateGqlAction extends TemplateAction {
  readonly type: TemplateActionTypes.CreateNewTemplateGql
  payload: {
    name: string
  }
}

export const createNewTemplateGql = (name: string): CreateNewTemplateGqlAction => {
  return {
    type: TemplateActionTypes.CreateNewTemplateGql,
    payload: {
      name,
    },
  }
}

export interface CreateNewTemplateGqlResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.CreateNewTemplateGqlResult
  success: boolean
  templateId: string
  errorCode?: string
  errorMessage?: string
}

export const createNewTemplateGqlResult = (
  success: boolean,
  templateId: string,
  errorCode?: string,
  errorMessage?: string
): CreateNewTemplateGqlResultAction => {
  return {
    type: TemplateActionTypes.CreateNewTemplateGqlResult,
    success,
    errorCode,
    errorMessage,
    templateId,
  }
}

export interface AddArticleToTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddArticleToTemplateGraphQL
  templateName: string
  input: CartTemplateAddItemsInput
  omitNotification?: boolean
}

export const addArticleToTemplateGraphQL = (
  templateName: string,
  input: CartTemplateAddItemsInput,
  omitNotification?: boolean
): AddArticleToTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddArticleToTemplateGraphQL,
  templateName,
  input,
  omitNotification,
})

export interface AddArticleToTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddArticleToTemplateGraphQLResult
  cartTemplate?: CartTemplate
  cartTemplateItems?: [CartTemplateItem]
  error?: Error
}

export const AddArticleToTemplateGraphQLResult = (
  cartTemplate?: CartTemplate,
  cartTemplateItems?: [CartTemplateItem],
  error?: Error
): AddArticleToTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddArticleToTemplateGraphQLResult,
  cartTemplate,
  cartTemplateItems,
  error,
})

export interface AddCartTemplatesToCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddCartTemplatesToCartTemplateGraphQL
  input: CartTemplateAddToCartTemplateInput
  originTemplateNames: string[]
  targetTemplateName: string
}

export const addCartTemplatesToCartTemplateGraphQL = (
  input: CartTemplateAddToCartTemplateInput,
  originTemplateNames: string[],
  targetTemplateName: string
): AddCartTemplatesToCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddCartTemplatesToCartTemplateGraphQL,
  input,
  originTemplateNames,
  targetTemplateName,
})

export interface AddCartTemplatesToCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddCartTemplatesToCartTemplateGraphQLResult
  success: boolean
  errorCode?: string
  errorMessage?: string
}

export const addCartTemplatesToCartTemplateGraphQLResult = (
  success: boolean,
  errorCode?: string,
  errorMessage?: string
): AddCartTemplatesToCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddCartTemplatesToCartTemplateGraphQLResult,
  success,
  errorCode,
  errorMessage,
})

export interface AddCartTemplateItemsToCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddCartTemplateItemsToCartTemplateGraphQL
  input: AddCartTemplateItemsToCartTemplateInput
  originTemplateName: string
  selectedItemCount: number
  targetTemplateName: string
}

export const addCartTemplateItemsToCartTemplateGraphQL = (
  input: AddCartTemplateItemsToCartTemplateInput,
  originTemplateName: string,
  selectedItemCount: number,
  targetTemplateName: string
): AddCartTemplateItemsToCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddCartTemplateItemsToCartTemplateGraphQL,
  input,
  originTemplateName,
  selectedItemCount,
  targetTemplateName,
})

export interface AddCartTemplateItemsToCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddCartTemplateItemsToCartTemplateGraphQLResult
  success: boolean
  successItemCount: number
  errorCode?: string
  errorMessage?: string
}

export const addCartTemplateItemsToCartTemplateGraphQLResult = (
  success: boolean,
  successItemCount: number,
  errorCode?: string,
  errorMessage?: string
): AddCartTemplateItemsToCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddCartTemplateItemsToCartTemplateGraphQLResult,
  success,
  successItemCount,
  errorCode,
  errorMessage,
})

export interface AddOrderItemsToCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOrderItemsToCartTemplateGraphQL
  input: AddOrderItemsToCartTemplateInput
  selectedItemCount: number
  cartTemplateName: string
}

export const addOrderItemsToCartTemplateGraphQL = (
  input: AddOrderItemsToCartTemplateInput,
  selectedItemCount: number,
  cartTemplateName: string
): AddOrderItemsToCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddOrderItemsToCartTemplateGraphQL,
  input,
  selectedItemCount,
  cartTemplateName,
})
export const addOrderItemsToCartTemplateGraphQLResult = (
  success: boolean,
  successItemCount: number,
  errorCode?: string,
  errorMessage?: string
): AddOrderItemsToCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddOrderItemsToCartTemplateGraphQLResult,
  success,
  successItemCount,
  errorCode,
  errorMessage,
})

export const addOrderItemsInArrearsToCartTemplateGraphQL = (
  input: AddOrderItemsInArrearsToCartTemplateInput,
  selectedItemCount: number,
  cartTemplateName: string
): AddOrderItemsInArrearsToCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddOrderItemsInArrearsToCartTemplateGraphQL,
  input,
  selectedItemCount,
  cartTemplateName,
})
export const addOrderItemsInArrearsToCartTemplateGraphQLResult = (
  success: boolean,
  successItemCount: number,
  errorCode?: string,
  errorMessage?: string
): AddOrderItemsInArrearsToCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddOrderItemsInArrearsToCartTemplateGraphQLResult,
  success,
  successItemCount,
  errorCode,
  errorMessage,
})

export interface AddOrderItemsInArrearsToCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOrderItemsInArrearsToCartTemplateGraphQL
  input: AddOrderItemsInArrearsToCartTemplateInput
  selectedItemCount: number
  cartTemplateName: string
}
export interface AddOrderItemsToCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOrderItemsToCartTemplateGraphQLResult
  success: boolean
  successItemCount: number
  errorCode?: string
  errorMessage?: string
}

export interface AddOrderItemsInArrearsToCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOrderItemsInArrearsToCartTemplateGraphQLResult
  success: boolean
  successItemCount: number
  errorCode?: string
  errorMessage?: string
}

export interface AddOfferToCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOfferToCartTemplateGraphQL
  input: CartTemplateAddOfferInput
  offerName: string
  selectedItemCount: number
  cartTemplateName: string
}

export const addOfferToCartTemplateGraphQL = (
  input: CartTemplateAddOfferInput,
  offerName: string,
  selectedItemCount: number,
  cartTemplateName: string
): AddOfferToCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.AddOfferToCartTemplateGraphQL,
  input,
  offerName,
  selectedItemCount: selectedItemCount,
  cartTemplateName,
})

export interface AddOfferToCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.AddOfferToCartTemplateGraphQLResult
  success: boolean
  errorCode?: string
  errorMessage?: string
}

export const addOfferToCartTemplateGraphQLResult = (
  success: boolean,
  errorCode?: string,
  errorMessage?: string
): AddOfferToCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.AddOfferToCartTemplateGraphQLResult,
  success,
  errorCode,
  errorMessage,
})

export interface DeleteCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateGraphQL
  templateName: string
  input: CartTemplateDeleteInput
}

export const deleteCartTemplateGraphQL = (
  input: CartTemplateDeleteInput,
  templateName: string
): DeleteCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.DeleteCartTemplateGraphQL,
  input,
  templateName,
})

export interface DeleteCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateGraphQLResult
  success: boolean
  error?: Error
}

export const DeleteCartTemplateGraphQLResult = (
  success: boolean,
  error?: Error
): DeleteCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.DeleteCartTemplateGraphQLResult,
  success,
  error,
})

export interface DeleteCartTemplateItemsGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateItemsGraphQL
  singleDelete: boolean
  emptyCartTemplate: boolean
  cartTemplate: CartTemplateForDetailsPage
  itemIds: string[]
}

export interface DeleteCartTemplateItemsGraphQLActionBatch extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLBatch
  cartTemplateId: string
  cartTemplateName: string
  include: Array<string>
  exclude: Array<string>
  search: MultiSelectionSearch | null
  productTitle?: string
}

//@deprecated Please use deleteCartTemplateItemsGraphQLBatch instead
export const deleteCartTemplateItemsGraphQL = (
  cartTemplate: CartTemplateForDetailsPage,
  itemIds: string[],
  emptyCartTemplate: boolean,
  singleDelete: boolean
): DeleteCartTemplateItemsGraphQLAction => ({
  type: TemplateActionTypes.DeleteCartTemplateItemsGraphQL,
  cartTemplate,
  emptyCartTemplate,
  itemIds,
  singleDelete,
})

export const deleteCartTemplateItemsGraphQLBatch = (
  cartTemplateId: string,
  cartTemplateName: string,
  include: Array<string>,
  exclude: Array<string>,
  search: MultiSelectionSearch | null,
  productTitle?: string
): DeleteCartTemplateItemsGraphQLActionBatch => ({
  type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLBatch,
  cartTemplateId,
  cartTemplateName,
  productTitle,
  include,
  exclude,
  search,
})

export interface DeleteCartTemplateItemsGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLResult
  cartTemplateEmpty: boolean
  success: boolean
  error?: Error
}

export const DeleteCartTemplateItemsGraphQLResult = (
  cartTemplateEmpty: boolean,
  success: boolean,
  error?: Error
): DeleteCartTemplateItemsGraphQLResultAction => ({
  type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLResult,
  cartTemplateEmpty,
  success,
  error,
})
export interface DeleteCartTemplateItemsGraphQLResultActionBatch extends TemplateAction {
  readonly type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLResultBatch
  success: boolean
  successItemCount: number
  errorCode: string
  errorMessage: string
  error?: Error
}

export const DeleteCartTemplateItemsGraphQLResultBatch = (
  success: boolean,
  successItemCount: number,
  errorCode: string,
  errorMessage: string,
  error?: Error
): DeleteCartTemplateItemsGraphQLResultActionBatch => ({
  type: TemplateActionTypes.DeleteCartTemplateItemsGraphQLResultBatch,
  success,
  successItemCount,
  errorCode,
  errorMessage,
  error,
})

export interface DuplicateCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.DuplicateCartTemplateGraphQL
  input: CartTemplateDuplicateInput
  templateName: string
}

export const duplicateCartTemplateGraphQL = (
  input: CartTemplateDuplicateInput,
  templateName: string
): DuplicateCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.DuplicateCartTemplateGraphQL,
  input,
  templateName,
})

export interface DuplicateCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.DuplicateCartTemplateGraphQLResult
  success: boolean
  error?: Error
}

export const DuplicateCartTemplateGraphQLResult = (
  success: boolean,
  error?: Error
): DuplicateCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.DuplicateCartTemplateGraphQLResult,
  success,
  error,
})

export interface GetCartTemplateItemProductsAction extends TemplateAction {
  readonly type: TemplateActionTypes.GetCartTemplateItemProductsGraphQL
  cartTemplate: CartTemplateForDetailsPage
  items: MaybeCompleteCartTemplateDetailsItem[]
  storeIds: string[]
  handleResult: () => void
}

export const getCartTemplateItemProductsGraphQL = (
  cartTemplate: CartTemplateForDetailsPage,
  items: MaybeCompleteCartTemplateDetailsItem[],
  storeIds,
  handleResult
): GetCartTemplateItemProductsAction => ({
  type: TemplateActionTypes.GetCartTemplateItemProductsGraphQL,
  cartTemplate,
  items,
  storeIds,
  handleResult,
})

export interface CartTemplateImagesUpdate {
  id: string
  cartTemplateItems: {
    items: Array<{
      id: string
      product: Pick<ProductAggregate, 'sapId' | 'imageData'>
    }>
  }
}

export type GetCartTemplateItemImagesResponse = {
  getCartTemplatesById: CartTemplateImagesUpdate[]
}

export interface GetCartTemplateItemImagesAction extends TemplateAction {
  readonly type: TemplateActionTypes.GetCartTemplateItemImagesGraphQL
  cartTemplates: CartTemplate[]
}

export const getCartTemplateItemImagesGraphQL = (
  cartTemplates: CartTemplate[]
): GetCartTemplateItemImagesAction => ({
  type: TemplateActionTypes.GetCartTemplateItemImagesGraphQL,
  cartTemplates,
})

export interface GetCartTemplateItemImagesGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.GetCartTemplateItemImagesGraphQLResult
  error?: Error
}

export const GetCartTemplateItemImagesGraphQLResult =
  (): GetCartTemplateItemImagesGraphQLResultAction => ({
    type: TemplateActionTypes.GetCartTemplateItemImagesGraphQLResult,
  })

export type GetCartTemplateItemsResponse = {
  getCartTemplatesById: {
    id: string
    cartTemplateItems: { resultsCount: number; items: CartTemplateItem[] }
  }[]
}

type GetCartTemplateItemOptions = { limit: number; offset: number; searchTerm: string }

export interface GetCartTemplateItemsAction extends TemplateAction {
  readonly type: TemplateActionTypes.GetCartTemplateItemsGraphQL
  cartTemplate: CartTemplateForDetailsPage
  options: GetCartTemplateItemOptions
  shouldResetItems: boolean
  handleResult: () => void
}

export const getCartTemplateItemsGraphQL = (
  cartTemplate: CartTemplateForDetailsPage,
  options: GetCartTemplateItemOptions,
  shouldResetItems: boolean,
  handleResult: () => void
): GetCartTemplateItemsAction => ({
  type: TemplateActionTypes.GetCartTemplateItemsGraphQL,
  cartTemplate,
  options,
  shouldResetItems,
  handleResult,
})

export interface GetCartTemplateItemsGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.GetCartTemplateItemsGraphQLResult
  error?: Error
}

export const GetCartTemplateItemsGraphQLResult = (): GetCartTemplateItemsGraphQLResultAction => ({
  type: TemplateActionTypes.GetCartTemplateItemsGraphQLResult,
})

export interface UpdateCartTemplateGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.UpdateCartTemplateGraphQL
  templateName: string
  input: CartTemplateUpdateInput
}

export const updateCartTemplateGraphQL = (
  input: CartTemplateUpdateInput,
  templateName: string
): UpdateCartTemplateGraphQLAction => ({
  type: TemplateActionTypes.UpdateCartTemplateGraphQL,
  input,
  templateName,
})

export interface UpdateCartTemplateGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.UpdateCartTemplateGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  error?: Error
}

export const UpdateCartTemplateGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  error?: Error
): UpdateCartTemplateGraphQLResultAction => ({
  type: TemplateActionTypes.UpdateCartTemplateGraphQLResult,
  success,
  errorCode,
  errorMessage,
  error,
})

export interface UpdateCartTemplateItemAmountByIdGraphQLAction extends TemplateAction {
  readonly type: TemplateActionTypes.UpdateCartTemplateItemAmountByIdGraphQL
  templateId: string
  id: string
  amount: number
}

export const updateCartTemplateItemAmountByIdGraphQL = (
  templateId: string,
  id: string,
  amount: number
): UpdateCartTemplateItemAmountByIdGraphQLAction => ({
  type: TemplateActionTypes.UpdateCartTemplateItemAmountByIdGraphQL,
  templateId,
  id,
  amount,
})
export interface UpdateCartTemplateItemAmountByIdGraphQLResultAction extends TemplateAction {
  readonly type: TemplateActionTypes.UpdateCartTemplateItemAmountByIdGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  error?: Error
}
export const UpdateCartTemplateItemAmountByIdGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  error?: Error
): UpdateCartTemplateItemAmountByIdGraphQLResultAction => ({
  type: TemplateActionTypes.UpdateCartTemplateItemAmountByIdGraphQLResult,
  success,
  errorCode,
  errorMessage,
  error,
})
