import { Customer } from '@obeta/models/lib/models/CustomerData/Customer'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { CustomerSettings } from '../epics'

interface CustomerAction {
  type: CustomerActionTypes
}

export enum CustomerActionTypes {
  LoadProfile = '[Customer] Load Profile',
  LoadProfileResult = '[Customer] Load Profile Success',
  UpdateCard = '[Customer] Update Customer Card',
  UpdateCardResult = '[Customer] Update Customer Card Result',
  DeleteUserToken = '[User] Delete User Push Token',
  SaveCustomerSettings = '[Customer] Save Customer Settings',
  SaveCustomerSettingsResult = '[Customer] Save Customer Settings Result',
  LoadUserGraphql = '[User]] Load GraphQl User',
  LoadUserGraphqlResult = '[User]] Load GraphQl User Result',
  UpdateUserSettings = '[User] Update User Settings',
}

interface SimpleUserData {
  userId: string
  companyId: string
}

export interface LoadProfileAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadProfile
  user: SimpleUserData
}

export const loadUserProfile = (user: SimpleUserData): LoadProfileAction => ({
  type: CustomerActionTypes.LoadProfile,
  user,
})

export interface LoadProfileResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadProfileResult
  customer?: Customer
  error?: Error
}

export const loadUserProfileResult = (
  customer?: Customer,
  error?: Error
): LoadProfileResultAction => ({
  type: CustomerActionTypes.LoadProfileResult,
  customer,
  error,
})

export interface UpdateCardAction extends CustomerAction {
  readonly type: CustomerActionTypes.UpdateCard
  cardId: string
}

export const updateCard = (cardId: string): UpdateCardAction => ({
  type: CustomerActionTypes.UpdateCard,
  cardId,
})

export interface UpdateCardResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.UpdateCardResult
  error?: Error
}

export const updateCardResult = (error?: Error): UpdateCardResultAction => ({
  type: CustomerActionTypes.UpdateCardResult,
  error,
})

export interface SaveCustomerSettingsAction extends CustomerAction {
  readonly type: CustomerActionTypes.SaveCustomerSettings
  settings: CustomerSettings
}

export const saveCustomerSettings = (settings: CustomerSettings): SaveCustomerSettingsAction => ({
  type: CustomerActionTypes.SaveCustomerSettings,
  settings,
})

export interface SaveCustomerSettingsResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.SaveCustomerSettingsResult
  customer?: Customer
  error?: Error
}

export const saveCustomerSettingsResult = (
  customer?: Customer,
  error?: Error
): SaveCustomerSettingsResultAction => ({
  type: CustomerActionTypes.SaveCustomerSettingsResult,
  customer,
  error,
})

export interface LoadUserGraphQLAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadUserGraphql
}

export const loadUserGraphQl = (): LoadUserGraphQLAction => ({
  type: CustomerActionTypes.LoadUserGraphql,
})

export interface LoadUserGraphQlResultAction extends CustomerAction {
  readonly type: CustomerActionTypes.LoadUserGraphqlResult
  user?: UserV2
  error?: Error
}

export const loadUserGraphQLResult = (
  user?: UserV2,
  error?: Error
): LoadUserGraphQlResultAction => ({
  type: CustomerActionTypes.LoadUserGraphqlResult,
  user,
  error,
})

export interface UpdateUserSettingsAction {
  readonly type: CustomerActionTypes.UpdateUserSettings
  payload: {
    defaultStoreId?: string
    defaultCartId?: string
  }
}

export const updateUserSettings = (
  payload: UpdateUserSettingsAction['payload']
): UpdateUserSettingsAction => ({
  type: CustomerActionTypes.UpdateUserSettings,
  payload,
})

export interface DeleteUserToken extends CustomerAction {
  readonly type: CustomerActionTypes.DeleteUserToken
}
export const deleteUserToken = (): DeleteUserToken => ({
  type: CustomerActionTypes.DeleteUserToken,
})
