import { LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk'
import { Feature, featureToggles } from './index'

class FeatureToggleService {
  private static instance: FeatureToggleService
  private client?: LDClient | null
  private featureToggles: LDFlagSet

  private constructor() {
    this.client = null
    this.featureToggles = featureToggles
  }

  static getInstance(): FeatureToggleService {
    if (!FeatureToggleService.instance) {
      FeatureToggleService.instance = new FeatureToggleService()
    }
    return FeatureToggleService.instance
  }

  setClient(client?: LDClient | null): void {
    if (!client) {
      return
    }
    this.client = client
  }

  getFeatureToggleValue(flagKey: Feature): boolean {
    if (!this.client) return false
    const featureToggles = this.client.allFlags()
    return featureToggles[flagKey]
  }
}

const featureToggleService = FeatureToggleService.getInstance()
export default featureToggleService
