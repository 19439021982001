import { QueryParams } from '../VirtualizedList'

export const DEFAULT_ORDER_DETAILS_IN_ARREARS_QUERY_PARAMS: QueryParams = {
  limit: '20',
  offset: '0',
}

export interface OrderDetailsInArrearsFilters {
  searchTerm: string
}
export const DEFAULT_ORDER_DETAILS_IN_ARREARS_FILTERS: OrderDetailsInArrearsFilters = {
  searchTerm: '',
}

export type OrderDetailsInArrearsURLSearchParams = {
  searchTerm?: string
}
