import { useState } from 'react'
import clsx from 'clsx'
import styles from './AllCategory.module.scss'
import { SubcategoryTypesEnum } from '@obeta/models/lib/models/Category'
import { CategoryElementProps } from './types'
import { Subcategories } from './Subcategories'
import { Divider } from '@mui/material'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

export const ObetaAndDehaSubcategoriesDesktop: React.FC<CategoryElementProps> = (props) => {
  const [divHeight, setDivHeight] = useState<number>(0)
  const {
    mainLevelDataSelected,
    leftOffset,
    handleClickOnSubcategory,
    heightForScrollableSubcategoriesDesktop = 0,
  } = props
  const { dimensions } = useHeaderDimensions()

  return (
    <div
      className={clsx(
        styles.divListWrapper,
        styles.divListWrapperScrollable,
        styles.divListWrapperOrientation,
        styles.addDropBoxShadow2
      )}
      style={{
        left: leftOffset,
        maxHeight: heightForScrollableSubcategoriesDesktop - 200,
        top: `calc(${dimensions.height}px + var(--obeta-safe-area-top-root))`,
      }}
    >
      <div>
        {/* This div is needed in order to have the List element remain unaffected by the max-height set due to scroll behaviour */}
        <Subcategories
          mainLevelDataSelected={mainLevelDataSelected}
          categoryElements={mainLevelDataSelected?.obetaSubCategories}
          listSubheader={'Lagersortimentskatalog'}
          subcategoryType={SubcategoryTypesEnum.Obeta}
          setDivHeight={setDivHeight}
          handleClickOnSubcategory={handleClickOnSubcategory}
        />
      </div>

      <Divider
        orientation={'vertical'}
        className={styles.divider}
        variant={'middle'}
        style={{ height: `${divHeight - 32}px`, maxHeight: `${divHeight - 32}px` }}
      />

      <Subcategories
        mainLevelDataSelected={mainLevelDataSelected}
        categoryElements={mainLevelDataSelected?.dehaSubCategories}
        listSubheader={'Onlinekategorien'}
        handleClickOnSubcategory={handleClickOnSubcategory}
      />
    </div>
  )
}
