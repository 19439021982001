import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Workaround to disable webpack resource import on test run

export const loadI18n = () => {
  const resBundle =
    process.env.NODE_ENV !== 'test'
      ? // eslint-disable-next-line import/no-webpack-loader-syntax
        require('!i18next-resource-store-loader!./translations/index.js')
      : { de: {} }
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        de: {
          translation: resBundle.de,
        },
      },
      lng: 'de',
      fallbackLng: 'de',

      interpolation: {
        escapeValue: false,
      },
    })
}
