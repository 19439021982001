import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { ReactComponent as ObetaLogo } from 'assets/icon/designsystem/obeta_logo.svg'
import { ReactComponent as InstagramLogo } from 'assets/icon/designsystem/instagram.svg'
import { ReactComponent as YoutubeLogo } from 'assets/icon/designsystem/youtube-logotype.svg'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail.svg'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

export const LogoAndIconsAndContactUsTabletLandscape: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box className={styles.boxLogoAndIconsAndContactUsTabletLandscape}>
      <Box className={styles.contentLeft}>
        <Link
          underline="none"
          color="inherit"
          href="#"
          onClick={(e: React.MouseEvent) => history.push('/')}
        >
          <ObetaLogo className={styles.obetaLogoColor}></ObetaLogo>
        </Link>

        {/* TODO: due to the hybrid mode - will be set back */}
        {/*
        <Box className={styles.boxIconFollowedByText}>
          <Link target="_blank" rel="noopener" href={ShopRoutes.Contact}>
            <SmsIcon className={styles.SmsIcon} />
          </Link>
          <Typography variant={'bodyBold'} className={styles.textAfterIcon}>
            <Link
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener"
              href={ShopRoutes.Contact}
            >
              Ansprechpartner
            </Link>
          </Typography>
        </Box>
        */}

        <Box className={styles.boxIconFollowedByText}>
          <Link target="_blank" href="mailto: info@obeta.de">
            {' '}
            <MailIcon className={styles.SmsIcon} />{' '}
          </Link>
          <Typography variant={'bodyBold'} className={styles.textAfterIcon}>
            <Link underline="none" color="inherit" target="_blank" href="mailto: info@obeta.de">
              {t('FOOTER.CONTACT_US')}
            </Link>
          </Typography>
        </Box>
      </Box>

      <Box className={styles.contentRight}>
        <Link rel="noopener" target="_blank" href="https://www.instagram.com/obeta_electro/">
          <InstagramLogo className={clsx(styles.instagramAndYoutubeLogos)} />
        </Link>

        <Link rel="noopener" target="_blank" href="https://www.youtube.com/user/obetaelectro">
          <YoutubeLogo
            className={clsx(styles.instagramAndYoutubeLogos, styles.marginLeftOnefRem)}
          />
        </Link>
      </Box>
    </Box>
  )
}
