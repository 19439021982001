import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { ofType } from 'redux-observable'
import { catchError, defer, Observable, of, retry, switchMap } from 'rxjs'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { GetOpenPositionsQuery, GetOpenPositionsQueryVariables } from '@obeta/schema'
import {
  GetOpenPositionsAction,
  getOpenPositionsResult,
  OpenPositionsActionTypes,
} from '../actions/open-positions-actions'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'

export const GET_OPEN_POSITIONS_QUERY = gql`
  query getOpenPositions {
    getOpenPositions {
      id
      receipt
      receiptType
      isOverdue
      isDiscount
      invoiceFromDate
      invoiceDueDate
      invoiceAmount
      openAmount
      dunningLevel
      discountDate
    }
  }
`

export const getOpenPositionsGraphQl = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetOpenPositionsAction>) =>
    actions$.pipe(
      ofType(OpenPositionsActionTypes.GetOpenPositions),
      switchMap(() => {
        return defer(async () => {
          let items: OpenPosition[] = []
          try {
            const response = await apolloClient.query<
              GetOpenPositionsQuery,
              GetOpenPositionsQueryVariables
            >({
              query: GET_OPEN_POSITIONS_QUERY,
            })
            items = response.data.getOpenPositions ?? []
            const idsToDelete: string[] = []
            const existingData: OpenPosition[] = await db.openpositions.find().exec()
            for (const doc of existingData) {
              if (!items.some((item) => item.id === doc.id)) {
                idsToDelete.push(doc.id)
              }
            }
            await db.openpositions.bulkRemove(idsToDelete)
            const itemsWithInitialPosition = (item, index) => ({ ...item, initialPosition: index })
            await db.openpositions.bulkInsert(items.map(itemsWithInitialPosition))
          } catch (e) {
            return getOpenPositionsResult([])
          }

          return getOpenPositionsResult(items)
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(getOpenPositionsResult([]))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(getOpenPositionsResult([]))
      })
    )
}

export const initOpenPositionsEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [getOpenPositionsGraphQl(db, apolloClient)]
}
