import { UpdateOrderMetaDataInput } from '@obeta/schema'

interface ORderAction {
  type: OrderActionTypes
}

export enum OrderActionTypes {
  UpdateOrderMetaDataGraphQL = '[Order] Update meta data of order - e.g. the name - via GraphQL',
  UpdateOrderMetaDataGraphQLResult = '[Order] Update meta data of order - e.g. the name - via GraphQL Result',
}

export interface UpdateOrderMetaDataGraphQLAction extends ORderAction {
  readonly type: OrderActionTypes.UpdateOrderMetaDataGraphQL
  input: UpdateOrderMetaDataInput
}

export const updateOrderMetaDataGraphQL = (
  input: UpdateOrderMetaDataInput
): UpdateOrderMetaDataGraphQLAction => ({
  type: OrderActionTypes.UpdateOrderMetaDataGraphQL,
  input,
})

export interface UpdateOrderMetaDataGraphQLResultAction extends ORderAction {
  readonly type: OrderActionTypes.UpdateOrderMetaDataGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  orderId: string
  error?: Error
}

export const UpdateOrderMetaDataGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  orderId: string,
  error?: Error
): UpdateOrderMetaDataGraphQLResultAction => ({
  type: OrderActionTypes.UpdateOrderMetaDataGraphQLResult,
  success,
  errorCode,
  errorMessage,
  orderId,
  error,
})
