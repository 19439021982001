import { Message } from '@obeta/models/lib/models/Message'

export enum MessageActionTypes {
  Insert = '[Message] Insert',
  MessagesUpdated = '[Message] Updated',
}

export interface IInsertMessageAction<D> {
  readonly type: MessageActionTypes.Insert
  payload: Message<D> | Message<D>[]
}

export const saveMessage = <D>(message: Message<D> | Message<D>[]): IInsertMessageAction<D> => {
  return {
    type: MessageActionTypes.Insert,
    payload: message,
  }
}

export interface IMessagesUpdatedAction {
  readonly type: MessageActionTypes.MessagesUpdated
}

export const messagesUpdated = () => {
  return {
    type: MessageActionTypes.MessagesUpdated,
  }
}
