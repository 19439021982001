import { useState, useEffect } from 'react'
import Axios, { AxiosObservable } from 'axios-observable'
import { AxiosResponse } from 'axios'
import { filter, forkJoin, map, Observable, of, switchMap, take } from 'rxjs'
import { useRxDB } from 'rxdb-hooks'
import {
  LoadOptions,
  OxomiAttachmentResponse,
  OxomiData,
  OxomiImageResponse,
  OxomiMetaData,
  OxomiOptions,
  OxomiPageResponse,
  OxomiVideoResponse,
} from '@obeta/models/lib/models/Oxomi'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'
import {
  genOxomiAttachmentsUrl,
  genOxomiImagesUrl,
  IParams,
} from '@obeta/utils/lib/genOxomiDataUrl'
export * from '@obeta/models/lib/models/Oxomi'

export const useOxomiData = (oxomi: OxomiOptions | null, options: LoadOptions = {}): OxomiData => {
  const { images = true, video = true, pages = true, attachments = true } = options

  const db = useRxDB()
  const [oxomiData, setOxomiData] = useState<OxomiData>({
    oxomiAttachments: [],
    oxomiImages: [],
    oxomiPages: [],
    oxomiVideos: [],
  })

  useEffect(() => {
    //let obs = []

    let obs: Observable<RxLocalDocumentData<OxomiMetaData>>
    // compatibility with new and old way of passing oxomi token and portaldata
    if (oxomi?.accessToken && oxomi?.portal) {
      obs = of<RxLocalDocumentData<OxomiMetaData>>({
        data: { token: oxomi.accessToken, portal: oxomi.portal },
      } as RxLocalDocumentData<OxomiMetaData>)
    } else {
      obs = db.getLocal$<OxomiMetaData>('oxomi').pipe(
        map((document) =>
          document ? document.toJSON() : ({ data: {} } as RxLocalDocumentData<OxomiMetaData>)
        ),
        filter((doc) => {
          return Boolean(doc.data.token && doc.data.portal)
        }),
        take(1)
      )
    }

    const sub = obs
      .pipe(
        switchMap((oxomiMetaData: RxLocalDocumentData<OxomiMetaData>) => {
          if (!oxomi?.supplierId || !oxomi?.id) {
            return of([])
          }

          const oxomiParams: IParams = {
            // SAP supplierId adds a leading L, which oxomi does not accept or know, so slice it
            supplierId: oxomi.supplierId.startsWith('L')
              ? oxomi.supplierId.slice(1)
              : oxomi.supplierId,
            oxomiId: oxomi.id,
            oxomiMetaData: oxomiMetaData.data,
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obs: AxiosObservable<any>[] = []
          if (images) {
            obs.push(Axios.get(genOxomiImagesUrl(oxomiParams)))
          }
          if (attachments) {
            obs.push(Axios.get(genOxomiAttachmentsUrl(oxomiParams)))
          }
          if (video) {
            const params = `supplierNumber=${oxomi.supplierId}&item=${oxomi.id}&portal=${oxomiMetaData.data.portal}&user=obeta&accessToken=${oxomiMetaData.data.token}`
            obs.push(Axios.get(`https://oxomi.com/service/json/item/videos?${params}`))
          }
          if (pages) {
            const params = `supplierNumber=${oxomi.supplierId}&item=${oxomi.id}&portal=${oxomiMetaData.data.portal}&user=obeta&accessToken=${oxomiMetaData.data.token}`
            obs.push(Axios.get(`https://oxomi.com/service/json/item/pages?${params}`))
          }

          return forkJoin(obs)
        })
      )
      .subscribe((responses) => {
        const newData: OxomiData = {
          oxomiImages: [],
          oxomiAttachments: [],
          oxomiVideos: [],
          oxomiPages: [],
        }
        // Responses contain much more data than interfaces describe.
        // I had a case when object contained data that wasn't described
        // by interface accidently broke react component:
        // react component used props that had same name as undescribed data
        // TODO: make sure that useOxomiData doesn't return more data than its interfaces
        // specify.
        const response = responses[0] as AxiosResponse<OxomiImageResponse>
        if (response && !response.data.error && response.data.imagesFound) {
          const slides = [response.data.mainImage]
          response.data.additionalImages.forEach((image) => {
            slides.push({
              hd: image.hd,
              large: image.large,
              medium: image.medium,
              preview: image.preview,
              thumb: image.thumb,
              type: image.type,
              typeName: image.typeName,
            })
          })
          newData.oxomiImages = slides
        }

        const response2 = responses[1] as AxiosResponse<OxomiAttachmentResponse>
        if (response2 && !response2.data.error && response2.data.attachmentsFound) {
          newData.oxomiAttachments = response2.data.attachments.map((a) => {
            return {
              description: a.description,
              downloadUrl: a.downloadUrl,
              filename: a.filename,
              filesize: a.filesize,
              iconUrl: a.iconUrl,
              type: a.type,
              typeName: a.typeName,
              url: a.url,
            }
          })
        }

        const response3 = responses[2] as AxiosResponse<OxomiVideoResponse>
        if (response3 && !response3.data.error && response3.data.videosFound) {
          newData.oxomiVideos = response3.data.videos.map((v) => {
            return {
              mediumUrl: v.mediumUrl,
              name: v.name,
              previewUrl: v.previewUrl,
              smallUrl: v.smallUrl,
              thumbUrl: v.thumbUrl,
              type: v.type,
              typeName: v.typeName,
              id: v.id,
            }
          })
        } else {
          newData.oxomiVideos = []
        }

        const response4 = responses[3] as AxiosResponse<OxomiPageResponse>
        if (response4 && !response4.data.error && response4.data.pagesFound) {
          newData.oxomiPages = response4.data.pages.map((p) => {
            return {
              archiveLabel: p.archiveLabel,
              brand: p.brand,
              brandIcon: p.brandIcon,
              categories: p.categories,
              category: p.category,
              downloadUrl: p.downloadUrl,
              internalName: p.internalName,
              id: p.id,
              mediumUrl: p.mediumUrl,
              name: p.name,
              outdated: p.outdated,
              page: p.page,
              pdfAvailable: p.pdfAvailable,
              previewUrl: p.previewUrl,
              tags: p.tags,
              usePageNum: p.usePageNum,
            }
          })
        }

        setOxomiData(newData)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [
    oxomi?.id,
    oxomi?.supplierId,
    oxomi?.accessToken,
    oxomi?.portal,
    images,
    video,
    pages,
    attachments,
    db,
  ])

  return oxomiData
}
