import { useEffect } from 'react'
import { Grow, IconButton, Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '@obeta/assets/icon/designsystem/close.svg'
import { useDisableBodyScroll } from '@obeta/data/lib/hooks/useDisableBodyScroll'
import styles from './Modal.module.scss'

interface IClasses {
  content?: string
}

export interface ModalProps extends MuiModalProps {
  isFullScreen?: boolean
  marginThreshold?: 'xs' | 'lg'
  fitContent?: boolean
  onClose?: () => void
  onOpen?: () => void
  classNames?: IClasses
  disableBodyScroll?: boolean
}

export const ModalCloseIcon: React.FC<{ className?: string; onClose?: () => void }> = (props) => {
  const onCloseHandler = () => props.onClose?.()

  return (
    <IconButton onClick={onCloseHandler} className={clsx(styles.close, props.className)}>
      <CloseIcon />
    </IconButton>
  )
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    isFullScreen,
    disableBodyScroll = false,
    marginThreshold = 'xs',
    fitContent,
    classNames,
    onOpen,
    ...modalProps
  } = props

  useEffect(() => {
    if (modalProps.open && onOpen) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.open])

  useDisableBodyScroll(!!(modalProps.open && (isFullScreen || disableBodyScroll)))

  return (
    <MuiModal keepMounted={false} {...modalProps}>
      <div
        className={clsx(
          styles.modal,
          {
            [styles.fitContent]: fitContent,
            [styles.fullscreen]: isFullScreen,
            [styles.thresholdXs]: marginThreshold === 'xs',
            [styles.thresholdLg]: marginThreshold === 'lg',
          },
          classNames?.content
        )}
      >
        <Grow in={modalProps.open}>{children}</Grow>
      </div>
    </MuiModal>
  )
}
