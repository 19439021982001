import { getLocale } from '../getLocale'
import { defaultLocale } from '../variables'
import { normalizeNumber } from './normalizeNumber'
import { parseUnformattedNum } from './../parse-num'

export const normalizePrice = (
  price: string | number = '-',
  unit: string | undefined | false = undefined,
  locale = defaultLocale
) => {
  const p = normalizeNumber(price, 2, locale)

  if (unit === false) {
    return p
  }

  if (!unit) {
    return `${p} €` // TODO: I guess it should be tmp.
  }

  return `${p} ${unit}`
}

export const normalizePriceWithBindedLocale = ((): typeof normalizePrice => {
  const locale = getLocale()

  return (...args) => {
    return normalizePrice(args[0], args[1], locale)
  }
})()

export const formatListPriceForFilterAsNumber = (price: number | string) => {
  const formatter = new Intl.NumberFormat(defaultLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  let p
  if (typeof price === 'number') {
    p = price
  } else {
    if (price === '') {
      return 0
    }
    p = parseUnformattedNum(price)
  }
  return formatter.format(p)
}
