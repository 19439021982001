import React from 'react'
import clsx from 'clsx'
import { Popover, Theme, Typography, useMediaQuery } from '@mui/material'
import { ReactComponent as ChevronRight } from '@obeta/assets/icon/chevron_right20x20.svg'
import { ReactComponent as ShoppingCart } from '@obeta/assets/icon/designsystem/shopping_cart.svg'
import styles from './CartsMenu.module.scss'
import { CartListItem } from './components/CartListItem'
import { CartContentItem } from './components/CartContentItem'
import { Scrollbar } from '../scrollbar/Scrollbar'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { MenuWrapper } from '../menus'
import { PrimaryButton } from '../custom-button/CustomButton'
import { useCustomItemTitles } from '@obeta/utils/lib/cart/useCustomItemTitles'
import { geCustomProductsGraphQL } from '@obeta/data/lib/actions/cart-actions'
import { useEntityMetaData } from '@obeta/data/lib/hooks/useEntityMetaData'
import { useMarkedCarts } from '@obeta/data/lib/hooks/useMarkedCarts'
import { useSelectShoppingCart } from '@obeta/data/lib/hooks/useSelectShoppingCart'

interface ICartsMenu {
  open: boolean
  onClose: VoidFunction
  anchorEl: HTMLElement | null
  transitionDuration?: number
}

export const CartsMenu: React.FC<ICartsMenu> = (props) => {
  const { open, onClose, anchorEl, transitionDuration } = props
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.between('xs', 'sm'))
  const { cartsCount, activeCartsCount, activeCarts, activeCartId, activeCart } =
    useShoppingCartsV2()
  const onSetActiveCart = useSelectShoppingCart()
  const history = useHistory()
  const { markedCartsIds } = useMarkedCarts()
  const { isUpdatingCustomTitles } = useEntityMetaData('cartsv2')
  useCustomItemTitles({
    action: geCustomProductsGraphQL,
    cart: activeCart,
    items: activeCart?.items,
  })

  const handleCartClick = (id: string) => {
    if (id !== activeCartId) {
      onSetActiveCart(id)
    }
  }
  const handleCartsListOpen = () => {
    history.push(`/shopping-cart-list`)
    onClose()
  }

  const handleOpenActiveCart = () => {
    history.push(`/shopping-cart-details/${activeCartId}`)
    onClose()
  }

  const OpenActiveCartComp = (
    <div className={styles['carts-menu-open-active-cart-btn']}>
      <PrimaryButton
        disabled={!activeCart?.items?.length}
        onClick={handleOpenActiveCart}
        leftIcon={<ShoppingCart />}
      >
        {activeCart?.name} anzeigen
      </PrimaryButton>
    </div>
  )

  const getMenuContent = () => (
    <div
      className={clsx(styles['carts-menu-wrapper'], {
        [styles['carts-menu-wrapper-mobile']]: isMobile,
      })}
    >
      <div
        className={clsx(styles['carts-menu-header'], {
          [styles['carts-menu-header-mobile']]: isMobile,
        })}
      >
        <Typography variant="h4" className={styles['text-color-dark-gray']}>
          {cartsCount} Warenkörbe ({activeCartsCount} befüllt)
        </Typography>
        <div
          className={clsx(styles['carts-menu-open-carts-button'], {
            [styles['carts-menu-open-carts-button-mobile']]: isMobile,
          })}
          onClick={handleCartsListOpen}
        >
          <Typography variant="bodyBold" className={styles['text-color-dark-gray']}>
            Alle anzeigen
          </Typography>
          <ChevronRight className={styles['chevron-icon']} />
        </div>
      </div>
      <div className={styles['carts-menu-active-block-header']}>
        <Typography variant="body" className={styles['text-color-dark-gray']}>
          Aktiver Warenkorb
        </Typography>
      </div>
      <div className={styles['carts-menu-carts-block']}>
        <div
          className={clsx(styles['carts-menu-carts-items'], {
            [styles['carts-menu-carts-items-mobile']]: isMobile,
          })}
        >
          <Scrollbar className={styles['carts-menu-scrollbar']}>
            {activeCarts.map((cart) => (
              <div key={cart.id} onClick={() => handleCartClick(cart.id)}>
                <CartListItem
                  cart={cart}
                  isActive={activeCartId === cart.id}
                  isMarked={markedCartsIds.has(cart.id)}
                />
              </div>
            ))}
          </Scrollbar>
          {isMobile && OpenActiveCartComp}
        </div>
        {!isMobile && (
          <div className={styles['carts-menu-cart-content']}>
            <Scrollbar className={styles['carts-menu-scrollbar']}>
              {activeCart?.items.map((item) => (
                <CartContentItem isLoading={isUpdatingCustomTitles} key={item.id} item={item} />
              ))}
            </Scrollbar>
            {OpenActiveCartComp}
          </div>
        )}
      </div>
    </div>
  )

  if (isMobile) {
    return (
      <MenuWrapper show={open} onMenuClosed={onClose} className={styles.toolsListOverlay}>
        {getMenuContent()}
      </MenuWrapper>
    )
  }

  return (
    <Popover
      transitionDuration={transitionDuration}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '> .MuiBackdrop-root': {
          background: '#3F3F3E',
          opacity: '.2 !important',
        },
      }}
    >
      {getMenuContent()}
    </Popover>
  )
}
