import { Capacitor } from '@capacitor/core'
import { getRxStorageSQLite, getSQLiteBasicsCapacitor } from 'rxdb-premium/plugins/storage-sqlite'
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite'
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import { RxStorage } from 'rxdb'
import { Adapter } from '@obeta/models/lib/models'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

const defineSqlLiteStorage = () => {
  const sqlite = new SQLiteConnection(CapacitorSQLite)
  const basics = getSQLiteBasicsCapacitor(sqlite, Capacitor)

  basics.journalMode = ''
  basics.open = async (dbName: string) => {
    const ret = await sqlite.checkConnectionsConsistency()
    const isConn = (await sqlite.isConnection(dbName, false)).result
    let db: SQLiteDBConnection
    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection(dbName, false)
    } else {
      db = await sqlite.createConnection(dbName, false, 'no-encryption', 1, false)
    }

    await db.open()
    return db
  }
  return getRxStorageSQLite({
    /**
     * Different runtimes have different interfaces to SQLite.
     * For example in node.js we have a callback API,
     * while in capacitor sqlite we have Promises.
     * So we need a helper object that is capable of doing the basic
     * sqlite operations.
     */
    sqliteBasics: basics,
  })
}

/* We have to keep these conditions to set correct adapter exactly in the next way:
if ( (process.env.NEXT_PUBLIC_ADAPTER_NAME || process.env.REACT_APP_ADAPTER_NAME) === ${something} ) {}

because it allows us to get tree shaking working. In opposite way, e.g.
  ```const adapter = (process.env.NEXT_PUBLIC_ADAPTER_NAME || process.env.REACT_APP_ADAPTER_NAME)
     if (adapter === ${something}) {}
     else if (adapter === ${something2}
     else {} ```
tree shaking won`t be working and excess packages (adapters in this case) will be loaded into our bundle

 correct way to use env variables is 'as is', it means `process.env.VAR === 'something'`
 because in this way webpack will understand where is the useful code and where is useless dead code and will
 remove it from the bundle
* */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRxDbStorage = (forcedStorageAdapter?: () => RxStorage<any, any>) => {
  let storage
  if (forcedStorageAdapter) {
    storage = forcedStorageAdapter()
  } else if (
    (process.env.NEXT_PUBLIC_ADAPTER_NAME || process.env.REACT_APP_ADAPTER_NAME) ===
    Adapter.indexeddb
  ) {
    storage = getRxStorageIndexedDB()
  } else if (
    (process.env.NEXT_PUBLIC_ADAPTER_NAME || process.env.REACT_APP_ADAPTER_NAME) ===
    Adapter.capacitorsqlite
  ) {
    storage = defineSqlLiteStorage()
  } else {
    /**
     *  Just in case env not specified
     */
    const isWeb = isPlatform('web')

    if (isWeb) {
      storage = getRxStorageIndexedDB()
    } else {
      storage = defineSqlLiteStorage()
    }
  }
  return storage
}
