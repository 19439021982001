import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { useEntities } from './useEntities'

export const useSessionCart = (): undefined | ShoppingCartV2 => {
  const sessionContext = getSessionContext()
  const shoppingCarts = useEntities<ShoppingCartV2>('cartsv2')
  if (!sessionContext || shoppingCarts.length === 0) return
  // If there is an active session, there is only one available cart
  return shoppingCarts[0]
}
