import { useRxDB } from 'rxdb-hooks'
import { useState, useEffect } from 'react'
import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'

export const useActiveArticleSearchParams = (
  currentSearchParams?: ArticleSearchParamsWithId | null
): ArticleSearchParamsWithId | null => {
  const [lastSearch, setLastSearch] = useState<ArticleSearchParamsWithId | null>(null)
  const db = useRxDB()

  useEffect(() => {
    let isValid = true
    const getLastSearch = async () => {
      const doc = await db.getLocal<ArticleSearchParamsWithId>('lastSearch')
      if (doc && isValid) {
        const data = doc.toMutableJSON()
        setLastSearch(data.data)
      }
    }
    getLastSearch()
    return () => {
      isValid = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchParams])

  return lastSearch
}
