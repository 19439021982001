import React, { useState } from 'react'
import { IBottomSectionProps } from '../../types'
import styles from './BottomSection.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { NavSection } from './NavSection'
import { SubCategoryLists } from '../../../categories-dropdown/SubCategoryList'

export const BottomSection: React.FC<IBottomSectionProps> = (props) => {
  const { isLoggedIn } = props
  const { mobile } = useBreakpoints()
  const [dehaCatalogLevel0, setDehaCatalogLevel0] = useState<boolean>(false)

  if (mobile) {
    return (
      <>
        <NavSection
          setDehaCatalogLevel0={setDehaCatalogLevel0}
          dehaCatalogLevel0={dehaCatalogLevel0}
          isLoggedIn={isLoggedIn}
        />
        <SubCategoryLists
          setDehaCatalogLevel0={setDehaCatalogLevel0}
          dehaCatalogLevel0={dehaCatalogLevel0}
          categoryDivWidth={420}
        />
      </>
    )
  }

  return (
    <div className={styles.bottomSectionWrapper}>
      <NavSection
        setDehaCatalogLevel0={setDehaCatalogLevel0}
        dehaCatalogLevel0={dehaCatalogLevel0}
        isLoggedIn={isLoggedIn}
      />
      <SubCategoryLists
        setDehaCatalogLevel0={setDehaCatalogLevel0}
        dehaCatalogLevel0={dehaCatalogLevel0}
        categoryDivWidth={420}
      />
    </div>
  )
}
