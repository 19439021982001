import React from 'react'
import { BaseNotificationRow } from './BaseNotificationLayout'
import styles from './Notification.module.scss'

interface Props {
  rows: React.ReactNode[]
}

/** Only use as children of {@link Notification}
 * Notification component to render multiple elements given in an array in separate rows
 */
export const NotificationMultiRow: React.FC<Props> = ({ rows }) => {
  return (
    <div className={styles.multirowContainer}>
      {rows.map((row, index) => (
        <BaseNotificationRow key={row?.toString()}>{row}</BaseNotificationRow>
      ))}
    </div>
  )
}
