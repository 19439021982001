import { ShopDomains } from './domains'
import { ReactComponent as ObetaFullLogo } from 'assets/icon/obeta-full-logo.svg'
import { ReactComponent as EldisFullLogo } from 'assets/icon/eldis-full-logo.svg'
import { ReactComponent as HaeuslerFullLogo } from 'assets/icon/haeusler-full-logo.svg'

export const ShopFullLogoByDomain: Record<ShopDomains, React.FC<React.SVGProps<SVGSVGElement>>> = {
  [ShopDomains.Obeta]: ObetaFullLogo,
  [ShopDomains.Eldis]: EldisFullLogo,
  [ShopDomains.Haeusler]: HaeuslerFullLogo,
}

export const ShopEmailByDomain: Record<ShopDomains, string> = {
  [ShopDomains.Obeta]: 'info@obeta.de',
  [ShopDomains.Eldis]: 'info@eldis.de',
  [ShopDomains.Haeusler]: 'info@heinrich-haeusler.de',
}

export const ShopCopyrightByDomain: Record<ShopDomains, string> = {
  [ShopDomains.Obeta]: 'Copyright © Oskar Böttcher GmbH & Co. KG',
  [ShopDomains.Eldis]: 'Copyright © 2022 eldis electro distributor GmbH',
  [ShopDomains.Haeusler]: 'Copyright © 2022 Heinrich Häusler GmbH',
}

export const ShopNameByDomain: Record<ShopDomains, string> = {
  [ShopDomains.Obeta]: 'Obeta',
  [ShopDomains.Eldis]: 'Eldis',
  [ShopDomains.Haeusler]: 'Häusler',
}
