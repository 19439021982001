import { useRxDB } from 'rxdb-hooks'
import { useUserV2 } from './useUserV2'
import { createContext, useContext, useEffect, useState } from 'react'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { useAppActions } from './useAppActions'
import { Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { UserV2Data } from '@obeta/models/lib/models/Users/UserV2'
import { RxLocalDocumentData } from 'rxdb/dist/types/types'
import { getSessionContext } from '@obeta/utils/lib/session-context'

export interface CustomerMetaData {
  isFetching: boolean
  mustRefetch: boolean
  isLoggedIn: boolean
  lastUpdated: number | null
  companyId: string
  userId?: string
}

type Nullable<T> = T | null
type MetaData = Nullable<CustomerMetaData>

export const UserV2DataContextDefaultValues = {
  user: null,
  companyId: undefined,
  userId: undefined,
  permissions: undefined,
  isLoggedIn: false,
  tokens: null,
  allTokensExist: false,
  metaDataReady: false,
  isTokensFetched: false,
  algoliaUserToken: undefined,
}

export const UserV2DataContext = createContext<UserV2Data>(UserV2DataContextDefaultValues)

export const useUserDataV2 = (): UserV2Data => {
  return useContext(UserV2DataContext)
}

export const useInitialUserDataV2 = (): UserV2Data => {
  const db = useRxDB()
  const user = useUserV2() //ToDo calling the deprecated hook here is ok, should be inlined here anyhow

  const [userMetaDocs, setUserMetaDocs] = useState<RxLocalDocument<
    RxDatabase,
    CustomerMetaData
  > | null>(null)
  const [documentsFetched, setDocumentsFetched] = useState<boolean>(false)
  // flag uses to get info if tokens has already been requested or not
  // (it doesn't matter if tokens are exist or not, we just interested in fact if tokens has been requested)
  const [isTokensFetched, setIsTokenFetched] = useState<boolean>(false)

  const [latestRevCustomerMetaData, setLatestRevCustomerMetaData] = useState<MetaData>(null)
  const [tokens, setTokens] = useState<Nullable<Tokens>>(null)

  const appActions = useAppActions()

  useEffect(() => {
    const getUserDocument = async () => {
      const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
      setUserMetaDocs(userMeta)
      setDocumentsFetched(true)
    }
    getUserDocument()
  }, [db])

  useEffect(() => {
    if (userMetaDocs) {
      const subCustomerMetaData = userMetaDocs.$.subscribe(
        (doc: RxLocalDocumentData<CustomerMetaData>) => {
          setLatestRevCustomerMetaData(doc.data)
        }
      )

      return () => {
        subCustomerMetaData.unsubscribe()
        subCustomerMetaData.unsubscribe()
      }
    }
  }, [userMetaDocs])

  useEffect(() => {
    const tokensSubscription = appActions.tokens$.subscribe((tkns) => {
      setTokens(tkns)
      setIsTokenFetched(true)
    })
    return () => {
      tokensSubscription.unsubscribe()
    }
  }, [appActions.tokens$])

  const permissions = user?.permissions

  let allTokensExist =
    typeof tokens?.accessToken === 'string' && typeof tokens?.refreshToken === 'string'
  let isLoggedIn = allTokensExist

  /**
   * Currently in support we do not issue refreshTokens, so the isLoggedIn check will fail, although
   * we have a valid accessToken
   * TODO: This check should be improved
   */
  // @TODO: What is mobile-support.obeta.io?
  // @TODO: replace with envs?

  if (
    ['mobile-support.obeta.io', 'shop-support.obeta.io', 'staging-shop-support.obeta.io'].indexOf(
      window.location.host
    ) !== -1
  ) {
    allTokensExist = typeof tokens?.accessToken === 'string'
    isLoggedIn = allTokensExist
  }

  const sessionContext = getSessionContext()
  let isUserWithSessionBasedCart

  // TODO add condition when we have a user permission that allows an IDS user to have only a single session cart
  if (sessionContext && sessionContext.type === 'oci') {
    isUserWithSessionBasedCart = true
  }

  return {
    user,
    companyId: latestRevCustomerMetaData?.companyId,
    userId: latestRevCustomerMetaData?.userId,
    fullUserId:
      latestRevCustomerMetaData?.companyId && latestRevCustomerMetaData?.userId
        ? `${latestRevCustomerMetaData?.companyId}-${latestRevCustomerMetaData?.userId}`
        : undefined,
    permissions,
    isLoggedIn,
    tokens,
    allTokensExist,
    metaDataReady: documentsFetched,
    isTokensFetched,
    algoliaUserToken: user?.algoliaUserToken,
    isUserWithSessionBasedCart: isUserWithSessionBasedCart,
  }
}
