import React, { ReactNode, useState, useEffect } from 'react'
import clsx from 'clsx'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import { Typography } from '@mui/material'
import styles from './genericToast.module.scss'
import { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar'

export const GenericToast: React.FC<
  Omit<SnackbarProps, 'message'> & {
    header?: string
    message: string | ReactNode
    className?: string
  }
> = (props) => {
  const { open, message, onClose, header, className, ...rest } = props
  const [openLocal, setOpenLocal] = useState(props.open || false)

  useEffect(() => {
    setOpenLocal(!!props.open)
  }, [props.open])

  const onCloseCallback = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: React.SyntheticEvent<any> | Event,
    reason: SnackbarCloseReason
  ) => {
    setOpenLocal(false)
    onClose?.(event, reason)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      data-component-type="toast"
      onClose={onCloseCallback}
      open={openLocal}
      classes={{
        root: clsx(styles.root, className),
      }}
      message={
        <div className={styles.messageWrapper}>
          {props.header && (
            <Typography className={styles.header} variant="body">
              {header}
            </Typography>
          )}
          {typeof message === 'string' ? (
            <Typography className={styles.message} variant="body">
              {message}
            </Typography>
          ) : (
            message
          )}
        </div>
      }
      {...rest}
    />
  )
}
