import { gql } from '@apollo/client'

export const CART_TEMPLATE_LIST_ITEMS = gql`
  query getCartTemplateListItems(
    $input: GetCartTemplatesByIdInput!
    $limit: Float!
    $offset: Float
    $searchTerm: String
  ) {
    getCartTemplatesById(input: $input) {
      id
      cartTemplateItems(limit: $limit, offset: $offset, searchTerm: $searchTerm) {
        resultsCount
        items {
          id
          templateId
          productId
          amount
          updatedAt
          sortDate
          product {
            dehaId
            sapId
            title
            images {
              url
              width
            }
            imageData {
              images {
                large
              }
            }
            supplierImageData {
              large
            }
            oxomiId
            supplierId
            isTopseller
            isCutProduct
            isSendable
            minimumAmount
            type
            isCurrentlyNotAvailable
            unit
          }
        }
      }
    }
  }
`
