import React, { useReducer } from 'react'

enum ActionKind {
  INCREASE = 'SET_CATEGORIES_REFS',
}

interface ActionType {
  type: ActionKind
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

interface ICategoryRefs {
  categoryElementWrapperDivoffsetLeft: number
  bottomHeaderSectionDivoffsetTop: number
  bottomHeaderSectionDivoffsetHeight: number
  divMobileBottomHeaderSectionoffsetTop: number
  divMobileBottomHeaderSectionoffsetHeight: number
}

interface InitialStateType {
  categoryRefs: ICategoryRefs
}
interface IContext {
  state: InitialStateType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchRef: React.Dispatch<any>
}

const initialState = {
  categoryRefs: {
    categoryElementWrapperDivoffsetLeft: 0,
    bottomHeaderSectionDivoffsetTop: 0,
    bottomHeaderSectionDivoffsetHeight: 0,
    divMobileBottomHeaderSectionoffsetTop: 0,
    divMobileBottomHeaderSectionoffsetHeight: 0,
  },
}

const categoryReducer = (state, action: ActionType) => {
  const { type, payload } = action
  switch (type) {
    case 'SET_CATEGORIES_REFS':
      return {
        ...state,
        categoryRefs: payload,
      }
    default:
      return
  }
}

export const CategoryContext = React.createContext<IContext>({
  state: initialState,
  dispatchRef: () => null,
})

export const useCategoriesState = () => {
  const [state, dispatchRef] = useReducer(categoryReducer, initialState)
  return { state, dispatchRef }
}
