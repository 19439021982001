import { useEffect, useState } from 'react'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { PluginListenerHandle } from '@capacitor/core'

export const useKeyboardHeight = (withAutoScrollToView = false) => {
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  useEffect(() => {
    let showListener: PluginListenerHandle | undefined
    let hideListener: PluginListenerHandle | undefined

    if (!isPlatform('web')) {
      const runKeyboardListeners = async () => {
        showListener = await Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
          setKeyboardHeight(info.keyboardHeight)
          if (withAutoScrollToView === true) {
            setTimeout(() => {
              //skip one renderframe so that the consuming view can add scrollpaddings if needed
              document.activeElement?.scrollIntoView({ block: 'center' })
            }, 0)
          }
        })
        hideListener = await Keyboard.addListener('keyboardDidHide', () => setKeyboardHeight(0))
      }

      runKeyboardListeners()

      return () => {
        showListener?.remove()
        hideListener?.remove()
      }
    }
  }, [setKeyboardHeight, withAutoScrollToView])

  return keyboardHeight
}
