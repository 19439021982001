import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { useShoppingCartById } from '@obeta/data/lib/hooks/useShoppingCartById'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ReactComponent as HomeIcon } from 'assets/icon/designsystem/home_work.svg'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { ShoppingCartV2, StoreV2 } from '@obeta/models/lib/models'
import { useEntities } from '@obeta/data/lib/hooks'
import styles from './AddressInfo.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { ShippingGroup } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { useAddressInfo } from './useAddressInfo'
import { useAddressManagementModal } from './useAddressManagementModal'
import { AddressInfoModal } from './AddressInfoModal'

const getAddressLine = ({
  desktop,
  address,
}: {
  address: { zipCode: string; city: string }
  desktop?: boolean
}) => {
  if (!desktop) return address.zipCode
  return `${address.zipCode} ${address.city}`
}

const AddressInfoLayout: FC = ({ children }) => {
  const { t } = useTranslation()
  const { user } = useUserDataV2()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '')
  const { showAddressManagementModal, closeModal, userAddresses, openModal, canWriteAddress } =
    useAddressManagementModal()
  const { shippingGroup } = useAddressInfo()
  if (!cart || !shippingGroup) return null
  return (
    <>
      <Stack
        className={clsx(styles.layout, canWriteAddress && styles.interactive)}
        onClick={() => {
          if (canWriteAddress) {
            openModal()
          }
        }}
      >
        <Typography>{t('HEADER.DELIVERY_ADDRESS_INFO.DELIVERY_TO')}</Typography>
        <div className={styles.addressLine}>
          <HomeIcon />
          <Typography variant="bodyBold">{children}</Typography>
        </div>
      </Stack>
      {showAddressManagementModal && (
        <AddressInfoModal
          open={showAddressManagementModal}
          close={closeModal}
          userAddresses={userAddresses}
          shippingGroup={shippingGroup}
        />
      )}
    </>
  )
}

const DeliveryInfo: FC<{ cart: ShoppingCartV2 }> = ({ cart }) => {
  const { desktop } = useBreakpoints()
  const deliveryAddressText = getAddressLine({
    address: cart.shippingData.deliveryAddress,
    desktop,
  })

  return <AddressInfoLayout>{deliveryAddressText}</AddressInfoLayout>
}

const PickupInfo: FC<{ cart: ShoppingCartV2 }> = ({ cart }) => {
  const { desktop } = useBreakpoints()
  const userV2 = useUserV2()
  const stores = useEntities<StoreV2>('storesv2')
  const selectedStoreId = cart?.shippingData.storeId ?? userV2?.settings?.defaultStoreId

  if (!selectedStoreId) return null

  const store = stores.find((store) => store.id === selectedStoreId)
  if (!store) return null

  const pickupAddressText = getAddressLine({ address: store.address, desktop })
  return <AddressInfoLayout>{pickupAddressText}</AddressInfoLayout>
}

export const AddressInfo: FC = () => {
  const { user } = useUserDataV2()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '')
  const { showAddressInfo, shippingGroup } = useAddressInfo()

  if (!showAddressInfo || !shippingGroup || !cart) return null

  if (shippingGroup === ShippingGroup.Delivery) return <DeliveryInfo cart={cart} />
  if (shippingGroup === ShippingGroup.Pickup) return <PickupInfo cart={cart} />
  return null
}
